<app-details-loader
  *ngIf="loadFlag"
  [Height]="'70vh'"
  [LoaderMarginTop]="'19%'"
></app-details-loader>
<div class="" *ngIf="!loadFlag">
  <div class="row">
    <div class="col-12">
      <h3 class="headings">Analytics</h3>
    </div>
    <div class="col-12 pt-3 no-padding daySet">
      <div
        *ngFor="let dayConfig of dateSet"
        [class]="daySetActive === dayConfig.text ? 'active' : ''"
        (click)="dateSetFun(dayConfig)"
      >
        {{ dayConfig.text }}
      </div>
    </div>
  </div>
  <div class="home_body">
    <div class="row my-xl-3 my-lg-3 my-md-3 my-sm-3 mob_date">
      <div class="col-xl-9 col-lg-9 col-md-7 col-sm-9 col-8 date_mob">
        <span class="sub-text-dark">
          <input
            bsDatepicker
            [bsConfig]="{
              dateInputFormat: 'MMM DD, YYYY',
              showWeekNumbers: false
            }"
            type="text"
            class="textboxAslabel"
            [maxDate]="toDate"
            readonly
            [(ngModel)]="fromDate"
            (onHidden)="setDateFrom()"
          />
          <i class="fa fa-arrow-right"></i
          ><!--{{!toDate?'-&#45;&#45;' :toDate | date: 'MMM DD, YYYY'}}-->
          <input
            type="text"
            [maxDate]="today"
            bsDatepicker
            class="textboxAslabel"
            (onHidden)="setDateFrom()"
            [bsConfig]="{
              dateInputFormat: 'MMM DD, YYYY',
              showWeekNumbers: false
            }"
            readonly
            [(ngModel)]="toDate"
          />
        </span>
      </div>
      <div
        class="col-xl-3 col-lg-3 col-md-5 col-sm-3 col-4 text-right col-xs-12 no-padding days_report"
      >
        <span
          *ngFor="let unit of dateUnit"
          [class]="unit.criteria === criteria ? 'day-active' : ''"
          (click)="criteria = unit.criteria; setUrls()"
          >{{ unit.text }}</span
        >
      </div>
    </div>
    <div class="row pt-5">
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Total Sales</p>
          <p class="mat-card_content">
            {{
              !totalSales
                ? "$" + 0.0
                : (totalSales / 100 | currency : "USD" : "symbol" : "1.2-2")
            }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Total Refunds</p>
          <p class="mat-card_content">
            {{
              !totalRefunds
                ? "$" + 0.0
                : (totalRefunds / 100 | currency : "USD" : "symbol" : "1.2-2")
            }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Net Sales</p>
          <p class="mat-card_content">
            {{
              !netSales
                ? "$" + 0.0
                : (netSales / 100 | currency : "USD" : "symbol" : "1.2-2")
            }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title avg_per_sale">Average Sales Per Transaction</p>
          <p class="mat-card_content">
            {{
              !avg_revenue
                ? "$" + 0.0
                : (avg_revenue / 100 | currency : "USD" : "symbol" : "1.2-2")
            }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Total Transactions</p>
          <p class="mat-card_content">
            {{ total_transactions ? (total_transactions | number) : 0 }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Refund %</p>
          <p class="mat-card_content">
            {{ refundPercentage ? refundPercentage + "%" : "0%" }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Total Dispute</p>
          <p class="mat-card_content">{{ !t_disputes ? 0 : t_disputes }}</p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Dispute %</p>
          <p class="mat-card_content">
            {{ !p_disputes ? 0.0 + "%" : p_disputes + "%" }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title avg_per_sale">Total Disputes Volume</p>
          <p class="mat-card_content">
            {{
              !tv_disputes
                ? "$" + 0.0
                : (tv_disputes / 100 | currency : "USD" : "symbol" : "1.2-2")
            }}
          </p>
        </div>
      </div>
      <div class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Disputes Volume %</p>
          <p class="mat-card_content">
            {{ !pv_disputes ? 0.0 + "%" : pv_disputes + "%" }}
          </p>
        </div>
      </div>
      <div *ngIf="t_sb_merchants" class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title avg_per_sale">Total Active Split Campaigns</p>
          <p class="mat-card_content">
            {{
              !t_sb_merchants
                ?  + 0.0
                : (t_sb_merchants ")
            }}
          </p>
        </div>
      </div>
      <div *ngIf="own_payees" class="col col-12 col-sm-6 col-md-4 col-lg-3 mb-2 ">
        <div class="mat-card p-3 align-items-stretch">
          <p class="h_title">Total Payees</p>
          <p class="mat-card_content">
            {{ !own_payees ? 0.0 : own_payees }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9 col-lg-7 col-md-12 col-sm-12 col-12 home_graph mt-3">
        <!--      <p *ngIf="this.barchartres.length === 0" class="bar_norecord">No records found.</p>-->
        <mat-card class="" *ngIf="this.barchartres.length > 0">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 426px; display: block"
          ></highcharts-chart>
        </mat-card>
      </div>
      <div class="col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12 mt-3 pie_cht">
        <!--left pi chart-->

        <!--                  <p *ngIf="this.piechartres.length === 0" class="pie_no">No records found.</p>-->
        <highcharts-chart
          *ngIf="this.piechartres.length > 0"
          [Highcharts]="Highcharts"
          [options]="chartOptionsdonout"
          style="width: 100%; height: 458px; display: block"
        ></highcharts-chart>
      </div>
    </div>
  </div>
</div>
