import {UserAccountModel} from '../UserAccountModel/user_account.model';
import {BaseModel} from '../BaseModel/base.model';
import {ChargeV2Model} from '../ChargeModel/charge.model';



export class CollectedfeesModel {
  id?: string;
  account?: string;
  fee_amount?: number;
  charge_id?: string;
  created_at?: string;
  amount_refunded?: number;
  application?: string;
  balance_transaction?: string;
  charge?: string;
  created?: string;
  currency?: string;
  Date?: string;
  originating_transaction?: string;
  refunded?: boolean;

}

// export class UserAccount {
//   data?: UserAccountModel[];
// }
export class CollectedfeesV2Model extends BaseModel {
  account?: UserAccountModel;
  charge?: ChargeV2Model;
  id?: string;
  fee_amount?: number;
  charge_id?: string;
  created_at?: string;
  amount_refunded?: number;
  application?: string;
  balance_transaction?: string;
  created?: string;
  currency?: string;
  Date?: string;
  originating_transaction?: string;
  refunded?: boolean;

  constructor() {
    super();
    this.account = new UserAccountModel();
    this.charge = new ChargeV2Model();
  }
}
