/**
 * Created by abhijeet on 15/3/18.
 */
import {CardModel} from '../CardModel/card.model';
import {BaseModel, EventClass} from '../BaseModel/base.model';
import {InvoicesModel} from '../InvoicesModel/invoices.model';
import {TerminalModel} from '../TerminalModel/terminal.model';
import {BankAccountAchModel} from '../BankAchModel/bankaccountAch.model';

export class CustomerV2Model extends BaseModel {
  id?: string;
  customer_id?: string;
  mail?: string;
  name?: string;
  email?: string;
  card_number?: string;
  exp?: string;
  cvc?: string;
  details?: string;
  description?: string;
  currency?: string;
  delinquent?: number;
  default_source_id?: number;
  source_id?: string;
  send_email_address?: string;
  cc_email_address?: string;
  balance?: number;
  isCapture?: boolean;
  duration?: number;
  charge?: CustomerChargeData;
  ach_charge?: CustomerACHChargeData;
  created_at?: Date;
  card?: CustomerCard;
  invoice?: CustomerInvoices;
  country?: string;
  address_1?: string;
  under_review?: boolean;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: number;
  phone?: string;
  defaultCard?: object;
  trial_days: string;
  bank_account?: CustomerBankAcc;
  payment_types: string;

//  authorize_card?: boolean;

  constructor() {
    super();
    this.charge = new CustomerChargeData();
    this.ach_charge = new CustomerACHChargeData();
    this.card = new CustomerCard();
    this.invoice = new CustomerInvoices();
    this.bank_account = new CustomerBankAcc();
  }

  public getDefaultCard() {
    return this.card.data.find(x => x.is_default === true);
  }
}

export class CustomerCard {
  data?: CardModel[];
}

export class CustomerBankAcc {
  data?: BankAccountAchModel[];
}

export class CustomerInvoices {
  data?: InvoicesModel[] = [];
}

export class CustomerChargeData {
  data?: CustomerCharge[] = [];
}
export class CustomerACHChargeData {
  data?: CustomerACHCharge[] = [];
}
class TransactionMetadataData {
  data?: TransactionMetadata[] = [];
}
class TransactionMetadata {
  id?: string;
  key?: string;
  object: 'TransactionMeta';
  value?: string;
}
export class CustomerCharge {
  id?: string;
  amount?: number;
  amount_refunded?: number;
  captured: number;
  status?: string;
  card_level?: string;
  failure_code?: number;
  failure_message?: string;
  created_by?: string;
  terminal_register?: TerminalModel;
  amount_captured?: number;
  charge_description?: string;
  created_at?: Date;
  type?: string;
  updated_at?: Date;
  under_review?: boolean;
  transaction_metadata?: TransactionMetadataData;
  card?: CustomerCardDeatials;
  sale_terminal_id?: string;

  constructor() {
    this.card = new CustomerCardDeatials();
  }
   getMetadata(key: any) {
    const index = this.transaction_metadata.data.findIndex(obj1 => obj1.key === key);
    return this.transaction_metadata.data[index].value;
  }
}

export class CustomerACHCharge {
  id?: string;
  amount?: number;
  status?: string;
  failure_code?: number;
  failure_message?: string;
  created_by?: string;
  created_at?: Date;
  type?: string;
  updated_at?: Date;
  authorization_id?: string;
  bank_account?: CustomerBankAccountDeatials;

  constructor() {
    this.bank_account = new CustomerBankAccountDeatials();
  }

}


export class CustomerCardDeatials {
  data: CardModel;

  constructor() {
    this.data = new CardModel();
  }

}

export class CustomerBankAccountDeatials {
  data: BankAccountAchModel;

  constructor() {
    this.data = new BankAccountAchModel();
  }

}
