import {Pipe, PipeTransform} from '@angular/core';
/*
 * Converts CreditCardMaskPipe into html breaks
*/
@Pipe({ name: 'CreditCardMaskPipe' })
export class CreditCardMaskPipe implements PipeTransform {
  transform(value: any): any {
    let temp = value.toString().substring(0, 4) + ' ' + value.toString().substring(4);
    return temp.trim();
  }
}
