import {Component, OnInit} from '@angular/core';
import {UserRoleAction} from './user-role.action';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent extends UserRoleAction implements OnInit {

  constructor(router: Router) {
    super();
    this.router = router;
  }

  ngOnInit() {
  }

  toUserRole() {
    this.routeWithId('/new-role', null);
  }

  toNewUser() {
  }
}
