<div class="container-fluid ">
  <div class="row">
    <div class="col-sm-12 top no-padding  ">

      Settings – Users and Roles
    </div><!-- / .col-sm-12 .col-md-6-->



  </div><!-- / .row -->
</div>
<div class="containerx">
  <mat-card class="card">
    <div class="row">
      <div class="col-md-12 heading">
        Users and Roles
      </div>
      </div>


    <div class="row mt-4">
      <div class="col-md-12">
        <button (click)="toNewUser()" class="btn button mr-1">New User</button>
        <button (click)="toUserRole()" class="btn button">Manage Roles</button>
      </div>
    </div>
  </mat-card>
</div>
