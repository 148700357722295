import {BaseComponent} from '../../abstract/base.component';
import {ErrorConstants} from '../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class ManualAchInvoicePaymentAction extends BaseComponent {
  protected _getManualAchInvoiceUrl: string;
  protected  _createAchUrl: string;

  protected getManualACHInvoice(url) {
    this.loadFlag = true;
    this.dataManager.getRequest(url, 0)
      .subscribe(
        (response) => {
          this.passManualACHInvoice(response);
          if (response.data.length === 0) {
            this.errorMsg = ErrorConstants.paymentDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.paymentDetailErrorMsg);
          this.passManualInvoiceError(error); }
      );
  }

  protected abstract passManualACHInvoice(paymentDetails);
  protected abstract passManualInvoiceError(error);

  protected achPostApiCall(postBody) {
    this.loadFlag = true;
      this.dataManager.postRequest(this._createAchUrl, postBody, 0)
      .subscribe(
          (response) => this.passAchResponse(response),
          error => {
              this.passAchError(error);
              this.networkErrorHandler(error);
            }
            );
        }

    protected abstract passAchResponse(charge);

    // protected abstract passManualAchInvoiceError(error);
    protected abstract passAchError(error);


}
