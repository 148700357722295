
<div class="new-campaign-bdy">
    <div class="heading-card">
        <div class="row left-heading no-margin">
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 padding-left-heading pl-4 title">
            <h1>Edit Payee</h1>
            <h3>ID:  {{payeeId}}</h3>
          </div>
          <!-- below is code for delete button -->
        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 padding-left-heading pl-4 title">
          <a (click)="deletePayee(payeeId)"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffa8a8" viewBox="0 0 20 20" aria-labelledby="delete" role="presentation" class="fill-current"><path fill-rule="nonzero" d="M6 4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6H1a1 1 0 1 1 0-2h5zM4 6v12h12V6H4zm8-2V2H8v2h4zM8 8a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path></svg></a> 
          </div>
        </div>
      </div>

      <div class="new-payee-bdy">
        <div class="container-fluid b_shadow mt-5">
          
          <div class="col-lg-12 col-12 col-sm-12 col-md-12 my-lg-4 my-md-3">
              <div class="row" *ngIf="errorMsgFlag">
                 <p class="col-md-12 error mb-3" id="err"> {{errorMsg}}</p>
              </div>
             <div class="row">
                <ng-container *ngIf="errorMsgFlag">
                   <div class="row" *ngFor="let value of keys1" class="col-md-12 error mb-3" id="err">
                      <div class="col-sm-12 error mb-3" *ngFor="let obj of value">
                         <div class="col-sm-12 error mb-3"> {{obj}}</div>
                      </div>
                   </div>
                </ng-container>
             </div>
        </div>
    <form [formGroup]="editPayee" >
        <div class="row d-flex justify-content-center">
            <div class="col-sm-12 col-md-8 col-lg-8 col-8 col-xl-4 mb-4 p-3 ">
                <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="first_name">Payee First Name<i class="required-icon">*</i></label>
                    
                    <div class="form-group   row ">
                        
                        <input class="form-control" id="first_name" formControlName="first_name">
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.first_name.status==='INVALID' " style="bottom: 0">Please provide payee name. Min 3 letters</div>
                 
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="last_name">Payee Last Name<i class="required-icon">*</i></label>
                    
                    <div class="form-group   row ">
                        
                        <input class="form-control" id="last_name" formControlName="last_name">
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.last_name.status==='INVALID' " style="bottom: 0">Please provide payee name. Min 3 letters</div>
                 
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="email">Payee Email<i class="required-icon">*</i></label>
                <div class="form-group   row ">
                    <input class="form-control" id="email" formControlName="email">
                </div>
                <div class="tip_for_inputs" *ngIf="editPayee.controls.email.status==='INVALID' " style="bottom: 0">Please provide payee e-mail.<p style="color: grey;">Example: jhon@doe.com</p></div>
                
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="type">Type:<i class="required-icon">*</i></label>
                    <div class="form-group   row">
                    <select #type class="custom-select"  formControlName="type" (change)="onSelected(type.value)">
                        <option value="" disabled selected>Select Type</option>
                            <option value="percent" >Percentage</option>
                            <option value="fixed">Flat Rate</option>
                            <option value="percent_and_fixed">Percentage + Flat Rate</option>
                            
                        </select>
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.type.pristine" style="bottom: 0">Please select type!</div>
                </div>
                <div *ngIf="typePayeePercentage" class="form-group row align-items-center mt-sm-0 mt-2">
                    <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Amount % <i class="required-icon">*</i></label>
                    <div class="form-group   row ">
                        <input class="form-control" type="text" id="amount" formControlName="percent" (keypress)="_keyPress($event)" placeholder="0">
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.percent.status==='INVALID' " style="bottom: 0"> Between 0 and 100.
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.percent.pristine" style="bottom: 0">Please provide number! Between 0 and 100.
                    </div>
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-2" *ngIf="typePayeeAmount">
                    <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Amount $ <i class="required-icon">*</i></label>
                    <div class="form-group   row " >
                    <input class="form-control" type="text" id="amount" min="0.01" max="9999999" formControlName="fixed_amount" (keypress)="_keyPress($event)" placeholder="0.00">
                </div>
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Payee Access to Dashboard?<i class="required-icon">*</i></label>
                    
                    <input type="radio"   id="radio_yes" value="1" formControlName="dashboard_access">
                    <label class="p-l-r" for="radio_yes">Yes</label>
                    <input type="radio"  id="radio_no" value="0" formControlName="dashboard_access"> 
                    <label class="p-l-r" for="radio_no">No</label>
    
                 
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.dashboard_access.status==='INVALID' " style="bottom: 0">Please select Yes or No.</div>
                </div>
                <!-- <div class="form-group row align-items-center mt-sm-0 mt-2">
                    <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="type">Campaign:</label>
                    <div class="form-group  row   ">
                        <select class="form-control custom-select" formControlName="campaign_id"  >
                        <option  value="" selected  disabled>Select Campaign</option>
                        <option *ngFor="let campaign of listofCampaigns" [campaign]="campaign"  [value]="campaign.id">{{campaign.name}}</option>
                        
                        </select>
                    </div>
                    <div class="tip_for_inputs" *ngIf="editPayee.controls.campaign_id.status==='INVALID' " style="bottom: 0">Please select Campaign.</div>
                    </div>-->
    
                    <div class="row justify-content-end pt-3 pb-3">
                        <button class="cancel_btn" (click)="cancelPayeeEdited()">Cancel</button>
                        <app-loading-button class="{{editPayee.invalid ? 'disabled' : ''}}"  (buttonClick)="this.editPayee.invalid ? print(editPayee) : submitPayeeEdited()" [disable]="editPayee.invalid"  type="submit" [buttonText]="buttonText"></app-loading-button>
                    </div> 
                  </div>
        </div>
    </form>
    </div>
    <!-- <div class="row d-flex justify-content-center background_white m-0 p-4"> -->
        <!-- <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
          <ng-container *ngIf="topErrorMessage">
            <div class="row err" *ngFor="let obj of keys1 ">
               <div class="col-md-12 col-lg-12 col-12 col-sm-12 col-xl-12" *ngFor="let value of obj ">
                  <p class="error" id="err">{{value}}</p>
               </div>
            </div>
         </ng-container>
         <div class="row" *ngIf="!topErrorMessage">
            <div class="error "> {{errorMsg}}</div>
         </div>
          <form [formGroup]="editPayee" #editPayeeF="ngForm" class="form-group">  
          <div class="form-group row pt-m-18 mt-3">
            <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="first_name">Campaign Name</label>
            <div class="col-sm-7 col-12 px-m-0">
                    <input id="first_name" class="form-control" type="text" formControlName="first_name">
                    <span class="validation-err-msg" *ngIf="editPayeeF.controls['first_name'].errors?.required || editPayeeF.controls['first_name'].status === 'INVALID'">Please provide Name for the Payee.Symbols left {{editPayeeF.controls['first_name'].errors?.minlength==undefined?'5': (editPayeeF.controls['first_name'].errors.minlength.requiredLength-editPayeeF.controls['first_name'].errors.minlength.actualLength)}}</span>
                    <span class="validation-err-msg" *ngIf="editPayeeF.controls['first_name'].errors?.pattern">Name for the Payee is invalid.</span>
                  </div>
                </div>
                <div class="form-group row pt-m-18 mt-3">
                  <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="last_name">Campaign Name</label>
                  <div class="col-sm-7 col-12 px-m-0">
                          <input id="last_name" class="form-control" type="text" formControlName="last_name">
                          <span class="validation-err-msg" *ngIf="editPayeeF.controls['last_name'].errors?.required || editPayeeF.controls['last_name'].status === 'INVALID'">Please provide Name for the Payee.Symbols left {{editPayeeF.controls['last_name'].errors?.minlength==undefined?'5': (editPayeeF.controls['last_name'].errors.minlength.requiredLength-editPayeeF.controls['last_name'].errors.minlength.actualLength)}}</span>
                          <span class="validation-err-msg" *ngIf="editPayeeF.controls['last_name'].errors?.pattern">Name for the Payee is invalid.</span>
                        </div>
                      </div>

                <div class="form-group row pt-m-18 mt-3 mb-6">
                  <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="campaignDesc">Payee emeil:</label>
                  <div class="col-sm-7 col-12 px-m-0">
                    <input id="email" class="form-control mt-3" type="text" rows="3" formControlName="email" >
                    <span class="validation-err-msg" *ngIf="editPayee.controls['email'].errors?.required || editPayee.controls['email'].status === 'INVALID' ">Please provide Description for the Payee.Symbols left {{editPayee.controls['email'].errors?.minlength==undefined?'5': (editPayee.controls['email'].errors.minlength.requiredLength-editPayee.controls['email'].errors.minlength.actualLength)}}</span>
                    <span class="validation-err-msg" *ngIf="editPayee.controls['email'].errors?.pattern">Description for the Payee is invalid.</span>
                  </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="type">Type:</label>
                <div class="col-sm-7 col-12 px-m-0">
                  
                  <input id="type" class="form-control" type="input" formControlName="type" >
                  
                </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="type">Status</label>
              <div class="col-sm-7 col-12 px-m-0">
                
                <select id="status" class="form-control" type="textarea" formControlName="status"   >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
                
              </div>
              
            </div>

            <div [ngSwitch]="payeeData.type" class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label *ngSwitchCase="'percent'"  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Percent: </label>
              <div *ngSwitchCase="'percent'" class="col-sm-7 col-12 px-m-0"> 
                {{payeeData.percent}} %
              </div>
              <label *ngSwitchCase="'fixed'"  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Amount: </label>
              <div *ngSwitchCase="'fixed'" class="col-sm-7 col-12 px-m-0"> 
                $ {{payeeData.fixed_amount}}
              </div>    
                <label  *ngSwitchDefault class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Percent: </label>
                <div  *ngSwitchDefault  class="col-sm-7 col-12 px-m-0"> 
                  {{payeeData.percent}} %
                </div>
                <label *ngSwitchDefault  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Amount: </label>
                <div *ngSwitchDefault class="col-sm-7 col-12 px-m-0"> 
                  $ {{payeeData.fixed_amount}}
                </div> 
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Status:</label>
              <div class="col-sm-7 col-12 px-m-0">   {{payeeData.status | titlecase}}            
              </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Dashboard Access:</label>
              <div class="col-sm-7 col-12 px-m-0">   
                <input id="dashboard_access" class="form-control" type="input" formControlName="dashboard_access" >     
              </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="type">Updated At: </label>
              <div class="col-sm-7 col-12 px-m-0">
                <!-- {{updated_at | date: "MM/dd/yyyy hh:mm a"}} --- {{readable_updated_at}} -->
              <!-- </div> -->
            <!-- </div> -->
            <!-- </form> -->

          <!-- </div> -->
        <!-- </div>  -->
        <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5">
          <button class="cancel_btn" (click)="cancelPayeeEdited()">Go Back</button>
          <!-- <app-loading-button  (buttonClick)="campaign.invalid ? print(campaign) : submitPayeeEdited()"   [buttonText]="buttonText">
          </app-loading-button> -->
        </div>
        <div class="table_content  mt-xl-3 mt-lg-3 mt-md-3" >
       
         
  </div>
  