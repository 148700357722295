import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  AfterViewInit,
  AfterContentInit, OnChanges, Renderer2, OnDestroy
} from '@angular/core';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {AppConstants} from '../../shared/constants/app-constants';
import {ToolBarAction} from './tool-bar.action';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import {ProfileDialogComponent} from '../../dialogs/profile-dialog/profile-dialog.component';
import {CreateNewAccountDialogComponent} from '../../dialogs/create-new-account-dialog/create-new-account-dialog.component';
import {AccountModel} from '../../data/model/AccountModel/account.model';
import {UpdateContactDialogComponent} from '../../dialogs/update-contact-dialog/update-contact-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SettingsComponent} from '../../merchant/components/settings/settings.component';


@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})

export class ToolBarComponent extends ToolBarAction implements OnInit {
  @Output() logoClicked = new EventEmitter();
  current_User_Name: string;
  selected: string;
  keys: String[];
  show: boolean;
  newResult: EventEmitter<boolean> = new EventEmitter();
  account_name: string;
  serviceName: string;
  scrollMenu: any;
  showHide: boolean;
  homeFlag: boolean;
  filteredItems: any;
  name: string;
  currentAccountId: string;
  currentUsers: AccountModel[];
  activeUser: AccountModel;
  currentResolution: number;
  settings: SettingsComponent;

  // showHide: boolean;
  // const userAccount = JSON.parse(localStorage.getItem('account_id'));


  get userName(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
    return currentUser1;
  }
 get userNameIcon(): any{
   const iconname = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
   const split = iconname.split(' ');

   return (split.length > 1) ? split[0][0] + split[1][0] : split[0][0];
 }
  get menuName(): any {
    const menu = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_FIRST_MENU));
    return menu;
  }

  get userEmail(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_EMAIL));
    return currentUser1;
  }


  get currentAccountName(): any {
    const currentAccountName = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME));
    this.setUserAccounts();
    return currentAccountName;
  }

  constructor(dataManager: DataManagerService, dialog: MatDialog, _router: Router, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any, myElement: ElementRef, snackBar: MatSnackBar,
              private renderer: Renderer2) {
    super();
    this.dataManager = dataManager;
    this.router = _router;
    this.snackBar= snackBar;
    this._registerUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_REGISTER;
    this._logoutUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_LOGOUT;
    this.alertDialog = dialog;
    this._getaccount = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT;
    this.show = false;
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this.serviceName = '/switch';
    this.showHide = false;
    this.currentUsers = [];
    this.activeUser = new AccountModel();
    this.current_User_Name = this.currentAccountName;
    this.name = null;
    this.currentResolution = window.screen.width;
    console.log(this.menuName, 'first menu');

    // this.assignCopy = null;
    // this.filteredItems = null;
    // this.assignCopy();
  }

  ngOnInit() {}

  toggle() {
    this.logoClicked.emit();
    // this.trigger.openMenu();
  }

  action(key) {
    switch (key) {
      case 'edit-profile':
        this.routeWithId('/merchant-update', this.dataManager.getCurrentUserId());
    }
  }

  setUserAccounts() {
    this.currentUsers = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));
    if (!this.isNullOrUndefined(this.currentUsers) && this.currentUsers.length > 0) {
      this.setCurrentAccount();
    }
  }

  setCurrentAccount() {
    this.activeUser = this.currentUsers.find(x => x.id === this.currentAccountId);
  }

  switchAccount(id, account_name) {
    localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(account_name));
    }
    this.settings = new SettingsComponent(this.dataManager, this.localStorage);


    this._getaccount = AppConstants.BASE_URL + this.serviceName + '/' + id;
    this.getSwitchAccount(this._getaccount);
    this.router.navigate(['loading..']);
  }

  createAccount() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'inventoryPanel';
    this.alertDialog.open(CreateNewAccountDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        res => this.newResult.emit(res)
      );
  }

  signOut() {
    this.dataManager.logOut();
    // this.assignCopy = null;
    // this.assignCopy = null;
    this.name = null;
    // sessionStorage.clear();
    // localStorage.clear();
  }

  myprofile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(ProfileDialogComponent, dialogConfig)
      .afterClosed();
    // .subscribe(
    //   (res) => {
    //     if (res) {
    //       if (this.localStorage.getItem(FilterConstants.CUSTOMER_FILTER_STORAGE_KEY)
    //         && this.localStorage.getItem(FilterConstants.CUSTOMER_FILTER_COUNT_STORAGE_KEY)) {
    //         console.log(this.filterPostFix);
    //         this.firstCustomerListCall(this.filterPostFix);
    //       } else {
    //         this.firstCustomerListCall();
    //       }
    //     }
    //   }
    // );
  }

  // assignCopy() {
  //   this.filteredItems = this.userAccount;
  // }
  // filterItem(value) {
  //   if (!value) {
  //     this.assignCopy();
  //   } // when nothing has typed
  //   this.filteredItems = Object.assign([], this.userAccount).filter(
  //     item => item.account_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ,
  //     console.log()
  //   );
  // }
  protected passAccounts(res) {
    // this.dataManager.userAccountInfoObject = res.data;
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(res.current_account_id));
    }
    console.log('response on accounts', res);
    this.loadFlag = true;
      this.routeNavigate(this.dataManager.menuArray[0].routerLink);
  }

  protected passAcountError(err) {
    this.loadFlag = false;
    this.routeNavigate('home');
    console.log(err);
  }

  protected passLogOutErrorResponse(err) {
    console.log(err);
  }

  protected passLogOutResponse(res) {
    console.log(res);
  }

  protected passcreatNewAccount(response) {
    console.log(response);
  }

  protected passcreatNewAccountError(error) {
    console.log(error);
  }

  toUpdateContact() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(UpdateContactDialogComponent, dialogConfig)
      .afterClosed().subscribe(
        res => {if (res){
          this.showSnackBar('Contact updated successfully.', 'Ok', 3000);
        }}
    );
  }
  protected passGetSettingError(error) {

  }

}





