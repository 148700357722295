import {CustomerV2Model} from '../CustomerV2Model/customer-v2.model';
import {CouponModel} from '../CouponModel/coupon.model';
import {BaseModel} from '../BaseModel/base.model';

export class SubscriptionModel extends BaseModel {
  _object ?: string;
  id?: string;
  customer_id?: string;
  plan_id?: string;
  discount_id?: string;
  application_fee_percent?: number;
  billing_type?: number;
  start_after_days?: number;
  end_after_cycles?: number;
  payment_due_days?: number;
  start_at?: Date = new Date();
  cancel_at_period_end?: number;
  canceled_at?: Date = new Date();
  current_period_end?: Date = new Date();
  current_period_start?: Date = new Date();
  days_until_due?: number;
  ended_at?: Date = new Date();
  begin?: Date = new Date();
  status?: string;
  end_at?: Date = new Date();
  tax_percent?: number;
  description?: string;
  tax_amount?: number;
  chain_end?: Date = new Date();
  trial_start?: Date = new Date();
  trial_end?: Date = new Date();
  created_at?: any = {};
  updated_at?: Date = new Date();
  plan?: SubscriptionPlanModel = new SubscriptionPlanModel();
  customer?: SubscriptionCustomerModel = new SubscriptionCustomerModel();
  discount?: SubscriptionCouponModel = new SubscriptionCouponModel();
  trial_days: number;
  email?: string;
  name?: string;
  payment_type?: string;
}

export class SubscriptionPlanModel {
 data?: PlanModel = new PlanModel();
}
export class SubscriptionCustomerModel {
 data?: CustomerV2Model = new CustomerV2Model();
}
export class SubscriptionCouponModel {
 data?: CouponModel = new CouponModel();
}

export class PlanModel {
  object?: string;
  id?: string;
  plan_id?: string;
  real_id?: number;
  amount?: number;
  interval?: string;
  interval_count?: number;
  name?: string;
  trial_period_days?: number;
  currency?: string;
  created_at?: Date = new Date();
  updated_at?: Date = new Date();
  start_after_days?: number;
  end_after_cycles?: number;
}


