import {CustomDateModel} from '../CustomDate/custom-date.model';

export class EventModel {
  id?: string;
  type?: string;
  event_type?: string;
  description?: string;
  created_at?: CustomDateModel;
  updated_at?: CustomDateModel;
  request?: any;
  response?: any;
  [key: string]: any;


  constructor(eventData?: Partial<EventModel>) {
    this.created_at = new CustomDateModel();
    this.updated_at = new CustomDateModel();

    if (eventData) {
      Object.assign(this, eventData);
      this.request = this.parseJsonSafely(eventData.request);
      this.response = this.parseJsonSafely(eventData.response);
    }
  }


  private parseJsonSafely(value: any): any {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch {
        return value; // Return the original string if parsing fails
      }
    }
    return value;
  }

}
