import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { CustomerDetailsComponent } from '../components/customer-details/customer-details.component';
import { CreateCustomerComponent } from '../../shared/components/create-customer/create-customer.component';
import { AddCustomerCardComponent } from '../components/add-customer-card/add-customer-card.component';
import { CustomerListComponent } from '../components/customer-list/customer-list.component';
import { CustomersComponent } from '../customers/customers.component';
import { CreateNewSubscriptionComponent } from '../../shared/components/create-new-subscription/create-new-subscription.component';
import { CapturePaymentComponent } from '../../shared/components/capture-payment/capture-payment.component';
import { EditCustomerCardComponent } from '../components/edit-customer-card/edit-customer-card.component';
import { AddBankAccountComponent } from '../components/add-bank-account/add-bank-account.component';
import { EditBankAccountComponent } from '../components/edit-bank-account/edit-bank-account.component';

const customersRoutes: Routes = [
  {
    path: '',
    component: CustomersComponent,
    children: [
      {path: 'customer-list', component: CustomerListComponent},
      {path: 'create-customer', component: CreateCustomerComponent},
      {path: 'update-customer', component: CreateCustomerComponent},
      {path: 'customer-details', component: CustomerDetailsComponent},
      {path: 'add-card', component: AddCustomerCardComponent},
      {path: 'edit-card', component: EditCustomerCardComponent},
      {path: 'create-subscription', component: CreateNewSubscriptionComponent},
      {path: 'capture-payment', component: CapturePaymentComponent},
      {path:'add-bank-account',component:AddBankAccountComponent},
      {path:'edit-bank-account',component:EditBankAccountComponent}
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(customersRoutes)
  ],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
