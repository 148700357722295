import { Routes } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { CustomerDetailsComponent } from '../customers/components/customer-details/customer-details.component';
import { EditCustomerComponent } from '../components/customer/edit-customer/edit-customer.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { MerchantAccountActivationComponent } from '../components/merchant-account-activation/merchant-account-activation.component';
import { RegistrationComponent } from '../components/registration/registration.component';
import { EventListingComponent } from '../components/event-listing/event-listing.component';
import { EventDetailsComponent } from '../components/event-details/event-details.component';
import { MerchantProfileComponent } from '../merchant/components/merchant-profile/merchant-profile.component';
import { UserRoleComponent } from '../user-role/user-role.component';
import { EditUserComponent } from '../merchant/components/edit-user/edit-user.component';
import { AuthGuardService } from '../data/services/AuthGuard/auth-guard.service';
import { ManualInvoicePaymentComponent } from '../components/manual-invoice-payment/manual-invoice-payment.component';
// import {WebhooksComponent} from '../components/webhooks/webhooks.component';
import { CreateConnectAccountComponent } from '../components/connect/create-connect-account/create-connect-account.component';
import { AccountListComponent } from '../components/connect/account-list/account-list.component';
import { AccountSettingsComponent } from '../components/connect/account-settings/account-settings.component';
import { AccountDetailsComponent } from '../components/connect/account-details/account-details.component';
import { CollectedFeesListComponent } from '../components/connect/collected-fees-list/collected-fees-list.component';
import { CollectedFeesDetailsComponent } from '../components/connect/collected-fees-details/collected-fees-details.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { DisputesComponent } from '../paymemtModule/components/disputes/disputes.component';
import { DisputeDetailsComponent } from '../paymemtModule/components/dispute-details/dispute-details.component';
import { DisputesFormComponent } from '../paymemtModule/components/disputes-form/disputes-form.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { AdminComponent } from '../components/admin/admin.component';
import { AccountBrandingComponent } from '../components/account-branding/account-branding.component';
import { StatementsComponent } from '../components/statements/statements.component';
import { MultiFactorAuthenticationComponent } from '../multi-factor-authentication/multi-factor-authentication.component';
import { ManualAchInvoicePaymentComponent } from '../components/manual-ach-invoice-payment/manual-ach-invoice-payment.component';

/**
 * Created by abhijeet on 30/1/18.
 */

export const appRoutes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full'},
  // {path: 'admin', redirectTo: '/admin', pathMatch: 'full'},
  // { path: 'payment', pathMatch: 'full', canActivate: [AuthGuardService] },
  // routes for component in app core
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'pay/:id', component: ManualInvoicePaymentComponent },
  { path: 'pay-ach/:id', component: ManualAchInvoicePaymentComponent},
  { path: 'disputes/dispute-list', component: DisputesComponent, canActivate: [AuthGuardService] },
  { path: 'loading..', component: LoaderComponent, canActivate: [AuthGuardService] },
  { path: 'disputes/dispute-details', component: DisputeDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'disputes/dispute-form', component: DisputesFormComponent, canActivate: [AuthGuardService] },
  // {path: 'customer/customer-list', component: CustomerListComponent, canActivate: [AuthGuardService]},
  { path: 'signin', component: SignInComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'merchant/branding', component: AccountBrandingComponent, canActivate: [AuthGuardService] },
  { path: 'register', component: RegistrationComponent },
  { path: 'signin/forgotpassword', component: ForgotPasswordComponent },
  { path: 'statements', component: StatementsComponent, canActivate: [AuthGuardService] },
  { path: 'signin/step-verification', component: MultiFactorAuthenticationComponent, canActivate: [AuthGuardService]},
  {
    path: 'events-and-logs',
    component: EventListingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'event-details',
    component: EventDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'merchant/merchant-activation',
    component: MerchantAccountActivationComponent,
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'merchant-split-funding',
  //   component: MerchantSplitFundingComponent,
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'merchant-update',
    component: MerchantAccountActivationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'merchant',
    loadChildren: () => import('../../app/merchant/merchant.module').then(m => m.MerchantModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'merchant-bridge',
    loadChildren: () => import('../../app/merchant-bridge-module/merchant-bridge-module.module').then(m => {console.log('merchant bridge',m); return m.MerchantBridgeModule}),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-and-roles',
    component: UserRoleComponent,
    canActivate: [AuthGuardService],
  },
  // routes to initialize modules
  {
    path: 'payment',
    loadChildren: () => import('../../app/paymemtModule/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'balance',
    loadChildren: () => import('../../app/balanceModule/balance.module').then(m => m.BalanceModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'reports',
    loadChildren: () => import('../../app/reportsModule/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscription',
    loadChildren:
      () => import('../../app/subscriptionModule/subscription.module').then(m => m.SubscriptionModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'api',
    loadChildren: () => import('../../app/apiModule/api.module').then(m => m.ApiModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'risks',
    loadChildren: () => import('../../app/risks/risk.module').then(m => m.RiskModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer',
    loadChildren: () => import('../../app/customers/customers.module').then(m => {console.log(m); return m.CustomersModule}),
    canActivate: [AuthGuardService],
  },
  // {path: 'webhooks', component: WebhooksComponent},
  { path: 'bridge/bridge-accounts', component: AccountListComponent, canActivate: [AuthGuardService] },
  { path: 'bridge/bridge-account-details', component: AccountDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'bridge/collected-fees', component: CollectedFeesListComponent, canActivate: [AuthGuardService] },
  {
    path: 'bridge/bridge-account-settings',
    component: AccountSettingsComponent, canActivate: [AuthGuardService]

  },
  {
    path: 'collected-fees/collected-fees-details',
    component: CollectedFeesDetailsComponent, canActivate: [AuthGuardService]
  },
  { path: 'create-bridge-account', component: CreateConnectAccountComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: '/signin' },

];
