import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../constants/app-constants';
import {CreditCard, CreditCardValidators} from 'angular-cc-library';
import {CreditService} from '../../../credit.service';
import {CustomerAddCardDetailsSectionAction} from './customer-add-card-details-section.action';
import {CardModel} from '../../../data/model/CardModel/card.model';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {MapsAPILoader} from '@agm/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-customer-add-card-details-section',
  templateUrl: './customer-add-card-details-section.component.html',
  styleUrls: ['./customer-add-card-details-section.component.css']
})
export class CustomerAddCardDetailsSectionComponent extends CustomerAddCardDetailsSectionAction implements OnInit {
  /* Google maps autocomplete */
  private API_KEY = AppConstants.GOOGLE_MAPS_API_KEY;
  isApiLoaded = false;
  options: any = {
    componentRestrictions: { country: ['US', 'CA'] }
  };
  /* End Google maps autocomplete */
  addCustomerCard: FormGroup;
  @Input() customerCard: CardModel;
  @Input() heading: string;
  @Output() onCustomerCardChange = new  EventEmitter<CardModel>();
  @Output() onFormGroupChange = new EventEmitter<FormGroup>();
  @Output() onCvvError = new EventEmitter<boolean>();
  @Input() showCreditCardFields: boolean | undefined;


  get currentAccountId(): any {
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    return currentAccountid;
  }
  card_img_obj = {
    'discover': '../../../../assets/images/disc.png', 'visa': '../../../../assets/images/visa.png',
    'amex': '../../../../assets/images/amex.png', 'mastercard': '../../../../assets/images/master.png',
    'jcb': '../../../../assets/images/jcb.png', 'default': '../../../../assets/images/card-default.png'
  };

  cvvoptional: number;
  addresopt: number;
  countries = AppConstants.countryList;
  creditnum: any;
  type: string;
  cvcError: boolean ;
  @ViewChild('addCardSubmitButton', { static: true }) addCardSubmitButton;
  states = AppConstants.stateList;
  flagAddressChange=false;

  constructor(private creditService: CreditService, private _fb: FormBuilder, dataManager: DataManagerService,
              @Inject(DOCUMENT) private document: Document, private mapsAPILoader: MapsAPILoader,
              private elementRef: ElementRef) {  /*document, mapsAPILoader, elementRef needed by google API*/
    super();
    this.dataManager = dataManager;
    this.onFormGroupChange = new EventEmitter<FormGroup>();
    this.customerCard = new CardModel();

    this.flagAddressChange=false;

  }

  ngOnInit() {
    /* Google maps api */
    this.mapsAPILoader.load().then(() => {
      this.isApiLoaded = true;
    });
    /* End Google maps api */
    this.addCustomerCard = this._fb.group({
      creditCard: ['', [<any>Validators.required, Validators.maxLength(23), <any>CreditCardValidators.validateCCNumber]],
      expDate: ['', [<any>Validators.required, <any>CreditCardValidators.validateExpDate, <any>Validators.minLength(4)]],
      cvc: ['', [<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4)]],
      uname: ['', [<any>Validators.required, Validators.maxLength(200)]],
      Street: ['', <any>Validators.maxLength(200)],
      line2: ['', <any>Validators.maxLength(200)],
      City: [''],
      zip: ['', <any>Validators.maxLength(10)],
      state: [''],
      Country: [''],
      auth: [''],
    });

    this.onFormGroupChange.emit(this.addCustomerCard);
    this._getaccount = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '/' + this.currentAccountId;
    this.getAddress();
    console.log('this.customerCard', this.showCreditCardFields ,this.heading);
    if (this.showCreditCardFields === undefined) {
      this.showCreditCardFields = true;
    }
    this.subscribeToCountryChanges();
  }

  protected getAddressResponse(res) {
    console.log('authlogiee', res);
    this.cvvoptional = res.data.cvv_optional;
    this.addresopt = res.data.address_optional;
    if (this.addresopt === 0) {
      this.addCustomerCard.controls['Street'].setValidators([<any>Validators.required, <any>Validators.maxLength(200)]);
      this.addCustomerCard.controls['Street'].updateValueAndValidity();
      this.addCustomerCard.controls['City'].setValidators([<any>Validators.required]);
      this.addCustomerCard.controls['City'].updateValueAndValidity();
      this.addCustomerCard.controls['state'].setValidators([<any>Validators.required]);
      this.addCustomerCard.controls['state'].updateValueAndValidity();
      this.addCustomerCard.controls['Country'].setValidators([<any>Validators.required]);
      this.addCustomerCard.controls['Country'].updateValueAndValidity();
      this.addCustomerCard.controls['zip'].setValidators([<any>Validators.required, <any>Validators.maxLength(10)]);
      this.addCustomerCard.controls['zip'].updateValueAndValidity();
    } else {
      this.addCustomerCard.controls['Street'].setValidators([<any>Validators.maxLength(200)]);
      this.addCustomerCard.controls['Street'].updateValueAndValidity();
      this.addCustomerCard.controls['City'].clearValidators();
      this.addCustomerCard.controls['City'].updateValueAndValidity();
      this.addCustomerCard.controls['state'].clearValidators();
      this.addCustomerCard.controls['state'].updateValueAndValidity();
      this.addCustomerCard.controls['Country'].clearValidators();
      this.addCustomerCard.controls['Country'].updateValueAndValidity();
      this.addCustomerCard.controls['zip'].setValidators([<any>Validators.maxLength(10)]);
      this.addCustomerCard.controls['zip'].updateValueAndValidity();

    }
    if (this.cvvoptional === 0) {

      this.addCustomerCard.controls['cvc'].setValidators([<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(4)]);
      this.addCustomerCard.controls['cvc'].updateValueAndValidity();
    } else {
      this.addCustomerCard.controls['cvc'].setValidators([<any>Validators.minLength(3), <any>Validators.maxLength(4)]);
      this.addCustomerCard.controls['cvc'].updateValueAndValidity();
    }
    this.onFormGroupChange.emit(this.addCustomerCard);
  }

  protected getAddressError(err) {
    console.error(err);
  }

  onCardDataChange() {
    this.onCustomerCardChange.emit(this.customerCard);
    this.addCustomerCard.updateValueAndValidity();
    this.validateDifferencessInAddress();
  }

  getCardType(event) {
    this.type = CreditCard.cardType(event.target.value);
    this.creditnum = this.creditService.creditnumber(event);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkCvc(cvv) {
    if (cvv === '000' || cvv === '0000') {
      this.cvcError = true;
    } else {
      this.cvcError = false;
    }
    this.onCvvError.emit(this.cvcError);
  }
  /* Google maps api */
  loadScript() {
    return new Promise((resolve, reject) => {
      const element = this.document.createElement('script');
      element.type = 'text/javascript';
      element.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places&language=en`;
      element.onload = resolve;
      element.onerror = reject;
      this.elementRef.nativeElement.appendChild(element);
    });
  }
  handleAddressChange(address: Address) {
    this.customerCard.address_line1 = address.formatted_address;
    let addr: any;
    for (addr of address.address_components) {
      if (addr.types[0] === 'country') {
        this.customerCard.country = addr.short_name;
      }
      if (addr.types[0] === 'administrative_area_level_1') {
        this.customerCard.state = addr.long_name;
      }
      if (addr.types[0] === 'locality') {
        this.customerCard.city = addr.long_name;
      }
      if (addr.types[0] === 'postal_code') {
        this.customerCard.zip = addr.long_name;
      }
    }
  }
  /* End Google maps api */
  numericOnly(event) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === '-') // dash
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  validateDifferencessInAddress() {

    if (this.addCustomerCard.controls['City'].dirty||
    this.addCustomerCard.controls['zip'].dirty) {
      this.flagAddressChange=true
    }
    this.addCustomerCard.get('state').valueChanges.subscribe(mode => {
      this.flagAddressChange=true;
    })
  }

  private subscribeToCountryChanges() {
    this.addCustomerCard.get('Country').valueChanges.subscribe((country) => {
      if (country === 'US') {
        this.states = AppConstants.stateList;
      } else if (country === 'CA') {
        this.states = AppConstants.canadaStateList;
      }
      this.addCustomerCard.patchValue({ state: null });
    });
  }

}
