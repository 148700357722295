import {Component, OnInit} from '@angular/core';
import {KountAction} from './kount.action';
import {AppConstants} from '../../../shared/constants/app-constants';
import {MerchantKountSettingModel} from '../../../data/model/merchant-kount-setting-model/merchant-kount-setting-model.model';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-kount',
  templateUrl: './kount.component.html',
  styleUrls: ['./kount.component.css']
})
export class KountComponent extends KountAction implements OnInit {
  settings: MerchantKountSettingModel;
  currentAccountId: string;
  hasSettings = false;
  errorMsg = null;

  protected passGetSettingError(err: any) {

  }

  protected passSetSettingResponse(res: any) {
    res.data.forEach(obj => {
      if (obj.hasOwnProperty('kount_enable')) {
        this.settings.kount_enable = obj.kount_enable.toString() === '1';
        this.settings.api = obj.kount_api_key;
        this.settings.mid = obj.kount_merchant_id;
        this.hasSettings = obj.hasSettings;
        this.errorMsg = null;
      }
    });
    this.showSnackBar('Changes have been saved successfully', 'OK', 3000);
  }

  protected passSetSettingError(err: any) {
    let errm = JSON.parse(err.error.message);
    this.errorMsg = errm.mid;
  }

  constructor(dataManager: DataManagerService, private MatSnackBar: MatSnackBar) {
    super();
    this.dataManager = dataManager;
    this.settings = new MerchantKountSettingModel();
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this._getSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_KOUNT + '/' + this.currentAccountId;
    this._setSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_KOUNT + '/' + this.currentAccountId;
    this.getSetting();
    this.snackBar = MatSnackBar;
  }

  setKount() {
    const postBody = {};
    // postBody['id'] = this.settings.tokenize_id;
    postBody['kount_enable'] = this.settings.kount_enable ? 1 : 0;
    this.setSetting(postBody);
  }

  onSubmit() {
    const postBody = {};
    postBody['kount_enable'] = this.settings.kount_enable ? 1 : 0;
    postBody['api'] = this.settings.api;
    postBody['mid'] = this.settings.mid;
    this.setSetting(postBody);
  }

  ngOnInit() {

  }

  protected passGetSettingResponse(res) {
    res.data.forEach(obj => {
      if (obj.hasOwnProperty('kount_enable')) {
        this.settings.kount_enable = obj.kount_enable.toString() === '1';
        this.settings.api = obj.kount_api_key;
        this.settings.mid = obj.kount_merchant_id;
        this.settings.web_hook = obj.hasOwnProperty('charges/kount_notification') ? AppConstants.BASE_URL + '/' + obj['charges/kount_notification'] : 'Please contact PayArc for support in Kount integration.';
        this.hasSettings = obj.hasSettings;
      }
    });

  }

  copy(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'Copied';
  }
}
