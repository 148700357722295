import { BaseComponent } from '../../../abstract/base.component';
import { ErrorConstants } from '../../../shared/constants/error-constants';
import { Subject } from 'rxjs';
import { Directive } from "@angular/core";

@Directive()
export abstract class DisputeListAciton extends BaseComponent {
  protected _disputeListUrl: string;
  protected _disputeChartUrl: string;
  _acceptDisputeUrl: string;
  loadFlag2: boolean;
  public searchKeyword: string;
  fromDate: Date;
  toDate: Date;
  searchText: Subject<string>;
  protected abstract passDisputeList(res);

  protected abstract passError(error);

  protected abstract passDisputeChartResponse(res);

  protected abstract passDisputeChartError(error);

  protected _getdisputeexcel: string;
  protected abstract passdisputeExcelResponse(res);
  protected abstract passdisputeExcelError(err);

  getlogexcel(url) {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(url, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passdisputeExcelResponse((new Blob([res], { type: 'application/vnd.ms-excel' })));
      },
      (err) => {
        this.loadFlag = false;
        this.passdisputeExcelError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }

  /**
   * func to call data
   * list of disputes
   */
  getDisputeList(url) {
    this.loadFlag = true;
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => {
          if (res.data.length === 0) {
            this.errorMsg = ErrorConstants.disputeErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
          this.passDisputeList(res);
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.disputeErrorMsg);
          this.passError(error.error);
        }
      );
  }

  getDisputeChart() {
    this.loadFlag2 = true;
    this.dataManager.getRequest(this._disputeChartUrl, 0)
      .subscribe(
        res => {
          this.loadFlag2 = false;
          this.passDisputeChartResponse(res);
        },
        error => {
          this.loadFlag2 = false;
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.disputeErrorMsg);
          this.passDisputeChartError(error.error);
        }
      );
  }




}

