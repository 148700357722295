
<div class="new-campaign-bdy">
    <div class="heading-card">
        <div class="row left-heading no-margin">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padding-left-heading pl-4 title">
            <h1>Create Campaign</h1>
          </div>
        </div>
      </div>
    <div class="row d-flex b_shadow justify-content-center background_white m-0 p-4">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
          <ng-container *ngIf="topErrorMessage">
            <div class="row err" *ngFor="let obj of keys1 ">
               <div class="col-md-12 col-lg-12 col-12 col-sm-12 col-xl-12" *ngFor="let value of obj ">
                  <p class="error" id="err">{{value}}</p>
               </div>
            </div>
         </ng-container>
         <div class="row" *ngIf="!topErrorMessage">
            <div class="error "> {{errorMsg}}</div>
         </div>

        <form [formGroup]="addCampaign" #campaign="ngForm" class="form-group">
            <div class="form-group row pt-m-18 mt-3">
                <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="name">Campaign Name</label>
                <div class="col-sm-7 col-12 px-m-0">
                    <input id="name" class="form-control" type="text" formControlName="name">
                    <span class="tip_for_inputs" *ngIf="addCampaign.controls['name'].errors?.required || addCampaign.controls['name'].status === 'INVALID'">Please provide Name for the Campaign.Symbols left {{addCampaign.controls['name'].errors?.minlength==undefined?'3': (addCampaign.controls['name'].errors.minlength.requiredLength-addCampaign.controls['name'].errors.minlength.actualLength)}}</span>
                    <span class="tip_for_inputs" *ngIf="addCampaign.controls['name'].errors?.pattern">Name for the Campaign is invalid.</span>
                </div>
            </div>
            <div class="form-group row pt-m-18 mt-3 mb-6">
                <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="campaignDesc">Campaign Description</label>
                <div class="col-sm-7 col-12 px-m-0">
                <textarea id="description" class="form-control mt-3" type="textarea" rows="3" formControlName="description" ></textarea>
                <span class="tip_for_inputs" *ngIf="addCampaign.controls['description'].errors?.required || addCampaign.controls['description'].status === 'INVALID' ">Please provide Description for the Campaign.Symbols left {{addCampaign.controls['description'].errors?.minlength==undefined?'3': (addCampaign.controls['description'].errors.minlength.requiredLength-addCampaign.controls['description'].errors.minlength.actualLength)}}</span>
                <span class="tip_for_inputs" *ngIf="addCampaign.controls['description'].errors?.pattern">Description for the Campaign is invalid.</span>
                </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Notes</label>
                <div class="col-sm-7 col-12 px-m-0">

                    <textarea id="notes" class="form-control" type="textarea" formControlName="notes" rows="4" placeholder="If you have any notes add here"></textarea>
                 
                </div>
            </div>
            </form>
            <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5">
              <button class="cancel_btn" (click)="cancelCampaignNew()">Go Back</button>
              <app-loading-button  (buttonClick)="campaign.invalid ? print(campaign) : submitCampaignNew()"   [buttonText]="buttonText">
              </app-loading-button>
            </div>
          </div>
        </div>
      
      <div class="table_content  mt-xl-3 mt-lg-3 mt-md-3" >
        
        <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-m-0">
        
           <div  (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag"  class="top-scroll" id="TopScroll">
            <img src="" height=1 [style]="checkTablewidth()">
          </div>
          <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
            <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>  
            <table  *ngIf="!errorMsg" class="table mb-0" id="table-width">
              <thead>
              <tr>
                <th class="campaign-name"><a (click)="setSorting('name')">Campaign Name
                  <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                </a></th>
                <th class="description">Description</th>
                <th class="id">Merchant </th>
                <th class="amount">Agent</th>
                <th class="status">Status</th>
                <th class="created"><a (click)="setSorting('created_at')">Created
                  <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                </a></th>
                <th class="cust">Actions</th>
                
              </tr>
            </thead>

            <tbody>
              <ng-container *ngIf="loadFlag">
                <tr class="loader">
                    <td colspan="7">
                        <div class="loader-container">
                            <img class="table-loader" src="../../../../assets/images/loading.svg">
                    </div>
                  </td>
                </tr>
            </ng-container>
              <ng-container *ngIf="!loadFlag && (payees.data !==undefined) ? payees.data.length > 0:''">
                  <tr *ngFor="let payee of payees.data" class="cursor"   >
                  <td class="campaign-name "><a>{{payee.name}}</a></td>
                  <td class="description"><a>{{payee.description ? payee.description:'No info'}}</a></td>
                  <td class="amount amount_color"><a>{{payee.account.data.legal_name ? payee.account.data.legal_name :'No record' }}</a></td>
                  <td class="amount amount_color"><a>{{payee.agent.data.company_dba ? payee.agent.data.company_dba  :'No field in DB'}}</a></td>
                  <td class="status "><a>{{payee.status ? payee.status :'No field in DB'}}</a></td>
                  <td class="created"><a>{{payee.created_at |date: 'MM/dd/yyyy hh:mm a'}}</a></td>
                  <td class="amount amount_color">
                    <a  [routerLink]="redirect_link_details+'/'+payee.id" [state]="{campaign:payee}" [queryParams]="{page:'add-new-campaign'}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 16" aria-labelledby="view" role="presentation" fill="#729fcf" class="fill-current"><path d="M16.56 13.66a8 8 0 0 1-11.32 0L.3 8.7a1 1 0 0 1 0-1.42l4.95-4.95a8 8 0 0 1 11.32 0l4.95 4.95a1 1 0 0 1 0 1.42l-4.95 4.95-.01.01zm-9.9-1.42a6 6 0 0 0 8.48 0L19.38 8l-4.24-4.24a6 6 0 0 0-8.48 0L2.4 8l4.25 4.24h.01zM10.9 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path></svg>
                    </a>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                    <a [routerLink]="redirect_link_edit+'/'+payee.id"
                    [queryParams]="{id: payee.id,page:'add-new-campaign'}"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" aria-labelledby="edit" role="presentation" fill="#729fcf" class="fill-current"><path d="M4.3 10.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM6 14h2.59l9-9L15 2.41l-9 9V14zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h6a1 1 0 1 1 0 2H2v14h14v-6z"></path></svg> &nbsp; &nbsp; &nbsp; &nbsp;</a>
                    <a (click)="deleteCampaign(payee.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffa8a8" viewBox="0 0 20 20" aria-labelledby="delete" role="presentation" class="fill-current"><path fill-rule="nonzero" d="M6 4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6H1a1 1 0 1 1 0-2h5zM4 6v12h12V6H4zm8-2V2H8v2h4zM8 8a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path></svg></a>
                  </td>
           
                  
                </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      
          <!--pagination start-->
          <div class="row m-0 pt-sm-4 pt-1 border-2" *ngIf="!loadFlag && (payees.data !==undefined) ? payees.data.length > 0:''"> 
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
                 <span class="paginate">
                  {{ 1 + (metadata['current_page'] - 1) * limit }} -
                  <span *ngIf="metadata['current_page'] == 1 &&!limitSelected">{{metadata['count'] +(metadata['current_page'] - 1) * limit}}</span> &nbsp; 
                  <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                          *ngIf="metadata['current_page'] === 1; "> 
                    <option *ngFor="let no of limitArray" [value]="no">{{
                      no
                      }}</option>
                    </select>
                  <span *ngIf="metadata['current_page'] !== 1">{{metadata['count'] +(metadata['current_page'] - 1) * limit}}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
                </span>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
                <ul class="list-inline"
                *ngIf="!loadFlag && (payees.data !==undefined) ? payees.data.length > 0:'' && objectKeys(metadata.links).length > 0">
              <li class="list-inline-item">
                <button type="button" class="btn btn-default btn_prenext pl-m-3" [disabled]="!metadata.links['previous']"
                        (click)="paginate( metadata.links['previous'])">
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                </button>
              </li>
              
              <li class="list-inline-item" *ngFor="let number of numbers()">
                <div *ngIf="+number.toString() ">
                  <a class="page-link" *ngIf="metadata.links.next"
                     [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                     (click)="nextpage(number, metadata.links.next)">{{number}}</a>
                  <a class="page-link"
                     *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)"
                     [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                     (click)="nextpage(number, metadata.links['previous'])">{{number}}</a>
                </div>
                <a class="page-link" *ngIf="!+number.toString()">{{number}}</a>
              </li>
              <li class="list-inline-item">
                <button type="button" class="btn btn-default btn_prenext px-m-0" [disabled]="!metadata.links['next']"
                        (click)="paginate(metadata.links['next']) ">
                  Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
              </li>
            </ul>
  
  
          </div>
        </div>
               
          <!--pagination end-->
        </div>
      </div>
      
    </div>
  </div>
  