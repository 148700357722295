export class DisputeChartModel {
  pi_chart_data?: piChartModel[] = [];
  dispute_volume_chart_data?: disputeVolumeChartData[] = [];
  dispute_count_chart_data ?: disputCountChartData[] = [];
}


export class piChartModel {
  dispute_count?: number;
  data_type?: string;

}

export class disputeVolumeChartData {
  dispute_volume?: number;
  ratio_volume?: number;
  label?: string;
}

export class disputCountChartData {
  dispute_count?: number;
  ratio_count?: number;
  label?: string;
}
