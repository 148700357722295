import {Component, OnInit} from '@angular/core';
import {DisputeDetailsAction} from './dispute-details.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {CasesModel, DisputeContainerModel, FileModel} from '../../../data/model/DisputeModel/dispute.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {ActivatedRoute} from '@angular/router';
import {PermissionConstants} from '../../../shared/constants/permission-constants';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {UploadModal} from '../../../data/model/UploadModel/upload.modal';
import {AlertMessageDialogComponent} from '../../../dialogs/alert-message-dialog/alert-message-dialog.component';


@Component({
  selector: 'app-dispute-details',
  templateUrl: './dispute-details.component.html',
  styleUrls: ['./dispute-details.component.css'],
})
export class DisputeDetailsComponent extends DisputeDetailsAction
  implements OnInit {

  fileList: File[];
  dispute: DisputeContainerModel;
  can_download: boolean;
  step = 0;
  primaryCaseId: any;
  multipartRequest: FormData;
  response: string;
  uploadModel: UploadModal;
  fileURL: string;
  topErrorMessage: string;
  keys: String[];
  keys1: String[];
  casesDataList: ApiResponseModel<CasesModel>;
  card_img_obj = {
    'D': '../../../../assets/images/disc.png', 'V': '../../../../assets/images/visa.png',
    'A': '../../../../assets/images/amex.png', 'M': '../../../../assets/images/master.png',
    'J': '../../../../assets/images/jcb.png'
  };

  constructor(dataManager: DataManagerService, route: ActivatedRoute, snackBar: MatSnackBar, private dialog: MatDialog) {
    super();
    this.dataManager = dataManager;
    this.errorMsg = null;
    this.snackBar = snackBar;
    this.fileList = [];
    this.uploadModel = new UploadModal();
    this.casesDataList = new ApiResponseModel();
    this.route = route;
    this.can_download = this.permissionCheck(
      PermissionConstants.PERMISSION_DOWNLOAD_DISPUTE
    );
    if (this.fetchIdFromRouteQueryParams()) {
      this._getDisputeDetailsUrl =
        AppConstants.BASE_URL +
        AppConstants.SERVICE_NAME_CASES +
        '/' +
        this.id +
        '?include=evidences,dispute_subbmissions';
    }

    this.dispute = new DisputeContainerModel();
    this.getDisputeDetails();
  }

  ngOnInit() {
    this.addfilePicker();
  }

  addfilePicker() {
    if (this.fileList.length < 3) {
      if (!this.isNullOrUndefined(this.fileList[this.fileList.length - 1]) || this.fileList.length < 1) {
        let file: File;
        this.fileList.push(file);
      } else {
        this.showSnackBar('Select a file first!', 'Ok', 3000);
      }

    }
  }

  removeFiles(index) {
    console.log(this.fileList);
    if (this.fileList.length > 1) {
      this.fileList.splice(index, 1);
    }

    console.log(this.fileList);
  }

  openFilePicker(i) {
    document.getElementById(i.toString()).click();
  }

  addFile(file: FileList, index) {
    this.fileList[index] = file[0];
  }

  protected passDisputeResponse(res) {
    this.dispute = res;
    this.primaryCaseId = this.dispute.primary_case.data.is_primary_case ? this.dispute.primary_case.data.id
      : this.dispute.related_cases.data.find(x => x.is_primary_case);
    if ((this.primaryCaseId)) {
      this.primaryCaseId = this.primaryCaseId.id;
    }
    this.response = 'Case number#: ' + this.dispute.primary_case.data.case_number;
    console.log('disp res', res, this.primaryCaseId);

  }

  protected passDisputeError(err) {
    console.log(err);
  }

  download(data) {
    window.open(data);
  }


  viewFile(file) {

    console.log('viewFile', file);
    this._getChargeBackPdf = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CASES + '/' + this.id + AppConstants.SERVICE_NAME_FILE + '/' + file.id;
    this.getDisputeChargeBackPdf();
    this.showSnackBar('File is loading.', 'Ok', 3000);

  }

  viewEvidence(file) {

    console.log('viewFile', file);
    this._getChargeBackPdf = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVIDENCES + '/' + file.id + AppConstants.SERVICE_NAME_DOWNLOAD;
    this.getDisputeChargeBackPdf(file);
    this.showSnackBar('File is loading.', 'Ok', 3000);

  }


  protected passChargeBackPdfResponse(res: any) {
    console.log(res);
    this.openFileInBrowser(res);
  }

  protected passChargeBackPdfError(err: any) {
    console.log(err);
  }

  protected passChargeBackPdfComplete() {
   // window.open(this.fileURL, '_blank');
    const a = document.createElement('a');
    a.href = this.fileURL;
    a.setAttribute('target', '_blank');
    a.click();
  }

  openFileInBrowser(res) {
    console.log(res);
    const pdfFile = res;
    this.fileURL = URL.createObjectURL(pdfFile);
  }

  switchCases(obj) {
    console.log(obj, 'switchCases');
    this.dispute.related_cases.data = [this.dispute.primary_case.data, ...this.dispute.related_cases.data.filter(cases => cases.id !== obj.id)];
    this.dispute.primary_case.data = obj;
    this.response = 'Case Number#: ' + obj.case_number;
  }

  protected postEvidenceResponse(res: any, i) {

    this.dispute.primary_case.data.evidence.data.push(res.data);
    this.uploadFiles(i + 1);
  }

  protected postEvidenceError(error: any, i) {
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    this.uploadFiles(i + 1 );
  }

  onSubmit() {
    if ((this.fileList[0] == null || this.fileList[0] == undefined)) {
      this.showSnackBar('Please select a file first!', 'Ok', 3000);
    } else {
      this._postEvidenceUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CASES + '/' + this.dispute.primary_case.data.id + AppConstants.SERVICE_NAME_EVIDENCE;
      this._postWrittenResUrl = AppConstants.BASE_URL +  AppConstants.SERVICE_NAME_CASES + '/' + this.dispute.primary_case.data.id + AppConstants.SERVICE_NAME_SUBMIT;
      this.uploadFiles(0);
    }

  }

  uploadFiles(i) {
    if ((this.fileList[i]) && i < this.fileList.length) {
      this.multipartRequest = new FormData();
      this.multipartRequest.append('file', this.fileList[i], this.fileList[i].name);
      this.multipartRequest.append('text', typeof this.uploadModel.text === 'undefined' ? '' : this.uploadModel.text);
      this.postEvidence(this.multipartRequest, i);
    } else {
      this.fileList = [];
      this.addfilePicker();
      if ((this.response)) {
        this.uploadWrittenResponse();
      }
    }

  }
  showComment(text, cnt) {
    const data = {
      head : 'Comment to Document ' + (cnt + 1),
      message: text,
      disableClose: true
    };
    const dialog = this.dialog.open(AlertMessageDialogComponent, {data});
  }
  uploadWrittenResponse() {
      const postBody = {};
      postBody['message'] = this.response;
      this.postWrittenRes(postBody);
  }

  protected postWrittenResError(err) {
    console.log(err);
    this.response = '';


  }

  protected postWrittenResResponse(res) {
    console.log(res);
  }

  }
