import {BaseModel} from '../BaseModel/base.model';
export class AccountBrandingModel extends BaseModel {
    created_at?: Date;
    id: string;
    real_id: string;
    updated_at: Date;
    success_url: string;
    failure_url:string;
    product_desc:string
    address:string;
    website_url:string;
  }
