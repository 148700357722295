<div class="dialog-head">Create new bridge account</div>
<div *ngIf="topErrorMessage">
  <div class="row err" *ngFor="let obj of keys1 " >
    <div class="col-md-12 mb-1" *ngFor="let value of obj " >
      <p style="margin-bottom: 1.5%" class="col-md-12 error" id="err" >{{value}}</p>
    </div>
  </div>
</div>
<div class="row err" *ngIf="!topErrorMessage && errMsg">
  <p class="col-md-12 error" id="err1">{{errMsg}}</p>
</div>
<div class="container">
  <mat-dialog-content class="dialog-content">
    <form #createAccountDialog="ngForm">
      <div class="form-group row">
        <label class="col-5 col-form-label accountname">Account name:</label>
        <span class="error_sign" id="name1">!</span>
        <div class="col-7">
          <input type="text" class="form-control accountinput" id="name" [(ngModel)]="account_name" name="name" #name="ngModel" required>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-5 col-form-label accountname">Email Id:</label>
        <span class="error_sign" id="email1">!</span>
        <div class="col-7">
          <input type="email" class="form-control accountinput" id="email" [(ngModel)]="emailid" name="email" #email="ngModel" required>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-10" style="margin-left: 15%; margin-top: 2%;">

          <app-loading-button (buttonClick)="createAccount()" [disable]="createAccountDialog.invalid" [loadFlag]="loadFlag"
                              [buttonText]="'Create bridge account'" ></app-loading-button>

          <button mat-button [mat-dialog-close]="false" class="button cancel_btn" style="height: 96%; margin-left: 2%">Cancel</button>
        </div>
      </div>

    </form>
  </mat-dialog-content>
</div>
