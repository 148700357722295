<div class="accnt_search">
    <input class="form-control input-sm" type="search" placeholder="search" (input)="searchHits.next()" [(ngModel)]="searchText" />
</div>
<app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
<table *ngIf="!errorMsg" class="table">
    <thead>
        <tr>
            <th class="event"><a (click)="setSorting('fee_amount')" [class]="orderBy === 'fee_amount' ? sortBy : ''">AMOUNT</a></th>
            <th class="id"><a (click)="setSorting('charge_id')" [class]="orderBy === 'charge_id' ? sortBy : ''">DESCRIPTION</a></th>
            <th class="date"><a (click)="setSorting('created_at')" [class]="orderBy === 'created_at' ? sortBy : ''">DATE</a></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="loadFlag">
            <tr class="loader">
                <td colspan="7">
                    <div class="loader-container">

                        <img class="table-loader" src="../../../../assets/images/loading.svg">
                    </div>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="!loadFlag && collected_fees.data.length > 0">
            <tr *ngFor="let ApplicationFee of collected_fees.data">
                <td class="account">
                    <a [routerLink]="redirect_link" [queryParams]="{id: ApplicationFee.id}">
          ${{ApplicationFee.fee_amount ? ApplicationFee.fee_amount/100 : '---'}} USD <span class="refunded">refunded</span>
        </a>
                </td>
                <td class="status">
                    <a [routerLink]="redirect_link" [queryParams]="{id: ApplicationFee.id}">
          {{ApplicationFee.charge_id ? ApplicationFee.charge_id : '---'}}
        </a>
                </td>
                <td class="type">
                    <a [routerLink]="redirect_link" [queryParams]="{id: ApplicationFee.id}">
          {{ApplicationFee.created_at ? (ApplicationFee.created_at | dateFormat: 'MM/dd/yyyy'): '-- / -- / --'}}
        </a>
                </td>
            </tr>
        </ng-container>
    </tbody>
    <tfoot>
        <!--pagination start-->
        <tr class="bottom-row">
            <td colspan="2" class="bottom-row-left" *ngIf="collected_fees.meta.pagination.total">
                <span class="paginate">
            {{1 + (collected_fees.meta.pagination.current_page - 1) * limit}} -
            <select
            [(ngModel)]="limit"
            (change)="setUrl()"
            class="page-limit"
            *ngIf="collected_fees.meta.pagination.current_page === 1"
          >
          <option *ngFor="let no of limitArray" [value]="no">{{
              no
            }}</option>
          </select>
          <span *ngIf="collected_fees.meta.pagination.current_page !== 1">
          {{collected_fees.meta.pagination.count + (collected_fees.meta.pagination.current_page - 1) * limit}}
          </span> &nbsp;of &nbsp;{{ collected_fees.meta.pagination.total }}
                </span>
            </td>
            <td colspan="2" class="bottom-row-right">
                <button type="button" class="btn btn-default" [disabled]="!disablePrev" (click)="paginate(collected_fees.meta.pagination.links.previous)">Previous
      </button>
                <button type="button" class="btn btn-default" [disabled]="!disableNext" (click)="paginate(collected_fees.meta.pagination.links.next)">Next
      </button>
            </td>
        </tr>
        <!--pagination end-->
    </tfoot>

</table>
