import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class AccountDetailsAction extends BaseComponent {
  protected _accountDetailsUrl: string;
  protected _getPayoutsUrl: string;
  protected _getConnectedFeesUrl: string;

  /**
   * func to call Account details
   */
  protected getAcountDetails() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._accountDetailsUrl, 0)
      .subscribe(
        res => {
          this.passAcountDetailsResponse(res);
          if (res.data.length === 0) {
            this.errorMsg = ErrorConstants.payoutDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        err => {
          this.errorMsg = this.networkErrorHandler(err.error, ErrorConstants.payoutDetailErrorMsg);
          this.passAcountDetailsError(err);
        }
      );
  }

  /**
   * func to call payout list on account
   */
  getPayout() {
    this.dataManager.getRequest(this._getPayoutsUrl, 0)
      .subscribe(
        res => {
          this.passPayout(res);
          if (res.data.length === 0) {
            this.errorMsg = ErrorConstants.payoutErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        err => {
          this.errorMsg = this.networkErrorHandler(err.error, ErrorConstants.payoutErrorMsg);
          this.passPayoutError(err);
        }
      );
  }

  /**
   * func to call collectedfees for account
   */
  getConnectedFess() {
    this.dataManager.getRequest(this._getConnectedFeesUrl, 0)
      .subscribe(
        res => this.passConnectedFess(res),
        error => {
          this.networkErrorHandler(error.error);
          this.passConnectedFessError(error);
        }
      );
  }

  protected abstract passConnectedFess(res);
  protected abstract passPayout(res);
  protected abstract passAcountDetailsResponse(res);
  protected abstract passAcountDetailsError(err);
  protected abstract passPayoutError(err);
  protected abstract passConnectedFessError(err);

}





// import {BaseComponent} from '../../abstract/base.component';
// import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
// import {ErrorConstants} from '../../shared/constants/error-constants';
//
// export abstract class AccountDetailsAction extends BaseComponent {
//   protected _accountDetails: string;
//   protected _collectedfeesUrl: string;
//   protected _transfersUrl: string;
//   protected _paymentsUrl: string;
//   protected _payoutUrl: string;
//   errorMessage: string;
//   protected getAccountDetails() {
//     this.dataManager.getRequest(this._accountDetails, 0)
//       .subscribe(
//         res => {
//           this.passAccountDetails(res);
//           if (res.data.length === 0) {
//             this.errorMsg = ErrorConstants.accountDetailErrorMsg[0];
//           } else {
//             this.errorMsg = null;
//           }
//         },
//         error => {
//           this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.accountDetailErrorMsg);
//           this.errorMessage = this.errorMsg;
//           this.passAccountDetailsError(error);
//         }
//       );
//   }
//
//   protected abstract passAccountDetails(res);
//   protected abstract passAccountDetailsError(err);
//
//   // getCollectedFeesDetails() {
//   //   this.dataManager.getRequest(this._collectedfeesUrl, 0)
//   //     .subscribe(
//   //       res4 => this.passCollectedFeesDetails(res4),
//   //       err => this.passCollectedFeesDetailsError(err));
//   // }
//   // protected abstract passCollectedFeesDetails(res);
//   // protected abstract passCollectedFeesDetailsError(error);
//
//
//   protected getCollectedFeesDetails() {
//     this.dataManager.getRequest(this._collectedfeesUrl, 0)
//       .subscribe(
//         res4 => {
//           this.passCollectedFeesDetails(res4);
//           if (res4.data.length === 0) {
//             this.errorMsg = ErrorConstants.collectedFeesErrorMsg[0];
//           } else {
//             this.errorMsg = null;
//           }
//         },
//         err => {
//           this.errorMsg = this.networkErrorHandler(err.error, ErrorConstants.collectedFeesErrorMsg);
//           this.errorMessage = this.errorMsg;
//           this.passCollectedFeesDetailsError(err);
//         }
//       );
//   }
//
//   protected abstract passCollectedFeesDetails(res);
//   protected abstract passCollectedFeesDetailsError(err);
//
//   getTransfersDetails() {
//     this.dataManager.getRequest(this._transfersUrl, 0)
//       .subscribe(
//         res1 => this.passTransfersDetails(res1),
//         err => this.passTransfersDetailsError(err));
//   }
//   protected abstract passTransfersDetails(res1);
//   protected abstract passTransfersDetailsError(error);
//
//   getPaymentsDetails() {
//     this.dataManager.getRequest(this._paymentsUrl, 0)
//       .subscribe(
//         res2 => this.passPaymentsDetails(res2),
//         err1 => this.passPaymentsDetailsError(err1));
//   }
//   protected abstract passPaymentsDetails(res2);
//   protected abstract passPaymentsDetailsError(err1);
//
//   getPayoutsDetails() {
//     this.dataManager.getRequest(this._payoutUrl, 0)
//       .subscribe(
//         res3 => this.passPayoutsDetails(res3),
//         err => this.passPayoutsDetailsError(err));
//   }
//   protected abstract passPayoutsDetails(res3);
//   protected abstract passPayoutsDetailsError(error);
// }
