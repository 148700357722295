<div class="container-fluid overlay">
  <div class="row image">
    <img class="left-image" src="../../../assets/images/left-image.png"  >
    <img class="right-image d-sm-flex d-none" src="../../../assets/images/right-image.png"  >
    <img class="right-image d-sm-none d-flex" src="../../../assets/images/right-image-mobile.png"  >
  </div>
  <div class="row">

    <div class="col-sm-12 col-12 signLogo" >
      <img class="logo-align img-fluid"  src="{{dataManager.loginlogo}}" />
    </div>
  </div>

  <div class="row">
    <div class="container-card ipad_iphone">


      <ng-container *ngIf="topErrorMessage">
        <div class="row err" *ngFor="let obj of keys1 " >
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let value of obj " >
            <p class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error" id="err" >{{value}}</p>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="!topErrorMessage">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error mb-1"> {{errorMsg}}</div>
      </div>
      <br>
        <h3 class="text-center heading2">Reset password</h3>
        <div class="row">
        <p class="text-center sub-heading mt-3 px-2">Enter the email address associated with your account and we'll send you a link to reset you password</p>
        </div>
      <div class="form-group row">
        <span class="error_sign col-2" id="email1">!</span>
        <div class="col-sm-12">
          <div class="row">
            <label class="col-md-12 l_label text-left">Email</label>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <input type="text" class="form-control" name="username" (keydown)="onKeydown($event)"  required
                   [(ngModel)]="username"  id="username"
                  >
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 mt-3">
          <button type="submit" [disabled]="loadFlag" class="bt_forgot form-control" (click)="resetpass()" >Send Link<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
        </div>
      </div>
      <div class="col-12 text-right back_button">
        <span (click)="back()" class="b_back pointer">  Back</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5 col-md-5 col-sm-5 pt-3 container justify-content-center text-center privacy-m" >
      <div class="col">
        <a href="https://www.payarc.com/privacy-policy" class="privacy" target="_blank">Privacy Policy</a><span class="p_line"> | </span><a href="https://www.payarc.com/company/terms-and-conditions" class="privacy" target="_blank">Terms &amp; Conditions</a>
      </div>

    </div>
  </div>
</div>


