import {Component, Inject, OnInit} from '@angular/core';
import {SettingsAction} from './settings.action';
import {AppConstants} from '../../../shared/constants/app-constants';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends SettingsAction implements OnInit {

  constructor(dataManager: DataManagerService, @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.dataManager = dataManager;
    this._getSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS + '?limit=200';
    // '/account-settings/?limit=500'; // + AppConstants.SERVICE_NAME_ACCOUNT_SETTING  + AppConstants.SERVICE_NAME_TOKENIZE;
    this._setSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS;
    // AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_TOKENIZE;
    this.getSetting();
  }

  ngOnInit() {
  }

  setTokenize() {
    const postBody = {};
    postBody['id'] = this.settings.tokenize_id;
    postBody['tokenize'] = this.settings.tokenize ? 1 : 0;
    this.setSetting(postBody);
  }

  setMetadata() {
    const postBody = {};
    postBody['id'] = this.settings.display_transactional_metadata_id;
    postBody['display_transactional_metadata'] = this.settings.display_transactional_metadata ? 1 : 0;
    this.setSetting(postBody);
  }

  setChargeEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.disable_charge_notification_email_id;
    postBody['disable_charge_notification_email'] = this.settings.disable_charge_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }
  setChargebackEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_chargeback_notification_email_id;
    postBody['enable_chargeback_notification_email'] = this.settings.enable_chargeback_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }
  setChargeSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.disable_charge_notification_sms_id;
    postBody['disable_charge_notification_sms'] = this.settings.disable_charge_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }
  setChargebackSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_chargeback_notification_sms_id;
    postBody['enable_chargeback_notification_sms'] = this.settings.enable_chargeback_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }

  setDepositEmailNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_deposit_notification_email_id;
    postBody['enable_deposit_notification_email'] = this.settings.enable_deposit_notification_email ? 1 : 0;
    this.setSetting(postBody);
  }

  setDepositSmsNotification() {
    const postBody = {};
    postBody['id'] = this.settings.enable_deposit_notification_sms_id;
    postBody['enable_deposit_notification_sms'] = this.settings.enable_deposit_notification_sms ? 1 : 0;
    this.setSetting(postBody);
  }

  protected passSetSettingError(err) {
  }

  protected passSetSettingResponse(res) {
  }
  protected passGetSettingError(error) {

  }

}
