import { BaseListComponent } from "../../../abstract/base-list.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class GetCustomerAction extends BaseListComponent {
  protected _getcustomerexcel: string;
  protected abstract passCustomerExcelResponse(res);
  protected abstract passCustomerExcelError(err);

  getcustomerlogexcel() {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(this._getcustomerexcel, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passCustomerExcelResponse((new Blob([res], {type: 'application/vnd.ms-excel'})));
      },
      (err) => {
        this.loadFlag = false;
        this.passCustomerExcelError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
}
