<div class="row" [hidden]="!hasSettings">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
    <div class="b_shadow">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 heading-card left-heading">
        <span class="pl-4">Enable Kount</span>
      </div>
      <div class="px-xl-5 px-lg-5  py-xl-4 py-lg-4">
        <div class="row">
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 p-lg-0 p-md-4 p-0">
            <div class="row">
              <!--data-->
              <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 py-sm-2 py-3">
                <mat-slide-toggle [(ngModel)]="settings.kount_enable" [disabled]="loadFlag" [color]="'primary'">
                  Enable
                </mat-slide-toggle>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-4 col-sm-4 col-4">
                <label for="api" class="table-data details_info">API key: </label>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8">
                <input type="text" class="form-control" id="api"
                       required
                       [(ngModel)]="settings.api" name="api" #api="ngModel">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-5 col-sm-5 col-7">
                <label for="mid" class="table-data details_info">Kount Merchant ID: </label>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-7 col-sm-7 col-5">
                <input type="text" class="form-control" id="mid"
                       required
                       [(ngModel)]="settings.mid" name="api" #mid="ngModel">
              </div>
              <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12">
                <button type="button" (click)="onSubmit()" class="btn btn-primary btn-block float-right mb-2 mx-w-35">Save
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" *ngIf="settings.kount_enable">
                <label for="web_hook" class="table-data details_info">Kount Feedback endpoint: </label>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8" *ngIf="settings.kount_enable">
                <span class="form-control border-0" id="web_hook">{{ settings.web_hook }}</span>
              </div>
<!--              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"></div>-->
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" *ngIf="settings.kount_enable">
                  <button type="button" class="btn btn-primary btn-block float-right mb-2" (click)="copy( settings.web_hook )"
                          id="myTooltip">
                    Copy
                  </button>
                </div>


            </div>
          </div>
        </div>
      </div>

      <div class=" col-sm-10">
        <ul>
          <li *ngFor="let item of errorMsg; let i = index" class="err_url">
            {{i + 1}}: {{item}}
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>
