import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {MerchantSettingModel} from '../../../data/model/MerchantSettingModel/merchantsetting.model';

@Directive()
export  abstract class SettingsAction extends BaseComponent {
  protected _setSettingUrl: string;
  public settings = new MerchantSettingModel();
  protected _getSettingUrl =  AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS + '?limit=500';

  protected getSetting() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getSettingUrl, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passGetSettingResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.passGetSettingError(err);
        }
      );
  }
  protected abstract  passGetSettingError(err: any);
  protected  passSetSettingResponse(res) {}
  protected passSetSettingError(err) {}

  protected passGetSettingResponse(res) {
    res.data.forEach(obj => {
      if (obj.key === 'display_transactional_metadata') {
        this.settings.display_transactional_metadata_id = obj.id;
        this.settings.display_transactional_metadata = obj.value === '1';
      } else if (obj.key === 'tokenize') {
        this.settings.tokenize_id = obj.id;
        this.settings.tokenize = obj.value === '1';
      } else if (obj.key === 'disable_charge_notification_email') {
        this.settings.disable_charge_notification_email = obj.value === '1';
        this.settings.disable_charge_notification_email_id = obj.id;
      } else if (obj.key === 'disable_charge_notification_sms') {
        this.settings.disable_charge_notification_sms_id = obj.id;
        this.settings.disable_charge_notification_sms = obj.value === '1';
      } else if (obj.key === 'enable_chargeback_notification_email') {
        this.settings.enable_chargeback_notification_email_id = obj.id;
        this.settings.enable_chargeback_notification_email = obj.value === '1';
      } else if (obj.key === 'enable_chargeback_notification_sms') {
        this.settings.enable_chargeback_notification_sms_id = obj.id;
        this.settings.enable_chargeback_notification_sms = obj.value === '1';
      } else if (obj.key === 'enable_deposit_notification_email') {
        this.settings.enable_deposit_notification_email_id = obj.id;
        this.settings.enable_deposit_notification_email = obj.value === '1';
      } else if (obj.key === 'enable_deposit_notification_sms') {
        this.settings.enable_deposit_notification_sms_id = obj.id;
        this.settings.enable_deposit_notification_sms = obj.value === '1';
      } else if (obj.key === 'display_signature_pad') {
        this.settings.display_signature_pad_id = obj.id;
        this.settings.display_signature_pad = obj.value === '1';
      }  else if (obj.key === 'display_prepaid') {
        this.settings.display_prepaid_id = obj.id;
        this.settings.display_prepaid = obj.value === '1';
      } else if (obj.key === 'Enable ACH payments') {
        this.settings.enable_ach_payments = obj.value === '1';
      } else if (obj.key === 'allowed_ach_types') {
        this.settings.allowed_ach_types = obj.value;
      } else if (obj.key === 'debt_repayment_enabled') {
        this.settings.debt_repayment_enabled = obj.value === '1';
      }
    });

    localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS, JSON.stringify(this.settings));
  }
  protected setSetting(postBody) {
    this.loadFlag = true;
    this.dataManager.postRequest(this._setSettingUrl, postBody, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passSetSettingResponse(res);
          localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS, JSON.stringify(this.settings));
        },
        err => {
          this.loadFlag = false;
          this.passSetSettingError(err);
        }
      );
  }

}
