import { BaseListComponent } from "../../abstract/base-list.component";
import { BaseComponent } from "../../abstract/base.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class GetExportExcelAction extends BaseListComponent {
  protected _getExportexcel: string;
  protected abstract passExportExcelResponse(res);
  protected abstract passExportExcelError(err);

  getExportlogexcel(_getExportexcel) {
    this.loadFlag = true;
    this.dataManager.getRequestExcel(this._getExportexcel, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passExportExcelResponse(res);
      },
      (err) => {
        this.loadFlag = false;
        this.passExportExcelError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
}
