import {Directive, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appMinMaxValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: MinMaxValidatorDirective, multi: true}
  ]
})
export class MinMaxValidatorDirective implements Validator, OnInit {
  @Input() min: number;
  @Input() max: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  validate(c: FormControl): ValidationErrors | null {
    console.log(c.value, 'this.max', this.max, 'this.min', this.min);
    if (!this.isNullOrUndefined(c.value) && !isNaN(c.value) && c.value !== '') {
      if (c.value > this.max) {
        return {'max': true, 'requiredValue': this.max};
      }
      if (c.value < this.min) {
        return {'min': true, 'requiredValue': this.min};
      }
    }
    return null;
  }
  isNullOrUndefined(value: any) {
    return value == null || value == undefined;
  }

}
