  <div class="container-fluid cus_block">
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-xs-12 padding-m">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <h3 class="headings">Payee Summary</h3>
        </div>
      </div>
    </div>
    <div class="text-right col-xl-6 col-lg-12 col-md-12 col-xs-12 new_cus mt-2 pt-1" *ngIf="!matchUrl('create-customer') && !matchUrl('customer-details') && !matchUrl('update-customer') && !matchUrl('add-card') && !matchUrl('edit-card') && !matchUrl('create-subscription') && !matchUrl('capture-payment')" >
      <ul class="list-inline">
        <li  class="list-inline-item cust-filter">
          <app-filter class="" [filter_storage_key]="filter_storage_key" [count_storage_key]="count_storage_key" (campaignNameChange)="changeStateOfCampaignName($event)"
            [filter]="filter"></app-filter>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="payee_bdy">
  <div class="table_content  mt-xl-3 mt-lg-3 mt-md-3" >
      <div class="row px-xl-4 px-lg-4 px-md-4 py-4 d-flex justify-content-between" [class.pt-3]="matchUrl('payee-summary')">
      
        <div class="col-lg-7 col-xl-6 col-md-12 col-sm-9 col-8 mb-md-2 mob_calendar mb-xl-0 mb-lg-0 ">
     
        </div>
        <div class="col-lg-4 col-xl-4 col-md-12 col-sm-3 col-4 text-right">
    
          <button class="export_btn" (click)="createNewPayee()">Create New Payee</button>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-m-0">
          <div  (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag"  class="top-scroll" id="TopScroll">
            <img src="" height=1 [style]="checkTablewidth()">
          </div>
          <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
          <h4 class="px-3"  *ngIf="campaignName!==''"> Payees for campaign: <strong>{{campaignName}}</strong></h4>
            <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
            <table *ngIf="!errorMsg" class="table mb-0" id="table-width">
              <thead>
              <tr>
                <th class="payee-name"><a (click)="setSorting('first_name')">Payee Name
                  <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                </a></th>
                <th class="status">Payee Dashboard Access</th>
                <th class="created"><a (click)="setSorting('created_at')">Payee Creation Date
                  <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                </a> </th>
                <th class="created"><a (click)="setSorting('updated_at')"> Payee Updated Date
                  <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                </a></th>
                <th class="campaign-assigned" *ngIf="campaignName ==''" >Campaigns Assigned to</th>
                <th class="actions">Actions</th>
                
              </tr>
              </thead>
              <tbody>
              <ng-container *ngIf="loadFlag">
                <tr class="loader">
                  <td colspan="7">
                    <div class="loader-container">
                      <img class="table-loader" src="../../../../assets/images/loading.svg">
                    </div>
                  </td>
                </tr>
              </ng-container>
    
              <ng-container >
                <tr *ngFor="let payee of listOfPayees;" [payee]="payee" class="cursor" >
                  <td class="payee-name"><a>{{payee.first_name}} {{payee.last_name}}</a></td>
                  <td class="status"><a>{{(payee.dashboard_access!==null||undefined)
                    ? (payee.dashboard_access
                    ?'Yes':'No')
                    :'No info'
                  }}
                  </a>
                  </td>
                  <td class="created"><a>{{payee.created_at |date: 'MM/dd/yyyy hh:mm a'}}</a></td>
                  <td class="created"><a>{{payee.updated_at | date: 'MM/dd/yyyy hh:mm a'}}</a></td>
                  <td class="campaign-assigned" *ngIf="campaignName==''" >
                    <tr class="nested-row"  *ngFor="let campaigns of payee.campaigns.data">
                      <td class="nested-data">{{campaigns.name}} | {{campaigns.status}}</td>
                    </tr>
                  </td>
                  <td class="actions">
                    <a  [routerLink]="redirect_link" [queryParams]="{payee:payeeId}" [state]="{payee}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 16" aria-labelledby="view" role="presentation" fill="#729fcf" class="fill-current"><path d="M16.56 13.66a8 8 0 0 1-11.32 0L.3 8.7a1 1 0 0 1 0-1.42l4.95-4.95a8 8 0 0 1 11.32 0l4.95 4.95a1 1 0 0 1 0 1.42l-4.95 4.95-.01.01zm-9.9-1.42a6 6 0 0 0 8.48 0L19.38 8l-4.24-4.24a6 6 0 0 0-8.48 0L2.4 8l4.25 4.24h.01zM10.9 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path></svg>
                    </a>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <a  [routerLink]="redirect_link_edit+'/'+payee.id"
                    [queryParams]="{page: 'payee-summary',campaignId:campaignId}"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" aria-labelledby="edit" role="presentation" fill="#729fcf" class="fill-current"><path d="M4.3 10.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM6 14h2.59l9-9L15 2.41l-9 9V14zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h6a1 1 0 1 1 0 2H2v14h14v-6z"></path></svg>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    </a>
                    <a (click)="deletePayee(payee.id)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffa8a8" viewBox="0 0 20 20" aria-labelledby="delete" role="presentation" class="fill-current"><path fill-rule="nonzero" d="M6 4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6H1a1 1 0 1 1 0-2h5zM4 6v12h12V6H4zm8-2V2H8v2h4zM8 8a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path></svg></a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <!--pagination start-->
          <div class="row m-0 pt-sm-4 pt-1 border-2" *ngIf="!loadFlag && (listOfPayees !==undefined) ? listOfPayees.length > 0:''">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
                <span class="paginate">
                  {{ 1 + (metadata['current_page'] - 1) * limit }} -
                  <span *ngIf="metadata['current_page'] == 1 &&limitSelected">{{metadata['count'] +(metadata['current_page'] - 1) * limit}}</span> &nbsp; 
                  <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                          *ngIf="metadata['current_page'] === 1; "> 
                    <option *ngFor="let no of limitArray" [value]="no">{{
                      no
                      }}</option>
                    </select>
                  <span *ngIf="metadata['current_page'] !== 1">{{metadata['count'] +(metadata['current_page'] - 1) * limit}}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
                </span>
            </div> 
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
              <ul class="list-inline"
                  *ngIf="!loadFlag && (listOfPayees !==undefined) ? listOfPayees.length > 0:'' && objectKeys(metadata.links).length > 0">
                <li class="list-inline-item">
                  <button type="button" class="btn btn-default btn_prenext pl-m-3" [disabled]="!metadata.links['previous']"
                          (click)="paginate( metadata.links['previous'])">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                  </button>
                </li>
                <li class="list-inline-item" *ngFor="let number of numbers()">
                  <div *ngIf="+number.toString() ">
                    <a class="page-link" *ngIf="metadata.links.next"
                       [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links.next)">{{number}}</a>
                    <a class="page-link"
                       *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)"
                       [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links['previous'])">{{number}}</a>
                  </div>
                  <a class="page-link" *ngIf="!+number.toString()">{{number}}</a>
                </li>
                <li class="list-inline-item">
                  <button type="button" class="btn btn-default btn_prenext px-m-0" [disabled]="!metadata.links['next']"
                          (click)="paginate(metadata.links['next']) ">
                    Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
    
    
            </div>
          </div>
        </div>
         <!--pagination end-->
      </div>
 
    </div>
    </div>
    