import {BaseComponent} from '../../abstract/base.component';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import { Directive } from "@angular/core";

@Directive()
export abstract class RegisterAction extends BaseComponent {
  protected _registerUrl: string;
  protected abstract passResponse(res);
  protected abstract passError(error: ApiErrorModel);
  registerUser(postBody) {
    this.dataManager.login(this._registerUrl, postBody)
      .subscribe(
        response => this.passResponse(response),
        error => {
          this.networkErrorHandler(error.error);
          this.passError(error.error);
        }
      );
  }
}
