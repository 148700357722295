<div class="container-fluid main_container">

  <mat-sidenav-container (backdropClick)="close()"  [hasBackdrop]="side.value === 'over' ? true : false">
    <mat-sidenav #sidenav [opened]="toggleSideNav" [disableClose]="true" [mode]="side.value"  *ngIf="!matchUrl('/signin')"
                 class="side-nav side-block" (keydown.escape)="close()">
      <div class="sidenave-items ">
       <div class="row">
        <div class="col-md-10 col-9 logo_bt">
          <img (click)="toggle()"
               src="{{dataManager.logoUrl }}" class="logo_icon img-fluid">
        </div>
        <div class="col-md-2 col-3 profile_toggle dropdown p_icon mob_view" >
          <span>
                    <span class="prfile_first" (click)="userProfile(); showSideNav()   " *ngIf="userName" >{{userNameIcon | camalizeStr}}</span>
                </span>
        </div>
       </div>
        <div class="row mob_view" >

          <div class="col-12 text-right">
            <div class="loader loader--style8" title="loading" *ngIf="!currentAccountName && menuName == 'Home'"
                 style="float: right;">
              <img src="../../../assets/images/uploading.svg">
            </div>
            <div class="dropdown" *ngIf="currentAccountName">
              <span class="switch-account "  (click)="switchAccountMob();  showSideNav()" >
                  {{activeUser}}

              </span>
            </div>
          </div>
        </div>
        <ul class="col-md-12 no-padding ">
          <li class="main-list" *ngFor=" let menu of dataManager.menuArray"> <!--menuList-->
            <a class="side-nav-menu menu_list" (click)="myMenuClick(menu);clearFiltersFromLocal()"
               [routerLink]="menu.routes"
               routerLinkActive="link-active"
               *ngIf="menu.subMenu === null">
              <div class="icons"><img class="menu-icons {{menu.name | lowercase}}" src="../../../assets/fwdpayarcicons/{{menu.name | lowercase}}.svg"></div>
              <div class="icon_label" > {{menu.name}}</div>
<!--              <i *ngIf="menu.subMenu != null"-->
<!--                 [class]="ActiveMenu === menu.name ? 'fa fa-caret-down' : 'fa fa-caret-right'"></i>-->
            </a>
            <a class="side-nav-menu menu_list"
               *ngIf="menu.subMenu != null" [routerLink]="menu.routes" routerLinkActive="link-active"
               (click)="menuClick(menu);clearFiltersFromLocal()">
              <div class="icons"> <img class="menu-icons {{menu.name | lowercase}}" src="../../../assets/fwdpayarcicons/{{menu.name | lowercase}}.svg"></div>
              <div class="icon_label" >{{menu.name}}
<!--                <i *ngIf="menu.subMenu != null" [class]="ActiveMenu === menu.name ? 'fa fa-caret-down' : 'fa fa-caret-right'"></i>-->
              </div>

            </a>
            <ul *ngIf="menu.subMenu != null ? menu.subMenu[0].routerLink=== 'batch-detail'? false: true :''" class="submenu-container"
                [ngStyle]="{'display': (ActiveMenu === menu.name ? 'block' : 'none')} ">
              <li *ngFor="let submenu of menu.subMenu" class="submenu-li">
                <a class="submenu" [routerLink]="submenu.routerLink" routerLinkActive="link-active-sub" (click)="clickedSubmenu(submenu);clearFiltersFromLocal()">
                  <span class="submenu-text"
                        >{{submenu.name}}</span></a>
              </li>
            </ul>
          </li>
        </ul>
        <p class="p_vers">Version 2.0</p>
      </div>

    </mat-sidenav>

    <mat-sidenav-content class=" right_content_block " [ngClass]="{'w-100 mobile_view_right': matchUrl('/signin') }">
      <div class="app-loader" *ngIf="loadFlag">
        <img src="../../../assets/images/loader2.svg">
      </div>

      <app-tool-bar class="row fixed-top mob_tool" (logoClicked)="showSideNav()" *ngIf="!matchUrl('/signin')"></app-tool-bar>

      <div #routerOutlet class="page-container">
        <div class="body-contain"  id='body_contain'>
          <div class="row no-margin">
            <div class="col-md-12 no-padding">
              <div *ngIf="!matchUrl('/signin')">
              <button class="btn btn-link d-sm-none rounded-circle go_back" (click)="goBack()"><img src="../../../assets/images/back.svg"></button>
              <button  class="btn btn-link d-sm-none rounded-circle toggle_btn" *ngIf="!matchUrl('/signin')" (click)="showSideNav()">
                <img src="../../../assets/images/accordian.svg">
              </button>
              </div>
              <router-outlet (activate)="onActivate(routerOutlet)"></router-outlet>
            </div>
          </div>
        </div>
        <div class="row no-margin"  *ngIf="!matchUrl('/signin')">
          <div class="col-md-12 no-padding">
            <app-footer></app-footer>

          </div>
        </div>

      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

