<div class="row top">
        <div class=" col-md-12">
          <h3 class="headings">Statements</h3>
        </div>
</div>
<div class="statement-bdy">
<div class="table_content mt-3">
  <div class="row px-sm-4 px-0 py-4">
    <div class="col-md-8 d-flex">
      <input type="text" class="form-control calander-input-icon form-control-sm col-md-2 mr-3" #dp="bsDatepicker" placement="bottom" [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="onOpenCalendarfrom($event)" placeholder="From" bsDatepicker [(ngModel)]="from_date" (bsValueChange)="checkdate()" (onHidden)="openAdfReport()">
      <input type="text" class="form-control calander-input-icon form-control-sm col-md-2 mr-3" #dp1="bsDatepicker" placement="bottom" [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="onOpenCalendarto($event)" placeholder="To" bsDatepicker [(ngModel)]="to_date" (bsValueChange)="checkdate2()" (ngModelChange)="updateMyDate($event)" (onHidden)="openAdfReport()">
<!--      <button class="export_btn" (click)="openAdfReport()">Submit</button>-->
    </div>
    <!-- <div class="col-md-4 text-right">
      <button class="export_btn" (click)="openAdfReport()">Submit</button>
    </div> -->
  </div>
  <div class="row">
  <div class="col-md-12 col-xs-12">
    <div (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag" class="top-scroll" id="TopScroll">
      <img src="" height=1 [style]="checkTablewidth()">
    </div>
    <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
      <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
    <table *ngIf="!errorMsg" class="table m-0" id="table-width">
      <thead>
      <tr>
        <th class="charge w-8" title="Date"><a (click)="setSorting('processing_date')">Date
          <i class="fa fa-thin" [ngClass]="orderBy === 'processing_date' ? stylesortBy : 'fa-angle-down'"></i>
        </a></th>
      <th class="charge"title="Download">Download</th>
      <th class="charge"title="Preview">Preview</th>
      <th class="charge" title="Total Authorizations"><a (click)="setSorting('total_authorizations')">Total Authorizations
        <i class="fa fa-thin" [ngClass]="orderBy === 'total_authorizations' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="charge"title="Total Captured Sales"><a (click)="setSorting('total_sales_count')">Total Captured Sales
        <i class="fa fa-thin" [ngClass]="orderBy === 'total_sales_count' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="charge"title="Total Sales Volume"><a (click)="setSorting('total_sales_volume')">Total Sales Volume
        <i class="fa fa-thin" [ngClass]="orderBy === 'total_sales_volume' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="charge"title="Total Refunds"><a (click)="setSorting('total_refund_count')">Total Refunds
        <i class="fa fa-thin" [ngClass]="orderBy === 'total_refund_count' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="charge"title="Total Refund Volume"><a (click)="setSorting('total_refund_amount')">Total Refund Volume
        <i class="fa fa-thin" [ngClass]="orderBy === 'total_refund_amount' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="loadFlag">
      <tr class="loader">
      <td colspan="7">
      <div class="loader-container">
      <img class="table-loader" src="../../../../assets/images/loading.svg" />
      </div>
      </td>
      </tr>
      </ng-container>

      <ng-container *ngIf="!loadFlag && statementlist && !errorMsg">
      <tr class="cus_list" *ngFor="let statement of statementlist.data">
        <td data-th="Date" class="email w-8">
         <div>{{statement.processing_date | date: 'MM/dd/yyyy'}}</div>
        </td>
        <td data-th="Download">
        <div  class="url_link" (click)="download_statement(statement.id)">PDF</div>
        </td>
        <td data-th="Preview">
          <div class="url_link" (click)="view_statement(statement.id)"><img class="icon" src="../../../assets/images/computericon.png" ></div>
        </td>
        <td data-th="Total Authorizations" class="email">
         <div>{{statement.total_authorizations || statement.total_authorizations == 0 ? (statement.total_authorizations | number) : '--' }}</div>
        </td>
        <td data-th="Total Captured Sales" class="email">
         <div>{{statement.total_sales_count || statement.total_sales_count == 0 ? (statement.total_sales_count | number) : '--' }}</div>
        </td>
        <td data-th="Total Sales Volume" class="email">
        <div>{{statement.total_sales_volume || statement.total_sales_volume == 0 ? (((statement.total_sales_volume) | currency:'USD':'symbol':'1.2-2') + ' USD') : '--'}}</div>
        </td>
        <td data-th="Total Refunds" class="email">
        <div>{{statement.total_refund_count || statement.total_refund_count == 0 ? (statement.total_refund_count | number) : '--' }}</div>
        </td>
        <td data-th="Total Refund Volume" class="email">
        <div>{{statement.total_refund_amount || statement.total_refund_amount == 0 ? (((statement.total_refund_amount) | currency:'USD':'symbol':'1.2-2') + ' USD') : '--'}}</div>
        </td>
      </tr>
      </ng-container>
      </tbody>
      </table>
      </div>
      <div class="row m-0 pt-sm-4 pt-2 border-2" *ngIf="!loadFlag && statementlist.data && statementlist.data.length > 0">
        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
            <span class="paginate">
              {{ 1 + (metadata['current_page'] - 1) * limit }} -
              <select [(ngModel)]="limit" (change)="openAdfReport()" class="page-limit"
                      *ngIf="metadata['current_page'] === 1">
                <option *ngFor="let no of limitArray" [value]="no">{{
                  no
                  }}</option>
              </select>
              <span *ngIf="metadata['current_page'] !== 1">{{
                metadata['count'] +
                (metadata['current_page'] - 1) * limit
                }}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
            </span>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
          <ul class="list-inline"  *ngIf="!loadFlag && statementlist.data.length > 0 && objectKeys(metadata.links).length > 0">
            <li  class="list-inline-item"><button type="button" class="btn btn-default btn_prenext" [disabled]="!metadata.links['previous']"
                                                  (click)="paginate( metadata.links['previous'].replace('http:','https:'))">
              <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
            </button></li>
            <li class="list-inline-item" *ngFor="let number of numbers()">
              <div *ngIf="+number.toString() ">
                <a class="page-link" *ngIf="metadata.links.next" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                   (click)="nextpage(number, metadata.links.next.replace('http:','https:'))" >{{number}}</a>
                <a class="page-link" *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                   (click)="nextpage(number, metadata.links['previous'].replace('http:','https:'))" >{{number}}</a>
              </div>
              <a class="page-link" *ngIf="!+number.toString()" >{{number}}</a>
            </li>
            <li  class="list-inline-item" ><button type="button" class="btn btn-default btn_prenext" [disabled]="!metadata.links['next']"
                                                   (click)="
                paginate(metadata.links['next'].replace('http:','https:') ) ">
              Next  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button></li>
          </ul>



      </div>
      </div>
  </div>
</div>
</div>
</div>
