<div>
    <h2>{{individualData[0].campaign}}</h2>
    <p>Status: {{individualData[0].status}}</p>
    <p>Campaign description:{{individualData[0].description}}</p>
</div>
<div class="split_funding_bdy">
    <div class="table_content  mt-xl-3 mt-lg-3 mt-md-3" >
  
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-m-0">
            <div  (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag"  class="top-scroll" id="TopScroll">
              <img src="" height=1 [style]="checkTablewidth()">
            </div>
            <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
              <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
              <table *ngIf="!errorMsg" class="table mb-0" id="table-width">
                <thead>
                <tr  class="d-flex justify-content-around" >
               
                  <th class="status">Payee Name</th>
                  <th class="id">Payee Description</th>
                  <th class="id">Payees Acct#</th>
                  <th class="id">Payee Routing # </th>
                  <th class="id">Amount $ </th>
                  <th class="status">Amount %</th>
                  <th class="id">Type (% or $) </th>
                  
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="loadFlag">
                  <tr class="loader">
                    <td colspan="7">
                      <div class="loader-container">
                        <img class="table-loader" src="../../../../assets/images/loading.svg">
                      </div>
                    </td>
                  </tr>
                </ng-container>
      
                <ng-container >
                  <tr *ngFor="let campaign of individualData;" [campaign]="campaign" class="cursor d-flex justify-content-around" [routerLink]="[redirect_link, campaign.id]"
                      [queryParams]="{id: campaign.id}">
                    <td class="campaign"><a [innerHTML]="highlight(campaign.campaign)">{{campaign.name}}</a></td>
                    <td class="date"><a>{{campaign.description}}</a></td>
                    <td class="date"><a>{{campaign.accountNumber}}</a></td>
                    <td class="date"><a>{{campaign.routingNumber}}</a></td>
                    <td class="date"><a>{{campaign.amount/100}}</a></td>
                    <td class="cust"><a>{{campaign.amountPercent +' %'}}</a></td>
                    <td class="cust"><a>{{campaign.type}}</a></td>
                    
             
                  </tr>
                </ng-container>
                <!--pagination start-->
      
                <!--pagination end-->
                </tbody>
              </table>
            </div>
            <div class="row m-0 pt-sm-4 pt-1 border-2" *ngIf="!loadFlag && chargeList.data.length > 0">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
                  <span class="paginate">
                    {{ 1 + (metadata['current_page'] - 1) * limit }} -
                    <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                            *ngIf="metadata['current_page'] === 1">
                      <option *ngFor="let no of limitArray" [value]="no">{{
                        no
                        }}</option>
                    </select>
                    <span *ngIf="metadata['current_page'] !== 1">{{
                      metadata['count'] +
                      (metadata['current_page'] - 1) * limit
                      }}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
                  </span>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
                <ul class="list-inline"
                    *ngIf="!loadFlag && chargeList.data.length > 0 && objectKeys(metadata.links).length > 0">
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-default btn_prenext pl-m-3" [disabled]="!metadata.links['previous']"
                            (click)="paginate( metadata.links['previous'])">
                      <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                    </button>
                  </li>
                  <li class="list-inline-item" *ngFor="let number of numbers()">
                    <div *ngIf="+number.toString() ">
                      <a class="page-link" *ngIf="metadata.links.next"
                         [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                         (click)="nextpage(number, metadata.links.next)">{{number}}</a>
                      <a class="page-link"
                         *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)"
                         [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                         (click)="nextpage(number, metadata.links['previous'])">{{number}}</a>
                    </div>
                    <a class="page-link" *ngIf="!+number.toString()">{{number}}</a>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-default btn_prenext px-m-0" [disabled]="!metadata.links['next']"
                            (click)="
                      paginate(metadata.links['next']) ">
                      Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                  </li>
                </ul>
      
      
              </div>
            </div>
          </div>
        </div>
   
      </div>
      </div>
