import {Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  current_year: number = new Date().getFullYear();
  constructor(private elRef: ElementRef) { }

  ngOnInit() {

  }

}
