import {ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataManagerService} from '../data/services/DataManager/data-manager.service';
import {HttpClientModule} from '@angular/common/http';
import {EventComponent} from '../components/event/event.component';
import {LoadingButtonComponent} from './loading-button/loading-button.component';
import {AmountValidatorDirective} from './Validators/amount-validator/amount-validator.directive';
import {FilterComponent} from './filter/filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CalanderValidatorDirective} from './Validators/calendar-validator/calander-validator.directive';
import {DetailsLoaderComponent} from './details-loader/details-loader.component';
import {MaterialModule} from '../material/material.module';
import {RouterModule} from '@angular/router';
import { MessageOnListComponent } from './message-on-list/message-on-list.component';
import {Camalize} from './custom-pipes/camalize/camalize';
import {CreditCardMaskPipe} from './custom-pipes/newline/newline';
import { SearchPipe } from './custom-pipes/search/search.pipe';
import {DateFormatPipe} from './custom-pipes/date-format-pipe/dateformatpipe';
import { DateonlyPipe } from './custom-pipes/date-format-pipe/dateonly.pipe';
import {HighchartsChartModule} from './lib/highcharts-chart.module';
import { PhoneMaskDirective } from './directives/phone-mask/phone-mask.directive';
import { MinMaxValidatorDirective } from './directives/min-max-validator/min-max-validator.directive';
import {Formatdatepipe} from './custom-pipes/date-format-pipe/formatdatepipe';
import { MetadataKeyPipe } from './custom-pipes/metadata-key/metadata-key.pipe';
import {ExelService} from '../data/services/Exel/exel.service';
import {Replacestring} from './custom-pipes/replacestring/replacestring';
import { CustomerAddCardDetailsSectionComponent } from './components/customer-add-card-details-section/customer-add-card-details-section.component';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { CreateNewSubscriptionComponent } from './components/create-new-subscription/create-new-subscription.component';
import { TypeAheadComponentComponent } from './components/type-ahead-component/type-ahead-component.component';
import { CapturePaymentComponent } from './components/capture-payment/capture-payment.component';
import { DefaultSelectComponent } from '../components/default-select/default-select.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ExportExcelComponent } from './export-excel/export-excel.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AgmCoreModule} from '@agm/core';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AppConstants} from './constants/app-constants';
import { PhoneFormatPipe } from './custom-pipes/phone-format/phone-format.pipe';

@NgModule({
    imports: [
        CommonModule,
        MatButtonToggleModule,
        HttpClientModule,
        FormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        MaterialModule,
        RouterModule,
        HighchartsChartModule,
        ReactiveFormsModule,
        CreditCardDirectivesModule,
        AgmCoreModule.forRoot({
          apiKey: AppConstants.GOOGLE_MAPS_API_KEY,
          libraries: ['places']
        }),
        GooglePlaceModule,
    ],
  declarations: [EventComponent, LoadingButtonComponent, AmountValidatorDirective, FilterComponent, CalanderValidatorDirective, DetailsLoaderComponent, MessageOnListComponent, Camalize, Replacestring, CreditCardMaskPipe, SearchPipe, DateFormatPipe, DateonlyPipe, PhoneMaskDirective, MinMaxValidatorDirective, Formatdatepipe, CustomerAddCardDetailsSectionComponent, CreateCustomerComponent, CreateNewSubscriptionComponent, TypeAheadComponentComponent, CapturePaymentComponent, DefaultSelectComponent, ExportExcelComponent, MetadataKeyPipe, PhoneFormatPipe],
  exports: [EventComponent, LoadingButtonComponent, AmountValidatorDirective, FilterComponent, DetailsLoaderComponent, MessageOnListComponent, Camalize, CreditCardMaskPipe, SearchPipe, DateFormatPipe, DateonlyPipe, HighchartsChartModule, PhoneMaskDirective, MinMaxValidatorDirective, MinMaxValidatorDirective, Formatdatepipe, Replacestring, CustomerAddCardDetailsSectionComponent, ExportExcelComponent, MetadataKeyPipe,TypeAheadComponentComponent, PhoneFormatPipe],

  })
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DataManagerService, ExelService]
    };
  }
}
