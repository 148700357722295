import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {CollectedFeesDetailsAction} from './collected-fees-details.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CollectedfeesModel, CollectedfeesV2Model} from '../../../data/model/CollectedFeesModel/collectedfees.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {ApiErrorModel} from '../../../data/model/ApiErrorModel/api-error.model';
import { MatDialog } from '@angular/material/dialog';
// import {CreateNewCouponDialogComponent} from '../../../dialogs/create-new-coupon-dialog/create-new-coupon-dialog.component';
import {MessageDialogComponent} from '../../../dialogs/message-dialog/message-dialog.component';
import {PermissionConstants} from '../../../shared/constants/permission-constants';

@Component({
  selector: 'app-collected-fees-details',
  templateUrl: './collected-fees-details.component.html',
  styleUrls: ['./collected-fees-details.component.css']
})
export class CollectedFeesDetailsComponent extends CollectedFeesDetailsAction implements OnInit {

  collectedfeesObj: CollectedfeesV2Model;
  eventListUrl: string;

  constructor(dataManager: DataManagerService, router: Router, route: ActivatedRoute, public dialog: MatDialog) {
    super();
    this.collectedfeesObj = new CollectedfeesV2Model();
    this.dataManager = dataManager;
    this.errorMsg = null;
    // this.delete_permission = this.permissionCheck(PermissionConstants.PERMISSION_DELETE_COUPON);
    this.router = router;
    this.route = route;
    this.loadFlag = true;
    this.fetchIdFromRouteQueryParams();
    console.log('fetchIdFromRouteQueryParams called');
    this._collectedFeesDetails = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_COLLECTED_FEES + '/' + this.id;
    // this._couponDeleteUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_TRANSFER + '/' + this.id;
    const url = this._collectedFeesDetails;
    // this.eventListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?' + AppConstants.TRANSFER_ID + '=' + this.id;
    this.getCollectedFeesDetails(url);
  }

  ngOnInit() {
  }

  // deleteAction() {
  //   const data = {
  //     head: 'Delete coupon', message: 'Delete this coupon '
  //   };
  //   this.dialog.open(MessageDialogComponent, {data})
  //     .afterClosed().subscribe(value => {
  //     console.log(value);
  //     if (value) {
  //       this.deleteCoupon();
  //     }
  //   });
  // }
  protected passCollectedFeesDeatils(response) {
    this.loadFlag = false;
    console.log(response);
    this.collectedfeesObj = response.data;
    // if (!isNaN(response.data.amount_off) && response.data.amount_off !== 0) {
    //   this.collectedfeesObj.amount_off = response.data.amount_off / 100;
    // }
  }

  protected passCollectedFeesDeatilsError(error) {
    this.loadFlag = false;
  }
  // protected passDeleteResponse(res: any) {
  //
  //   this.dataManager.appLoaderFlag(false);
  //   this.routeNavigate('/subscription/coupon-list');
  // }
  //
  // protected passDeleteError(error: ApiErrorModel) {
  //   this.dataManager.appLoaderFlag(false);
  //   console.log(error.message);
  // }



}
