import { BaseComponent } from "../../abstract/base.component";
import { ApiErrorModel } from "../../data/model/ApiErrorModel/api-error.model";
import { Directive } from "@angular/core";

@Directive()
export abstract class SecurityDialogAction extends BaseComponent {
    protected getOtpUrl: string;
    protected verifyOtpUrl: string;
    protected abstract passSendOtpResponse(res);
    protected abstract passSendOtpError(error: ApiErrorModel);
    protected abstract passverifyOtpResponse(res);
    protected abstract passverifyOtpError(error: ApiErrorModel);

    sendOtp(postBody) {
        this.loadFlag2 = true;
        this.dataManager.sendUserOtp(this.getOtpUrl, postBody)
          .subscribe(
            res => this.passSendOtpResponse(res),
            error => {
              this.loadFlag2 = false;
              this.networkErrorHandler(error.error);
              this.passSendOtpError(error.error);
            }
          );
      }
    verifyOtp(postbody) {
        this.loadFlag = true;
        this.dataManager.verifyUserOtp(this.verifyOtpUrl, postbody)
        .subscribe(
            res => this.passverifyOtpResponse(res),
            error => {
              this.loadFlag = false;
              this.networkErrorHandler(error.error);
              this.passverifyOtpError(error.error);
            }
          );
    }
}