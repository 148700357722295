<app-details-loader *ngIf="loadFlag" [Height]="'70vh'" [LoaderMarginTop]="'19%'"
                    ></app-details-loader>
<div class="container-fluid overlay" *ngIf="!loadFlag">
  <div class="row">
    <div class="container-card col-xl-5 col-lg-8 col-md-8 col-sm-8 col-xs-12">
      <div class="row">
          <div class="col-12 text-center">
            <h4 class="main-title">Invoice from {{ReceievedInvoice.account_name}}</h4>
          </div>
      </div>
      <div class="row my-5">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <p class="bill_text">Billed to {{ReceievedInvoice.customer_name}}</p>
            <p class="bill_text">Invoice #{{ReceievedInvoice.invoice_number}}</p>
          </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-right" *ngIf="ReceievedInvoice.amount_due">

              <p class="bill_text">{{ReceievedInvoice.amount_due ? (ReceievedInvoice.amount_due/100 | currency:'USD':'symbol': '1.2-2') + ' USD' : '$0.00 USD'}}  due on</p>
             <p class="bill_text">{{ReceievedInvoice.payment_due_date ?( ReceievedInvoice.payment_due_date | dateFormat: 'MMM dd, yy') : '--' }}</p>
        </div>
      </div>
      <div class="row" *ngIf="ReceievedInvoice.paid === 1">
        <div class="col-md-12" style="text-align: center; margin-bottom: 4%;">
          <div class="check_mark">
            <div class="sa-icon sa-success animate">
              <span class="sa-line sa-tip animateSuccessTip"></span>
              <span class="sa-line sa-long animateSuccessLong"></span>
              <div class="sa-placeholder"></div>
              <div class="sa-fix"></div>
            </div>
          </div>
          <span class="paid">Paid</span>
        </div>
      </div>
      <div class="row" *ngIf="ReceievedInvoice.paid !== 1">
        <div class="col-12 text-left pay_card mb-4">Pay By Card</div>
         <div class="col-md-12 error" *ngIf="failureMessage" >{{failureMessage}}</div>
         
        <!-- Manual invoice forms start here -->
        <form [formGroup]="manualInvoiceForm" (ngSubmit)="payInvoice()" #manualInvoice>

            <div class="row ml-3">
              <div class="col-sm-6 col-10 pl-0 pb-m-19">
                <input class="form-control  mr-3" placeholder="•••• •••• •••• ••••" [(ngModel)]="customerCard.card_number"
                       type="tel" name="card_number" id="card_number" autocomplete="cc-number"
                       formControlName="creditCard" value="" ccNumber appAmountValidator [maxLength]="23" (input)="getCardType($event)"
                       [class.invalid]="submitted && f.creditCard.invalid" required/>
                <img class="card-img" [src]="card_img_obj[type] ? card_img_obj[type] : card_img_obj['default'] " />
                <div *ngIf="submitted && f.creditCard.invalid" class="validation-err-msg">
                  <span *ngIf="f.creditCard.errors.required">Card number is required.</span>
                  <span *ngIf="!manualInvoiceForm.controls['creditCard'].errors?.required && manualInvoiceForm.controls['creditCard'].invalid">Card number is invalid.</span>
                  <span *ngIf="f.creditCard.errors.maxlength">Card number limit exceeds.</span>
                </div>
              </div>
              <div class="col-sm-3 col-6 pl-m-0 pr-0">
                <input class="form-control" type="tel" (input)="onCardDataChange()" [(ngModel)]="customerCard.exp"
                       [class.invalid]="submitted && f.expDate.invalid" name="expiry" id="expiry" required formControlName="expDate"
                       autocomplete="cc-exp" placeholder="MM/YYYY" ccExp />
                <div *ngIf="submitted && f.expDate.invalid" class="validation-err-msg">
                  <span *ngIf="f.expDate.errors.required">Expiry date is required.</span>
                  <span *ngIf="!manualInvoiceForm.controls['expDate'].errors?.required && manualInvoiceForm.controls['expDate'].invalid">Invalid expiry.</span>
                </div>
              </div>
              <div class="col-sm-3 col-4">
                <input class="form-control" type="text" [class.invalid]="submitted && f.cvc.invalid"
                       [(ngModel)]="customerCard.cvc_check_code" (keyup)="checkCvc(customerCard.cvc_check_code)"
                       (input)="onCardDataChange()" name="cvc" id="cvc"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required
                       (keypress)="numericOnly($event)" [required]="cvvoptional === 0" autocomplete="off" formControlName="cvc"
                       placeholder="•••" appAmountValidator maxLength="4" ccCvc />
                <div *ngIf="submitted && f.cvc.invalid" class="validation-err-msg">
                  <div *ngIf="f.cvc.errors.required">Cvv is required.</div>
                  <span *ngIf="!manualInvoiceForm.controls['cvc'].errors?.required && manualInvoiceForm.controls['cvc'].invalid">Invalid cvv.</span>
                  <span *ngIf="!manualInvoiceForm.controls['cvc'].invalid && cvcError === true || manualInvoiceForm.controls['cvc'].errors?.minLength">Invalid cvv.</span>
                </div>
              </div>
            </div>
          <div class="row ml-0">
                <div class="col-12">
                  <input class="form-control mt-3 " type="text" id="address_line1" name="street" placeholder="Address line 1" [(ngModel)]="customerCard.address_line1"
                         maxlength="100" formControlName="address_line1" />
                </div>
          </div>
            <div class="row ml-3">
              <div class="col-sm-6 col-10 pl-0">
                <input class="form-control my-3" type="text" id="city" name="city" placeholder="City" maxlength="200"
                       formControlName="city"/>
                <select class="form-control my-3" id="state" name="state" formControlName="state" [(ngModel)]="customerCard.state">
                  <option value="" disabled>Choose a state</option>
                  <option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
                </select>
              </div>
              <div class="col-sm-6 col-10 pl-m-0">
                <input class="form-control my-3" type="text" id="zip" maxlength="10" (keypress)="numericOnly($event)" name="zip" [(ngModel)]="customerCard.zip"
                       placeholder="Zip code" formControlName="zip" />
                <select class="form-control my-3" id="country" name="country" formControlName="country" [ngModel]="customerCard.country">
                  <option [value]="" disabled>Choose a country</option>
                  <option *ngFor="let country of countries" [value]="country.key" [selected]="country === country.key">{{country.name}}</option>
                </select>
              </div>
            </div>

          <div class="col-md-12 pl-0 py-3">
            <button type="submit" class="btn btn-primary btn-lg col-md-12 pay_submit" [disabled]="load" >Pay Invoice <i *ngIf="load" class="fa fa-spinner fa-pulse loader"></i></button>
          </div>
        </form>
        <!-- Manual invoice forms end here -->

      </div>
      <div class="col-md-12 px-m-0">
        <div class="box" >
          <div class="row">
<!--               <div class="col-md-12" >-->
<!--              <div class="row" *ngIf="ReceievedInvoice.paid === 1">-->
<!--                <div class="col-md-12" style="text-align: center; margin-bottom: 4%;">-->
<!--                  <div class="check_mark">-->
<!--                    <div class="sa-icon sa-success animate">-->
<!--                      <span class="sa-line sa-tip animateSuccessTip"></span>-->
<!--                      <span class="sa-line sa-long animateSuccessLong"></span>-->
<!--                      <div class="sa-placeholder"></div>-->
<!--                      <div class="sa-fix"></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span class="paid">Paid</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <ng-container class="top">
              <div class="{{ReceievedInvoice.paid !== 1 ? 'col-md-12 p-0' : 'col-md-6 col-5 px-m-0 ml-m-3'}}">
                <button class="btn btn-default btn-lg col-md-12 down_invoice {{ReceievedInvoice.paid !== 1 ? 'dlinvoice' : 'lft'}}" (click)="dlInvoice()">Download Invoice</button>
              </div>
              <div class="col-md-6 col-5 px-m-0" *ngIf="ReceievedInvoice.paid === 1">
                <button class="btn btn-default btn-lg col-md-12"  (click)="dlreceipt()">Download receipt </button>
              </div>
            </ng-container>
            <div class="row py-5">
              <div class="col-md-12 desc"><span >

              {{ReceievedInvoice.period_start ?
                (ReceievedInvoice.period_start | dateFormat: 'MMM dd, yy'):
                '--'}} -
                {{ReceievedInvoice.period_end?
                (ReceievedInvoice.period_end | dateFormat: 'MMM dd, yy')
                : '--'}}
              </span></div>
            </div>
            <div class="col-md-12 pl-m-0">
              <div class="row">

                <div class="col-sm-4 col-4 invoice_table_header">Description</div>
                <div class="col-sm-2 col-2 invoice_table_header text-center">Qty</div>
                <div class="col-sm-3 col-3 invoice_table_header">Unit price</div>
                <div class="col-sm-3 col-3 invoice_table_header text-right">Amount</div>
              </div>
              <hr>
              <!--<div class="row">-->
                    <!--<span class="col-md-12 desc"><span style="    font-size: 14px;-->
    <!--font-weight: 600;">-->

                  <!--{{ReceievedInvoice.period_start ?-->
                    <!--(ReceievedInvoice.period_start | dateFormat: 'MMM dd, yy'):-->
                    <!--'&#45;&#45;'}} - -->
                    <!--{{ReceievedInvoice.period_end?-->
                      <!--(ReceievedInvoice.period_end | dateFormat: 'MMM dd, yy')-->
                      <!--: '&#45;&#45;'}}-->
                    <!--</span></span>-->
              <!--</div>-->
              <div class="row">
                  <span class="col-md-4 col-4 plan_desc">
                    <span>
                      {{ReceievedInvoice.plan_name}}
                    </span>
                  </span>
                <div class="col-md-2 col-2 text-center"><span class="plan_desc">{{ReceievedInvoice.quantity > 0 ? ReceievedInvoice.quantity : 1}}</span></div>
                <div class="col-md-3 col-3"><span class="plan_desc" >{{ReceievedInvoice.plan_amount != null ? ((ReceievedInvoice.plan_amount / 100) | currency:'USD':'symbol': '1.2-2') + ' USD'
                    : '--'}}</span></div>
                <div class="col-md-3 col-3 text-right "><span class="plan_desc">
                  {{ReceievedInvoice.plan_amount != null ? ((ReceievedInvoice.plan_amount / 100) | currency:'USD':'symbol': '1.2-2') + ' USD'
                  : '--'}}
                </span></div>
              </div>
              <hr>
              <div class="col-md-12 pl-m-0">
                <div class="row">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-3">
                    <span class="plan_desc">Sub Total</span>
                  </div>
                  <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.subtotal != null ? (((ReceievedInvoice.subtotal/100) | currency:'USD':'symbol': '1.2-2') +
                      ' USD') :
                      '--'}}</span>
                  </div>
                  <ng-container *ngIf="ReceievedInvoice.tax_amount">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-3">
                      <span class="plan_desc">Tax</span>
                    </div>
                    <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.tax_amount != null ? (((ReceievedInvoice.tax_amount/100) | currency:'USD':'symbol': '1.2-2') +
                      ' USD') :
                      '--'}}</span>
                    </div>
                  </ng-container>
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-3">
                    <span class="plan_desc">Total</span>
                  </div>
                  <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.total != null ? (((ReceievedInvoice.total/100) | currency:'USD':'symbol':'1.2-2') +
                      ' USD') :
                      '--'}}</span>
                  </div>
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-3">
                    <span class="plan_desc">Amount Paid</span>
                  </div>
                  <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.amount_paid != null ? (((ReceievedInvoice.amount_paid/100) | currency:'USD':'symbol': '1.2-2') +
                      ' USD') :
                      '--'}}</span>
                  </div>
                  <ng-container *ngIf="ReceievedInvoice.discount_amount">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-3">
                      <span class="plan_desc">Discount Amount</span>
                    </div>
                    <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.discount_amount != null ? (((ReceievedInvoice.discount_amount/100) | currency:'USD':'symbol':'1.2-2') +
                      ' USD') :
                      '--'}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="ReceievedInvoice.amount_due">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-3">
                      <span class="plan_desc">Amount Due</span>
                    </div>
                    <div class="col-md-3 text-right pr-0">
                    <span class="plan_desc">{{ReceievedInvoice.amount_due != null ? (((ReceievedInvoice.amount_due/100) | currency:'USD':'symbol': '1.2-2') +
                      ' USD') :
                      '--'}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <hr>
              <!--<div class="col-md-12">-->
                <!--<div class="row">-->
                  <!--<span class="col-md-12" style="text-align: center;"><small>If you have any questions, contact {{ReceievedInvoice.account_name}}</small></span>-->
                  <!--<span class="col-md-12" style="text-align: center;"><small> at anisha.a.aga@gmail.com or call at (541) 754-3010.</small></span>-->
                <!--</div>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


