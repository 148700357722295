<app-details-loader *ngIf="loadFlag || errorMsg" [errorMsg]="errorMsg" [Height]="'70vh'" [LoaderMarginTop]="'19%'"
></app-details-loader>
<div class="container-fluid cust_detail_block" *ngIf="!loadFlag && !errorMsg ">
   <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-12">
         
         <div class="heading-card">
            <div class="row left-heading no-margin">
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 padding-left-heading pl-4 title">
                <h1>{{campaign.name}}</h1>
                <h3>ID:  {{campaignId}}</h3>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 padding-left-heading pl-4 title">
            <a (click)="editBtn()"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" aria-labelledby="edit" role="presentation" fill="#729fcf" class="fill-current"><path d="M4.3 10.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM6 14h2.59l9-9L15 2.41l-9 9V14zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h6a1 1 0 1 1 0 2H2v14h14v-6z"></path></svg> </a>
            </div>
            </div>
        </div>  
         <!-- Campaign Details start here-->
         <div class="b_shadow mb-4">
            <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
                  <span>Details</span>
               </div>
               <div class="col-md-6 col-lg-6 col-sm-12 col-7 right-heading text-right">
                  <span class="right-heading green-active detail-btn" *ngIf="update_permission" (campaignData)="campaign($event)" (click)="updateCustomer()">
                  Update details
                  </span>
               </div>
            </div>
            <!-- Account information start here-->
            <div class="row m-0 px-2 py-3">
               <div class="col-md-12 col-lg-8 col-12 col-sm-12 padding-m">
                  <div class="row">
                     <div class="col-md-12 my-md-2">
                        <h5 class="table-heading det_head">Account information</h5>
                     </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-5">
                       <span class="table-data detail_desc">Name:</span>
                    </div>
                    <div class="col-sm-6 col-7">
                       <span class="table-data">{{campaign.name? campaign.name : '--'}}</span>
                    </div>
                 </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc">ID:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data">{{campaign.id? campaign.id : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                      <div class="col-sm-6 col-5">
                          <span class="table-data detail_desc ">Description:</span>
                      </div>
                      <div class="col-sm-6 col-7">
                          <span class="table-data inform">{{campaign.description ? campaign.description : '--'}}</span>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-sm-6  col-5 text-nowrap">
                          <span class="table-data detail_desc">Notes:</span>
                        </div>
                        <div class="col-sm-6 col-7 email-div">
                            <span class="table-data inform">{{campaign.notes ? campaign.notes : '--'}}</span>
                        </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6  col-5">
                          <span class="table-data detail_desc">Created At:</span>
                       </div>
                       <div class="col-sm-6 col-7 pr-0">
                          <span class="table-data">{{campaign.created_at ? (campaign.created_at | date: 'MM/dd/yyyy hh:mm a') : '--' }} --- {{campaign.readable_created_at}}</span>
                       </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6  col-5">
                           <span class="table-data detail_desc">Updated At:</span>
                        </div>
                        <div class="col-sm-6 col-7 pr-0">
                           <span class="table-data">{{campaign.updated_at ? (campaign.updated_at | date: 'MM/dd/yyyy hh:mm a') : '--' }} --- {{campaign.readable_updated_at}}</span>
                        </div>
                     </div>
               </div>
               <!-- <div class="col-md-12 col-lg-6 col-12 col-sm-12 padding-m mt-sm-0 mt-4">
                  <div class="row">
                     <div class="col-sm-12 col-12 my-md-2">
                        <span class="table-heading det_head">Invoicing settings</span>
                     </div>
                  </div>
                  <div class="row pt-sm-0 pt-2">
                     <div class="col-sm-6  col-6 text-nowrap">
                        <span class="table-data detail_desc">Send invoices to:</span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{campaign.send_email_address ? campaign.send_email_address : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc">Address:</span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{campaign.address_1 ? campaign.address_1 + ', ' : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{campaign.address_2 ? campaign.address_2 + ', ': '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{(campaign.city ? campaign.city + ', ' : '--') + (campaign.state ? campaign.state + ', ' : '--') }}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6  col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{(campaign.zip ? campaign.zip + ', ' : '--' ) + (campaign.country ? campaign.country + ' ' : '--')}}</span>
                     </div>
                  </div>
               </div> -->
            </div>
            <!-- Account information end here-->
            <!-- Agent information start here-->
               <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
                  <span>Agent</span>
               </div>
            </div>
             <div class="row m-0 px-2 py-3">
               <div class="col-md-12 col-lg-8 col-12 col-sm-12 padding-m">
                  <div class="row">
                     <div class="col-md-12 my-md-2">
                        <h5 class="table-heading det_head">Agent Information</h5>
                     </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-5">
                       <span class="table-data detail_desc">Company DBA:</span>
                    </div>
                    <div class="col-sm-6 col-7">
                       <span class="table-data">{{campaign.agent.data.company_dba? campaign.agent.data.company_dba : '--'}}</span>
                    </div>
                 </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc">ID:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data">{{campaign.agent.data.id? campaign.agent.data.id : '--'}}</span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Agent information end here-->
            <!-- Payee information start here-->
            <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
                  <span>Assigned Payee to the Campaign <strong> {{campaign.payees.data.length}}</strong></span>
               </div>
            </div>
             <div class="row m-0 px-2 py-3">
               <div class="col-md-12 col-lg-8 col-12 col-sm-12 padding-m" *ngFor="let payee of campaign.payees.data">
                  <hr *ngIf="campaign.payees.data.length > 1 ">
                  <div class="row">
                    <div class="col-sm-6 col-5">
                       <span class="table-data detail_desc">Name:</span>
                    </div>
                    <div class="col-sm-6 col-7">
                       <span class="table-data">{{payee.first_name? payee.first_name : '--'}} {{payee.last_name}}</span>
                    </div>
                 </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc">ID:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data">{{payee.id? payee.id : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc">Dashboard Access:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data">{{payee.dashboard_access !==null? payee.dashboard_access 
                           ? 'Yes' : 'No' : '--'}}</span>
                     </div>
                  </div>
                   <div class="row">
                      <div class="col-sm-6 col-5">
                         <span class="table-data detail_desc">Status:</span>
                      </div>
                      <div class="col-sm-6 col-7">
                         <span class="table-data">{{payee.status? payee.status : '--'}}</span>
                      </div>
                   </div>

                  
               </div>
            </div>
            </div>
            <!-- Payee info ends here-->
        
         <!-- Campaign Details end here-->
         <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5">
            <button class="cancel_btn" (click)="cancelBtn()">Go Back</button>
        
            <button class="cancel_btn" (click)="editBtn()">Edit Campaign</button>
         </div>
         <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5">
        
         </div>

   </div>
</div>

