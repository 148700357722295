import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";


@Directive()
export abstract class CaptureChargeAction extends BaseComponent {
  protected _createChargeUrl: string;
  protected _captureChargeUrl: string;
  protected _getTerminalListUrl: string;
  protected _getaccount: string;
  protected _getSettingUrl: string;
  protected _setSettingUrl: string;
  taxCategories = [
    {text: 'Service', value: 'SERVICE'},
    {text: 'Duty', value: 'DUTY'},
    {text: 'Vat', value: 'VAT'},
    {text: 'Alternate', value: 'ALTERNATE'},
    {text: 'National', value: 'NATIONAL'},
    {text: 'Tax exempt', value: 'TAX_EXEMPT'},
  ];

  protected getSetting() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getSettingUrl, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passGetSettingResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.passGetSettingError(err);
        }
      );
  }

  protected abstract passGetSettingResponse(res);

  protected abstract passGetSettingError(err);

  protected chargeApiCall(url, postBody) {
    this.loadFlag = true;
    this.dataManager.postRequest(url, postBody, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passResponse(response);
        },
        error => {
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passError(error.error);
        }
      );
  }

  protected abstract passResponse(charge);

  protected abstract passError(error);

  protected getAddress(url) {
    this.loadFlag = true;
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.getAddressResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.networkErrorHandler(err.error);
          this.getAddressError(err.error);
        }
      );
  }

  protected abstract getAddressResponse(res);

  protected abstract getAddressError(err);

  protected getTerminal() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getTerminalListUrl, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.getTerminalResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.networkErrorHandler(err.error);
          this.getTerminalError(err.error);
        });
  }

  protected abstract getTerminalResponse(res);

  protected abstract getTerminalError(err);


}
