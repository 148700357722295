<div class="container-fluid">
  <div class="row">
      <div class="col-12">
        <h3 class="headings">Disputes</h3>
      </div>

  </div>
  <div class="container-fluid dispute-bdy p-0"  *ngIf="!showExport">
  <div class="row py-4">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
      <div class="row">
      <label class="col-xl-3 col-lg-12 col-md-12 col-sm-3 col-12 col-form-label pr-0 pl-m-0">Type:
      </label>
      <select class="form-control col-xl-8 col-lg-12 col-md-12 col-sm-8 col-12 mt-m-11" [(ngModel)]="filterOnSelected" (ngModelChange)="setUrl()">
        <option *ngFor=" let obj of filterOn " [value]="obj.key">{{obj.text}}</option>
      </select>
    </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
      <div class="row">
        <label class="col-xl-3 pl-xl-0 col-lg-12 col-md-12 col-sm-12 col-3 col-form-label pr-0 col-12 pl-m-0">Date range:
        </label>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-4 col-4 pl-m-0">
          <input type="text" class="form-control  calendar-textbox calander-input-icon"
                 #dp="bsDatepicker" [bsConfig]="bsDateConfig" placement="bottom" placeholder="From" bsDatepicker
                 [(bsValue)]="fromDate" (onHidden)="setUrl()" [maxDate]="toDate" [(ngModel)]="fromDate" readonly />
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-4 col-4 pl-m-0">
          <input type="text" class="form-control calendar-textbox calander-input-icon"
                 #dp="bsDatepicker" [bsConfig]="bsDateConfig" [(ngModel)]="toDate" placeholder="To" placement="bottom" bsDatepicker
                 [minDate]="fromDate" [(bsValue)]="toDate" (onHidden)="setUrl()" readonly />
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-4 text-right exp-div">
      <button class="export_btn" (click)="exportexcel()">Export</button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-8 form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input type="search" class="form-control search-bar col-xl-5 col-lg-8 col-md-12 col-12 py-2" [(ngModel)]="searchKeyword" (input)="searchText.next()"
             placeholder="Search" />
    </div>

  </div>

  <div class="row" *ngIf="this.disputeChartsData.dispute_volume_chart_data.length > 0 || this.disputeChartsData.dispute_count_chart_data.length > 0">
      <div class="col-xl-4 col-lg-12 col-md-12 col-12 mt-m-11" *ngIf="this.disputeChartsData.dispute_volume_chart_data.length > 0">
        <highcharts-chart   [Highcharts]="Highcharts" [options]="chartOptionsdonout" style="width: 100%; height:530px; display: block;"></highcharts-chart>
      </div>
      <div class="col-xl-8 col-lg-12 col-md-12 col-12 mt-m-22">
          <div class="col-12 pb-3 mt-0 p-0" *ngIf="this.disputeChartsData.dispute_volume_chart_data.length > 0">
            <highcharts-chart [Highcharts]="Highcharts" [options]="dispVolChartOptions" style="width: 100%; height: 256px; display: block;"></highcharts-chart>
          </div>
          <div class="col-12 p-0 mt-m-22" *ngIf="this.disputeChartsData.dispute_count_chart_data.length > 0">
            <highcharts-chart [Highcharts]="Highcharts" [options]="dispCountChartOptions" style="width: 100%; height: 256px; display: block;"></highcharts-chart>
          </div>
      </div>
  </div>

<!--  <div class="row mb-4" >-->
<!--    <div class="col-lg-4 bar-cht" *ngIf="this.disputeChartsData.dispute_volume_chart_data.length > 0">-->
<!--      <highcharts-chart   [Highcharts]="Highcharts" [options]="chartOptionsdonout" style="width: 100%; height: 300px; display: block;"></highcharts-chart>-->
<!--    </div>-->

<!--    <div class="col-lg-4 bar-cht" *ngIf="this.disputeChartsData.dispute_volume_chart_data.length > 0">-->
<!--      <highcharts-chart [Highcharts]="Highcharts" [options]="dispVolChartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>-->
<!--    </div>-->
<!--    <div class="col-lg-4 bar-cht" *ngIf="this.disputeChartsData.dispute_count_chart_data.length > 0">-->
<!--      <highcharts-chart [Highcharts]="Highcharts" [options]="dispCountChartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>-->

<!--    </div>-->
<!--  </div>-->


  <!--  graph_chart-->
  <div class="table_content my-4">
  <div class="row">
    <div class="col-md-12 col-xs-12 mob_no_padding">
      <div (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag" class="top-scroll" id="TopScroll">
        <img src="" height=1 [style]="checkTablewidth()">
      </div>
      <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
        <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
  <table *ngIf="!errorMsg" class="table" id="table-width">
    <thead>
    <tr>
      <th title="Chargeback date" class="d_date">Chargeback date</th>
      <th class="id">Case number</th>
      <th class="sptype" >Primary / Secondary</th>
      <th title="Transaction date" class="t_date">Transaction date</th>
      <th class="amount_field">Amount</th>
      <th class="reason_field">Reason</th>
      <th class="case_type">Case type</th>
      <th>RDR</th>
      <th class="status">Status</th>
      <th class="card_no">Card number</th>
      <th class="responded_table">Responded</th>
      <th class="responded_by">Respond by</th>
      <th class="last_updated" title="Last updated">Last updated</th>
      <!-- <th>LAST UPDATED</th>-->
      <!--      <th>DISPUTED DATE</th>-->

    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="loadFlag || loadFlag2">
      <tr class="loader">
        <td colspan="7">
          <div class="loader-container">
            <img class="table-loader" src="../../../../assets/images/loading.svg">
          </div>


        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="!loadFlag && disputeList && !errorMsg">

      <tr *ngFor="let dispute of disputeList.data" (click)="openDispute($event, dispute)">
        <td data-th="Chargeback date" class="d_date"><div>{{dispute.report_date ? (dispute.report_date | date: 'MM/dd/yyyy') : '--'}}</div>
        </td>
        <td data-th="Case number" class="id"><div>{{dispute.case_number ? dispute.case_number : '--'}}</div></td>
        <td data-th="Primary / Secondary" class="sptype "><div>{{dispute.is_primary_case ? 'Primary' : 'Secondary'}}</div></td>
        <!--<td ><a [routerLink]="redirect_link" [queryParams]="{id: dispute.id}">{{dispute.kind ? dispute.kind : '&#45;&#45;'}}</a></td>-->
        <td data-th="Transaction date" class="t_date"><div>{{dispute.transaction_date ? (dispute.transaction_date | date: 'MM/dd/yyyy') : '--'}}</div></td>
        <td data-th="Amount" class="amount_field">
          <div>{{dispute.case_amount ? ('-' + ((dispute.case_amount) | currency: 'USD' : 'symbol')) : '--'}}</div></td>
        <td data-th="Reason" class="reason_field"><div>{{dispute.reason_desc ? ( dispute.reason_desc  | camalizeStr : true) : '--'}}</div></td>
        <td data-th="Case type" [title]="dispute.case_type ? dispute.case_type : ''" class="case_type">
          <div>{{dispute.case_type ? (dispute.case_type  | camalizeStr : true ) : '--'}}</div></td>
        <td data-th="RDR"><div>{{dispute.rdr_indicator === 1 ? "Y" : ""}}</div></td>
        <td data-th="Status" class="status">
          <div>
            <i aria-hidden="true" class="fa fa-circle "
              [class.c_red]="dispute.status === 'Currently resolved in Bank\'s favor'"
              [class.c_light_green]="dispute.status === 'RDR'"
              [class.c_green]="dispute.status === 'Currently resolved in Merchant\'s favor'"
              [class.responded]="dispute.status === 'Responded'"
              [class.notresponded]="dispute.status === 'Not Responded'"
              [class.c_orange]="dispute.status === 'Pending'">
            </i>
            {{dispute.status ? ( dispute.status ) : '--'}}
          </div>
        </td>
        <td  data-th="Card number" class="card_no"><div><img class="card-img" *ngIf="dispute.card_type"
                                 [src]="card_img_obj[dispute.card_type] ? card_img_obj[dispute.card_type] : '../../../../assets/images/card-default.png'" />{{dispute.card_number ? dispute.card_number : '--'}}</div>
        </td>
        <td data-th="Responded" class="responded_table"><div><a [routerLink]="redirect_link"
               [queryParams]="{id: dispute.id}">{{dispute.case_submitted ? 'Responded' : 'Not responded'}}</a></div>
        </td>
        <td data-th="Responded By" class="responded_by"><div>{{dispute.responded_date ? (dispute.responded_date | date: 'MM/dd/yyyy') : '--'}}</div></td>
        <td data-th="Last updated" class="last_updated"><div>{{dispute.last_updated_date ? (dispute.last_updated_date | date: 'MM/dd/yyyy') : '--'}}</div></td>

      </tr>
    </ng-container>
    <!--pagination start-->

    <!--pagination end-->
    </tbody>


  </table>
      </div>
      <div class="row m-0 pt-4" *ngIf="disputeList?.meta?.pagination?.total">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-5">
          <span class="paginate">
            {{1 + (disputeList?.meta?.pagination?.current_page - 1) * limit}} -
            <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                    *ngIf="disputeList?.meta?.pagination?.current_page === 1">
              <option *ngFor="let no of limitArray" [value]="no">{{
                no
                }}</option>
            </select>
            <span *ngIf="disputeList?.meta?.pagination?.current_page !== 1">
              {{disputeList?.meta?.pagination?.count + (disputeList?.meta?.pagination?.current_page - 1) * limit}}
            </span> &nbsp;of &nbsp;{{ disputeList?.meta?.pagination?.total }}
          </span>
        </div>
      </div>
        <!-- <tr class="bottom-row">
          <td colspan=4 class="bottom-row-left" >

          </td>
          <td colspan="3" class="bottom-row-right">
    <!-     <button type="button" class="btn btn-default" [disabled]="!disputeList?.meta?.pagination?.links?.previous"-->
    <!--                (click)="paginate(disputeList?.meta?.pagination?.links?.previous?.replace('http:', 'https:'))">Previous-->
    <!--        </button>-->
    <!--        <button type="button" class="btn btn-default" [disabled]="!disputeList?.meta?.pagination?.links?.next"-->
    <!--                (click)="paginate(disputeList?.meta?.pagination?.links?.next?.replace('http:', 'https:'))">Next-->
    <!--        </button>
          </td>
        </tr> -->
    </div>
  </div>
  </div>
</div>
<div class="table-content" *ngIf="showExport">
  <app-export-excel [exportExcel]="exportExcel" [serviceName]="exportData"[filterUrl]="filterPostFix" [searchData]="searchKeyword"></app-export-excel>
</div>
</div>
