<div class="container-fluid " >
  <div class="row border-bottom align-items-center" *ngIf="data.head">
    <div class="col-md-12 alert-head text-center p-3" >
      {{data.head}}
    </div>
  </div>
  <div class="row justify-content-center mt-5" *ngIf="data.isIcoEnable">
    <img src="../../../assets/images/failure.svg" alt="Failure" title="Failure"/>
  </div>
  <div class="row">  
  <mat-dialog-content class="m-0 col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 py-4 delete_cust" fxLayout="row wrap" fxLayoutAlign="center">
    {{data.message}}
  </mat-dialog-content>
  <mat-dialog-actions class="button-container" fxLayout="row wrap" fxFlexFill  fxLayoutAlign="center" fxLayoutGap="2%">

    <button  class="update-btn" [mat-dialog-close]="true">{{data.confirmBtn ? data.confirmBtn : 'Ok'}}</button>
  </mat-dialog-actions>
</div>
</div>
