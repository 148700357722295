import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class DisputeDetailsAction extends BaseComponent {
  protected _getDisputeDetailsUrl: string;
  protected _getChargeBackPdf: string;
  protected _postEvidenceUrl: string;
  protected _postWrittenResUrl: string;
  public loadFlag2: boolean;
  public loadFlag3: boolean;
  public errorMsg2: string;

  getDisputeDetails() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getDisputeDetailsUrl, 0).subscribe(
      (res) => {
        this.loadFlag = false;
        this.passDisputeResponse(res);
        if (!res.primary_case.data) {
          this.errorMsg = ErrorConstants.disputeDetailErrorMsg[0];
        } else {
          this.errorMsg = null;
        }
      },
      (err) => {
        this.loadFlag = false;
        this.errorMsg = this.networkErrorHandler(
          err.error,
          ErrorConstants.disputeDetailErrorMsg
        );
        this.passDisputeError(err);
      }
    );
  }

  protected abstract passDisputeResponse(res);

  protected abstract passDisputeError(err);

  postEvidence(postBody, i) {

    this.dataManager.fileUpload(this._postEvidenceUrl, postBody, 0)
      .subscribe(
        res => this.postEvidenceResponse(res, i),
        err => {
          this.networkErrorHandler(err.error);
          this.postEvidenceError(err.error, i);
        }
      );
  }

  protected abstract postEvidenceResponse(res, i);

  protected abstract postEvidenceError(error, i);

  postWrittenRes(postBody) {
    this.loadFlag = true;

    this.dataManager.postRequest(this._postWrittenResUrl, postBody, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.postWrittenResResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.networkErrorHandler(err.error);
          this.postWrittenResError(err.error);
        }
      );
  }

  protected abstract postWrittenResResponse(res);

  protected abstract postWrittenResError(err);

  getDisputeChargeBackPdf(file = null) {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(this._getChargeBackPdf, 0).subscribe(
      (res) => {
        this.loadFlag = false;
        this.passChargeBackPdfResponse((new Blob([res], {type: file ? file.file_type : 'application/pdf'})));
      },
      (err) => {
        this.loadFlag = false;
        this.passChargeBackPdfError(err);
      },
      () => this.passChargeBackPdfComplete()
    );
  }

  protected abstract passChargeBackPdfResponse(res);

  protected abstract passChargeBackPdfError(err);

  protected abstract passChargeBackPdfComplete();
}
