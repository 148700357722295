<div class="container-fluid mt-sm-5 mt-1">
  <div class="row b_shadow">
    <div class="col-sm-12 col-12 col-md-12 col-lg-12 header create-header bg-infos">
        {{ 'Export '+ (serviceName.heading )}}
    </div>
    <div class="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 px-3 py-4">
      <div class="row" *ngIf="topErrorMessage">
        <div class="col-md-12 error">
          {{topErrorMessage}}
        </div>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12 form-group">
        <div class="head">Date Range</div>
      </div>
      <div class="col-lg-12 col-sm-12 col-md-12 col-12 col-xl-12" *ngFor="let component of exportExcel">
        <div class="row my-2" *ngIf="component.componentType == 'date' && (component.key != 'month' && component.key != 'year')">
          <div [ngClass]="[component.heading == 'Custom' ? 'col-md-12 col-lg-4 col-sm-6 col-12 col-xl-4 mob_date' : 'col-md-6 col-lg-4 col-sm-6 col-6 col-xl-4 mob_date',(component.key === 'time' || component.key === 'Transaction_date_time' || component.key === 'status') ? 'mt-2': '' ]">
            <input id="{{component.key}}" type="radio" name="range" (change)="exportData(component)" value="component.checked"
              [checked]=component.checked /><label for="{{component.key}}" class="exportLabel ml-xl-3 ml-lg-3 ml-md-3 ml-1 ophead">{{component.heading}}</label>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-6 mob_date"   *ngIf="(component.heading == 'Custom' || component.heading === 'Date range') && component.checked">
            <div class="row m-0">
              <div class="col-xl-4 col-lg-4 col-md-4 col-6 mob_date large_date">
              <input type="text" [(ngModel)]="from_date" class="form-control calander-input-icon  mr-md-3 mr-xl-3" #dp="bsDatepicker" placement="bottom" [bsConfig]="bsDateConfig" placeholder="MM/DD/YYYY" bsDatepicker [(bsValue)]="from_date" [maxDate]="current_date" [minDate]="min_date" (bsValueChange)="setCustomdate(component)" (onHidden)="validateDate()">
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-6 mob_cus_range large_date">
                <input type="text" [(ngModel)]="to_date" class="form-control calander-input-icon " #dp1="bsDatepicker" [bsConfig]="bsDateConfig"placement="bottom" placeholder="MM/DD/YYYY" bsDatepicker  [(bsValue)]="to_date" [maxDate]="current_date" (bsValueChange)="setCustomdate2(component)" (onHidden)="validateDate()">
              </div>
                <span class="error" *ngIf="showError">{{errorMsg}}</span>
              </div>
        </div>
        <div class="col-md-6 col-lg-8 col-sm-12 col-6 col-xl-8" *ngIf="component.key === 'time'">
          <div class="row m-0">
              <select class="form-control col-6 col-md-6 col-lg-2 col-xl-2 col-sm-6 mr-3" required="" [(ngModel)]="component.fromTime" (change)="checkTime(component)">
                <option [value]="null" selected disabled>Select</option>
                <option *ngFor="let time of timePeriods" [value]="time">{{time}}</option>
              </select>
            <select class="form-control col-6 col-md-6 col-lg-2 col-xl-2 col-sm-6" required="" [(ngModel)]="component.toTime" (change)="checkTime(component)">
              <option [value]="null" selected disabled>Select</option>
              <option *ngFor="let time of timePeriods" [value]="time">{{time}}</option>
            </select>
      </div>
      <span *ngIf="showError && component.toTime != null" class="validation-err-msg">To time should not be lesser than From time.</span>
      <span *ngIf="showError && component.toTime == null && component.checked" class="validation-err-msg">From time and to time is required.</span>
    </div>
    <div class="col-md-6 col-lg-8 col-sm-12 col-6 col-xl-8" *ngIf="component.key === 'Transaction_date_time'">
      <div class="row m-0">
          <select class="form-control col-6 col-md-6 col-lg-3 col-xl-3 col-sm-6 mr-1" required="" [(ngModel)]="component.fromDateTime" (change)="checkDateTime(component)" placeholder="MM/DD/YYYY">
            <option [value]="null" selected disabled>Select</option>
            <option *ngFor="let transaction_date of component.options" [value]="transaction_date">{{transaction_date}}</option>
          </select>
          <select class="form-control col-6 col-md-6 col-lg-2 col-xl-2 col-sm-6 mr-1" required="" [(ngModel)]="component.fromTime" (change)="checkDateTime(component)">
            <option [value]="null" selected disabled>Select</option>
            <option *ngFor="let fromTime of timePeriods" [value]="fromTime">{{fromTime}}</option>
          </select>
          <div class="m-2">-</div>
          <select class="form-control col-6 col-md-6 col-lg-3 col-xl-3 col-sm-6 mr-1" required="" [(ngModel)]="component.toDateTime" (change)="checkDateTime(component)">
            <option [value]="null" selected disabled>Select</option>
            <option *ngFor="let transaction_date of component.options" [value]="transaction_date">{{transaction_date}}</option>
          </select>
        <select class="form-control col-6 col-md-6 col-lg-2 col-xl-2 col-sm-6" required="" [(ngModel)]="component.toTime" (change)="checkDateTime(component)">
          <option [value]="null" selected disabled>Select</option>
          <option *ngFor="let toTime of timePeriods" [value]="toTime">{{toTime}}</option>
        </select>
    </div>
     <span *ngIf="showError && component.toTime != null" class="validation-err-msg">To date & time should not be lesser than From date & time. </span>
     <span *ngIf="showError && component.toTime == null && component.checked" class="validation-err-msg">From date & time and to date & time is required.</span>
  </div>
<div class="col-md-6 col-lg-5 col-sm-12 col-6 col-xl-5 mt-2" *ngIf="component.key === 'status'">
  <div class="row m-0">
<select class="form-control col-6 col-md-6 col-lg-5 col-xl-5 col-sm-6 mr-3" required="" [(ngModel)]="component.selectedOption" (change)="onChange(component)">
  <option [value]="null" selected disabled>Select</option>
  <option *ngFor="let status of component.options" [value]="status">{{status}}</option>
</select>
</div>
<span *ngIf="showError && component.selectedOption == null && component.checked" class="validation-err-msg">Please select a status.</span>
</div>
        <div class="col-md-6 col-lg-4 col-sm-6 col-6 col-xl-4" *ngIf="component.key === 'reference_number'">
          <select class="form-control" required="" [(ngModel)]="component.selectedOption" (change)="onChange(component)">
            <option [value]="null" selected disabled>Select</option>
            <option *ngFor="let reference of component.options" [value]="reference">{{reference}}</option>
          </select>
          <span *ngIf="showError" class="validation-err-msg">Please select reference number.</span>
        </div>
          <div class="col-md-6 col-lg-4 col-sm-6 col-6 col-xl-4" *ngIf="component.visible">
            <span class="export-content" >{{component.value}}</span>
          </div>
        </div>
        <div class="row my-2" *ngIf="component.componentType == 'date' && (component.key === 'month')">
          <div class="col-md-6 col-lg-4 col-sm-6 col-6 col-xl-4 mob_date">
            <input id="{{component.key}}" type="radio" name="range" (change)="exportData(component)" value="component.checked"
              [checked]="component.checked" /><label for="{{component.key}}" class="exportLabel ml-xl-3 ml-lg-3 ml-md-3 ml-1 ophead">{{component.heading}}</label>
          </div>
          <div *ngIf="component.key == 'month' " class="col-md-6 col-lg-2 col-sm-12 col-6 col-xl-2">
            <select class="form-control" [(ngModel)]="dmonth" required="" (change)="onChange(component)">
              <option value="01" [selected]="this.dmonth == 01 ? true : false ">January</option>
              <option value="02" [selected]="this.dmonth == 02 ? true : false ">February</option>
              <option value="03" [selected]="this.dmonth == 03 ? true : false ">March</option>
              <option value="04" [selected]="this.dmonth == 04 ? true : false ">April</option>
              <option value="05" [selected]="this.dmonth == 05 ? true : false ">May</option>
              <option value="06" [selected]="this.dmonth == 06 ? true : false ">June</option>
              <option value="07" [selected]="this.dmonth == 07 ? true : false ">July</option>
              <option value="08" [selected]="this.dmonth == 08 ? true : false ">August</option>
              <option value="09" [selected]="this.dmonth == 09 ? true : false ">September</option>
              <option value="10" [selected]="this.dmonth == 10 ? true : false ">October</option>
              <option value="11" [selected]="this.dmonth == 11 ? true : false ">November</option>
              <option value="12" [selected]="this.dmonth == 12 ? true : false ">December</option>
            </select>
          </div>
        </div>
        <div class="row my-2" *ngIf="component.componentType == 'date' && (component.key === 'year')">
          <div class="col-md-6 col-lg-4 col-sm-6 col-6 col-xl-4 mob_date">
            <input 
            *ngIf="!(component.componentType == 'date' && (component.key === 'year'))"
            id="{{component.key}}" type="radio" name="range" (change)="exportData(component)" value="component.checked"
              [checked]="component.checked" />
              <label for="{{component.key}}" 
                [ngClass]="(component.componentType == 'date' && (component.key === 'year'))?'pl-4 ml-1':'exportLabel ml-xl-3 ml-lg-3 ml-md-3 ml-1 ophead'">
                {{component.heading}}
              </label>
          </div>
          <div class="col-md-6 col-lg-2 col-sm-12 col-6 col-xl-2">
            <select class="form-control" [(ngModel)]="dyear" required="" (change)="onChange(component)">
              <option value="2016" [selected]="dyear == 2016 ? true : false ">2016</option>
              <option value="2017" [selected]="dyear == 2017 ? true : false ">2017</option>
              <option value="2018" [selected]="dyear == 2018 ? true : false ">2018</option>
              <option value="2019" [selected]="dyear == 2019 ? true : false ">2019</option>
              <option value="2020" [selected]="dyear == 2020 ? true : false ">2020</option>
              <option value="2021" [selected]="dyear == 2021 ? true : false ">2021</option>
              <option value="2022" [selected]="dyear == 2022 ? true : false ">2022</option>
              <option value="2023" [selected]="dyear == 2023 ? true : false ">2023</option>
              <option value="2024" [selected]="dyear == 2024 ? true : false ">2024</option>
              <option value="2025" [selected]="dyear == 2025 ? true : false ">2025</option>
              <option value="2026" [selected]="dyear == 2026 ? true : false ">2026</option>
              <option value="2027" [selected]="dyear == 2027 ? true : false ">2027</option>
              <option value="2028" [selected]="dyear == 2028 ? true : false ">2028</option>
              <option value="2029" [selected]="dyear == 2029 ? true : false ">2029</option>
              <option value="2030" [selected]="dyear == 2030 ? true : false ">2030</option>
            </select>
          </div>
        </div>
        <div class="row my-2 pl-5 py-3" *ngIf="(component.componentType == 'date' && (component.key === 'year'))"><b>Please if you need period different from 1 specific month of a year, select below the "Date Range".</b>
        </div>
        <div class="row mt-xl-4 mt-lg-4 mt-md-4" *ngIf="component.componentType == 'list'">
          <div class="col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12 head mob_date form-group">
            {{component.heading}}
          </div>
          <div class="pl-xl-3 pl-lg-3 pl-md-3 pl-0">
            <ng-multiselect-dropdown
            [placeholder]="'Select columns'"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onDeSelect($event)"
            [required]='requiredField'
            [ngClass]="setClass()"
            (onFilterChange)="onFilterChange($event)">
           </ng-multiselect-dropdown>
           <div class="mb-4" *ngIf="selectedItems.length <=0">
            <span class="validation-err-msg">Columns are required.</span>
          </div>
        </div>
          <div class="col-12 mt-2 mb-2 mob_date" *ngIf="selectedItems.length> 0">
            <ul class="nav nav-pills">
              <li *ngFor="let items of selectedItems ; let i = index" class="export-content nav-items">{{items.value}}<span>{{i != (selectedItems.length -1) ? ',&nbsp;': ''}}  </span></li>
            </ul>
          </div>
        </div>
        <div class="row" *ngIf="component.componentType == 'general'" >
          <div class="col-md-6 mob_date">
            <input id="{{component.key}}" type="radio" name="general" (change)="exportGeneralData(component)" value="component.checked" [checked]="component.checked"/><label for="{{component.key}}" class="exportLabel ml-xl-3 ml-lg-3 ml-md-3 ml-1 ophead">{{component.heading}}</label>
          </div>
        <div class="col-md-6 mob_date">
            <span class="export-content">{{component.value}}</span>
          </div>
        </div>

        </div>
        <div class="row d_footer float-xl-right float-lg-right float-md-right">
          <div class="col-lg-12 col-sm-12 col-md-12 pt-3 pb-3">
            <button (click)="cancelBtn()" class="cancel_btn">Cancel</button>
            <button [disabled]="selectedItems.length <= 0 || showError" class="update-btn" (click)="(selectedItems.length > 0 ? export() : '')">Export<i *ngIf="loadFlag" class="mb-1 fa fa-spinner fa-pulse loader"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
