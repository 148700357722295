import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import { Directive } from '@angular/core';
import {BaseComponent} from '../../abstract/base.component';



@Directive()
export abstract class SiginAction extends BaseComponent {
  protected _sigInUrl: string;
  protected _getAccountInfoUrl: string;
  protected _forgotPassword: string;


  protected  abstract passSiginResponse(res);
  // protected abstract passForgotPasswordResponse(res);
  protected abstract passSignInError(error: ApiErrorModel);
  protected abstract passAccountInfoResponse(res);
  protected abstract passError(error);


  // protected abstract passForgotPasswordError(error: ApiErrorModel);
  getAccountInfo() {
    this.dataManager.getRequest(this._getAccountInfoUrl, 0)
      .subscribe(
        (res) => this.passAccountInfoResponse(res),
        (err) => {
          this.networkErrorHandler(err.error);
          this.passError(err.error);
        }
      );
  }
  userSignIn(postBody) {
    this.dataManager.login(this._sigInUrl, postBody)
      .subscribe(
        res => this.passSiginResponse(res),
        error => {
          this.networkErrorHandler(error.error);
          this.passSignInError(error.error);
        }
      );
  }

  // resetPassword(postBody) {
  //   this.dataManager.postRequest(this._forgotPassword, postBody, 0)
  //     .subscribe(
  //       res => this.passForgotPasswordResponse(res),
  //       error => {
  //         this.networkErrorHandler(error.error);
  //         this.passForgotPasswordError(error.error);
  //       }
  //     );
  // }
}
