import { Component, OnInit } from '@angular/core';
import { AppConstants } from "../../shared/constants/app-constants";
import { DataManagerService } from "../../data/services/DataManager/data-manager.service";
import { GetStatementsAction } from '../statements/get-statements.action'
import { BaseListComponent } from "../../abstract/base-list.component";
import { DatePipe } from '@angular/common';
import { ErrorConstants } from "../../shared/constants/error-constants";
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatementsModel } from '../../data/model/StatementsModel/statements.model';
import {ApiResponseModel} from '../../data/model/ApiResponseModel/api-response.model';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.css']
})
export class StatementsComponent extends GetStatementsAction implements OnInit {
  statementlist: ApiResponseModel<StatementsModel>;
  stamentservice: string;
  fileURL: string;
  lasdatemonth: any;
  from_date: Date = new Date();
  to_date: Date = new Date();
  blob: any;

  constructor(
    dataManager: DataManagerService, private datePipe: DatePipe, snackBar: MatSnackBar) {
    super();
    this.snackBar = snackBar;
    this.dataManager = dataManager;
    this.setDates();
    this.statementlist = new ApiResponseModel();
    // this.getListUrl = AppConstants.BASE_URL + this.authlogservicename;
    // this.includes = '&from_date=' + this.datePipe.transform(this.from_date, 'yyyy-MM-dd')
    //   + '&to_date=' + this.datePipe.transform(this.to_date, 'yyyy-MM-dd');
    // this.loadFlag = true;
    this.stamentservice = '/merchant_statements';
    // this.stamentservice = '/authorization/summary';
    this.getListUrl = AppConstants.BASE_URL + this.stamentservice;

  }

  ngOnInit() {
    this.openAdfReport();
    // this.setUrl();
  }

  updateMyDate(newDate) {
    console.log("lastdate", newDate);
    this.lasdatemonth = newDate
    this.to_date = new Date(this.lasdatemonth.getFullYear(), this.lasdatemonth.getMonth() + 1, 0);
    console.log("lastdateresult", this.to_date)
  }

  onOpenCalendarto(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onOpenCalendarfrom(container) {
    container.monthSelectHandler = (event: any): void => {
      var dd = container._store.dispatch(container._actions.select(event.date));

    };
    container.setViewMode('month');
  }

  checkdate() {
    if (!this.isNull(this.from_date) && this.from_date.toString() === 'Invalid Date') {
      this.from_date = null;
    }
  }

  checkdate2() {

    if (!this.isNull(this.to_date) && this.to_date.toString() === 'Invalid Date') {
      this.to_date = null;
    }
  }

  openAdfReport(): void {
    if (this.isNull(this.from_date) || this.isNull(this.to_date) || this.from_date > this.to_date) {
      this.showSnackBar('Enter valid date.', 'Ok', 3000);
    } else {
      this.loadFlag = true;
      // this.getListUrl = AppConstants.BASE_URL + this.authlogservicename;
      // this.includes = '&from_date=' + this.datePipe.transform(this.from_date, 'yyyy-MM-dd')
      //   + '&to_date=' + this.datePipe.transform(this.to_date, 'yyyy-MM-dd')
      // this.setUrl();

      this.from =  this.datePipe.transform(this.from_date, 'yyyy-MM-dd');
       this.to = this.datePipe.transform(this.to_date, 'yyyy-MM-dd');
      this.setUrl();
    }
  }

  setDates() {
    const today = new Date();
    console.log("todaydate", today)
    //this.from_date = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    this.from_date = new Date(today.getFullYear(), 0, 1);
    console.log("todaydateresult", this.from_date)
    this.to_date = new Date()
    // this.to_date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    console.log("monthlastdate", this.to_date)

  }
  // protected getListRes(response) {
  //   this.loadFlag = false;
  //   console.log('authlogiee', response);
  //   this.statementlist = response;
  //   //this.customerList = customerResponse;
  //   // if (customerResponse.data.length === 0) {
  //   //   this.errorMsg = ErrorConstants.customerErrorMsg[0];
  //   // } else {
  //   //   this.errorMsg = null;
  //   // }

  // }
  protected getListRes(response) {
    if (response.data.length === 0) {
      this.errorMsg = ErrorConstants.statementErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
    this.loadFlag = false;
    console.log('authlogiee', response);
    this.statementlist = response;
    this.metadata = response.meta.pagination;
  }
  protected getListErr(error: any) {
    this.loadFlag = false;
    console.log("", error)
  }
  // protected getListErr(error: any) {
  //   this.errorMsg = this.networkErrorHandler(
  //     error,
  //     ErrorConstants.statementErrorMsg
  //   );
  //   console.log(error);
  // }

  // paginate(paginateExtension) {
  //   this.getList(paginateExtension);
  // }
  // paginate(paginateExtension) {
  //   this.getStatementsListData(paginateExtension);
  //   this.loadFlag = true;
  // }
  download_statement(id) {

    this._getstatementPdf = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_STATEMENTS + '/' + id
    this.getStatementPdf();
    this.showSnackBar('File is loading.', 'Ok', 3000);
  }
  protected passStatementPdfResponse(res: any) {
    console.log(res);
    // this.openFileInBrowser(res);
    this.blob = new Blob([res], { type: 'application/pdf' });
    var downloadURL = window.URL.createObjectURL(res);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = "statement.pdf";
    link.click();
  }

  protected passStatementPdfError(err: any) {
    console.log(err);
  }

  view_statement(id) {
    this._getstatementPdfView = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_STATEMENTS + '/' + id
    this.getStatementPdfView();
    this.showSnackBar('File is loading.', 'Ok', 3000);
  }
  protected passStatementPdfViewResponse(res: any) {
    this.blob = new Blob([res], { type: 'application/pdf' });
    var downloadURL = window.URL.createObjectURL(res);
   // window.open(downloadURL, '_blank')
    var a = document.createElement('a');
    a.href = downloadURL;
    a.setAttribute('target', '_blank');
    a.click();
  }

  protected passStatementPdfViewError(err: any) {
    console.log(err);
  }

  paginate(paginateExtension) {

    this.getList(paginateExtension);
  }

  // protected passstatementPdfComplete() {
  //   window.open(this.fileURL, '_blank');
  // }
  openFileInBrowser(res) {
    console.log(res);
    // const pdfFile = res;
    // this.fileURL = URL.createObjectURL(pdfFile);

  }
}

