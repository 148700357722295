import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {Router} from '@angular/router';
import {CreateNewBridgeAccountAction} from './create-new-bridge-account.action';


@Component({
  selector: 'app-create-new-bridge-account',
  templateUrl: './create-new-bridge-account.component.html',
  styleUrls: ['./create-new-bridge-account.component.css']
})
export class CreateNewBridgeAccountComponent extends CreateNewBridgeAccountAction implements OnInit {
  errorMsgFlag: boolean;
  source_id: string;
  account_name: string;
  emailid: string;
  errorMsg: string;
  topErrorMessage: string;
  errMsg: string;
  keys: String[];
  keys1: String[];
  serviceName: string;
  switchAccountId: string;
  constructor(private dialogRef: MatDialogRef<CreateNewBridgeAccountComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, dataManager: DataManagerService,
              _router: Router) {
    super();
    console.log(data);
    this.router = _router;
    this.dataManager = dataManager;
    this.errorMsgFlag = false;
    // this._getAccountInfoUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '?limit=100' + '&page=1' ;
    this.serviceName = '/bridge';
  }

  ngOnInit() {
  }
  createAccount() {
    this.loadFlag = true;
    const postBody = {};
    postBody['name'] = this.account_name ? this.account_name : null;
    postBody['email'] = this.account_name ? this.emailid : null;
    this.createAccountUrl = AppConstants.BASE_URL + this.serviceName + '/accounts' ;
    this.postAccount(this.createAccountUrl, postBody);
    // localStorage.removeItem(AppConstants.STORAGE_KEY_ACCOUNT_ID);
    // localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID);
    // this._compiler.clearCache();
  }
  protected passResponse(response) {
    console.log(response, 'asu');
    this.loadFlag = false;
    this.dialogRef.close(true);
  }

  protected passError(error) {
    this.errorMsgFlag = true;
    this.loadFlag = false;
    this.errMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    let i;
    let j;
    let name = ['name', 'email'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
}
