<div class="new-payee-bdy">
    <div class="container-fluid b_shadow mt-5">
      <div class="row create-header bg-infos">
         <span class="header"> {{heading}} </span>
      </div>
      <div class="col-lg-12 col-12 col-sm-12 col-md-12 my-lg-4 my-md-3">
          <div class="row" *ngIf="errorMsgFlag">
             <p class="col-md-12 error mb-3" id="err"> {{errorMsg}}</p>
          </div>
         <div class="row">
            <ng-container *ngIf="errorMsgFlag">
               <div class="row" *ngFor="let value of keys1" class="col-md-12 error mb-3" id="err">
                  <div class="col-sm-12 error mb-3" *ngFor="let obj of value">
                     <div class="col-sm-12 error mb-3"> {{obj}}</div>
                  </div>
               </div>
            </ng-container>
         </div>
    </div>
<form [formGroup]="addPayee" >
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-8 col-8 col-xl-4 mb-4 p-3 ">
            <div class="form-group row align-items-center mt-sm-0 mt-2">
                <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="first_name">Payee First Name<i class="required-icon">*</i></label>
                
                <div class="form-group   row ">
                    
                    <input class="form-control" id="first_name" formControlName="first_name">
                </div>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.first_name.status==='INVALID' " style="bottom: 0">Please provide payee name. Min 3 letters</div>
             
            </div>
            <div class="form-group row align-items-center mt-sm-0 mt-2">
                <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="last_name">Payee Last Name<i class="required-icon">*</i></label>
                
                <div class="form-group   row ">
                    
                    <input class="form-control" id="last_name" formControlName="last_name">
                </div>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.last_name.status==='INVALID' " style="bottom: 0">Please provide payee name. Min 3 letters</div>
             
            </div>
            <div class="form-group row align-items-center mt-sm-0 mt-2">
                <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="email">Payee Email<i class="required-icon">*</i></label>
            <div class="form-group   row ">
                <input class="form-control" id="email" formControlName="email">
            </div>
            <div class="tip_for_inputs" *ngIf="addPayee.controls.email.status==='INVALID' " style="bottom: 0">Please provide payee e-mail.<p style="color: grey;">Example: jhon@doe.com</p></div>
            
            </div>
            <div class="form-group row align-items-center mt-sm-0 mt-2">
                <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="type">Type:<i class="required-icon">*</i></label>
                <div class="form-group   row">
                <select #type class="custom-select"  formControlName="type" (change)="onSelected(type.value)">
                    <option value="" disabled selected>Select Type</option>
                        <option value="percent" >Percentage</option>
                        <option value="fixed">Flat Rate</option>
                        <option value="percent_and_fixed">Percentage + Flat Rate</option>
                        
                    </select>
                </div>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.type.pristine" style="bottom: 0">Please select type!</div>
            </div>
            <div *ngIf="typePayeePercentage" class="form-group row align-items-center mt-sm-0 mt-2">
                <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Amount % <i class="required-icon">*</i></label>
                <div class="form-group   row ">
                    <input class="form-control" type="text" id="amount" formControlName="percent" appMinMaxValidator
                    appAmountValidator min="0.01" max="9999999" (keypress)="onlyNumber($event)" placeholder="0">
                </div>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.percent.status==='INVALID' " style="bottom: 0"> Between 0 and 100.
                </div>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.percent.pristine" style="bottom: 0">Please provide number! Between 0 and 100.
                </div>
            </div>
            <div class="form-group row align-items-center mt-sm-0 mt-2" *ngIf="typePayeeAmount">
                <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Amount $ <i class="required-icon">*</i></label>
                <div class="form-group   row " >
                <input class="form-control" type="text" id="amount" appMinMaxValidator
                appAmountValidator min="0.01" max="9999999"formControlName="fixed_amount" (keypress)="onlyNumber($event)" placeholder="0.00">
            </div>
            </div>
            <div class="form-group row align-items-center mt-sm-0 mt-2">
                <label class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="amount">Payee Access to Dashboard?<i class="required-icon">*</i></label>
                
                <input type="radio"   id="radio_yes" value="1" formControlName="dashboard_access">
                <label class="p-l-r" for="radio_yes">Yes</label>
                <input type="radio"  id="radio_no" value="0" formControlName="dashboard_access"> 
                <label class="p-l-r" for="radio_no">No</label>

             
                <div class="tip_for_inputs" *ngIf="addPayee.controls.dashboard_access.status==='INVALID' " style="bottom: 0">Please select Yes or No.</div>
            </div>
              <div class="form-group row align-items-center mt-sm-0 mt-2" *ngIf="campaign_id===undefined">
                <label  class="col-sm-6 col-md-6 col-lg-4 col-12 col-xl-4 padding-m" for="type">Campaign:</label>
                <!-- <div class="form-group  row   ">
                    <select class="form-control custom-select" formControlName="campaign_id"  >
                    <option  value="" selected  disabled>Select Campaign</option>
                    <option *ngFor="let campaign of listofCampaigns" [campaign]="campaign"  [value]="campaign.id">{{campaign.name}}</option>
                    
                    </select>
                </div> -->
                <app-type-ahead-component  (selectedObject)="payeeSelected($event)" [serviceName]="_service_name"   [selectedValue]="null" [require]="true" (keyup)=" deletePayee()"></app-type-ahead-component>
                <div class="tip_for_inputs" *ngIf="addPayee.controls.campaign_id.status==='INVALID' " style="bottom: 0">Please select Campaign.</div>
                </div> 

                <div class="row justify-content-end pt-3 pb-3">
                    <button class="cancel_btn" (click)="cancelAddPeyee()">Go Back</button>
                    <app-loading-button class="{{addPayee.invalid ? 'disabled' : ''}}"  (buttonClick)="this.addPayee.invalid ? print(addPayee) : createNewPayee()" [disable]="addPayee.invalid"  type="submit" [buttonText]="buttonText"></app-loading-button>
                </div> 
              </div>
    </div>
</form>
</div>