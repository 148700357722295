import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import {AppConstants} from '../../shared/constants/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ForgotPasswordAction} from './forgot-password.action';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends ForgotPasswordAction implements OnInit {
  email: string;
  password: string;
  username: string;
  name: string;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  forgot: string;

  constructor(router: Router, dataManager: DataManagerService, snackBar: MatSnackBar,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.snackBar = snackBar;
    this.forgot = '/forgot';
    this._forgotPassword = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_FORGOT_PASSWORD + this.forgot;
    this.router = router;
    this.dataManager = dataManager;
  }

  ngOnInit() {
  }

  resetpass (): void {
    this.loadFlag = true;
    const postBody = {};
    postBody['username'] = this.username;
    postBody['reseturl'] = 'password-reset';
    this.resetPassword(postBody);
  }

  back() {
    this.routeNavigate('/signin');
  }
  redirectToRegister() {
    this.routeNavigate('/register');
  }

  protected passForgotPasswordResponse(res: any) {
    console.log('registered forgot password data', res.data);
    // this.dataManager.accessToken = res.data.response_content.token_type + ' ' + res.data.response_content.access_token;
    // this.dataManager.menuArray = res.menu ? res.menu : [];
    // this.dataManager.createHeaderWithUersAccessToken();
    this.snackBar.open(
      'Password sent to your mail id successfully',
      'OK',
      {
      duration: 30000,
      panelClass: 'success-snack'
    });
    //this.showSnackBar('Password sent to your mail id successfully', 'OK', 30000);
    this.routeNavigate('/signin');
  }

  protected passForgotPasswordError(error: ApiErrorModel) {
    this.showSnackBar(error.message, 'Registration Error', 30000);
    console.log(error);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    let i;
    let j;
    let name = ['email', 'password'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  onKeydown(event) {
    if (event.key === 'Enter') {
      console.log(event);
      this.resetpass();
    }
  }
}
