<div class="row ">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 px-4 py-4 ml-2 bold text-center" *ngIf="eventList.data.length <= 0">
        {{create_permission ? 'No events available' : 'Permission Denied'}}
      </div>
    </div>
  </div>
  <div class="col-md-12 padding-m" *ngIf="eventList">
    <div class="row event-data" *ngFor="let event of eventList.data; let i = index">
      <div class="col-md-12 pb-3 pt-3 border-bottom w-sm-75" *ngIf="i < 8"><!--(click)="navigateToEventDetails(event.id)"-->
        <div class="float-left event-left-text col-lg-10 col-md-8  col-6 col-6"><a [routerLink]="redirect_link_details" class="break-word" [queryParams]="{id: event.id}">{{event.description}}</a>
        </div>
        <div class="float-right event-right-text col-lg-2 col-md-4 col-6 padding-m"><a [routerLink]="redirect_link_details"
                                                     [queryParams]="{id: event.id}">
          {{event.created_at | dateFormat: 'MM/dd/yyyy hh:mm'}}</a></div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="eventList.data && eventList.data.length > 8">
  <div class="col-md-12">
    <div class="view-more-button-container p-2">
      <button mat-raised-button class="detail-btn" (click)="redirectToEventList()">View More Events</button>
    </div>
  </div>
</div>
