import {Component, DoCheck, Inject, OnChanges, OnInit, PLATFORM_ID} from '@angular/core';
import {AccountModel} from '../../data/model/AccountModel/account.model';
import {AppConstants} from '../../shared/constants/app-constants';
import {isPlatformBrowser} from '@angular/common';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {BaseComponent} from '../../abstract/base.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-switch-account-dialog',
  templateUrl: './switch-account-dialog.component.html',
  styleUrls: ['./switch-account-dialog.component.css']
})
export class SwitchAccountDialogComponent extends BaseComponent implements OnInit{
  currentUsers: AccountModel[];
  activeUser: AccountModel;
  _getaccount: string;
  serviceName: string;
  currentAccountId: string;
  name: string;
  currentResolution: number;
  get currentAccountName(): any {
    const currentAccountName = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME));
    this.setUserAccounts();
    return currentAccountName;
  }
  get menuName(): any {
    const menu = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_FIRST_MENU));
    return menu;
  }

  constructor( router: Router, private  dialog: MatDialogRef<SwitchAccountDialogComponent>, dataManager: DataManagerService, route: ActivatedRoute,
               @Inject(PLATFORM_ID) private platformId: any,
               @Inject('LOCALSTORAGE') private localStorage: any,
               private _route: ActivatedRoute,  private localeService: BsLocaleService) {
   super();
    this.dataManager = dataManager;
    this.router = router;
    this.route = route;
    this.name = null;
    this.serviceName = '/switch';
    this.currentUsers = [];
    this.activeUser = new AccountModel();
    this._getaccount = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT;
    this.currentResolution = window.screen.width;
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
  }

  ngOnInit(): void {
  }
  setCurrentAccount() {

    this.activeUser = this.currentUsers.find(x => x.id === this.currentAccountId);

  }
  switchAccount(id, account_name) {
    console.log('switchaccount', id);
    // this.alertDialog.closeAll();
    this.dialog.close(true);
    localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(account_name));
    }


    this._getaccount = AppConstants.BASE_URL + this.serviceName + '/' + id;
    this.getSwitchAccount(this._getaccount);
    this.router.navigate(['loading..']);
  }
  getSwitchAccount(postBody) {
    this.dataManager.postRequest(this._getaccount, postBody, 0)
      .subscribe(
        res => this.passAccounts(res),
        error => {
          this.networkErrorHandler(error.error);
          this.passAcountError(error.error);
        }
      );
  }
  passAcountError(err) {
    this.loadFlag = false;
    this.routeNavigate('home');
    console.log(err);
  }
  passAccounts(res) {
    // this.dataManager.userAccountInfoObject = res.data;

    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(res.current_account_id));
    }
    console.log('response on accounts', res);
    this.loadFlag = true;
    this.routeNavigate(this.dataManager.menuArray[0].routerLink);
  }
  setUserAccounts() {
    this.currentUsers = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));

    if (!this.isNullOrUndefined(this.currentUsers) && this.currentUsers.length > 0) {
      this.setCurrentAccount();
    }
  }
}
