/**
 * Created by arun on 6/6/18.
 */
export class BankAccountModel {
  object?: string;
  id?: string;
  account_holder_first_name?: string;
  account_holder_last_name?: string;
  account_holder_type?: string;
  bank_name?: string;
  bank_phone_number?: string;
  last4?: number;
  routing_number?: string;
  bank_account_number?: string;
  account_type?: string;
  transaction_type?: string;

}

