<div class="container-fluid no-padding">
  <div class="row">
      <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
        <h3 class="headings">{{headingSubscription()}}</h3>
      </div>
    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 text-right pl-0 btn-mbl mt-2" [ngClass]="{'d-md-none': this.matchUrl('resend-invoice')}" *ngIf="!this.matchUrl('setting') && !this.matchUrl('subscription-details') && !this.matchUrl('cycle-details') && !this.matchUrl('create-plan') && !matchUrl('/subscription/update-plan') && !matchUrl('/subscription/create-discount')
    && !this.matchUrl('discount-details') && !this.matchUrl('invoice-details') && (!this.matchUrl('create-customer') || !this.matchUrl('create-subscription') || !this.matchUrl('update-subscription'))" id="new">
      <ul class="list-inline">
        <li class="list-inline-item pb-md-3" *ngIf="!this.matchUrl('invoice-list') && !this.matchUrl('subscription-list') && !this.matchUrl('create-customer') && !this.matchUrl('create-subscription') && !this.matchUrl('update-subscription') && create_permission"><span (click)="openDialog()" class="add-button new-btn" [class.new-align]="this.matchUrl('/subscription/resend-invoice')" ><i class="fa fa-plus mr-1"></i>New</span></li>
        <li class="list-inline-item pb-md-3" *ngIf=" create_permission  && (this.matchUrl('subscription-list') || this.matchUrl('create-customer')) && !this.matchUrl('create-subscription')"><span (click)="openDialog()" class="add-button" [class.new-cust-subs]="this.matchUrl('/subscription/create-customer')"><i class="fa fa-plus plus-icon mr-sm-1 mr-0"></i> New Subscription</span></li>
        <li class="list-inline-item pb-md-3 subs-btn" *ngIf="create_permission  && (this.matchUrl('subscription-list') || this.matchUrl('create-subscription'))"
        [class.new-subs]="this.matchUrl('/subscription/create-subscription')"
          ><span (click)="toNewCust()" class="add-button"><i class="fa fa-plus plus-icon mr-sm-1 mr-0" ></i> New Customer</span></li>
        <li class="list-inline-item pb-md-3 filter-btn" [class.plan-filter]="this.matchUrl('/subscription/plan-list') || this.matchUrl('/subscription/discount-list')" [class.invoice-filter]="this.matchUrl('/subscription/invoice-list')">
          <app-filter *ngIf="!this.matchUrl('create-customer') && !this.matchUrl('create-subscription') && !this.matchUrl('update-subscription') && !this.matchUrl('create-plan') && !this.matchUrl('resend-invoice')" [filter_storage_key]="filter_storage_key" [count_storage_key]="count_storage_key" [filter]="filter"></app-filter>
        </li>
      </ul>
    </div><!-- / .col-sm-12 .col-md-6-->
  </div>
  <div class="  row text-center mt-0 ml-1 d-sm-flex d-none">
    <div class="subscription_main_tabs">
    <div class="subscription_tabs" routerLinkActive ="active" routerLink="/subscription/subscription-list"
        (click)="clearFiltersFromLocal()" [class.active]="matchUrl('/subscription/subscription-details') || matchUrl('/subscription/subscription-list') || matchUrl('/subscription/create-subscription') || matchUrl('/subscription/update-subscription')">
      Subscriptions
    </div>
    </div>
    <div class="subscription_main_tabs">
    <div class="subscription_tabs" routerLinkActive ="active" routerLink="/subscription/plan-list"
        [class.active]="matchUrl('/subscription/cycle-details') || matchUrl('/subscription/plan-list') || matchUrl('/subscription/create-plan') || matchUrl('/subscription/update-plan')" (click)="clearFiltersFromLocal()">
        Plans
     </div>
    </div>
    <div class="subscription_main_tabs">
    <div class="subscription_tabs" routerLinkActive ="active" routerLink="/subscription/discount-list"
        [class.active]="matchUrl('/subscription/discount-details') || matchUrl('/subscription/discount-list') || this.matchUrl('/subscription/create-discount')"
        (click)="clearFiltersFromLocal()">
         Discounts
    </div>
    </div>
    <div class="subscription_main_tabs">
    <div class="subscription_tabs" routerLinkActive ="active" routerLink="/subscription/invoice-list"
        [class.active]="matchUrl('/subscription/invoice-details') || matchUrl('/subscription/invoice-list') || matchUrl('/subscription/resend-invoice')"
        (click)="clearFiltersFromLocal()">
       Invoices
    </div>
    </div>
    <div class="subscription_main_tabs">
    <div class="subscription_tabs" routerLinkActive ="active" routerLink="/subscription/setting" (click)="clearFiltersFromLocal()">
     Settings</div>
    </div>
</div>
  <select class="d-sm-none d-flex col-12 ml-3 subs-nav"  (change)="changeRoute($event)" [class.invoice-align-nav]="matchUrl('/subscription/invoice-list')" [class.setting-align-nav]="matchUrl('/subscription/setting') || matchUrl('/subscription/create-discount') || matchUrl('/subscription/discount-details') || matchUrl('/subscription/invoice-details') || matchUrl('/subscription/create-plan') || matchUrl('/subscription/cycle-details') || matchUrl('/subscription/resend-invoice')">
    <option value="/subscription/subscription-list" [attr.selected]="matchUrl('/subscription/subscription-list') || matchUrl('/subscription/subscription-details') || matchUrl('/subscription/create-subscription') || matchUrl('/subscription/update-subscription')">Subscriptions</option>
    <option value="/subscription/plan-list"  [attr.selected]="matchUrl('/subscription/cycle-details') || matchUrl('/subscription/plan-list') || matchUrl('/subscription/create-plan') || matchUrl('/subscription/update-plan')">Plans</option>
    <option value="/subscription/discount-list" [attr.selected]="matchUrl('/subscription/discount-details') || matchUrl('/subscription/discount-list') || this.matchUrl('/subscription/create-discount')">Discounts</option>
    <option value="/subscription/invoice-list" [attr.selected]="matchUrl('/subscription/invoice-details') || matchUrl('/subscription/invoice-list') || matchUrl('/subscription/resend-invoice')">Invoices</option>
    <option value="/subscription/setting" [attr.selected]="matchUrl('/subscription/setting')">Settings</option>
  </select>
</div><!-- / .container -->
<router-outlet></router-outlet>

