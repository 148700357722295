import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MerchantActivationModel} from '../../../data/model/MerchantActivationModel/merchant-activation.model';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../../shared/constants/app-constants';
import {CreateConnectAccountAction} from './create-connect-account.action';

@Component({
  selector: 'app-create-connect-account',
  templateUrl: './create-connect-account.component.html',
  styleUrls: ['./create-connect-account.component.css']
})
export class CreateConnectAccountComponent extends CreateConnectAccountAction implements OnInit {
  merchantActivation: MerchantActivationModel;
  accountNumberCheck: string;
  heading: string;
  maxDate = new Date();
  bsValue: Date = new Date();
  idPresent: boolean;
  countries = AppConstants.countryList;
  states = AppConstants.stateList;
  auth: string;
  constructor(dataManager: DataManagerService, route: ActivatedRoute, router: Router) {
    super();
    this.dataManager = dataManager;
    this.merchantActivation = new MerchantActivationModel();
    this.merchantActivation.country_code = this.countries[0].key;
    this.merchantActivation.state = null;
    this.route = route;
    this.auth = '/authorize';
    this.router = router;
    this.route.url.subscribe(value => console.log('urls', value[0].path));
    this._merchantActivationUrl = AppConstants.BASE_URL + this.auth + '/b' + AppConstants.SERVICE_NAME_ACCOUNT + '/' + this.id;
  }

  ngOnInit() {
    this.idPresent = this.fetchIdFromRouteQueryParams();
    this.heading = this.idPresent ? 'Edit Account' : 'Create Connect Account application';
  }

  activationPostCall() {
    const postBody = {};
    postBody['country_code'] = this.merchantActivation.country_code;
    postBody['business_type'] = this.merchantActivation.business_type;
    postBody['tax_id'] = this.merchantActivation.tax_id;
    postBody['address'] = this.merchantActivation.address;
    postBody['city'] = this.merchantActivation.city;
    postBody['state'] = this.merchantActivation.state;
    postBody['zip'] = this.merchantActivation.zip;
    postBody['first_name'] = this.merchantActivation.first_name.trim();
    postBody['last_name'] = this.merchantActivation.last_name.trim();
    postBody['dob_month'] = this.merchantActivation.dob.getMonth();
    postBody['dob_date'] = this.merchantActivation.dob.getDate();
    postBody['dob_year'] = this.merchantActivation.dob.getFullYear();
    postBody['ssnlast4'] = this.merchantActivation.ssnlast4;
    postBody['business_name'] = this.merchantActivation.business_name.trim();
    postBody['phone_number'] = this.merchantActivation.phone_number;
    postBody['routing_number'] = this.merchantActivation.routing_number;
    postBody['product_desc'] = this.merchantActivation.product_desc.trim();
    postBody['website_url'] = this.merchantActivation.website_url;
    postBody['email'] = this.merchantActivation.email;
    postBody['password'] = this.merchantActivation.password;
    // postBody['account_type'] = this.merchantActivation.account_type;
    postBody['account_type'] = null;
    if (this.merchantActivation.account_number === this.accountNumberCheck) {
      postBody['account_number'] = this.merchantActivation.account_number;
    } else {
      alert('Verification for Account number Failed');
      this.loadFlag = false;
      return;
    }
    postBody['phone_country_code'] = '1';
    postBody['phone_number'] = this.merchantActivation.phone_number;
    this.loadFlag = true;
    this.activateMerchant(postBody);
  }

  protected passResponse(res) {
    this.loadFlag = false;
  }

  protected passError(error) {
    this.loadFlag = false;
  }


}
