<div class="container-fluid b_shadow mt-5">
  <div class="row">
     <div class="col-sm-12 col-12 col-md-12 col-lg-12 header create-header bg-infos">
        Edit card
     </div>
  </div>
  <div class="col-sm-12 col-12 col-md-12 col-lg-12 pl-4 pr-4 mt-4">
     <ng-container *ngIf="topErrorMsg">
        <div class="row err" *ngFor="let obj of keys1 ">
           <div class="col-md-12 mb-1" *ngFor="let value of obj ">
              <p class="col-md-12 error" id="err">{{value}}</p>
           </div>
        </div>
     </ng-container>
     <div class="row" *ngIf="!topErrorMsg">
        <div class="col-md-12 mb-1 error"> {{errorMsg}}</div>
     </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-12 col-xl-12">
     <form #editCard="ngForm">
        <div class="form-group row">
           <label for="exp_month" class="col-5 col-form-label">Exp. month:</label>
           <span class="error_sign" id="exp_month1">!</span>
           <div class="col-7">
              <select class="form-control" id="exp_month" name="month" [(ngModel)]="updateCard.exp_month" #month="ngModel">
              <option #monthNotSelected *ngFor="let month of months" [value]="month.text">{{month.text}}</option>
              </select>
           </div>
        </div>
        <div class="form-group row">
           <label class="col-5 col-form-label">Exp. year:</label>
           <span class="error_sign" id="exp_year1">!</span>
           <div class="col-7">
              <select class="form-control" id="exp_year" name="year" [(ngModel)]="updateCard.exp_year" #year="ngModel">
              <option #yearNotSelected *ngFor="let year of years" [value]="year">{{year}}</option>
              </select>
           </div>
        </div>
        <div class="form-group row">
           <label class="col-5 col-form-label">Cardholder name:<i class="required-icon">*</i></label>
           <span class="error_sign" id="name1">!</span>
           <div class="col-7">
              <input class="form-control" type="text" id="card_holder_name" name="name" maxlength="200" required
              #name="ngModel" [class.invalid]="name.invalid && editCard.submitted"
              [(ngModel)]="updateCard.card_holder_name" value="">
              <span class="validation-err-msg" *ngIf="editCard.submitted && name.errors?.required">Cardholder name is
              required.</span>
              <span class="validation-err-msg" *ngIf="editCard.submitted && name.errors?.maxLength">Cardholder name limit
              exceed.</span>
           </div>
        </div>
        <div class="form-group row">
           <label for="street" class="col-5 col-form-label">Address line 1:<i class="required-icon"
              *ngIf="addresopt === 0">*</i></label>
           <div class="col-7" *ngIf="isApiLoaded">
              <input class="form-control" type="text" id="street" name="street" maxlength="200" [required]="addresopt === 0"
              #street="ngModel" [class.invalid]="street.invalid && editCard.submitted" [(ngModel)]="updateCard.address1"
              ngx-google-places-autocomplete
              [options]="options"   
              (onAddressChange)="handleAddressChange($event)"
             >
              <span class="validation-err-msg" *ngIf="editCard.submitted && street.errors?.required">Address line 1 is
              required.</span>
              <span class="validation-err-msg" *ngIf="editCard.submitted && street.errors?.maxLength">Address line 1 limit
              exceed.</span>
           </div>
        </div>
        <div class="form-group row">
           <label class="col-5 col-form-label">Address line 2:</label>
           <div class="col-7">
              <input class="form-control" type="text" id="street2" name="street2" maxlength="200" #street2="ngModel"
              [class.invalid]="street2.invalid && editCard.submitted" [(ngModel)]="updateCard.address2" value="">
              <span class="validation-err-msg" *ngIf="editCard.submitted && street2.errors?.maxLength">Address line 2 limit
              exceed.</span>
           </div>
        </div>
        <div class="form-group row">
           <label for="street" class="col-5 col-form-label">City:<i class="required-icon"
              *ngIf="addresopt === 0">*</i></label>
           <div class="col-7">
              <input class="form-control" type="text" id="city" name="city" maxlength="200" [required]="addresopt === 0"
              #city="ngModel" [class.invalid]="city.invalid && editCard.submitted" value="" [(ngModel)]="updateCard.city">
              <span class="validation-err-msg" *ngIf="editCard.submitted && city.errors?.required">City is required.</span>
           </div>
        </div>
        <div class="form-group row">
           <label for="street" class="col-5 col-form-label">Zip code:<i class="required-icon"
              *ngIf="addresopt === 0">*</i></label>
           <span class="error_sign" id="zip1">!</span>
           <div class="col-7">
              <input class="form-control" type="text" id="zip" name="zip" maxlength="10" [required]="addresopt === 0"
              #zip="ngModel" value="" [class.invalid]="zip.invalid && editCard.submitted" [(ngModel)]="updateCard.zip"
              (keypress)="numericOnly($event)">
              <span class="validation-err-msg" *ngIf="editCard.submitted && zip.errors?.maxLength">Zip code limit
              exceed.</span>
              <span class="validation-err-msg" *ngIf="editCard.submitted && zip.errors?.required">Zip code is
              required.</span>
              <span class="validation-err-msg" *ngIf="editCard.submitted && zip.invalid && !zip.errors?.required">Zip format
              is invalid.</span>
           </div>
        </div>
        <div class="form-group row">
           <label for="street" class="col-5 col-form-label">State/Province:<i class="required-icon"
              *ngIf="addresopt === 0">*</i></label>
           <div class="col-7">
              <select class="form-control" [required]="addresopt === 0" id="state" name="state" #state="ngModel"
              [class.invalid]="state.invalid && editCard.submitted" [(ngModel)]="updateCard.state">
              <option [value]="null" disabled selected>Choose a state</option>
              <option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
              </select>
              <span class="validation-err-msg" *ngIf="editCard.submitted && state.errors?.required">State/Province is
              required.</span>
           </div>
        </div>
        <div class="form-group row">
           <label for="street" class="col-5 col-form-label">Country:<i class="required-icon"
              *ngIf="addresopt === 0">*</i></label>
           <div class="col-7">
              <select class="form-control" id="country" name="country" #country="ngModel"
              [class.invalid]="country.invalid && editCard.submitted" [(ngModel)]="updateCard.country"
              [required]="addresopt === 0" type="text">
              <option [value]="null">Choose a country</option>
              <option *ngFor="let country of countries" [value]="country.key">{{country.name}}
              </option>
              </select>
              <span class="validation-err-msg" *ngIf="editCard.submitted && country.errors?.required">Country is
              required.</span>
           </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12 pt-3 pb-3 ">
           <div class="row d_footer justify-content-end">
              <button mat-button (click)="cancelBtn()" class="cancel_btn">Cancel</button>
              <button mat-button class="update-btn" type="submit" (click)="editCard.invalid ? '' : updateCardFunc()">Update
              card <i *ngIf="loadFlag" class="fa fa-spinner fa-pulse loader"></i></button>
           </div>
        </div>
     </form>
  </div>
</div>