<div  id="profile_dialog">
  <div class="">
    <div class="main_profile">
      <div class="modal-header profile_header">
        <ng-container>
          <div class="row">
            <div class="col-12">
              <h3 class="profile_name pb-2" *ngIf="userName">

                {{userName | camalizeStr }}

              </h3>
            </div>
            <div class="col-12 pl-1">
              <h3 class="profile_email">

                {{userEmail}}

              </h3>
            </div>
          </div>


        </ng-container>

      </div>

      <!-- Modal body -->
      <div class="p-0">
        <div class="dropdown-footer">
          <div class="row">
            <div class="col-sm-4 col-6 pl-xl-4 pl-lg-4">
              <button class="btn btn-default lg-btn profile" (click)="myprofile()">Profile</button>
            </div>
            <div class="col-sm-4 col-6">
              <button class="btn btn-default lg-btn profile" (click)=" toUpdateContact()">Contact</button>
            </div>
            <div class="col-sm-4 col-12 mob_logout mt-2">
              <button class="btn btn-default lg-btn p_signout p-0" (click)="signOut()">
                <img src="../../../assets/images/sign_out_icon.png" class="siginout_icon pr-2" alt="sign out icon">
                Signout</button>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
