<div class="container-fluid cus_block">
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-xs-12 padding-m">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <h3 class="headings">Customers</h3>
        </div>
      </div>
    </div>
    <div class="text-right col-xl-6 col-lg-12 col-md-12 col-xs-12 new_cus mt-2 pt-1" *ngIf="!matchUrl('create-customer') && !matchUrl('customer-details') && !matchUrl('update-customer') && !matchUrl('add-card') && !matchUrl('edit-card') && !matchUrl('create-subscription') && !matchUrl('capture-payment')" >
      <ul class="list-inline">
        <li  class="list-inline-item"> <span  (click)="addNew(); clearFiltersFromLocal()"  class="mr-2 add-button"><i class="fa fa-plus"></i>
            New</span></li>
        <li  class="list-inline-item cust-filter">
          <app-filter class="" [filter_storage_key]="filter_storage_key" [count_storage_key]="count_storage_key"
            [filter]="filter"></app-filter>
        </li>
      </ul>
    </div>
  </div>
 <router-outlet (activate)="onActivate()"></router-outlet>
</div>
