import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class GetCustomerDetailsAction extends BaseComponent {
  protected _getCustomerDetailsUrl: string;
  protected _deleteCustomerUrl: string;
  protected _getCustomerSubscriptionDetailsUrl: string;
  protected _getCustomerInvoicesDetailsUrl: string;
  protected _changeDefaultCardUrl: string;

  classRedList = [
    'failed_by_gateway',
    'Descriptor Issue',
    'Transaction Amount Invalid',
    'Inactive terminal: Contact Payarc',
    'Invalid Service: Contact Payarc',
    'Invalid Date',
    'Declined',
    'Insufficient Funds',
    'Bad Swipe',
    'Insufficient Funds',
    'Activity Limit Exceeded',
    'Invalid Card Number',
    'Bad Request - Try Again',
    'Host Validation Error: Contact Payarc',
    'Invalid From Account: Contact Payarc',
    'Declined',
    'Pin Attempts Exceeded',
    'Bad Request - Try Again',
    'Expired Card',
    'Invalid Amount',
    'Incorrect Pin',
    'No Such Issuer',
    'Re-enter Transaction'
  ];

  getCustomerDetails() {
    this.dataManager
      .getRequest(this._getCustomerDetailsUrl, 0)
      .subscribe(
        (response) => {
          this.passCustomerDetails(response);
          if (response.data.length === 0) {
            this.errorMsg = ErrorConstants.customerDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        (error) => {
          console.log('error on get cus');
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.customerDetailErrorMsg);
          this.passError(error);
        }
      );
  }
  getCustomerSubscriptionDetails() {
    this.dataManager
      .getRequest(this._getCustomerSubscriptionDetailsUrl, 0)
      .subscribe(
        (response) => this.passCustomerSubscriptionDetails(response),
        error => {
          console.log('error on cus subs');
          this.networkErrorHandler(error.error);
          this.passCustomerSubscriptionDetailsError(error);
        }
      );
  }

  protected deleteCustomerFromApi() {
    this.dataManager.appLoaderFlag(true);
    this.dataManager.deleteRequest(this._deleteCustomerUrl)
      .subscribe(
        (res) => {
          // console.log(res);
          this.passDeleteConfirmation(res);
        },
        error => {
          console.log('error on delete cus');
          this.networkErrorHandler(error.error);
          this.passCustomerDeleteError(error);
        }
      );
  }

  protected deleteCustomerCard(url) {
    this.dataManager.deleteRequest(url)
      .subscribe(
        res => this.passDeletedCardConfirmation(res),
        error => {
          console.log('error on delete cus');
          this.networkErrorHandler(error.error);
          this.passCustomerCardDeleteError(error);
        }
      );
  }

  deleteBankAccountFromApi(url) {
    this.dataManager.deleteRequest(url)
      .subscribe(
        res => this.passDeletedBankAccountConfirmation(res),
        error => {
          console.log('error on delete bank account');
          this.networkErrorHandler(error);
          this.passCustomerCardDeleteError(error);
        }
      );
  }

  postDefaultBankAccount(url, body) {
    console.log('postDefaultBankAccount',url, body);
  this.dataManager.patchRequest(url, body)
      .subscribe(
        response => this.passDefaultBankAccountResponse(response),
        error => {
          this.passCustomerBankAccountPostError(error);
          this.networkErrorHandler(error);
          this.passError(error);
        }
      );

  }

  getCustomerInvoicesDetails(url) {
    this.dataManager
      .getRequest(url, 0)
      .subscribe(
        (response) => this.passCustomerInvoicesDetails(response),
    error => {
      console.log('error on invoice details');
      this.networkErrorHandler(error.error);
          this.passCustomerInvoicesDetailsError(error);
        }
      );
  }
  changeDefaultCards(url, body) {
    this.dataManager.patchRequest(url, body)
      .subscribe(
        response => this.passDefaultcardResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passError(error);
        }
      );
  }


  protected abstract passCustomerDetails(customer);
  protected abstract passDefaultcardResponse(response);
  protected abstract passError(error);
  protected abstract passDeleteConfirmation(response);
  protected abstract passCustomerDeleteError(error);
  protected abstract passDeletedCardConfirmation(response);
  protected abstract passCustomerCardDeleteError(error);
  protected abstract passCustomerSubscriptionDetails(response);
  protected abstract passCustomerSubscriptionDetailsError(error);
  protected abstract passCustomerInvoicesDetails(response);
  protected abstract passCustomerInvoicesDetailsError(error);
  protected abstract passDeletedBankAccountConfirmation(res);
  protected abstract passCustomerBankAccountPostError(response);
  protected abstract passDefaultBankAccountResponse(response);
}
