import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";
@Directive()
export abstract class KountAction extends BaseComponent {

  protected _getSettingUrl: string;
  protected _setSettingUrl: string;


  protected getSetting() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getSettingUrl, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passGetSettingResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.passGetSettingError(err);
        }
      );
  }

  protected abstract passGetSettingResponse(res);

  protected abstract passGetSettingError(err);

  protected setSetting(postBody) {
    this.loadFlag = true;
    this.dataManager.postRequest(this._setSettingUrl, postBody, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passSetSettingResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.passSetSettingError(err);
        }
      );
  }

  protected abstract passSetSettingResponse(res);

  protected abstract passSetSettingError(err);

}
