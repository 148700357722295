<div class="container-fluid no-padding">
    <div class="row no-margin left b_bottom">
        <div class="col-sm-12 col-md-9 no-padding">
            <span>Bridge</span>
        </div>
        <!-- / .col-sm-12 .col-md-6-->
        <div class="col-sm-12 col-md-3  right " id="new">
            <span class="add_account" style="cursor: pointer;" (click)="createAccount()"><i class="fa fa-plus"></i> New</span>
        </div>
    </div>
    <!-- / .row -->
    <!-- </div> -->
    <!-- / .row -->

</div>
<div class="accnt_search">
    <input class="form-control input-sm" type="search" placeholder="search" (input)="searchHits.next()" [(ngModel)]="searchText" />
</div>
<app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
<table *ngIf="!errorMsg" class="table table-striped">
    <thead>
        <tr>
            <th class="event"><a (click)="setSorting('id')" [class]="orderBy === 'id' ? sortBy : ''">Account</a></th>
            <th class="id"><a (click)="setSorting('status')" [class]="orderBy === 'status' ? sortBy : ''">Status</a></th>
            <th class="date"><a (click)="setSorting('account_type')" [class]="orderBy === 'account_type' ? sortBy : ''">Type</a></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="loadFlag">
            <tr class="loader">
                <td colspan="7">
                    <div class="loader-container">

                        <img class="table-loader" src="../../../../assets/images/loading.svg">
                    </div>
                </td>
            </tr>
        </ng-container>

        <ng-container *ngIf="!loadFlag && accounts.data.length > 0">
            <tr *ngFor="let Account of accounts.data">
                <td class="account">
                    <a [routerLink]="redirect_link" [queryParams]="{id: Account.id}">
          {{Account.id}}
        </a>
                </td>
                <td class="status">
                    <a [routerLink]="redirect_link" [queryParams]="{id: Account.id}">
          {{Account.status}}
        </a>
                </td>
                <td class="type">
                    <a [routerLink]="redirect_link" [queryParams]="{id: Account.id}">
          {{Account.account_type ? Account.account_type : "NA"}}
        </a>
                </td>
            </tr>
        </ng-container>
        <!--pagination start-->
        <!--pagination end-->
    </tbody>
    <tfoot>
        <tr class="bottom-row">
            <!--<td colspan="2" class="bottom-row-left" *ngIf="accounts.meta.pagination.total">around-->
            <!--{{accounts.meta.pagination.total}} results-->
            <!--</td>-->
            <td colspan="2" class="bottom-row-left">around 6 results
            </td>
            <td colspan="2" class="bottom-row-right">
                <button type="button" class="btn btn-default" [disabled]="!disablePrev" (click)="paginate(accounts.meta.pagination.links.previous)">Previous
      </button>
                <button type="button" class="btn btn-default" [disabled]="!disableNext" (click)="paginate(accounts.meta.pagination.links.next)">Next
      </button>
            </td>
        </tr>
    </tfoot>

</table>
