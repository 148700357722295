

import { Component, OnDestroy,Inject, OnInit,PLATFORM_ID, Input } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {FilterConstants} from '../../../shared/constants/filter-constants';
import {ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';

import {DatePipe} from '@angular/common';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Highlight} from '../../../shared/highlights/highlight';
import {ExportModel} from '../../../data/model/ExportModel/export-model';
import {ExportConstants} from '../../../shared/constants/export-constants';
import {debounceTime} from 'rxjs/operators';
import {Subscription, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';

import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-split-funding-details',
  templateUrl: './split-funding-details.component.html',
  styleUrls: ['./split-funding-details.component.css']
})
export class  SplitFundingDetailsComponent extends BaseComponent
 implements OnInit, OnDestroy {

  // @Input() exampleData: any;
  displayedColumns = ['id', 'transactiondate', 'type', 'status', 'customer', 'paymentinformation', 'amount'];
  listCallFlag: boolean;
  exportData: Object;
  from_date: any;
  to_date: any;
  n = 0;
  filters: string;
  private sortDirection: string;
  today: Date = new Date();
  public searchHits: Subject<null>;
  _router: Router;
  public searchText: string;
  showMeta = false;
  chargeList: ResponseWrapper<any>;
  metadata:any;
  exampleData = [
    {name: 'Baboon Mohon',
    accountNumber: '123456789',
    routingNumber: '123456789',
    amount: '1000',
    amountPercent: '50',
    type: 'Bank Account',
 status: 'Inactive',
 creationDate: '12/12/2019',
 payees: '2',
 requestDate: '12/12/2019',
 requestStatus: 'Active',
 updateDate: '12/12/2019',
 actionItems: '200',
  campaign: 'Campaign 1',
  id: '1234567890',
  assignedTo: 'Miranda',
  description: ' Chicago,IL  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
},{
  name: 'Raboon Mohon',
      accountNumber: '123456789',
      routingNumber: '123456789',
      amount: '5000',
      amountPercent: '50',
      type: 'Bank Account',
   status: 'Inactive',
   creationDate: '12/12/2019',
   payees: '2',
   requestDate: '12/12/2019',
   requestStatus: 'Active',
   updateDate: '12/12/2019',
   actionItems: '200',
    campaign: 'Campaign 3',
    id: '1234567894',
    assignedTo: 'Miranda',
    description: ' Chicago,IL  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
  },{
    name: 'Jhon Mohon',
    accountNumber: '123456789',
    routingNumber: '123456789',
    amount: '1000',
    amountPercent: '50',
    type: 'Bank Account',
    status: 'Active',
    creationDate: '12/12/2019',
    payees: '2',
    requestDate: '12/12/2019',
    requestStatus: 'Active',
    updateDate: '12/12/2019',
    actionItems: 'one',
    assignedTo: 'Humbolt',
     campaign: 'Campaign 2',
     id: '1234567892',
     description: ' New York , Manhattan  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
  }
  ]

  individualData = [];


  constructor(private _route: ActivatedRoute, router: Router,  private datePipe: DatePipe,    private dialog: MatDialog, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any, snackBar: MatSnackBar) {
    super();
    this.showMeta = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)) ?
    JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)).display_transactional_metadata : false;
    this.errorMsg = null;
    this.searchHits = new Subject();
    this._router = router;
    this.router = router;
    this.dataManager = dataManager;
    this.chargeList = new ResponseWrapper<any>();
    this.loadFlag =false;
    this.redirect_link = '/merchant-bridge/split-funding-summary';

   }

  ngOnInit(): void {
    this.searchHits.pipe(debounceTime(600), ).subscribe((res) => {
      if (this.searchText) {
        // this.setUrl();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_STORAGE_KEY)
        && !this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_COUNT_STORAGE_KEY)) {
        // this.setUrl();
      } else  {
        // this.setUrl();
      }
    }
    this._route.paramMap.subscribe(params=>{
      const objectId= params.get('id');
      this.individualData = this.exampleData.filter((item)=>item.id === objectId)    
    
    })

  }

  navigateToDetails(id: any) {
    this.router.navigate(['/merchant-bridge/split-funding-summary',id]);
  }

  createNewPayee() {
   console.log('createNewPayee');
  }

  protected getListRes(res) {
    this.loadFlag = false;
    if (this.showMeta) {
      this.formatMetadata(res);
    }
    console.log(res);
    this.chargeList = res;
    this.metadata = res.meta.pagination;
    if (res.data.length === 0) {
      this.errorMsg = ErrorConstants.paymentErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
  }

  protected formatMetadata(res: any): void {
    // res.data.forEach(
    //   charge => {
    //     this._paymentComponent.metadata_keys.forEach(
    //       meta_type => {
    //         const index = charge.transaction_metadata.data.findIndex(o => o.key === meta_type.key);
    //         if (index >= 0) {
    //           charge['meta_' + meta_type.key] = charge.transaction_metadata.data[index].value;
    //         }
    //       }
    //     );
    //   }
    // );
  }
  checkdate() {
    if (!this.isNull(this.from_date) && this.from_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  checkdate2() {

    if (!this.isNull(this.to_date) && this.to_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  highlight(value) {
    return Highlight.highlight(value, this.searchText);
  }

  datereport() {
    if ((this.from_date && this.from_date.toString() === 'Invalid Date') || (this.to_date && this.to_date.toString() === 'Invalid Date')) {
      this.showSnackBar('Enter valid date.', 'Ok', 3000);
    } else if ((this.isNullOrUndefined(this.from_date)) || this.isNullOrUndefined(this.to_date)) {
      if ((!this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))
        || (this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date)))) {
        this.from = this.from_date;
        this.to = this.to_date;
        // this.setUrl();
      } else {
        return;
      }
    } else if (this.from_date > this.to_date) {
      this.showSnackBar('From date should not exceed ' + this.datePipe.transform(this.to_date, 'MM/dd/yyyy') + '.', 'Ok', 3000);
    } else {
      this.from = (this.isNullOrUndefined(this.from_date) ? '' : (this.datePipe.transform(this.from_date, 'yyyy-MM-dd')));
      this.to = (this.isNullOrUndefined(this.to_date) ? '' : (this.datePipe.transform(this.to_date, 'yyyy-MM-dd')));
      // this.setUrl();
    }
  }

}
