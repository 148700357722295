import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { Component, DoCheck, Inject, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../abstract/base.component';
import { FilterModel } from '../../data/model/filteModel/filter-model';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { FilterConstants } from '../../shared/constants/filter-constants';
import { PermissionConstants } from '../../shared/constants/permission-constants';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent extends BaseComponent implements DoCheck, OnChanges, OnInit {
  filter: FilterModel[] = FilterConstants.customerListFilter;
  back_press: boolean;
  enable_test_ui: boolean;


  constructor(_router: Router, dataManager: DataManagerService,
    locationPlatform: PlatformLocation, @Inject(PLATFORM_ID) private platformId: any, route: ActivatedRoute,
    @Inject('LOCALSTORAGE') private localStorage: any, private localeService: BsLocaleService) {
    super();
    this.filter_storage_key = FilterConstants.CUSTOMER_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.CUSTOMER_FILTER_COUNT_STORAGE_KEY;
    this.route = route
    this.dataManager = dataManager;
    this.router = _router;
    this.create_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_CUSTOMER);
    locationPlatform.onPopState(() => {
      this.back_press = true;
    });
  }
  ngOnInit() {
    this.localeService.use('en-gb');
  }
  ngDoCheck() {
    if (location.pathname === '/customer') {
      this.routeNavigate('/customer/customer-list');
    }
  }
  clearFiltersFromLocal() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.CUSTOMER_DATA)
    }
  }
  addNew() {
    this.router.navigate(['create-customer'], { relativeTo: this.route });
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  onActivate() {
    document.getElementsByClassName('page-container')[0].scrollTop = 0;
  }
}
