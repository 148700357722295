import {BaseComponent} from '../../abstract/base.component';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import { Directive } from "@angular/core";

@Directive()
export abstract class CreatNewAccountAction extends BaseComponent {
  protected createAccountUrl: string;
  protected _getAccountInfoUrl: string;
  protected _getaccount: string;
  postAccount(url, postBody) {
    this.dataManager.postRequest(url, postBody, 0)
      .subscribe(
        response => this.passResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passError(error.error);
        }
      );
  }

  protected abstract passResponse(response);

  protected abstract passError(error);

  protected abstract passAccountInfoResponse(response);
  protected abstract passAccountInfoError(error);

  getAccountInfo() {
    this.dataManager.getRequest(this._getAccountInfoUrl, 0)
      .subscribe(
        (res) => this.passAccountInfoResponse(res),
        (err) => {
          this.networkErrorHandler(err.error);
          this.passAccountInfoError(err.error);
        }
      );
  }
  getSwitchAccount(postBody) {
    this.dataManager.postRequest(this._getaccount, postBody, 0)
      .subscribe(
        res => this.passAccounts(res),
        error => {
          this.networkErrorHandler(error.error);
          this.passAcountError(error.error);
        }
      );
  }
  protected abstract passAccounts(res);
  protected abstract passAcountError(error);

}
