import {Injectable} from '@angular/core';
import {CreditCard} from 'angular-cc-library';

@Injectable()
export class CreditService {
  type: any;

  constructor() {
  }

  creditnumber(event) {
    this.type = CreditCard.cardType(event.target.value);
    const input = event.target as HTMLInputElement;
    const trimmed = input.value.replace(/\s+/g, '');
    const numbers = [];
    if (this.type === 'amex') {
      let cardNumber;
      cardNumber = (trimmed.slice(0, 4).replace(/(.{4})/g, '$1 ') +
        trimmed.slice(4, 10).replace(/(.{6})/g, '$1 ') +
        trimmed.slice(10, 15)).trim();
      numbers.push(cardNumber);
    } else if (this.type === 'mastercard') {
      for (let i = 0; i < trimmed.length; i += 4) {
        if(i < 16) {
        numbers.push(trimmed.substr(i, 4));
        }
      }
    } else {
      for (let i = 0; i < trimmed.length; i += 4) {
        numbers.push(trimmed.substr(i, 4));
      }
    }
    input.value = numbers.join(' ');
  }
}
