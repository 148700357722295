import { Component, OnInit ,PLATFORM_ID,Inject} from '@angular/core';
import {Pagination, ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';
import { FormBuilder, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { EditCampaignAction } from './edit-campaign.action';
import { isPlatformBrowser } from '@angular/common';
import { AppConstants } from '../../../shared/constants/app-constants';
import { ErrorConstants } from '../../../shared/constants/error-constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {MessageDialogComponent} from '../../../dialogs/message-dialog/message-dialog.component';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.css']
})
export class EditCampaignComponent extends EditCampaignAction implements OnInit {

  loadFlag = false;
  chargeList: ResponseWrapper<any>;
  editCampaign: FormGroup;
  buttonText: string='Update Campaign';
  load: boolean;
  failureMessage: string;
  _service_name = '/campaigns';
  _service_name_campaign = '/campaigns';
  topErrorMessage: string;
  keys1: String[];
  keys: String[];
  flagButton: boolean;
  campaignApiPostUrl: string;
  redirect_link_edit: string;
  campaignId: string;
  created_at: string; 
  updated_at: string;
  readable_updated_at: string;
  actionActive: boolean;
  payees:{}[];
  listOfPayees: any;
  payeeId: string;
  pageName: string;



  constructor( 
    router:Router, 
    route:ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private fb:FormBuilder,
    dataManager: DataManagerService, 
    snackBar: MatSnackBar,
    private dialog: MatDialog
    ) {
    super();
    this.limit = 10;
    this.getListUrl = AppConstants.BASE_URL + this._service_name;
    this.editCampaignPostUrl = AppConstants.BASE_URL + this._service_name_campaign;
    this.router = router;
    this.route = route;
    this.dataManager = dataManager;
    this.snackBar = snackBar;
    this.topErrorMessage = null;
    this.actionActive=false;
    this.redirect_link_edit = '/merchant-bridge/edit-payee';
    
   }

   
   ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.campaignId = params['id'];
        this.editCampaignPostUrl = AppConstants.BASE_URL + this._service_name_campaign;
        this.editCampaignPostUrl = this.editCampaignPostUrl + '/' + this.campaignId;
      }
      if (params['page']) {
        this.pageName = params['page'];
      }
    });
      this.route.paramMap.subscribe( paramMap => {
        this.campaignId = paramMap.get('id');
        // this.editCampaignPostUrl = this.editCampaignPostUrl;
    })
    if (isPlatformBrowser(this.platformId)) {
      console.log('deyan below is from ngOnInit');
      this.getEditCampaignData(this.getListUrl + '/' +this.campaignId +'?include=payees' + '&orderBy=created_at&sortedBy=desc');
    }
    this.editCampaign = this.fb.group({
      name: ['',[<any>Validators.required,Validators.maxLength(50),Validators.minLength(5)]],
      description: ['', [<any>Validators.required,Validators.minLength(5)]],
      notes: [''],
      status: [''],
      created_at:[''],
      updated_at:['']
    });

  }

  submitCampaignEdited() {
    let bodyPost=this.editCampaign.value;
    this.editCampaignPost(bodyPost);
//put action where to navigate to

  }

  passEditResponse(response) {
    this.topErrorMessage = response.errors;
    if (response.code === 201 || 200) {
      
    }
    if (this.topErrorMessage ) {
      this.keys = Object.keys(this.topErrorMessage);
      this.keys1 = Object.values(this.topErrorMessage);
      let i;
      let j;
      const name = ['name', 'description', 'status'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + '1').style.display = 'block';
          }
        }
      }
    } else {
      this.errorMsg = response.message;
    }
    this.showSnackBar('Updating Campaign', 'OK', 2500);
    
    this.getEditCampaignData(this.getListUrl + '/' +this.campaignId);
    
    setTimeout(() => {
      this.showSnackBar('You can press Go Back button to List', 'OK', 2000);
      
      // this.router.navigate(['../campaign-summary'], {relativeTo: this.route});
    },2500)
  }
  deleteCampaign(id: string) {
    if (id === undefined || id === null || id === ''){
      id = this.campaignId;
    }
    
    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Campaign', message: 'This will permanently remove the campaign. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        console.log('deyan',id);
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CAMPAIGNS+'/'+id;
        console.log('deyan',url);
        
        this.deleteCampaignApi(url);
        this.loadFlag = true;
        this.router.navigate(['../campaign-summary'], {relativeTo: this.route});
        this.showSnackBar('Campaign Deleted.', 'Ok', 3000);

      }
    });

  }

  deletePayee(id: string) {
    if (id === undefined || id === null || id === ''){
      this.payeeId =id ;
    }
    
    if (this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Payee', message: 'This will permanently remove the payee. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        console.log('deyan',id);
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PAYEES+'/'+id;
        console.log('deyan',url);
        
        this.deletePayeeApi(url);
        this.loadFlag = true;
        this.router.navigate(['merchant-bridge/edit-campaign'+'/'+this.campaignId]);
       

      }
    });

  }
 
  protected passDeleteResponse(response: any) {
    console.log('deyan',response);
    window.location.reload();
    this.showSnackBar('Payee Deleted.', 'Ok', 3000);
  }
  passGetResponse(response) {
    this.topErrorMessage = response.errors;
    if (this.topErrorMessage) {
      this.keys = Object.keys(this.topErrorMessage);
      this.keys1 = Object.values(this.topErrorMessage);
      let i;
      let j;
      const name = ['name', 'description', 'status'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + '1').style.display = 'block';
          }
        }
      }
    } else {
      this.errorMsg = response.message;
    }
      this.editCampaign.patchValue({
        name:response.data.name,
        description:response.data.description,
        notes:response.data.notes,
        status:response.data.status,
      })
      this.created_at = response.data.created_at;
      this.updated_at = response.data.updated_at;
      this.readable_updated_at = response.data.readable_updated_at;
      this.payees = response.data.payees.data;
      this.listOfPayees = response.data.payees.data;
  }
  cancelCampaignEdited() {
    this.backToPage();
  }

  addPayee() {
    this.router.navigate(['merchant-bridge/add-new-payee'],{queryParams:{id:this.campaignId}});
  } 

  backToPage() {
   //dont know where to navigate to
   if (this.pageName===undefined) {
    this.router.navigate(['../../campaign-summary'], {relativeTo: this.route});
   } else {
      this.router.navigate(['merchant-bridge/'+this.pageName] );
   }
  }



  protected passError(error){
    this.topErrorMessage = error.code;
    if (this.topErrorMessage) {
      this.keys = Object.keys(this.topErrorMessage);
      this.keys1 = Object.values(this.topErrorMessage);
      let i;
      let j;
      const name = ['name', 'account_id', 'agent_id', 'status'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + '1').style.display = 'block';
          }
        }
      }
    } else {
      this.errorMsg = error.message;
    }
    this.showSnackBar('Not Updated', 'OK', 3000);
  };

  viewPayee(id: string) {
    let payee = this.listOfPayees.find((payee) => payee.id === id);
    console.log('deyan',payee, id)
    this.router.navigate(['../merchant-bridge/payee-details'],{queryParams:{id:id,campaign:this.campaignId}, state:{payee:payee}})

  }

  protected passGetPayeesResponse(response: any) {
    
  }
  print(obj) {
    console.log(obj); 
  }


}
