import {BaseModel} from '../BaseModel/base.model';
import {CustomDateModel} from '../CustomDate/custom-date.model';
/**
 * Created by arun on 30/6/18.
 */
export class UserAccountModel extends BaseModel {
  object?:  string;
  id?:  string;
  account_name?: string;
  email?: string;
  status?:  string;
  account_type?:  string;
  country_code?:  string;
 product_desc?: string;
 tax_id?:  number;
 address?: string;
 city?: string;
 state?: string;
 zip?: number;
 first_name?:  string;
 last_name?:  string;
 dob_month?:  number;
 dob_date?:  number;
 dob_year?:  number;
 ssn_last4?:  number;
 business_name?: string;
 phone_number?: string;
 created_at?: CustomDateModel = new CustomDateModel();
 updated_at?:  CustomDateModel = new CustomDateModel();
}
