import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BankAccountAchModel } from '../../../data/model/BankAchModel/bankaccountAch.model';
import { CustomerV2Model } from '../../../data/model/CustomerV2Model/customer-v2.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateBankAccountAction } from './update-bank-account.action';
import { AppConstants } from '../../../shared/constants/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-bank-account',
  templateUrl: './edit-bank-account.component.html',
  styleUrls: ['./edit-bank-account.component.css']
})
export class EditBankAccountComponent extends UpdateBankAccountAction implements OnInit {
  topErrorMessage: string;
  errorMsgFlag: boolean;
  bankAccount:BankAccountAchModel;
  keys: String[];
  keys1: String[];
  errorMsg: string;
  bankAccountId: string;  
  bankAccountCustId: string;  
  customer: CustomerV2Model;
  editBankAccountForm:FormGroup; 
  categories: string[] = ['Personal Checking', 'Personal Savings', 'Business Checking', 'Business Savings' ];

  constructor(dataManager:DataManagerService,private fb:FormBuilder, route:ActivatedRoute,router:Router,snackBar:MatSnackBar ,private location: Location) {
    super();
     this._editBankAccountToCustomerUrl=AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACH_DETAILS;
     this.errorMsgFlag = false;
     this.dataManager = dataManager;
    this.bankAccount = new BankAccountAchModel();
    this.router =router;
    this.route =route;
    this.snackBar =snackBar;
    this.bankAccountId = this.route.snapshot.queryParams.id;
    this.bankAccountCustId = this.route.snapshot.queryParams.cust_id;

  }
  
  
  ngOnInit(): void {
    
    this.loadBankAccountInfo(this.bankAccountId);
    this.editBankAccountForm=this.fb.group({
      account_number: [{value:'',disabled:true}, [ <any>Validators.maxLength(17)]],
      routing_number: [{value:'',disabled:true}, [ <any>Validators.maxLength(9),Validators.pattern(/^[0-9]+$/)]],
      first_name: ['', [<any>Validators.required, <any>Validators.maxLength(50)]],
      last_name: ['',[<any>Validators.required,<any>Validators.maxLength(50)]],
      account_type: ['', [<any>Validators.required]],
    })
     
  }



  protected  passResponseAccountInfo(response) {
      this.bankAccount = response.data;
      this.editBankAccountForm.patchValue(this.bankAccount);
      this.loadFlag = false;
  };
  protected  passSourceResponse(res) {

  };
  protected  passSourceError(error) {

  };
  protected  passResponse(response) {
    this.goBackToDetails();
    this.showSnackBar('Customer editd successfully.', 'Ok', 3000);
    
  };
 
  protected  passError(error) {
    this.errorMsg = error.message;
    this.topErrorMessage = error.error;
    this.keys = Object.keys(this.topErrorMessage);
    this.errorMsgFlag = true;
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    let i;
    let j;
    const name = ['card_holder_name', 'card_number', 'address_line1', 'address_line2', 'zip', 'exp_month',
      'exp_year', 'customer_id', 'card_source'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  };

  editBankAccount(){
    this._editBankAccountToCustomerUrl=this._editBankAccountToCustomerUrl + '/' + this.bankAccountId;
    this.editBankAccountOnApi(this.editBankAccountForm.value);
  }

  print(obj) {
    console.log(obj);
  }
  cancelBankAccount() {
    this.goBackToDetails();
  }
  goBackToDetails() {
    // this.location.historyGo(-1);
    this.router.navigate(['../customer-details'], {queryParams: {id: this.bankAccountCustId},relativeTo : this.route});
  }
  
  loadBankAccountInfo(accInfo) {
    this.getAddress(this._editBankAccountToCustomerUrl + '/' + accInfo);
    
  }
}
