export class CouponModel {
  id?: string;
  amount_off?: number;
  percent_off?: number;
  duration?: string;
  discount_id?: string;
  duration_in_months?: number;
  max_redemptions?: number;
  redeem_by?: Date;
  times_redeemed?: number;
  status?: string;
  created_at?: Date;
  updated_at?: Date;
  // added feild as per ui design
  code?: string;
  name?: string;
  currency: string;
}
