<div class="dialog-head">Create new account</div>
<div class="container">
  <!--<div class="col-md-7 error" >
  </div>-->
  <ng-container *ngIf="topErrorMessage">
    <!--<div class="row" *ngFor="let obj of keys ">-->
    <!--<p class="col-md-12 error" id="err" >{{topErrorMessage[obj]}}</p>-->
    <!--</div>-->
    <div class="row err" *ngFor="let obj of keys1 " >
      <div class="col-md-12 mb-1" *ngFor="let value of obj " >
        <p style="font-size: 10px; margin-top: -14px" class="col-md-12 error" id="err" >{{value}}</p>
      </div>
    </div>
  </ng-container>
  <div class="row err" *ngIf="!topErrorMessage && errMsg">
    <p class="col-md-12 error" id="err1">{{errMsg}}</p>
  </div>
  <mat-dialog-content class="dialog-content">
    <form #createAccountDialog="ngForm">
      <div class="form-group row">
        <label class="col-5 col-form-label accountname">Account name:</label>
        <span class="error_sign" id="name1">!</span>
        <div class="col-7">
          <input type="text" class="form-control accountinput" id="name" [(ngModel)]="account_name" name="name" #name="ngModel" required>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">

          <app-loading-button (buttonClick)="createAccount()" [disable]="createAccountDialog.invalid" [loadFlag]="loadFlag"
                               [buttonText]="'Create account'" ></app-loading-button>

          <button mat-button [mat-dialog-close]="false" class="button cancel_btn">Cancel</button>
        </div>
      </div>

    </form>
  </mat-dialog-content>
</div>
