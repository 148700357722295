<div class="container-fluid no-padding" *ngIf="!loadFlag">
  <div class="row no-margin">
    <ng-container *ngIf="topErrorMessage && !loadFlag">
      <div class="row err" *ngFor="let obj of keys1 " >
        <div class="col-md-12" *ngFor="let value of obj " >
          <p class="col-md-12 error" id="err" >{{value}}</p>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="!topErrorMessage && !loadFlag && errorMsg">
      <div class="col-md-12 error mb-1"> {{errorMsg}}</div>
    </div>
    <div class="col-sm-12 top" >
      {{heading}}
      <p *ngIf="!idPresent">We need to learn more about you and your business before you can process payments on PayArc.</p>
    </div>
  </div>
</div>
<div class="containerx" *ngIf="!loadFlag">
  <mat-card class="card">
    <form #account='ngForm'>
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <p class="head-text">Where are you based?</p>
          <div class="form-group">
            <label for="country" class="form-control-label"> Country</label>
            <select name="country" id="country" #country="ngModel" required
                    [(ngModel)]="merchantActivation.country_code" class="form-control">
              <option *ngFor="let country of countries" [value]="country.key">{{country.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12"><p class="head-text">Account details </p></div>
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label class="form-control-label"> Your business type</label>
            <select id="account-details" name="account_details" #account_details="ngModel" required
                    [(ngModel)]="merchantActivation.business_type"
                    class="form-control">
              <option value="Corporation">Corporation</option>
              <option value="Individual" selected="selected">Individual</option>
              <option value="LLC">LLC</option>
              <option value="Non-profit">Non-profit</option>
              <option value="Partnership">Partnership</option>
            </select>
          </div>
        </div><!-- /.col-md-4 -->
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label class="form-control-label"> EIN (Tax ID)</label>
            <input type="text" name="ein_tax" id="ein-tax" #ein_tax="ngModel" required class="form-control"
                   [(ngModel)]="merchantActivation.tax_id" pattern="^[1-9]\d?-\d{7}$"
                   placeholder="12-3456789" (keypress)="EIN($event)" maxlength="10">
            <p>
              If you use your social security number for business tax purposes, you can use that instead. If you don't have an EIN yet, <a href="">apply
              online</a>.
            </p>
          </div>
        </div><!-- /.col-md-4 -->
        <div class="col-sm-4"></div>
        <div class="col-sm-12">
          <div class="row">
              <div class="col-sm-12">
                <label class="form-control-label"> Business address </label>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <input type="text" name="business_address" id="business-address" #business_address="ngModel" required
                         class="form-control"
                         [(ngModel)]="merchantActivation.address" placeholder="Address" maxlength="200">
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <input type="text" id="city1" name="city1" class="form-control" placeholder="City"
                         #city1="ngModel" required [(ngModel)]="merchantActivation.city" maxlength="200">
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <select id="city" name="city" #city="ngModel" required class="form-control"
                          [(ngModel)]="merchantActivation.state">
                    <option [value]="null">Choose your state</option>
                    <option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-2">
                <div class="form-group">
                  <input type="text" name="zip" id="zip" #zip="ngModel" required class="form-control" placeholder="Zip"
                         (keypress)="_keyPress($event)" [(ngModel)]="merchantActivation.zip" maxlength="5">
                </div>
              </div>
          </div><!-- /.row -->
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-control-label"> Website URL </label>
                <div class="form-group">
                  <input type="text" name="website_url" id="websiteUrl" #websiteUrl="ngModel" required
                         class="form-control" pattern="https?://.+"
                         [(ngModel)]="merchantActivation.website_url" placeholder="Your website">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="form-control-label"> Business description</label>
                <div class="form-group">
                  <input type="text" name="productdesc" id="product_desc" #product_desc="ngModel" required
                         class="form-control"
                         [(ngModel)]="merchantActivation.product_desc">
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.row -->
      </div>
      <hr class="hr">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <p class="head-text">You, the individual or sole proprietor </p>
          <div class="row">
              <div class="col-sm-12">
                <label class="form-control-label"> Legal name</label>
              </div>
              <div class="col-sm-12 col-md-4">
                <input type="text" name="legal_name_first" id="legal-name-first" #legal_name="ngModel" required
                       class="form-control" placeholder="First name"
                       [(ngModel)]="merchantActivation.first_name">
              </div>
              <div class="col-sm-12 col-md-4">
                <input type="text" name="legal_name" #legal_name2="ngModel" required
                       [(ngModel)]="merchantActivation.last_name" id="legal-name-last"
                       class="form-control" placeholder="Last name">
              </div>
              <div class="col-sm-12 col-md-4"></div>
          </div>
          <div class="row birth">
              <div class="col-sm-12">
                <label for="dob" class="form-control-label"> Date of birth</label>
              </div>
              <div class="col-sm-12 input-group">
                <input type="text" #dob="ngModel" required
                       class="form-control calendar-textbox"
                       #dp="bsDatepicker"
                       name="dob"
                       id="dob"
                       [maxDate]="maxDate"
                       placement="top" [(ngModel)]="merchantActivation.dob"
                       bsDatepicker [(bsValue)]="bsValue">
                <span class="input-group-btn">
                <button class="btn btn-secondary" (click)="dp.toggle()" type="button"><img class="calendar"
                                                                             src="../../../../assets/images/calendar-min.png"></button>
              </span>
              </div>
          </div><!-- /.row -->
          <div class="row">
            <div class="form-group last-digits">
              <div class="col-sm-12">
                <label class="form-control-label"> Last four digits of your social security number</label>
              </div>
              <div class="col-sm-12">
                <div class="input-group">
                  <div class="row">
                    <span class="input-group-addon last-d col-7">••• – •• –</span>
                    <input type="text" name="social_security" id="social-security" #social_security="ngModel" required
                           [(ngModel)]="merchantActivation.ssnlast4" (keypress)="_keyPress($event)"
                           class="form-control col-4" placeholder="9999" maxlength="4">
                  </div>
                <p class="left2">We use the last four digits to verify your identity.</p>
                </div>
              </div>


              <!--<div class="col-sm-12">-->
                <!--<div class="input-group">-->
                  <!--<span class="input-group-addon" id="phone">USA</span>-->
                  <!--<span class="input-group-addon country-code">+1</span>-->
                  <!--<input type="text" class="form-control" placeholder="" name="phone" id="phone" #phone="ngModel"-->
                         <!--required (keypress)="_keyPress($event)" [(ngModel)]="merchantActivation.phone_number" maxlength="10" pattern="^[0-9]*$"-->
                         <!--aria-describedby="phone">-->
                <!--</div>-->
              <!--</div>-->

              <div class="col-sm-12 col-md-7"></div>
            </div>
          </div><!-- /.row -->
        </div>
      </div><!-- /.row -->
      <hr class="hr">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <p class="head-text">Credit card statement details </p>
          <p class="sub-head-text">Your business name and phone number will appear on your customers' credit card
            statements. Don't worry, you can change this information later.
          </p>
        </div>
        <div class="col-sm-12">
          <div class="row">
              <div class="col-sm-12">
                <label class="form-control-label"> Business name</label>
              </div>
              <div class="col-sm-12 col-md-4">
                <input type="text" name="business_name" id="business-name" #business_name="ngModel" required
                       [(ngModel)]="merchantActivation.business_name"
                       class="form-control" placeholder="My company">
              </div>
              <div class="col-sm-12 col-md-8">
                <p class="sub-head-text">The name for your business that your customers will recognize. This may be the
                  legal entity name, or a doing business as (DBA), assumed business name, trade name, or trade
                  style.</p>
              </div>
            <div class="row">
              <div class="form-group phone">
                <div class="col-sm-12">
                  <div class="col-sm-12 col-md-12">
                    <label for="phone" class="form-control-label"> Phone</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="input-group">
                    <span class="input-group-addon" id="phone">USA</span>
                    <span class="input-group-addon country-code">+1</span>
                    <input type="text" class="form-control" placeholder="" name="phone" id="phone" #phone="ngModel"
                           required (keypress)="_keyPress( $event);" [(ngModel)]="merchantActivation.phone_number" maxlength="10" pattern="^[0-9]*$"
                           aria-describedby="phone">
                  </div>
                </div>
              </div>
            </div><!-- /.row -->
          </div>
        </div><!-- /.row -->
      </div>
      <hr class="hr">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <p class="head-text">Bank details</p>
          <p class="sub-head-text">You can accept USD. Your bank account must be a checking account</p>
          <!--<p>Your bank account must be a checking account</p>-->
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <label class="form-control-label"> Routing number</label>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <input type="text" name="routing_number" id="routing-number" #routing_number="ngModel" required  maxlength="9"
                       (keypress)="_keyPress($event)" pattern="^[0-9]*$" [(ngModel)]="merchantActivation.routing_number" class="form-control" placeholder="111000000">
              </div>
            </div>
          </div><!-- /.row -->
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <label class="form-control-label">Account number</label>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <input type="password" name="account_number" #account_number="ngModel" required id="account-number"
                       (keypress)="_keyPress($event)" [(ngModel)]="merchantActivation.account_number" class="form-control">
              </div>
            </div>
          </div><!-- /.row -->
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <label for="confirm_account_number" class="form-control-label"> Confirm account number</label>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <input type="password" name="confirm_account_number" id="confirm_account_number" (keypress)="_keyPress($event)"
                       #confirm_account_number="ngModel" required [(ngModel)]="accountNumberCheck" class="form-control">
                <label *ngIf="(confirm_account_number.touched || confirm_account_number.dirty) && (merchantActivation.account_number !== accountNumberCheck)"
                       class="error">Account numbers don't match. Try again.</label>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div><!-- /.row -->
      <div class="clearfix"></div>
      <hr class="hr">
      <div class="row">
        <div class="bottom-btns-activation col-sm-12">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="left">By activating your account, you agree to our <a href="" class="blue-text">services agreement</a>.</p>
              <p class="left bold">PayArc</p>
            </div><!-- / .col-sm-12 col-md-6-->
            <div class="col-sm-12 col-md-6 text-right">
              <!-- <button type="button"  class="btn btn-success" >Save for later</button>-->
              <!--<button type="button" class="btn btn-success" [disabled]="account.invalid || loadFlag"
                      (click)="activationPostCall()"><i *ngIf="loadFlag" class="fa fa-spinner fa-pulse"></i> <span>Activate Account</span>
              </button>-->
              <app-loading-button (buttonClick)="activationPostCall()" class="{{account.invalid ? 'disabled' : ''}}" [disable]="account.invalid" [loadFlag]="loadFlag" [buttonText]="'Activate account'"></app-loading-button>
            </div><!-- / .col-sm-12 col-md-6-->
          </div>
        </div><!-- / .bottom-btns-activation-->
      </div>
    </form>
  </mat-card>
</div>
