import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class AccountSettingsAction extends BaseComponent {

  // protected putSetting(url, postbody) {
  //   this.dataManager.patchRequest(url, postbody)
  //     .subscribe(
  //       res => this.putSettingResponse(res),
  //       err => {
  //         this.networkErrorHandler(err.error);
  //         this.putSettingError(err);
  //       }
  //     );
  // }
  // protected abstract putSettingResponse(res);
  // protected abstract putSettingError(err);

  protected getSetting(url) {
    // this.dataManager.appLoaderFlag(true);
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => this.getSettingResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.getSettingError(err);
        }
      );
  }
  protected abstract getSettingResponse(res);
  protected abstract getSettingError(err);

  protected postSetting(url, postbody) {
    this.dataManager.postRequest(url, postbody, 0)
      .subscribe(
        res => this.postSettingResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.postSettingError(err);
        }
      );
  }
  protected abstract postSettingResponse(res);
  protected abstract postSettingError(err);
}
