import {BaseComponent} from '../abstract/base.component';
import {ApiErrorModel} from '../data/model/ApiErrorModel/api-error.model';
import {Directive} from '@angular/core';
import {AppConstants} from '../shared/constants/app-constants';

@Directive()
export abstract class MultiFactorAuthenticaionAction extends BaseComponent {
  protected sendOtpUrl: string;
  protected verifyOtpUrl: string;
  protected _getAccountInfoUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '?limit=100' + '&page=1';
  protected _getSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS + '?limit=500';

  protected abstract passSendOtpResponse(res);

  protected abstract passSendOtpError(error: ApiErrorModel);

  protected abstract passverifyOtpResponse(res);

  protected abstract passverifyOtpError(error: ApiErrorModel);

  protected abstract passAccountInfoResponse(response);

  protected abstract passGetSettingResponse(res);

  getOtp(postBody) {
    this.loadFlag = true;
    this.dataManager.sendUserOtp(this.sendOtpUrl, postBody)
      .subscribe(
        res => this.passSendOtpResponse(res),
        error => {
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passSendOtpError(error.error);
        }
      );
  }

  VerifyOtp(postBody) {
    this.loadFlag2 = true;
    this.dataManager.verifyUserOtp(this.verifyOtpUrl, postBody)
      .subscribe(
        res => this.passverifyOtpResponse(res),
        error => {
          this.loadFlag2 = false;
          this.networkErrorHandler(error.error);
          this.passverifyOtpError(error.error);
        }
      );
  }

  protected getAccountInfo() {
    this.dataManager.getRequest(this._getAccountInfoUrl, 0)
      .subscribe(
        (res) => this.passAccountInfoResponse(res),
        (err) => {
          this.networkErrorHandler(err.error);
        }
      );
  }

  protected getSetting() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getSettingUrl, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passGetSettingResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.networkErrorHandler(err.error);
        }
      );
  }
}
