import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import { Subject } from 'rxjs';
import { GetCustomerAction } from './get-customer.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterModel } from '../../../data/model/filteModel/filter-model';
import { FilterConstants } from '../../../shared/constants/filter-constants';
import { ApiResponseModel } from '../../../data/model/ApiResponseModel/api-response.model';
import { CustomerV2Model } from '../../../data/model/CustomerV2Model/customer-v2.model';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../../shared/constants/app-constants';
import { PermissionConstants } from '../../../shared/constants/permission-constants';
import { AlertMessageDialogComponent } from '../../../dialogs/alert-message-dialog/alert-message-dialog.component';
import { ErrorConstants } from '../../../shared/constants/error-constants';
import { ExportModel } from '../../../data/model/ExportModel/export-model';
import { ExportConstants } from '../../../shared/constants/export-constants';
import {Highlight} from '../../../shared/highlights/highlight';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
})
export class CustomerListComponent extends GetCustomerAction implements OnInit {
  filter: FilterModel[] = FilterConstants.customerListFilter;
  customerList: ApiResponseModel<CustomerV2Model>;
  exportExcel: ExportModel[] = ExportConstants.customerListExport;

  exportData = {exportServiceName: AppConstants.SERVICE_NAME_EXPORT_DASHBOARD + '/customer', redirectUrl : '/customer/customer-list', fileName: 'Payarc_Customer_Report', heading: 'Customers'};
  showExport: boolean = false;
  _service_name = '/customers';
  defaultCard = null;
  card_img_obj = {
    R: '../../../../assets/images/disc.png',
    V: '../../../../assets/images/visa.png',
    X: '../../../../assets/images/amex.png',
    M: '../../../../assets/images/master.png',
    J: '../../../../assets/images/jcb.png',
  };
  excel = 1;
  blob: any;
  to_date: any;
  from_date: any;
  today: Date = new Date();

  constructor(
    router: Router,
    private dialog: MatDialog,
    route: ActivatedRoute,
    dataManager: DataManagerService,
    snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any, private datePipe: DatePipe
  ) {
    super();
    this.snackBar = snackBar;
    this.alertDialog = dialog;
    this.errorMsg = null;
    this.searchHits = new Subject();
    console.log(this.disableNext, this.disablePrev);
    this.dataManager = dataManager;
    this.filter_storage_key = FilterConstants.CUSTOMER_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.CUSTOMER_FILTER_COUNT_STORAGE_KEY;
    this.router = router;
    this.route = route;
    this.redirect_link = '/customer/customer-details';
    this.customerList = new ApiResponseModel();
    this.getListUrl = AppConstants.BASE_URL + this._service_name;
    this.hasAdvancefilter = true;
    this._getcustomerexcel = AppConstants.BASE_URL + this._service_name + '?limit=' + this.limit + '&page=1' + '&excel=' + this.excel;
    this.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSION_CREATE_CUSTOMER
    );
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      (url) => {
        this.filterPostFix = url;
        console.log(this.filterPostFix);
        this.dataManager.prev_url = url;
        this.is_filter = true;
        this.setUrl();
      },
      (err) => console.log(err)
    );
  }

  ngOnInit() {

    this.searchHits.pipe(debounceTime(600),).subscribe((res) => {
      if (!this.isNullOrUndefined(this.searchText)) {
        this._getcustomerexcel = this.setUrl()  + '&excel=' + this.excel;
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.localStorage.getItem(this.filter_storage_key) &&
        !this.localStorage.getItem(this.count_storage_key)
      ) {
        console.log('first call for list');
        this.setUrl();
      }
      console.log(
        'permission name',
        PermissionConstants.PERMISSION_CREATE_CUSTOMER
      );
    }
  }
  datereport()
  {
    if((this.from_date && this.from_date.toString() === 'Invalid Date') || (this.to_date && this.to_date.toString() === 'Invalid Date')) {
      this.showSnackBar('Enter valid date.', 'Ok', 3000);
    }
    else if ((this.isNullOrUndefined(this.from_date)) || this.isNullOrUndefined(this.to_date)) {
      if((!this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date))) || (this.isNullOrUndefined(this.searchText) && (this.isNull(this.from_date) && this.isNull(this.to_date))) ) {
        this.from = this.from_date;
        this.to = this.to_date;
        this.setUrl();
      } else {
      return;
      }
    }
    else if( this.from_date > this.to_date)
    {
      this.showSnackBar('From date should not exceed ' + this.datePipe.transform(this.to_date, 'MM/dd/yyyy') + '.' , 'Ok', 3000);
    }
    else {
      this.from = (this.isNullOrUndefined(this.from_date) ? '' : (this.datePipe.transform(this.from_date, 'yyyy-MM-dd')));
      this.to= (this.isNullOrUndefined(this.to_date) ? '' : (this.datePipe.transform(this.to_date, 'yyyy-MM-dd')));
      this.setUrl()
    }
  }
  checkdate() {
    if (!this.isNull(this.from_date) && this.from_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  checkdate2() {

    if (!this.isNull(this.to_date) && this.to_date.toString() === 'Invalid Date') {
      this.datereport();
    }
  }

  // setDates() {
  //   const today = new Date();
  //    this.from_date = new Date(today.getFullYear(), today.getMonth(), 1);
  //   this.to_date = new Date();

  // }
  limitChange()
  {
    this._getcustomerexcel = this.setUrl()  + '&excel=' + this.excel;
  }
  paginate(paginateExtension) {
    this._getcustomerexcel = paginateExtension + '&excel=' + this.excel;
    console.log('adad', paginateExtension);
    this.getList(paginateExtension);
  }

  /**
   * func to add charge
   * for selected customer
   * @param customer selected customer object
   */
  chargeCustomer(customer: CustomerV2Model) {
    const data = {};
    if (!this.permissionCheck(PermissionConstants.PERMISSION_CREATE_PAYMENT)) {
      data['head'] = 'Alert';
      data['message'] = PermissionConstants.PERMISSIONS_CREATE_MESSAGE_PAYMENT;
      this.dialog.open(AlertMessageDialogComponent, { data: data });
    } else if (customer.card.data.length <= 0) {
      data['head'] = 'Alert';
      data['message'] = 'This customer doesn\'t have any cards to charge on';
      this.dialog.open(AlertMessageDialogComponent, { data: data });
    } else {
      const data = {
                customer_id: customer.customer_id ? customer.customer_id : null,
                customer_email: customer.email ? customer.email : null,
                customer_phone: customer.phone ? customer.phone : null,
                source: customer.source_id ? customer.source_id : null,
                sourceList: customer.card.data ? customer.card.data : null,
                customer_name: customer.name ? customer.name: null
              }
      localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
      this.router.navigate(['../capture-payment'], {queryParams: {id: data.customer_id},relativeTo: this.route})
    }
  }

  /**
   * func to route to customer
   * details screen with selected
   * or clicked customer from customer list
   * @param customer object for clicked customer
   */
  openCustomerDetails(customer: CustomerV2Model) {
    const navigationExtras: NavigationExtras = {
      queryParams: { id: customer.customer_id },
    };
    console.log(customer);
    console.log(navigationExtras);
    this.router.navigate(['/customer/customer-details'], navigationExtras);
  }

  /**
   * func to open dialog
   * to add customer
   */
  nullToDoubleMinus(value) {
    return value ? value : '--';
  }

  highlight(value) {
    return Highlight.highlight(value, this.searchText);
  }
  protected getListRes(customerResponse: ApiResponseModel<CustomerV2Model>) {
    this.customerList = customerResponse;
    this.metadata = customerResponse.meta.pagination;
    if (customerResponse.data.length === 0) {
      this.errorMsg = ErrorConstants.customerErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
    for (const customer of this.customerList.data) {
      for (const card of customer.card.data) {
        if (card.is_default) {
          customer.defaultCard = card;
        }
      }
    }
  }

  protected getListErr(error: any) {
    this.errorMsg = this.networkErrorHandler(
      error,
      ErrorConstants.customerErrorMsg
    );
    console.log('error logged in customer list component');
    console.log(error);
  }

  exportexcel() {
    this.showExport = true
    // this.router.navigate([''])
    // this.loadFlag = true;
    // this._getcustomerexcel = AppConstants.BASE_URL + this._service_name + '?limit=' + this.limit + '&page=1' + '&excel=' + this.excel + (!isNullOrUndefined(this.filterPostFix)  ? this.filterPostFix  : '');
    // console.log('ecxel',  this._getcustomerexcel);
    // this.getcustomerlogexcel();
    // this.showSnackBar('File is loading.', 'Ok', 3000);
  }
  protected passCustomerExcelResponse(res: any,) {

    this.blob = new Blob([res], { type: 'application/vnd.ms-excel' });
    const downloadURL = window.URL.createObjectURL(res);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = "Payarc_Customer_Report.xls";
    link.click();

  }
  protected passCustomerExcelError(err: any) {
    // console.log(err);
  }

  filterDone(event) {
    console.log(event);
  }
}
