import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {CasesModel, DisputeModel} from '../../../data/model/DisputeModel/dispute.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {DisputeListAciton} from './dispute-list.aciton';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {FilterConstants} from '../../../shared/constants/filter-constants';
import {FilterModel} from '../../../data/model/filteModel/filter-model';
import {DisputeChartModel} from "../../../data/model/DisputeChartModel/dispute-chart-model";
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Highcharts from 'highcharts';
import StockModule from 'highcharts/modules/stock';
import { ExportModel } from '../../../data/model/ExportModel/export-model';
import { ExportConstants } from '../../../shared/constants/export-constants';
import { debounceTime } from 'rxjs/operators';

StockModule(Highcharts);


@Component({
  selector: 'app-dsiputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.css']
})
export class DisputesComponent extends DisputeListAciton implements OnInit, OnDestroy {
  exportExcel : ExportModel[] = ExportConstants.disputesListExport;
  showExport: boolean = false;
  disputeList: ApiResponseModel<CasesModel>;
  disputeChartsData: DisputeChartModel;
  redirect_link2: string;
  public orderBy: string;
  public sortBy = 'asc';
  excel = 1;
  blob: any;
  piechartres = [];
  Highcharts: any;
  dispVolChartOptions: any;
  dispCountChartOptions: any;
  chartOptionsdonout: Highcharts.Options;
  public limitArray = [25, 50, 100, 10000];
  filterOnSelected: string;
  filter: FilterModel[];
  exportData: Object;
  card_img_obj = {
    'D': '../../../../assets/images/disc.png', 'V': '../../../../assets/images/visa.png',
    'A': '../../../../assets/images/amex.png', 'M': '../../../../assets/images/master.png',
    'J': '../../../../assets/images/jcb.png'
  };
  filterOn = [{
    key: 'report_date',
    text: 'Chargeback date'
  },
    {
      key: 'transaction_date',
      text: 'Transaction date'
    },
    {
      key: 'last_updated_date',
      text: 'Last updated at'
    }
  ];


  constructor(router: Router, private dialog: MatDialog, dataManager: DataManagerService,
              snackBar: MatSnackBar,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any, private datePipe: DatePipe
  ) {
    super();
    this.exportData = {exportServiceName: AppConstants.SERVICE_NAME_CASES, redirectUrl : '/disputes/dispute-list', fileName: 'Payarc_Dispute_Report', heading : 'Disputes'};
    this.snackBar = snackBar;
    this.searchText = new Subject<any>();
    this.dataManager = dataManager;
    this.disputeChartsData = new DisputeChartModel();
    this.filterOnSelected = 'report_date';
    this.Highcharts = Highcharts;
    this.router = router;
    this.fromDate = new Date();
    if (localStorage.hasOwnProperty(FilterConstants.DISPUTE_FROM_TO_DATE_KEY)) {
      const filterDate = JSON.parse(localStorage.getItem(FilterConstants.DISPUTE_FROM_TO_DATE_KEY));
      this.fromDate = new Date(filterDate['from']);
      this.toDate = new Date(filterDate['to']);
    } else {
      this.fromDate.setMonth(new Date().getMonth(), 1);
      this.toDate = new Date();
    }

    // this.redirect_link = '/disputes/dispute-details';
    this.redirect_link2 = '/disputes/dispute-form';
    this.create_permission = false;
    this.filter = FilterConstants.disputeListFilter;
    this.filter_storage_key = FilterConstants.DISPUTE_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.DISPUTE_FILTER_COUNT_STORAGE_KEY;
    this.redirect_link = '/disputes/dispute-details';
    this.disputeList = new ApiResponseModel();
    this._disputeListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CASES;
    this._acceptDisputeUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_DISPUTE;
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      filterUrl => {
        this.filterPostFix = filterUrl;
        this.setUrl();
      },
      err => console.log(err)
    );
  }

  ngOnInit() {
    this.Highcharts = Highcharts;
    this.searchText.pipe(debounceTime(600),).subscribe(
      res => this.setUrl()
    );
    if (!this.localStorage.getItem(FilterConstants.DISPUTE_FILTER_STORAGE_KEY)
      && !this.localStorage.getItem(FilterConstants.DISPUTE_FILTER_COUNT_STORAGE_KEY)) {
      this.setUrl();
    }
  }

  setUrl() {
    let url = this._disputeListUrl;
    url =
      url +
      (isNaN(this.limit) ? '?limit=0' : '?limit=' + this.limit + '&page=1') + (this.isNullOrUndefined(this.orderBy)
      ? ''
      : '&orderBy=' + this.orderBy + '&sortedBy=' + this.sortBy) +
      (this.isNullOrUndefined(this.searchKeyword)
        ? ''
        : '&search=' + this.searchKeyword);
    url =
      url +
      (this.isNullOrUndefined(this.filterPostFix)
        ? ''
        : this.filterPostFix) +
      (this.isNullOrUndefined(this.fromDate) ? '' : ('&' + this.filterOnSelected + '[gte]=' + this.datePipe.transform(this.fromDate, 'yyyy/MM/dd')))
      +
      (this.isNullOrUndefined(this.toDate) ? '' : ('&' + this.filterOnSelected + '[lte]=' + this.datePipe.transform(this.toDate, 'yyyy/MM/dd')));

    this.getDisputeList(url);
    this._disputeChartUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_DISPUTE_CHART + '?' + this.filterOnSelected + '[gte]=' + this.datePipe.transform(this.fromDate, 'yyyy/MM/dd')
      + '&' + this.filterOnSelected + '[lte]=' + this.datePipe.transform(this.toDate, 'yyyy/MM/dd');
    this.getDisputeChart();
  }

  paginate(url) {
    this.getDisputeList(url);
  }

  setSorting(orderBy) {
    if (orderBy === this.orderBy) {
      this.sortBy = this.sortBy === 'asc' ? 'desc' : 'asc';
    } else {
      this.orderBy = orderBy;
      this.sortBy = 'asc';
    }
    this.setUrl();
  }

  protected passDisputeList(res) {
    this.loadFlag = false;
    this.disputeList = res;
  }

  protected passError(error) {
    this.loadFlag = false;
  }
  public openDispute($event, dispute) {
    this.router.navigateByUrl(this.redirect_link + '?id=' + dispute.id);
  }
  exportexcel() {
    // let url = '';
    // url =
    //   url +
    //   (this.isNullOrUndefined(this.orderBy)
    //   ? ''
    //   : '&orderBy=' + this.orderBy + '&sortedBy=' + this.sortBy) +
    //   (!this.isNullOrUndefined(this.searchKeyword)
    //     ? ('&search=' + this.searchKeyword) : '') +
    //   (this.isNullOrUndefined(this.filterPostFix)
    //     ? ''
    //     : this.filterPostFix) +
    //   (this.isNullOrUndefined(this.fromDate) ? '' : ('&' + this.filterOnSelected + '[gte]=' + this.datePipe.transform(this.fromDate, 'yyyy/MM/dd')))
    //   +
    //   (this.isNullOrUndefined(this.toDate) ? '' : ('&' + this.filterOnSelected + '[lte]=' + this.datePipe.transform(this.toDate, 'yyyy/MM/dd')));


    this.showExport = true
    this.exportData['include'] = this.filterOnSelected
  }

  protected passdisputeExcelResponse(res: any,) {
    this.blob = new Blob([res], {type: 'application/vnd.ms-excel'});
    var downloadURL = window.URL.createObjectURL(res);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = "Payarc_Dispute_Report.xls";
    link.click();

  }

  protected passdisputeExcelError(err: any) {
    console.log(err);
  }


  ngOnDestroy() {
    if (location.pathname.includes('dispute-details')) {
      this.localStorage.setItem(FilterConstants.DISPUTE_FROM_TO_DATE_KEY, JSON.stringify({
        from: this.fromDate,
        to: this.toDate
      }));
    } else {
      if (this.localStorage.hasOwnProperty(FilterConstants.DISPUTE_FROM_TO_DATE_KEY)) {
        this.localStorage.removeItem(FilterConstants.DISPUTE_FROM_TO_DATE_KEY);
      }
    }
  }

  protected passDisputeChartResponse(res: DisputeChartModel) {
    this.disputeChartsData = res;
    this.setPIchartData();
    this.setDisputeVolChart();
    this.setDisputeCountChart();

  }

  protected passDisputeChartError(error: any) {

    this.disputeChartsData.dispute_volume_chart_data.length = 0 ;
    this.disputeChartsData.dispute_count_chart_data.length = 0;
    throw new Error('Method not implemented.');
  }

  setPIchartData() {
    let outerCircle = [];
    let innerCircle = [];
    const data_type_map = {
      'Currently resolved in Merchant\'s favor': 1,
      'Pending': 3,
      'Currently resolved in Bank\'s favor': 2,
      'RDR': 4,
      'Responded': 5,
      'Not Responded': 6
    };
    if (this.disputeChartsData.pi_chart_data.length > 0) {
      this.disputeChartsData.pi_chart_data.forEach(obj => {
        // if (obj.data_type !== 'Responded' && obj.data_type !== 'Not Responded') {
          outerCircle.push({
            name: obj.data_type,
            color: this.pieChartColorsLables(obj.data_type),
            y: Number(obj.dispute_count),
            legendIndex: data_type_map[obj.data_type] ?? 0
          });
        // } else {
        //   console.log('printing obj2');
        //   outerCircle.push({
        //     name: obj.data_type,
        //     color: this.pieChartColorsLables(obj.data_type),
        //     y: Number(obj.dispute_count),
        //     legendIndex: ( (obj.data_type === 'Responded') ? 4 : 6)
        //   });
        // }
      });


      this.chartOptionsdonout = {

        tooltip: {
          useHTML: true,
          headerFormat: '<table><tr><td>{point.key}</td></tr>',
          pointFormat: '<tr><td ><span style="color:{point.color};">\u25CF</span> {series.name}: </td>' +
            '<td style="text-align: right"><b>{point.y:,.0f}</b></td></tr>',
          footerFormat: '</table>',
          style: {
            color: '#737376',
            fontSize: '12px',
            fontFamily: 'Stolzl-Regular'
          }
        },
        legend: {
          align: 'center',
          layout: 'horizontal',
          x: 20,
          y: -50,
          itemMarginBottom: 30,
          width: 400,
          itemWidth: 200,
          itemStyle: {
            textOverflow: null,
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '50%'],

          }
        },

        title: {text: null},
        credits: {
          enabled: false
        },

        series: [{
          showInLegend: true,
          type: 'pie',
          name: 'Dispute count',
          size: '100%',
          innerSize: '70%',
          data: [...outerCircle],

        }],

      };


    }


  }

  setDisputeVolChart() {

    if (this.disputeChartsData.dispute_volume_chart_data.length > 0) {
      const barChart = {};
      barChart['label'] = [];
      barChart['dispute_volume'] = [];
      barChart['ratio_volume'] = [];
      this.disputeChartsData.dispute_volume_chart_data.forEach(x => {
        barChart['label'].push(x.label);
        barChart['dispute_volume'].push(Number(x.dispute_volume));
        barChart['ratio_volume'].push(Number(x.ratio_volume));
      });

      this.dispVolChartOptions = {
        title: {
          text: null
        },

        chart: {
          marginLeft: 80
        },
        xAxis: {
          labels: {
            style: {
              color: '#737376',
              fontSize: '9px',
              fontFamily: 'Stolzl-Regular'
            }
          },
          categories: [...barChart['label']],
          title: {
            text: ''
          },
        },
        yAxis: [
          {
            labels: {
              formatter: function () {
                return '$' + Highcharts.numberFormat(this.value, 2, '.', ',');
              },
              style: {
                color: '#121744',
                fontSize: '12px',
                fontFamily: 'Stolzl-Medium'
              }
              // format: '${value:,.0f} USD',
              // pointFormat: '{point.percentage:,,.1.f}',
            },
            title: {
              text: '',
            },
          }, {
            labels: {
              formatter: function () {
                return Highcharts.numberFormat(this.value, 2, '.', ',') + '%';
              },
              style: {
                color: '#121744',
                fontSize: '12px',
                fontFamily: 'Stolzl-Medium'
              }
            },
            title: {
              text: '',
            },
            opposite: true,
          }
        ],
        legend: {
          verticalAlign: 'top',
          itemStyle: {
            fontSize: '12px',
            fontFamily: 'Stolzl-Regular',
            fontWeight: 'normal',
            color: '#737376',
          },
          },
        series: [
          {
            name: 'Dispute volume',
            data: [...barChart['dispute_volume']],
            type: 'column',
            color: '#89ABF2',
            tooltip: {
              useHTML: true,
              headerFormat: '<table><tr> <td style="font-size: 12px">{point.key}</td></tr><br>',
              pointFormat: '<tr><td ><span style="color:{point.color};">\u25CF</span> {series.name}: </td>' +
                '<td style="text-align: right"><b>${point.y:,.2f} USD</b></td></tr>',
              footerFormat: '</table>',
              style: {
                color: '#737376',
                fontSize: '12px',
                fontFamily: 'Stolzl-Regular'
              }
            },

          },
          {
            name: 'Ratio (Volume)',
            data: [...barChart['ratio_volume']],
            type: 'column',
            yAxis: 1,
            color: '#737376',
            tooltip: {
              useHTML: true,
              headerFormat: '<small style="font-size: 12px">{point.key}</small><br><table>',
              pointFormat: '<tr><td ><span style="color:{point.color};">\u25CF</span> {series.name}: </td>' +
                '<td style="text-align: right"><b>{point.y:,.2f}%</b></td></tr>',
              footerFormat: '</table>',
              style: {
                color: '#737376',
                fontSize: '12px',
                fontFamily: 'Stolzl-Regular'
              }
            },

          },
        ],

        credits: {
          enabled: false
        },


      };
    }
  }

  setDisputeCountChart() {

    if (this.disputeChartsData.dispute_count_chart_data.length > 0) {
      const barChart = {};
      barChart['label'] = [];
      barChart['dispute_count'] = [];
      barChart['ratio_count'] = [];
      this.disputeChartsData.dispute_count_chart_data.forEach(x => {
        barChart['label'].push(x.label);
        barChart['dispute_count'].push(Number(x.dispute_count));
        barChart['ratio_count'].push(Number(x.ratio_count));
      });

      this.dispCountChartOptions = {
        title: {
          text: null
        },
        chart: {
          marginLeft: 60
        },
        xAxis: {
          labels: {
            style: {
              color: '#737376',
              fontSize: '9px',
              fontFamily: 'Stolzl-Regular'
            }
          },
          categories: [...barChart['label']],
          title: {
            text: ''
          },

        },
        yAxis: [
          {
            width: 120,
            title: {
              text: '',
            },
            allowDecimals: false,
            labels: {

              formatter: function () {
                return Highcharts.numberFormat(this.value, 0, '.', ',');
              },
              style: {
                color: '#121744',
                fontSize: '12px',
                fontFamily: 'Stolzl-Medium'
              }
            },
          }, {
            labels: {
              formatter: function () {
                return Highcharts.numberFormat(this.value, 2, '.', ',') + '%';
              },
              style: {
                color: '#121744',
                fontSize: '12px',
                fontFamily: 'Stolzl-Medium'
              }
            },
            title: {
              text: '',
            },
            opposite: true,
          }
        ],
        legend: {
          verticalAlign: 'top',
          itemStyle: {
            fontSize: '12px',
            fontFamily: 'Stolzl-Regular',
            fontWeight: 'normal',
            color: '#737376',
          },
        },
        series: [
          {
            name: 'Dispute count',
            data: [...barChart['dispute_count']],
            type: 'column',
            color: '#4AD291',
            tooltip: {
              useHTML: true,
              headerFormat: '<table><tr style="font-size: 10px"> <td>{point.key}</td></tr><br>',
              pointFormat: '<tr><td ><span style="color:{point.color};">\u25CF</span> {series.name}: </td>' +
                '<td style="text-align: right"><b>{point.y:,.0f}</b></td></tr>',
              footerFormat: '</table>',
              style: {
                color: '#737376',
                fontSize: '12px',
                fontFamily: 'Stolzl-Regular'
              }
            },

          },
          {
            name: 'Ratio (Count)',
            data: [...barChart['ratio_count']],
            yAxis: 1,
            type: 'column',
            color: '#419DF1',
            tooltip: {
              useHTML: true,
              headerFormat: '<table><tr style="font-size: 10px"> <td >{point.key}</td></tr><br>',
              pointFormat: '<tr><td ><span style="color:{point.color};">\u25CF</span> {series.name}: </td>' +
                '<td style="text-align: right"><b>{point.y:,.2f}%</b></td></tr>',
              footerFormat: '</table>',
              style: {
                color: '#737376',
                fontSize: '12px',
                fontFamily: 'Stolzl-Regular'
              }
            },
          },
        ],

        credits: {
          enabled: false
        },


      };
    }
  }

  pieChartColorsLables(val) {
    switch (val) {
      case 'Currently resolved in Merchant\'s favor' :
        return '#4AD291';
      case 'Currently resolved in Bank\'s favor' :
        return '#121744';
      case 'Open' :

      case 'Pending' :
        return '#419DF1';
      case 'Responded' :
        return '#CBCBCB';
      case 'Not Responded' :
        return '#3E3E3E';
      case 'RDR' :
        return '#89ABF2';
    }

  }

}
