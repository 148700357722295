export class UploadModal {
  created_at?: Date;
  file_name?:  string;
  file_type?: string;
  file_size?:  number;
  length?:     number;
  id?:         string;
  real_id?:    string;
  text?:       string;
  updated_at?: Date;
  uploaded_file_url?: string;
}
