import {Component, Input, OnInit} from '@angular/core';
import {EventListAction} from './ApiAction/EventListAction';
import {ApiResponseModel} from '../../data/model/ApiResponseModel/api-response.model';
import {EventModel} from '../../data/model/EventModel/Event.model';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {Router} from '@angular/router';
import {PermissionConstants} from "../../shared/constants/permission-constants";


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent extends EventListAction implements OnInit {

  @Input() url: string;
  @Input()ComponentIdKey: string;
  @Input()ComponentId;
  redirect_link_details: string;


  eventList: ApiResponseModel<EventModel>;


  constructor(router: Router, dataManager: DataManagerService) {
    super();
   // this.id = ;
    this.dataManager = dataManager;
    this.router = router;
    this.create_permission = this.permissionCheck(PermissionConstants.PERMISSION_LIST_EVENT);
    this.eventList = new ApiResponseModel();
    this.redirect_link_details = '/event-details';
    console.log('id', this.id);

  }

  ngOnInit() {
    this._eventListUrl = this.url;
    this.getEventListData();
  }


  protected passEventList(eventResponse: ApiResponseModel<EventModel>) {

    this.eventList = eventResponse;
    console.log('response of  events : ', this.eventList);
    }

  protected passError(error: any) {
    console.log('errors events : ', error);
  }

  navigateToEventDetails(id) {
    this.routeWithId('/event-details', id);
  }

  redirectToEventList() {
    this.routeWithKeyAndId('events-and-logs', this.ComponentIdKey, this.ComponentId );
  }

}
