import {CustomDateModel} from '../CustomDate/custom-date.model';
import {ApiTokensModel} from '../ApiTokens/api-tokens.model';
import {RoleModel} from '../RoleModel/role.model';

export class AppUserModel {
  id?: string;
  name?: string;
  phone_number: number;
  username?: string;
  email?: string;
  current_account_id?: string;
  nickname?: string;
  gender?: string;
  birth?: Date;
  created_at: CustomDateModel;
  updated_at?: CustomDateModel;
  token_details?: ApiTokensModel;
  /*relation*/
  roles?: DataTArray<RoleModel>;
  /*fields for UI*/
  password?: string;
  confirm_password?: string;
  current_password?: string;
  accounts?: DataTArray <AccountModel>;
  user_settings?: DataTArray<UserNotificationModel>
}

export class DataTArray <T> {
  data?: T[] = [];
}

export class DataT <T> {
  data?: T;
}


export class AccountModel {
  id ?: string;
  mid ?: string;
  status ?: string;
  account_type ?: string;
  account_name ?: string;
  dba_name ?: string;
  default_descriptor ?: string;
  country_code ?: string;
  product_desc ?: string;
  tax_id ?: string;
  address ?: string;
  city ?: string;
  state ?: string;
  zip ?: string;
  first_name ?: string;
  last_name ?: string;
  dob_month ?: string;
  dob_date ?: string;
  dob_year ?: string;
  ssn_last4 ?: string;
  business_name ?: string;
  phone_number ?: number;
  cvv_optional ?: number;
  address_optional ?: number;
  is_bridge_account ?: number;
  created_at ?: Date;
  updated_at ?: Date;
}

export class UserNotificationModel {
  object?: string;
  id?: string;
  key?: string;
  value?: number;
  user_id?: string;
  account_id?: string
}


