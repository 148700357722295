import {CustomerCard, CustomerV2Model} from '../CustomerV2Model/customer-v2.model';
import {BaseModel} from '../BaseModel/base.model';

export class ChargeModel {
  amount?: number;
  amount_refunded?: number;
  captured?: number;
  healthcare?: number;
  healthcaretype?: string;
  charge_description?: string;
  cvv?: string;
  tip: number;
  source_id?: number;
  under_review?: any;
  amount_captured?: number;
  currency?: string;
  statement_description?: string;
  cardDetails?: string;
  customer_id?: string;
  customer_email?: string;
  prescription?: number;
  vision?: number;
  clinic?: number;
  dental?: number;
  sales_tax?: number;
  purchase_order?: string;
  supplier_reference_number?: string;
  customer_ref_id?: string;
  ship_to_zip?: string;
  amex_descriptor?: string;
  customer_vat_number?: string;
  summary_commodity_code?: string;
  shipping_charges?: number;
  duty_charges?: number;
  ship_from_zip?: string;
  destination_country_code?: string;
  vat_invoice?: string;
  order_date?: Date;
  tax_category?: string;
  tax_type?: string;
  tax_rate?: number;
  tax_amount?: number;
  receipt_phone?: string;
  receipt_email?: string;
  customer_phone?: string;
  customer_name?: string;
  avs_parameters?: string;
  bin_location_parameters?: string;
  bin_card_type_parameters?: string;
  tax_id_tin?: string;
  wosb?: boolean;
  // businessTypeID?: string;
  productName?: string;
  price?: number;
  quantity?: number;
  unit_asset_number?: string;
  productCode?: string;
  unit_shipping_split_number?: string;
  unit_total_mater_count?: number;
  service_credit?: number;
  orderID?: string;
  orderNumber?: string;
  industryType?: string;
}


export class ChargeV2Model extends BaseModel {
  id?: string;
  amount?: number;
  amount_refunded?: number;
  amount_captured?: number;
  captured?: boolean;
  healthcare?: number;
  under_review?: any;
  healthcaretype?: string;
  failure_code?: number;
  failure_message?: string;
  charge_description?: string;
  created_at?: Date;
  updated_at?: Date;
  card?: CustomerCard;
  prescription?: number;
  vision?: number;
  clinic?: number;
  dental?: number;
  constructor() {
    super();
    this.card = new CustomerCard();
  }
}
