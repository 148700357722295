import { BaseComponent } from '../../abstract/base.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class GetAccountBrandingAction extends BaseComponent {

  // protected _postBrandUrl: string;

  protected putBrand(url, postbody,) {
    this.dataManager.patchRequest(url, postbody,)
      .subscribe(
        res => this.putBrandResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.putBrandError(err.error);
        }
      );
  }
  protected abstract putBrandResponse(res);
  protected abstract putBrandError(err);


  protected getBrand(url) {
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => this.getBrandResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.getBrandError(err.error);
        }
      );
  }
  protected abstract getBrandResponse(res);
  protected abstract getBrandError(err);

  protected postBrand(url, multipartRequest, postbody) {
    this.dataManager.postRequest(url, multipartRequest, 0)
      .subscribe(
        res => this.postBrandResponse(res, postbody),
        err => {
          this.networkErrorHandler(err.error);
          this.postBrandError(err);
        }
      );
  }
  protected abstract postBrandResponse(res, postbody);
  protected abstract postBrandError(err);
}