<h1>Merchant Split Funding</h1>
<div class="split_funding_bdy">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right pl-0 mob_add" >
    <ul class="list-inline">
      <li class="list-inline-item pb-md-3"  >
       <app-filter  [filter_storage_key]="filter_storage_key" [count_storage_key]="count_storage_key" [filter]="filter"></app-filter> 
      </li>
    </ul>
  </div>
    <div class="table_content  mt-xl-3 mt-lg-3 mt-md-3" >
      <div class="row">
      </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-m-0">
            <div  (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag"  class="top-scroll" id="TopScroll">
              <img src="" height=1 [style]="checkTablewidth()">
            </div>
            <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
              <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
              <table *ngIf="!errorMsg" class="table mb-0" id="table-width">
                <thead>
                <tr  class="d-flex justify-content-around" >
                  <th class="amount"><a (click)="setSorting('amount')">Campaign Name
                    <i class="fa fa-thin" [ngClass]="orderBy === 'amount' ? stylesortBy : 'fa-angle-down'"></i>
                  </a></th>
                  <th class="status">Campaign Status</th>
                  <th class="id">Creation Date </th>
                  <th class="id">Payees</th>
                  <th class="id">Request Date </th>
                  <th class="id">Request Status </th>
                  <th class="status">Updated Date</th>
                  <th class="cust">Campaigns Assigned to</th>
                  <th class="id">Action Items </th>
                  
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="loadFlag">
                  <tr class="loader">
                    <td colspan="7">
                      <div class="loader-container">
                        <img class="table-loader" src="../../../../assets/images/loading.svg">
                      </div>
                    </td>
                  </tr>
                </ng-container>
      
                <ng-container >
                  <tr *ngFor="let campaign of exampleData;" [payee]="payee" class="cursor d-flex justify-content-around" [routerLink]="[redirect_link, campaign.id]"
                      [queryParams]="{id: campaign.id}">
                    <td class="campaign"><a [innerHTML]="highlight(campaign.campaign)">{{campaign.campaign}}</a></td>
                    <td class="status "><a>{{campaign.status}}</a></td>
                    <td class="date"><a>{{campaign.creationDate}}</a></td>
                    <td class="date"><a>{{campaign.payees}}</a></td>
                    <td class="date"><a>{{campaign.requestDate}}</a></td>
                    <td class="date"><a>{{campaign.requestStatus}}</a></td>
                    <td class="cust"><a>{{campaign.updateDate}}</a></td>
                    <td class="cust"><a>{{campaign.assignedTo}}</a></td>

                    <td class=""><a>{{campaign.actionItems}}</a></td>
                    
             
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <!--pagination start-->

          <div class="row m-0 pt-sm-4 pt-1 border-2" *ngIf="!loadFlag && chargeList.data.length > 0">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
                  <span class="paginate">
                    {{ 1 + (metadata['current_page'] - 1) * limit }} -
                    <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                    *ngIf="metadata['current_page'] === 1">
                    <option *ngFor="let no of limitArray" [value]="no">{{
                      no
                    }}</option>
                  </select>
                  <span *ngIf="metadata['current_page'] !== 1">{{
                    metadata['count'] +
                    (metadata['current_page'] - 1) * limit
                      }}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
                  </span>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
                <ul class="list-inline"
                *ngIf="!loadFlag && chargeList.data.length > 0 && objectKeys(metadata.links).length > 0">
                <li class="list-inline-item">
                  <button type="button" class="btn btn-default btn_prenext pl-m-3" [disabled]="!metadata.links['previous']"
                  (click)="paginate( metadata.links['previous'])">
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                </button>
              </li>
                  <li class="list-inline-item" *ngFor="let number of numbers()">
                    <div *ngIf="+number.toString() ">
                      <a class="page-link" *ngIf="metadata.links.next"
                      [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                      (click)="nextpage(number, metadata.links.next)">{{number}}</a>
                      <a class="page-link"
                      *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)"
                      [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                      (click)="nextpage(number, metadata.links['previous'])">{{number}}</a>
                    </div>
                    <a class="page-link" *ngIf="!+number.toString()">{{number}}</a>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-default btn_prenext px-m-0" [disabled]="!metadata.links['next']"
                    (click)="
                    paginate(metadata.links['next']) ">
                    Next <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
      
              
            </div>
          </div>
          <!--pagination end-->
          </div>
        </div>
        
      </div>
    </div>
    