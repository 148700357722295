export class BankAccountAchModel {
   object?: string;
  id?: string;
  account_holder_first_name?: string;
  account_holder_last_name?: string;
  account_holder_type?: string;
  bank_name?: string;
  bank_phone_number?: string;
  last4?: number;
  routing_number?: string;
  bank_account_number?: string;
  account_type?: string;
  transaction_type?: string;
  account_number?: number;
  first_name?: string;
  last_name?: string;
  customer_id?: string;
  sec_code?: string;
  constructor() {
    this.sec_code = 'WEB';
  }
  
}
