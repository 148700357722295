import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-campaigns-details',
  templateUrl: './campaigns-details.component.html',
  styleUrls: ['./campaigns-details.component.css']
})
export class CampaignsDetailsComponent implements OnInit {
  loadFlag: boolean;
  errorMsg: any;
  paramMap: string;
  campaignId: string;
  pageName: string;
  // ===>campaign:{} is the object that is passed from the previous page, which is the campaign summary page, and it is passed as a state; 
  //NO API call is made to get 'campaign' object==end==<
  campaign: {};
  constructor(private router:Router,private route: ActivatedRoute) { 
    this.router = router;
    this.route.paramMap.subscribe(params=>{
      this.campaignId = params.get('id');
    })
     this.campaign = this.router.getCurrentNavigation().extras.state.campaign;
     console.log('deyan',this.campaign)
    this.route.queryParams
      .subscribe(params=>{
        this.pageName = params.page;
      }) 
  }

  ngOnInit(): void {
  }


  cancelBtn(){

    this.router.navigate(['../merchant-bridge/'+this.pageName])
  }
  editBtn(){
    this.router.navigate(['../merchant-bridge/edit-campaign/'+this.campaignId],{queryParams:{id:this.campaignId,page:"campaign-details"}})
  }
}
