import {Component, OnInit} from '@angular/core';
import {MerchantActivationModel} from '../../data/model/MerchantActivationModel/merchant-activation.model';
import {MerchantActivationAction} from './merchant-activation.action';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {CardModel} from '../../data/model/CardModel/card.model';

@Component({
  selector: 'app-merchant-account-activation',
  templateUrl: './merchant-account-activation.component.html',
  styleUrls: ['./merchant-account-activation.component.css']
})
export class MerchantAccountActivationComponent extends MerchantActivationAction implements OnInit {

  merchantActivation: MerchantActivationModel;
  accountNumberCheck: string;
  heading: string;
  idPresent: boolean;
  maxDate = new Date();
  bsValue: Date = new Date();
  countries = AppConstants.countryList;
  states = AppConstants.stateList;
  errorMsg: string;
  errorMsgFlag: boolean;
  card: CardModel;
  loadFlag: boolean;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;

  constructor(dataManager: DataManagerService, route: ActivatedRoute, router: Router) {
    super();
    this.dataManager = dataManager;
    this.merchantActivation = new MerchantActivationModel();
    this.merchantActivation.country_code = this.countries[0].key;
    this.merchantActivation.state = null;
    this.merchantActivation.business_type = 'Individual';
    this.route = route;
    this.router = router;
    this.route.url.subscribe(value => console.log('urls', value[0].path));
    // TODO change url
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this._merchantActivationUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '/' + currentAccountid;

  }

  ngOnInit() {
    console.log('logreport', this.merchantActivation);
    this.idPresent = this.fetchIdFromRouteQueryParams();
    this.heading = this.idPresent ? 'Edit Account' : 'Account application';
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  // validateNumberInput(event) {
  //   return event.search(/^[0-9]{0,3}.?[0-9]{0,3}$/) === 0 ? true : false;
  // }
  EIN(event: any) {
    const pattern = /[0-9-]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  activationPostCall() {
    const postBody = {};
    postBody['country_code'] = this.merchantActivation.country_code;
    postBody['business_type'] = this.merchantActivation.business_type;
    postBody['tax_id'] = this.merchantActivation.tax_id.replace('-', '');
    postBody['address'] = this.merchantActivation.address;
    postBody['city'] = this.merchantActivation.city;
    postBody['state'] = this.merchantActivation.state;
    postBody['zip'] = this.merchantActivation.zip;
    // postBody['name'] = this.merchantActivation.first_name.trim() + this.merchantActivation.last_name.trim();
    postBody['first_name'] = this.merchantActivation.first_name.trim();
    postBody['last_name'] = this.merchantActivation.last_name.trim();
    postBody['dob_month'] = this.merchantActivation.dob.getMonth();
    postBody['dob_date'] = this.merchantActivation.dob.getDate();
    postBody['dob_year'] = this.merchantActivation.dob.getFullYear();
    postBody['ssnlast4'] = this.merchantActivation.ssnlast4;
    postBody['business_name'] = this.merchantActivation.business_name.trim();
    postBody['phone_number'] = this.merchantActivation.phone_number;
    postBody['routing_number'] = this.merchantActivation.routing_number;
    postBody['product_desc'] = this.merchantActivation.product_desc.trim();
    postBody['website_url'] = this.merchantActivation.website_url;
    // postBody['account_type'] = this.merchantActivation.account_type;
    postBody['account_type'] = null;
    postBody['account_name'] = this.merchantActivation.first_name + ' ' + this.merchantActivation.last_name;
    if (this.merchantActivation.account_number === this.accountNumberCheck) {
      postBody['account_number'] = this.merchantActivation.account_number;
    } else {
      alert('Verification for Account number Failed');
      this.loadFlag = false;
      return;
    }
    postBody['phone_country_code'] = '1';
    postBody['phone_number'] = this.merchantActivation.phone_number;
    this.loadFlag = true;
    this.activateMerchant(postBody);
  }

  protected passResponse(res) {
    this.loadFlag = false;
  }

  protected passError(error) {
    this.loadFlag = false;
    console.log(error);
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    this.errorMsgFlag = true;
    // let i;
    // let j;
    // let name = ['currency', 'amount', 'statement_description', 'charge_description',
    //   'capture', 'card_id', 'customer_id', 'health_care', 'health_care_type', 'tip_amount', 'prescription_amount',
    //   'vision_amount', 'clinic_amount', 'dental_amount'];
    // for (i = 0; i < this.keys.length; i++ ) {
    //   for (j = 0; j < name.length; j++) {
    //     if (this.keys[i].match(name[j])) {
    //       document.getElementById(name[j]).style.borderColor = '#a94442';
    //       document.getElementById(name[j] + 1).style.display = 'block';
    //     }
    //   }
    // }
  }


}
