<div class="" id="switch_account">
  <div class="">
    <div class="modal-content main_profile list_switch">
      <div class="modal-header switch_header">
        <div class="bg-white main_panel" >
          <div class="main-head">
            {{activeUser.dba_name ? activeUser.dba_name + ' ' + '-' + ' ' : ''}}{{currentAccountName}}{{activeUser.mid ? ' ' + '-' + ' ' + activeUser.mid : ''}}
          </div>

        </div>

      </div>

      <!-- Modal body -->
      <div class="modal-body p-0">
        <input class=" tool_filter" #myInput placeholder="Filter by name" [(ngModel)]="name"
               (focus)="name = null">
          <div class="account-list" *ngFor="let account of currentUsers | search:'account_name,mid,dba_name':name">

            <a  (touchstart)="switchAccount(account.id, account.account_name)"
                class=" pointer position-relative merchant_list" [class.activeAccount]="account.id === currentAccountId"  >
              {{account.dba_name ? account.dba_name+' '+'-'+' ': ''}} {{account.account_name ? (account.account_name) : account.id}} {{account.mid ? ' '+'-'+' '+ account.mid: ''}}
            </a>
          </div>


    </div>
  </div>
</div>
