import { HttpClient } from '@angular/common/http';
// import * as payeeData from './mock-data-payee.json';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { ResponseWrapper } from '../../data/model/APiWrapper/response-wrapper';


@Injectable()
export class DataService { 
    payeeList:any;
    private getPayeeUrl: string;
        constructor (private http:HttpClient, private dataManager: DataManagerService){ 
             
        }
                    getData():Observable<any> {
                        return this.dataManager.getRequest('./assets/JavaScript/mock-data-payee.json',0)
                    }
                    getPayeeData(url):Observable<any>   {
                        return  this.dataManager.getRequest(url,0)   
                    }

}