import { Component, OnInit } from '@angular/core';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { BaseComponent } from '../../abstract/base.component';


@Component({
  selector: 'app-merchant-bridge-module',
  templateUrl: './merchant-bridge.component.html',
  styleUrls: ['./merchant-bridge.component.css']
})
export class MerchantBridgeComponent extends BaseComponent implements OnInit {

 

}
