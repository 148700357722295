import {Component, OnInit} from '@angular/core';
import {DisputeFormAction} from './dispute-form.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DisputeModel} from '../../../data/model/DisputeModel/dispute.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {MessageDialogComponent} from '../../../dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dsiputes-form',
  templateUrl: './disputes-form.component.html',
  styleUrls: ['./disputes-form.component.css']
})
export class DisputesFormComponent extends DisputeFormAction implements OnInit {

  dispute: DisputeModel;
  fileName: any;
  headers: Headers;
  multipartRequest: FormData = new FormData();
  file: File;
  errorMsg: string;
  errorMsgFlag: boolean;
  urls = [];
  loading: boolean;
  topErrorMsg: string;
  values: String[];
  keys1: String[];
  IDevidence: string;
  totalSize: number;
  constructor(dataManager: DataManagerService, route: ActivatedRoute, router: Router,
              private dialog: MatDialog) {
    super();
    this.dataManager = dataManager;
    this.route = route;
    this.router = router;
    this.headers = new Headers();
    this.headers.append('Content-Type', 'multipart/form-data');
    this.dispute = new DisputeModel();
    this.errorMsgFlag = false;
    this.loadFlag = true;
    this.loading = false;
    this.totalSize = 0 ;
    if (this.fetchIdFromRouteQueryParams()) {
      this._getDisputeDetailUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_DISPUTE + '/' + this.id + '?include=evidences,dispute_subbmissions';
      this._postEvidenceUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_DISPUTE + '/' + this.id + AppConstants.SERVICE_NAME_EVIDENCE;
      this._submitEvidenceUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_DISPUTE + '/' + this.id + '/submit';
    }
    this.getDisputeDetail();

  }

  ngOnInit() {
  }

  selectFile(files: FileList) {
    console.log(files);
    this.file = files[0];
    this.fileName = this.file.name;
    this.multipartRequest.append('image', this.file, this.file.name);
  }

  protected getDisputeResponse(res) {
    this.loadFlag = false;
    this.dispute = res.data;
    this.totalSize = 0;
    console.log('dispute response', this.dispute);
    this.loading = false;
    let i;
    console.log(this.dispute.evidences.data.length, 'sauasuassu');
    for (i = 0 ; i < this.dispute.evidences.data.length ; i++ ) {
      this.totalSize = this.totalSize + Number(this.dispute.evidences.data[i].file_size);
    }
  }
  counter(i: number) {
    return new Array(i);
  }
  deleteFile(id) {
    // if (!this.actionActive) {
    //   this.dataManager.appLoaderFlag(false);
    //   return;
    // }
    // let loader: any = id + 'loader';
    this.IDevidence = id;
    this._deleteCustomerUrl = AppConstants.BASE_URL + '/evidences' + '/' + id;
    const data = {
      head: 'Delete File', message: 'This will permanently remove your uploaded file'
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        this.deleteCustomerFromApi();
      }
    });
  }
  protected getDisputeError(error) {
    this.loadFlag = false;
    this.errorMsg = error.error.message;
    this.topErrorMsg = error.error.errors;
    this.keys1 = Object.keys(this.topErrorMsg);
    this.values = Object.values(this.topErrorMsg);
  }

  protected postEvidenceResponse(res) {
    this.loadFlag = false;
    // this.loading = true;
    this.getDisputeDetail();
    // this.routeNavigate('/disputes/dispute-list');
  }

  protected postEvidenceError(error) {
    this.loadFlag = false;
    this.errorMsg = error.error.message;
    this.topErrorMsg = error.error.errors;
    this.keys1 = Object.keys(this.topErrorMsg);
    this.values = Object.values(this.topErrorMsg);
  }
  protected passDeleteFile(response: any) {
    document.getElementById(this.IDevidence + 'loader').style.display = 'block';
    this.getDisputeDetail();
    // this.router.navigate(['/disputes/dispute-form']);
  }

  protected passFileDeleteError(error: any) {
    document.getElementById(this.IDevidence + 'loader').style.display = 'none';
    this.dataManager.appLoaderFlag(true);
    this.loadFlag = false;
    this.errorMsg = error.error.message;
    this.topErrorMsg = error.error.errors;
    this.keys1 = Object.keys(this.topErrorMsg);
    this.values = Object.values(this.topErrorMsg);
  }
  protected passSubmitResponse(res) {
    this.routeNavigate('/disputes/dispute-list');
    // this.getDisputeDetail();
  }

  protected passSubmitError(error) {
    this.dataManager.appLoaderFlag(true);
    this.loadFlag = false;
    this.errorMsg = error.error.message;
    this.topErrorMsg = error.error.errors;
    this.keys1 = Object.keys(this.topErrorMsg);
    this.values = Object.values(this.topErrorMsg);
  }
  onCancel() {
    this.routeNavigate('/disputes/dispute-list');
  }

  onSave() {
    this.loadFlag = true;
    // this.multipartRequest.append('id', this.dispute.id);
    const postBody = {};
    // alert(this.totalSize );
    postBody['description'] = this.dispute.dispute_subbmissions.data.description;
    if(this.totalSize > 4000000)
    {
      this.errorMsg = 'The file may not be greater than 4 MB.';
      this.loadFlag = false;
    }
    else {
        this.submitApiCall(postBody);
    }
  }
  // upLoad() {
  //   this.postEvidence(this.multipartRequest);
  // }
  onSelectFile(event, file) {
    this.loadFlag = false;
    const fileList: FileList = event.target.files;
    this.fileName = event.target.files;
    console.log(fileList, 'chinu');
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      // document.getElementById('err').innerHTML = '';
      for (let i = 0; i < fileList.length; i++) {
        const reader = new FileReader();

        reader.onload = (events: any) => {
          this.urls.push(file);
          // this.fileSize.push(file.size);
          // console.log(file.name, 'asutosh');
        };
        reader.readAsDataURL(event.target.files[i]);
      }
      this.loading = true;
      this.multipartRequest.append('file', file, file.name);
      console.log('flist', fileList[0].size);
     if ( fileList[0].size > 1048576 ) {

        this.errorMsg = 'The file may not be greater than 1024 kilobytes.';
       this.loading = false;
     } else {
       this.errorMsg = '';
        this.postEvidence(this.multipartRequest);
      }
      // if (file.size > 30000) {
      //   document.getElementById('err').innerHTML = 'file size should be less than & equal to 30kb';
      // } else {
      //
      // }
    }
  }
}
