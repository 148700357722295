import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [LineChartComponent, PieChartComponent, BarChartComponent],
  exports: [LineChartComponent, PieChartComponent, BarChartComponent]
})
export class ChartsModule { }
