import {Component, OnInit} from '@angular/core';
import {GetAccountBrandingAction} from '../account-branding/get-account-branding.action';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {AccountBrandingModel} from '../../data/model/BrandModel/brand.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import {AccountModel} from '../../data/model/AccountModel/account.model';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-account-branding',
  templateUrl: './account-branding.component.html',
  styleUrls: ['./account-branding.component.css'],
})
export class AccountBrandingComponent extends GetAccountBrandingAction implements OnInit {
  load: boolean;
  file: File;
  fileName: any;
  headers: HttpHeaders;
  multipartRequest: FormData = new FormData();
  brandName: string;
  brandgetName: string;
  brandUploadName: string;
  brandUrl: string;
  brandgetUrl: string;
  branduploadUrl: string;
  accntbrandpostservicename: string;
  statement_descriptor: string;
  brandVal: AccountBrandingModel;
  marked: boolean;
  urls = [];
  failure_url: any;
  success_url: any;
  logourl: any;
  url: any;
  is_address_optional: number;
  totalSize: number;
  uploadurl: any;
  isFileUpload: boolean;
  topErrorMessage: string;
  keys: String[];
  keys1: String[];
  disactiveimg: boolean;
  uploadForm: FormGroup;
  activeUser: AccountModel;
  currentUsers: AccountModel[];
  currentAccountId: string;
  addtionalErrorMsg: string;
  csPhoneNumber: string;

  constructor(dataManager: DataManagerService, private _fb: FormBuilder, private MatSnackBar: MatSnackBar) {

    super();
    this.headers = new HttpHeaders();
    // this.headers.append('Content-Type', 'multipart/form-data');
    this.headers.append('Content-Type', 'multipart/form-data');
    this.dataManager = dataManager;
    this.brandVal = new AccountBrandingModel();
    this.brandName = '/accountbranding';
    this.brandUploadName = '/accountbranding';
    this.brandgetName = '/accounts/branding';
    this.brandUrl = AppConstants.BASE_URL + this.brandName;
    this.branduploadUrl = AppConstants.BASE_URL + this.brandUploadName;
    this.brandgetUrl = AppConstants.BASE_URL + this.brandgetName;
    this.getBrand(this.brandgetUrl);
    this.loadFlag = true;
    this.isFileUpload = false;
    this.is_address_optional = 1;
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this.activeUser = new AccountModel();
    this.currentUsers = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));
    this.activeUser = this.currentUsers?.find(x => x.id === this.currentAccountId)?? null;
    this.snackBar = MatSnackBar;
  }

  ngOnInit() {
    const nonWhitespaceRegExp: RegExp = new RegExp('\\S');
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const regs = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const usPhoneNumber = '^[0-9]{10}$';

    this.uploadForm = this._fb.group({
      sucessurl: ['', [Validators.pattern(reg)]],
      failureurl: ['', [Validators.pattern(regs)]],
      file: [''],
      desc: [null, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
      // desc: [null, [Validators.required, Validators.pattern(nonWhitespaceRegExp),Validators.minLength(5)]],
      addressyes: [''],
      addressno: [''],
      csPhoneNumber: ['', Validators.pattern(usPhoneNumber)]
    });
    console.log('current accout',  this.activeUser);
  }

  get sucessurl() {
    return this.uploadForm.get('sucessurl');
  }

  get failureurl() {
    return this.uploadForm.get('failureurl');
  }

  selectFile(files: FileList) {
    console.log(files);
    this.file = files[0];
    this.fileName = this.file.name;
    this.multipartRequest.append('image', this.file, this.file.name);
  }
  copy(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'Copied';
  }
  save() {

    this.load = true;
    const postbody = {};
    postbody['failure_url'] = this.failure_url ? this.failure_url : this.isFileUpload ? console.log(this.failure_url) : null;
    postbody['success_url'] = this.success_url ? this.success_url : this.isFileUpload ? console.log(this.success_url) : null;
    postbody['statement_descriptor'] = this.statement_descriptor;
    postbody['is_address_optional'] = this.is_address_optional;
    postbody['cs_phone_number'] = this.csPhoneNumber;

    if (this.isFileUpload) {
      this.postBrand((this.branduploadUrl), this.multipartRequest, postbody);
    } else {
      // make only patch call
      this.putBrand((this.brandUrl), postbody);
    }
  }


  onSelectFile(event, file) {
    this.loadFlag = false;
    const fileList: FileList = event.target.files;
    this.fileName = event.target.files[0].name;
    console.log(fileList, 'chinu');
    if (fileList.length > 0) {
      this.isFileUpload = true;
      this.file = fileList[0];
      const formData: FormData = new FormData();
      this.multipartRequest.append('file', this.file, this.file.name);
      console.log('flist', fileList[0].size);
      if (fileList[0].size > 2097152) {
        this.errorMsg = 'The maximum size for file upload is 2MB';
        this.disactiveimg = true;
        // event.srcElement.value = null;
      } else if (fileList[0].type !== 'image/jpeg' && fileList[0].type !== 'image/png' && fileList[0].type !== 'image/jpeg') {
        this.errorMsg = 'File type not supported';
        this.disactiveimg = true;
        //  event.srcElement.value = null;
      } else {
        this.errorMsg = '';
        this.disactiveimg = false;

      }
    }
  }

  protected getBrandResponse(res) {
    console.log('authlogiee', res);
    this.loadFlag = false;
    this.load = false;
    this.statement_descriptor = res.data.statement_descriptor;
    this.failure_url = res.data.failure_url;
    this.success_url = res.data.success_url;
    this.is_address_optional = res.data.is_address_optional;
    this.logourl = res.data.logo_url;
    this.errorMsg = null;
    this.topErrorMessage = null;
    this.csPhoneNumber = res.data.cs_phone_number;
  }

  protected getBrandError(err) {

    this.errorMsg = err.message;
    this.loadFlag = false;
    this.load = false;
    console.log(err);
  }

  protected putBrandResponse(res) {
    console.log('params', res);
    this.loadFlag = false;
    this.load = false;
    this.getBrand(this.brandgetUrl);
    this.brandVal = res.data;
    this.errorMsg = null;
    this.showSnackBar('Changes have been saved successfully', 'OK', 3000);
    this.isFileUpload = false;
  }

  protected putBrandError(err) {


    this.errorMsg = err.message;
    if( this.isFileUpload ) {
      this.showSnackBar('Brand logo uploaded successfully.', 'OK', 3000);
    }

    this.topErrorMessage = err.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.loadFlag = false;
    this.load = false;
    this.keys1 = Object.values(this.topErrorMessage);
    let i;
    let j;
    const name = ['sucessurl', 'failureurl'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }

  }

  protected postBrandResponse(res, postbody) {
    console.log('postSettingResponse', res);
    // after success of above post, make a call to update the brandlogo url
    // postbody['logo_url'] = res.url;
    this.putBrand((this.brandUrl), postbody);
    this.logourl = res.url;
  }

  protected postBrandError(err) {
    // this.errorMsg = err.message;
    this.loadFlag = false;
    this.load = false;
    console.log(err);
  }

}
