import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, NavigationExtras, ParamMap, Router, RoutesRecognized} from '@angular/router';
// import {SiginAction} from './sigin.action';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ManualAchInvoicePaymentAction} from './manual-ach-invoice-payment.action';
import {InvoicesModel} from '../../data/model/InvoicesModel/invoices.model';
import {switchMap} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BankAccountModel} from '../../data/model/BankAccountModel/bank-account.model';
import {BankAchChargeModel} from '../../data/model/ACHChargeModel/achcharge.model';
import {ChargeModel} from '../../data/model/ChargeModel/charge.model';



@Component({
  selector: 'app-manual-ach-invoice-payment',
  templateUrl: './manual-ach-invoice-payment.component.html',
  styleUrls: ['./manual-ach-invoice-payment.component.css']
})
export class ManualAchInvoicePaymentComponent extends ManualAchInvoicePaymentAction implements OnInit {
  public manualInvoiceForm : FormGroup;
  @Output() onCvvError = new EventEmitter<boolean>();
  submitted = false;
  cvcError: boolean;
  email: string;
  password: string;
  failureMessage: string;
  forgotpassword: boolean;
  signin: boolean;
  forgot: string;
  _downloadInvoice: string;
  _downloadReceipt: string;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  Location: string;
  selectedChargeId: string;
  ReceievedInvoice: InvoicesModel;
  type: string;
  load: boolean;
  dataFromCardComponent:any;
  category_test: {}[] = [
    {value: 'Personal Checking'},
    {value: 'Personal Savings'},
    {value: 'Business Checking'},
    {value: 'Business Savings'}
];

bank_account: BankAccountModel;
achcharge: BankAchChargeModel;
states = AppConstants.stateList;
countries = AppConstants.countryList;

  constructor(dataManager: DataManagerService, snackBar: MatSnackBar,
              router: Router, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any,
              private _route: ActivatedRoute, private fb: FormBuilder) {
    super();
    this.snackBar = snackBar;
    this.signin = true;
    this.forgotpassword = false;
    this.forgot = '/forgot';
    this.dataManager = dataManager;
    this.router = router;
    this.ReceievedInvoice = new InvoicesModel();

    this.bank_account= new BankAccountModel();
    this.achcharge = new ChargeModel();
    this.achcharge.currency = AppConstants.currencyList[0].name;
    this.achcharge.state = null;
    this.achcharge.country = null;

    this.router.events.subscribe(val => {

      if (val instanceof RoutesRecognized) {
        const id = val.state.root.firstChild.params['id'];
        this._getManualAchInvoiceUrl = AppConstants.BASE_URL + '/pay/' + id;
        this._downloadInvoice = AppConstants.BASE_URL + '/pay/' + id + '/invoice';
        this.getManualACHInvoice(this._getManualAchInvoiceUrl);
        this._downloadReceipt = AppConstants.BASE_URL + '/pay/' + id + '/receipt';
      }
    });


  }
  ngOnInit() {
    this.manualInvoiceForm = this.fb.group({
      accountNumber: ['', [<any>Validators.maxLength(17), <any>Validators.minLength(3)]],
      routingNumber: ['', [<any>Validators.required, <any>Validators.maxLength(9)]],
      account_type: ['', [<any>Validators.required]],
      // amount: ['', [<any>Validators.required, <any>Validators.maxLength(14), <any>Validators.min(0.01)]],
      // currencyInput: ['', [<any>Validators.required]],
      accountholderFirst: ['', [<any>Validators.required]],
      accountholderLast: ['', [<any>Validators.required]],

      street: ['', [<any>Validators.maxLength(200)]],

      city: [''],
      state: [''],
      zip: ['', [<any>Validators.maxLength(15), <any>Validators.minLength(2)]],
      country: [''],
    })
  }

  get f() { return this.manualInvoiceForm.controls;
  }

  payInvoice() {
    this.submitted = true;
    if(this.manualInvoiceForm.status == 'INVALID') {
      return
    } else {
      this.failureMessage = null;
      const achBody = {};

      achBody['account_number'] = this.manualInvoiceForm.value.accountNumber ? this.manualInvoiceForm.value.accountNumber : '';
      achBody['routing_number'] = this.manualInvoiceForm.value.routingNumber ? this.manualInvoiceForm.value.routingNumber : '';
      achBody['street'] = this.manualInvoiceForm.value.street ? this.manualInvoiceForm.value.street : '';
      achBody['country'] = this.manualInvoiceForm.value.country ? this.manualInvoiceForm.value.country : '';
      achBody['state'] = this.manualInvoiceForm.value.state ? this.manualInvoiceForm.value.state : '';
      achBody['city'] = this.manualInvoiceForm.value.city ? this.manualInvoiceForm.value.city : '';
      achBody['zip'] = this.manualInvoiceForm.value.zip ? this.manualInvoiceForm.value.zip : '';
      achBody['account_type'] = this.manualInvoiceForm.value.account_type ? this.manualInvoiceForm.value.account_type : '';

      achBody['first_name'] = this.manualInvoiceForm.value.accountholderFirst ? this.manualInvoiceForm.value.accountholderFirst : '';
      achBody['last_name'] = this.manualInvoiceForm.value.accountholderLast ? this.manualInvoiceForm.value.accountholderLast : '';
      this.achPostApiCall(achBody);
      this.load = true;
    }
  }

  protected passManualACHInvoice(res: any) {
    this.loadFlag = false;
    this.load = false;
    this.ReceievedInvoice = res.data;
  }

  protected passManualInvoiceError(error: any) {
    this.loadFlag = false;
    this.load = false;
    this.errorMsg = error.message;
  }

  protected passAchResponse(charge) {
    if (this.isNullOrUndefined(charge.data.failure_message)) {
      this.loadFlag = true;
    } else {
      this.loadFlag = false;
      this.load = false;
      this.failureMessage = charge.data.failure_message;
    }
  }
  protected passAchError(error) {
    this.loadFlag = false;
    this.load = false;
    // console.log(error);
    this.showSnackBar(error.message, 'Ok', 3000);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.error.errors;
    console.log(this.topErrorMessage);

    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    let i;
    let j;
    const name = ['account_number' , 'city', 'state' , 'zip', 'country', 'routing_number', 'account_type', 'first_name', 'last_name', 'street','card_number','card_cvv','card_expiry_month','card_expiry_year'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  numericOnly(event) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === '-') // dash
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  print(obj) {
    console.log(obj);
  }
}
