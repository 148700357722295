import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import {MenuModel} from '../../data/model/MenuModel/menu.model';
import {FormControl} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {SubMenuModel} from '../../data/model/SubMenuModel/sub-menu.model';
import {BaseComponent} from '../../abstract/base.component';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {FilterConstants} from '../../shared/constants/filter-constants';
import {isPlatformBrowser, Location} from '@angular/common';
import {AppConstants} from "../../shared/constants/app-constants";
import { SetupVerificationDialogComponent } from '../../dialogs/setup-verification-dialog/setup-verification-dialog.component';
import { ProfileDialogComponent } from '../../dialogs/profile-dialog/profile-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {  Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AccountModel} from '../../data/model/AccountModel/account.model';
import {SwitchAccountDialogComponent} from '../../dialogs/switch-account-dialog/switch-account-dialog.component';
import {UserProfileDialogComponent} from '../../dialogs/user-profile-dialog/user-profile-dialog.component';
@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.css']
})
export class NavDrawerComponent extends BaseComponent implements OnInit, DoCheck {
  @Input() toggleSideNav: boolean;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @Output() menuClicked = new EventEmitter();
  @Output() logoClicked = new EventEmitter();
  sideNavFlag: boolean;
  mode: string;
  arrow = 'fa fa-caret-right';
  side: FormControl;
  _router;
  display1 = 'none';
  currentResolution: number;
  ActiveMenu: string;
  loader: boolean;
  show: boolean;
  type: any;
  // = [];
  currentAccountId: string;
  currentUsers: AccountModel[];
  activeUser: AccountModel;
  is_log: string = this.localStorage.getItem('is_logged');
  logo_img_obj = {
    'default': '../../../../assets/images/payarc-logo.png', 'chabad1': '../../../../assets/images/chabad1.png',
  };
  loginAsMerchant: any;
  get menuName(): any {
    const menu = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_FIRST_MENU));
    return menu;
  }
  get userName(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
    return currentUser1;
  }
  get userNameIcon(): any{
    const iconname = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
    const split = iconname.split(' ');

    return (split.length > 1) ? split[0][0] + split[1][0] : split[0][0];
  }
  get currentAccountName(): any {
    const currentAccountName = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME));
    this.setUserAccounts();
    return currentAccountName;
  }

  constructor(renderer: Renderer2, router: Router, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any, private elRef: ElementRef, private dialog : MatDialog,  private activatedRoute: ActivatedRoute,
              private injector: Injector) {
    super();
    this.dataManager = dataManager;
    this._router = router;
    this.currentResolution = window.screen.width;
    this.menuStyle();
    console.log(window.screen.width, this.mode, window.screen.width > 996 ? 'side' : 'over');
    this.currentAccountId = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this.side = new FormControl(this.mode);
    this.alertDialog = dialog;
    this.currentResolution = window.screen.width;
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('renderer');
        renderer.setProperty(document.body, 'scrollTop', 0);
      }
    });



  }


  ngOnInit() {
    const domainurl = window.location.href;

   this.currentResolution = window.screen.width ;
    const urlfind = domainurl.includes('chabad');
    if (urlfind === true) {
      this.type = 'chabad1';
    } else {
      this.type = 'default';
    }
    if(!JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER)).is_owner) {
      if(this.dataManager.menuArray.find(x => x.name === 'Merchant Profile') ) {
        this.dataManager.menuArray.find(x => x.name === 'Merchant Profile').subMenu.splice(this.dataManager.menuArray.find(x => x.name === 'Merchant Profile').subMenu.findIndex(x => x.name === 'Settings'), 1);
      }
    }
    console.log('recive menu', JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER)).is_owner);

  }
  setUserAccounts() {
    this.currentUsers = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));
    if (!this.isNullOrUndefined(this.currentUsers) && this.currentUsers.length > 0) {
      this.setCurrentAccount();
    }
  }
  setCurrentAccount() {
    this.activeUser = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME));
  }

  ngDoCheck() {

    if (window.screen.width !== this.currentResolution) {
      this.menuStyle();
      this.currentResolution = window.screen.width;
    }
  }
  toggle() {
    this.logoClicked.emit();
    // this.trigger.openMenu();
  }
  onActivate(event) {
   if(!this.matchUrl('/signin')){
     const agent = window.navigator.userAgent.toLowerCase();
     console.log('browser list', agent);
     if(this.is_log === 'true')
     {

       if (/iPhone/.test(navigator.userAgent) || (agent.indexOf('chrome') > -1 && !!(<any>window).chrome))
       {
         document.getElementById('body_contain').style.marginTop = '120px';
         this.localStorage.setItem('is_logged', 'false');
       }
       else if(agent.indexOf('chrome') > -1 && !!(<any>window).chrome){

         document.getElementById('body_contain').style.marginTop = '25px';
         this.localStorage.setItem('is_logged', 'false');
       }

       else {

         document.getElementById('body_contain').style.marginTop = '80px';
         this.localStorage.setItem('is_logged', 'false');
       }
     }
     else {
       if (/iPhone/.test(navigator.userAgent) || (agent.indexOf('chrome') > -1 && !!(<any>window).chrome))
       {
         document.getElementById('body_contain').style.marginTop = '0px';

       }
       else if(agent.indexOf('chrome') > -1 && !!(<any>window).chrome){

         document.getElementById('body_contain').style.marginTop = '0px';
       }
       else {

         document.getElementById('body_contain').style.marginTop = '0px';
       }
     }
   }
    event.scrollTop = 0;
    this.loginAsMerchant = this.localStorage.getItem(AppConstants.STORAGE_KEY_USER) ? JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER)).login_as_merchant : null;
    if(!this.isNullOrUndefined(this.loginAsMerchant) && this.loginAsMerchant == false) {
    this.showSetupVerification();
    } else {
    }
  }

  navigate(navigationPath: string) {
    console.log(navigationPath);
    this._router.navigate([navigationPath]);
  }

  menuClick(menu: MenuModel): void {
    this.ActiveMenu = (menu.name === this.ActiveMenu) ? null : menu.name;
    this.menuClicked.emit();
    console.log('menuClick', this.dataManager.currentUser);
    this._router.navigate([menu.routerLink]);
  }

  clickedSubmenu(subMenu: SubMenuModel) {
    this.menuClicked.emit();
    this._router.navigate([subMenu.routerLink]);
  }

  myMenuClick(menu) {
    this.ActiveMenu = menu.name;
    this.menuClicked.emit();
  }

  clearFiltersFromLocal() {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.removeItem(FilterConstants.SUBSCRIPTION_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.SUBSCRIPTION_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYOUTS_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYOUTS_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.TRANSACTIONS_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.TRANSACTIONS_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.INVOICE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.INVOICE_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.COUPON_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CYCLE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CYCLE_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.DISPUTE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.DISPUTE_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CUSTOMER_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CUSTOMER_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.DISPUTE_REPORT_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.DISPUTE_REPORT_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.REVIEW_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.REVIEW_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_INVOICE_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.PAYMENT_INVOICE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(this.CUSTOMER_DATA);
    }
  }

  menuStyle() {
    this.mode = window.screen.width > 768 ? 'side' : 'over';

  }
  goBack(): void {
    const activatedRoute = this.injector.get(ActivatedRoute);
    this._router.navigate(['..'], { relativeTo: activatedRoute });
  }
  close() {
    this.menuClicked.emit();
    this.sidenav.close();
  }
  showSideNav() {
    this.localStorage.setItem('is_logged', 'false');

      document.getElementById('body_contain').style.marginTop = '0px';
    this.menuClicked.emit();
  //  this.sideNavFlag = window.screen.width > 768 ? true : !this.sideNavFlag;
  }
  showSetupVerification() {
  this.is_2fa_email_verified = this.localStorage.getItem(AppConstants.STORAGE_KEY_USER) ? JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER))['is_2fa_email_verified'] : '';
  this.is_2fa_phone_verified = this.localStorage.getItem(AppConstants.STORAGE_KEY_USER) ? JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER))['is_2fa_phone_verified'] : '';
  if(!this.matchUrl('signin')) {
    if( this.is_2fa_email_verified != '1' ){
    const data = {
      head: 'Set-up 2-Step Verification', message: '2-Step Verification is a proven method for preventing widespread cyberattacks. To protect your privacy and safety, we have activated 2-step verification for all accounts', info: 'Please take a few minutes to set up your account.'
    };
    const dialog = this.dialog.open(SetupVerificationDialogComponent, {data, disableClose: true });
    dialog.afterClosed().subscribe(value => {
      if(value == true) {
        this.loadFlag = false;
        const profileDialog = this.dialog.open(ProfileDialogComponent, {disableClose: true});
        profileDialog.afterClosed().subscribe(value => {
          if(value) {
            this.showSetupVerification();
          }
        })
      }
    })
  }
  }
}
  switchAccountMob() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(SwitchAccountDialogComponent, dialogConfig)
      .afterClosed();

  }
  userProfile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(UserProfileDialogComponent, dialogConfig)
      .afterClosed();

  }
}
