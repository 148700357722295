import { BaseListComponent } from "../../../abstract/base-list.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class AddNewPayeeAction extends BaseListComponent {
  protected _addPayeeToCustomerUrl: string;

  createPayeeOnApi(payeeBody) {
    this.loadFlag = true;
    this.dataManager
      .postRequest(this._addPayeeToCustomerUrl, payeeBody, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passResponse(response);
        },
        (error) => {
          console.log(error);
          this.loadFlag = false;
          this.passError(error.error);
        }
      );
    }

    callCampaignListApi() {
      this.loadFlag = true;
      this.dataManager.getRequest(this.getListUrl,0)
        .subscribe(res=>{
          this.loadFlag = false;
          this.passSourceResponse(res);
        },
        error =>{
          this.loadFlag = false;
          this.getListErr(error)
        })
    }
  protected abstract passSourceResponse(res);
  protected abstract passResponse(response);

  protected  passError(error){};
  protected  getListRes(res){};

  protected  getListErr(err){};

}
