import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {DataManagerService} from "../../data/services/DataManager/data-manager.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UpdateContactDialogAction} from "./update-contact-dialog.action";
import {AppConstants} from "../../shared/constants/app-constants";

@Component({
  selector: 'app-update-contact-dialog',
  templateUrl: './update-contact-dialog.component.html',
  styleUrls: ['./update-contact-dialog.component.css', '../common-css-for-dialog/common-dialog-stylesheet.css']
})
export class UpdateContactDialogComponent extends UpdateContactDialogAction implements OnInit {
  phone: string;
  user;
  constructor(private dialogRef: MatDialogRef<UpdateContactDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any,) {
    super();
    this.dataManager = dataManager;
    this.user = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER));
    if (this.user.notification_phone_number) {
      console.log(this.user.notification_phone_number);
      const phone = this.user.notification_phone_number.toString().match(/(\d{3})(\d{3})(\d{4})/);
      this.phone = '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
    }
    this.user.id = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_ID));
    console.log(this.user, JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_ID)));
    this._patchContactUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_USERS + '/' + this.user.id;
  }

  ngOnInit() {
  }

  toUpdate() {
    const postbody = {};
    postbody['notification_phone_number'] = this.phone ? this.phone.replace(/\D+/g, '') : console.log(this.phone);
    this.patchContact(postbody);

  }
  protected passPatchContactErr(err) {
  }

  protected passPatchContactRes(res) {

    console.log('passPatchContactRes',  res);
     this.localStorage.removeItem(AppConstants.STORAGE_KEY_USER);
     this.localStorage.setItem(AppConstants.STORAGE_KEY_USER, JSON.stringify(res.data));
    console.log('passPatchContactRes', this.localStorage.getItem(AppConstants.STORAGE_KEY_USER));
    this.dialogRef.close(true);
  }

}
