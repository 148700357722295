<div class="container-fluid mt-sm-5 mt-2 pt-sm-o pt-4 background-gray" [class.padding-120]="this.matchUrl('/subscription/create-customer')">
  <div class="row b_shadow">
    <div class="col-sm-12 col-12 col-md-12 col-lg-12 header create-header bg-infos">
        {{heading}}
    </div>
    <div class="col-sm-12 col-12 col-md-12 col-lg-12 pl-md-4 pl-sm-0 pl-0 pr-0 pr-sm-0 pr-md-4 mt-4">
      <ng-container *ngIf="topErrorMessage">
        <div class="row err" *ngFor="let obj of keys1 ">
          <div class="col-md-12 mb-1" *ngFor="let value of obj ">
            <p class="col-md-12 error" id="err">{{value}}</p>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="!topErrorMessage">
        <div class="col-md-12 error">
          {{errorMsg}}
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 padding-m" >
        <form [formGroup]="updateCustomerDetailForm" #updateCustomerDetail='ngForm' (ngSubmit)="updateCustomerDetail.invalid ? console.log(updateCustomerDetailForm) : setCustomerUpdate()">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                  <p class="head">Account information</p>
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-5 col-12">Name on the Account:<i class="required-icon">*</i></label>
                <span class="error_sign" id="name1">!</span>
                <div class="col-sm-7 col-12">
                  <input class="form-control" type="text" formControlName="name"
                         [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['name'].invalid" [(ngModel)]="customer.name"
                         name="name" id="name" [attr.disabled]="customerCreatedFlag"
                         value="" maxlength="200" required/>
                  <span class="validation-err-msg"
                        *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['name'].errors?.required">Name is required.</span>
                  <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['name'].errors?.pattern">Name is invalid.</span>
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-5 col-12">Email address:<i class="required-icon">*</i></label>
                <span class="error_sign" id="email1">!</span>
                <div class="col-sm-7 col-12">
                  <input class="form-control" type="email" formControlName="email" [(ngModel)]="customer.email" [attr.disabled]="customerCreatedFlag"
                         name="email" id="email" [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['email'].invalid"/>
                  <span class="validation-err-msg"
                        *ngIf="updateCustomerDetail.submitted && (updateCustomerDetailForm.controls['email'].errors?.email || updateCustomerDetailForm.controls['email'].errors?.pattern) &&  !updateCustomerDetailForm.controls['email'].errors?.required">Email address is invalid.</span>
                  <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['email'].errors?.required">Email address is required.</span>
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-5 col-12">Description:</label>
                <span class="error_sign" id="description1">!</span>
                <div class="col-sm-7 col-12">
              <textarea class="form-control" name="desc" id="description" formControlName='desc' [(ngModel)]="customer.description" [attr.disabled]="customerCreatedFlag"
                        type="text" [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['desc'].invalid" >
              </textarea>
                  <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['desc'].invalid">Description max limit exceed.</span>
                </div>
              </div>
              <div class="row mt-sm-0 mt-4">
                <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                  <p class="head">Contact preferences</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="opt-cnt pl-2">If the customer opts-in to receive email receipts or invoices, they will be sent to the
                    following email addresses:</p>
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-5 col-12 col-form-label">Send email to:</label>
                <span class="error_sign" id="send_email_address1">!</span>
                <div class="col-sm-7 col-12">
                  <input class="form-control" name="to_email" id="send_email_address" formControlName='to_email'
                         [(ngModel)]='customer.send_email_address' [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['to_email'].invalid"
                         type="email" value="" [attr.disabled]="customerCreatedFlag" >
                  <span class="validation-err-msg"
                        *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['to_email'].invalid">Email address is invalid.</span>

                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-5 col-12 col-form-label">CC:</label>
                <span class="error_sign" id="cc_email_address1">!</span>
                <div class="col-sm-7 col-12">
                  <input class="form-control" id="cc_email_address" name="cc" formControlName='cc'
                         [(ngModel)]="customer.cc_email_address" [attr.disabled]="customerCreatedFlag"
                         [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['cc'].invalid"
                         type="email"  value="">
                  <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['cc'].invalid">Email address is invalid.</span>
                </div>
              </div>
              <div class="form-group row justify-content-between">
                <label class="col-sm-5 col-12 col-form-label">Phone:</label>
                <span class="error_sign" id="phone1">!</span>
                <div class="col-sm-7 col-12">
                  <div class="row m-0">
                    <span class="input-group-addon last-d col-2 text-center pl-2 pr-2">+1</span>
                      <input class="form-control col-10" id="phone" name="phone" formControlName='phone'
                           placeholder="(000) 000-0000" [attr.disabled]="customerCreatedFlag"
                           [(ngModel)]="customer.phone" type="tel" appPhoneMask maxlength="14" autocomplete="off"
                           [class.invalid]="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['phone'].invalid"
                           />
                           <span class="validation-err-msg phn-err-msg"
                           *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['phone'].invalid">Phone number is invalid.</span>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6" *ngIf="!data">
              <div class="form-group row ">
                <label class="head col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12   padding-rl-0">Payment Type:<i  class="required-icon">*</i></label>
                <div class="col-md-7 col-lg-7 col-sm-7 col-12 col-xl-7 pl-4 mt-2 pl-m-12">
                    <div class="row m-0 col-6" >
                      <div class="col-sm-12 col-12 d-flex justify-content-between p-0">
                         <label class="form-check-label role pb-1">
                          <input class="custom-checkbox  mt-0"  type="checkbox" [checked]="true" [disabled]="!flagShowHideAch" name="checkbox_payment" value="card"  (change)="setPaymentTypes($event,'card')">
                          Card
                          </label>
                         <label class="form-check-label role pb-1"  *ngIf="enableAch">
                          <input class="custom-checkbox  mt-0" type="checkbox" [disabled]="!flagShowHideCard" name="checkbox_payment" value="ach"   (change)="setPaymentTypes($event,'ach')">
                          ACH
                          </label>

                      </div>

                    </div>

                </div>
              </div>
              <div class="row mt-sm-0 mt-4" *ngIf="flagShowHideAch">
              <div class="col-md-12">
                <p class="head">ACH Details</p>
                <div class="form-group row align-items-center">
                  <label for="account_number" class="col-sm-5 col-12 col-form-label mb-1">Account Number:<i class="required-icon">*</i></label>
                  <span class="error_sign" id="account_number1">!</span>
                  <div class="col-sm-7 col-12">
                    <input class="form-control" value="" name="account_number" id="account_number"
                          type="text" placeholder="••••••••••••••••"
                           formControlName="account_number"
                           maxlength="12" (change)="setAchDetails($event)" >
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['account_number'].errors?.required">Account Number is required.</span>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['account_number'].errors?.pattern">Account Number is invalid.</span>
                  </div>
                </div>

                <div class="form-group row align-items-center">
                  <label for="routing_number" class="col-sm-5 col-12 col-form-label mb-1">Routing Number:<i class="required-icon">*</i></label>
                  <span class="error_sign" id="routing_number_sign">!</span>
                  <div class="col-sm-7 col-12">
                    <input class="form-control" value="" name="routing_number" id="routing_number" [class.invalid]="updateCustomerDetailForm.controls['routing_number'].invalid && updateCustomerDetail.submitted" autocomplete="cc-number" type="text" placeholder="•••••••••••••" formControlName="routing_number"   maxlength="9" (change)="setAchDetails($event)">
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['routing_number'].errors?.required">Routing Number is required.</span>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['routing_number'].errors?.pattern">Routing Number is invalid.</span>
                  </div>
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-4">
                  <label for="card_holder_first_name" class="col-sm-5 col-12 col-form-label">First Name:<i class="required-icon">*</i></label>
                  <span class="error_sign" id="card_holder_first_name">!</span>
                  <div class="col-sm-7 col-12">
                    <input class="form-control" name="first_name"  formControlName="first_name"
                            type="text"
                            value="" maxlength="200" [class.invalid]="updateCustomerDetailForm.controls['first_name'].invalid && updateCustomerDetail.submitted"
                            id="first_name" (change)="setAchDetails($event)"/>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['first_name'].errors?.required">Account First Name is required.</span>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['first_name'].errors?.maxLength">Account First Name limit exceed.</span>
                  </div>
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-4">
                  <label for="card_holder_last_name" class="col-sm-5 col-12 col-form-label">Last Name:<i class="required-icon">*</i></label>
                  <span class="error_sign" id="card_holder_name1">!</span>
                  <div class="col-sm-7 col-12">
                    <input class="form-control" name="last_name" formControlName="last_name"
                            type="text"
                            value="" maxlength="200" [class.invalid]="updateCustomerDetailForm.controls['last_name'].invalid && updateCustomerDetail.submitted"
                            id="last_name" (change)="setAchDetails($event)"/>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['last_name'].errors?.required">Account Last Name is required.</span>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['last_name'].errors?.maxLength">Account Last Name limit exceed.</span>
                  </div>
                </div>
                <div class="form-group row align-items-center mt-sm-0 mt-4">
                  <label class="col-xl-5 col-lg-5 col-md-3 col-sm-3 col-12  pt-2">Account Type: <i class="required-icon">*</i></label>
                  <div class="col-sm-7 col-12">
                    <select class="form-control"  formControlName="account_type" id="account_type" (change)="setAchDetails($event)">
                      <option value="">Select Option</option>
                      <option *ngFor="let category of categories">{{category}}</option>
                    </select>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['account_type'].errors?.required">Account Type is required.</span>
                    <span class="validation-err-msg" *ngIf="updateCustomerDetail.submitted && updateCustomerDetailForm.controls['account_type'].errors?.maxLength">Account Type limit exceed.</span>
                  </div>
                </div>

              </div>
              </div>
              <div class="row mt-sm-0 mt-4" *ngIf="!flagShowHideCard ">
                <div class="col-md-12">
                  <p class="head">Card details</p>
                </div>
              </div>
               <app-customer-add-card-details-section *ngIf="flagShowHideCard" #addCardForm (onFormGroupChange)="setChildForm($event)" [customerCard]="card" (onCustomerCardChange)="onCardData($event)" (onCvvError)="cvcError = $event"></app-customer-add-card-details-section>


            </div>
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-6" *ngIf="data">
              <app-customer-add-card-details-section [showCreditCardFields]="showCreditCardFields" [heading]="heading"  #addCardForm (onFormGroupChange)="setChildForm($event)" [customerCard]="card" (onCustomerCardChange)="onCardData($event)" (onCvvError)="cvcError = $event"></app-customer-add-card-details-section>
            </div>  
          </div>
          <div class="row d_footer float-right mt-sm-0 mt-2">
            <div class="col-lg-12 col-sm-12 col-md-12 pt-3 pb-3">
              <button (click)="cancelBtn()" class="cancel_btn">Cancel</button>
<!--              <app-loading-button-->
<!--                (buttonClick)="childSubmit()"-->
<!--                class="float-right"-->
<!--                [loadFlag]="loadFlag" type="submit" [buttonText]="buttonText"></app-loading-button>-->
              <app-loading-button
                class="float-right"
                [loadFlag]="loadFlag" type="submit" [buttonText]="buttonText"></app-loading-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>  
</div>
