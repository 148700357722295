
<div class="">
  <div class="default_select" (click)="dropFlag = !dropFlag" >
    <span style="width: 100px; " class="card_brand"><img  class="card" [src]="card_img_obj[defaultObject.brand] ? card_img_obj[defaultObject.brand] : '../../../../assets/images/card-default.png'" /></span>
    <span class="table-heading pr-3 pt-2">{{defaultObject.last4digit ? defaultObject.last4digit : '*******'}}</span>
    <span class="table-heading pr-3 pt-2">{{defaultObject.exp_month + '/' + defaultObject.exp_year}}</span>
    <i class="fa pull-right" name="angle-down"></i>
  </div>
  <div class="dropdown-container" [style.display]= " dropFlag ? 'block' : 'none' " >
    <div class="options" *ngFor=" let card of cardList" (click)="clickedCard(card)">
      <span style="width: 100px; " class="card_brand"><img style="width: 40px;" class="card" [src]="card_img_obj[card.brand] ? card_img_obj[card.brand] : '../../../../assets/images/card-default.png'"/></span>
      <span class="table-heading pr-3 pt-2">{{card.last4digit ? card.last4digit : card}}</span>
      <span class="table-heading pr-3 pt-2">{{card.exp_month + '/' + card.exp_year}}</span>
    </div>
  </div>


</div>

