import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payee-details',
  templateUrl: './payee-details.component.html',
  styleUrls: ['./payee-details.component.css']
})
export class PayeeDetailsComponent implements OnInit {
  payeeData:any;
  route: any;
  campaignId: string;

  constructor(private router: Router ,route: ActivatedRoute) {
    this.payeeData = this.router.getCurrentNavigation().extras.state.payee;
    this.router = router;
    this.route = route;
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.campaignId = params['campaign']
    });
  }

  cancelPayeeView(){
    if (this.campaignId !==null && this.campaignId !== undefined && this.campaignId !== '') {

      this.router.navigate(['../merchant-bridge/edit-campaign/'+this.campaignId],)
    } else {
      this.router.navigate(['../merchant-bridge/payee-summary'])
    }
  }


}
