import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'camalizeStr'})
export class Camalize implements PipeTransform {
  transform(value: string, ulFirst = false): string {
    let wordArray = [];
    let temp = value.replace(/_/g, ' ');
    wordArray = temp.split(/ /g);
    temp = '';
    switch (ulFirst) {
      case false:
        wordArray.forEach(word => temp = temp + word.charAt(0).toUpperCase() + word.slice(1) + ' ');
        break;
      case true:
        temp = value.toLowerCase().replace(/_/g, ' ');
        temp = temp.charAt(0).toUpperCase() + temp.slice(1);
    }
    return temp.trim();
  }

}
