  <div class="row no-margin">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <h3 class="headings">Merchant Profile</h3>
    </div>
  </div>
  <div class="brand-bdy">  
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="b_shadow mt-4">
        <div class="heading-card">
          <div class="row left-heading no-margin">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padding-left-heading pl-4 title">
              Account branding information
            </div>
          </div>
        </div>
        <div class="body-container pl-4">
          <ng-container *ngIf="topErrorMessage">
            <div class="row err" *ngFor="let obj of keys1 " >
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-1" *ngFor="let value of obj " >
                <p class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 error" id="err" >{{value}}</p>
              </div>
            </div>
          </ng-container>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 accnt-error mb-1" *ngIf="!topErrorMessage">{{errorMsg}}</div>
          <form [formGroup]="uploadForm">
            <div class="form-group row">
              <label class="col-md-4  col-lg-3 col-3 col-form-label table-heading">MID:</label>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-9 copy">
              {{ this.activeUser.mid}} 
                <span class="tooltiptext" id="myTooltip" (click)="copy(this.activeUser.mid )">Copy</span>
              </div>

            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-3 table-heading col-form-label">Branding logo:</label>
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-9 text-left">
                <div>
                  <span class="file-picke-button" (click)="upload.click()">
                    <svg  viewBox="0 0 384 512" class="svg"><path d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg> Upload logo
                  </span><span class="file-name" *ngIf="file"> {{file.name}}</span>
                    <input type="file"  #upload (change)="onSelectFile($event, upload)" name="file" class="d-none"
                           accept=".jpg,.png,.jpeg" formControlName="file">

                </div>
                <div class="loader loader--style8" title="loading" *ngIf="loadFlag">
                  <img src="../../../assets/images/uploading.svg">
                </div>
                <div class="mt-1 col d-sm-flex d-none">
                  <small class="max-file-size pl-0">File size should not exceed 2MB. File format should be png, jpg, or jpeg.</small>
                </div>
                <div *ngIf="logourl!=null" class="logo-sec">
                  <img class="logo-url" src="{{logourl}}">
                </div>
              </div>
              <div class="d-sm-none d-flex col-12 pr-0">
                <small class="max-file-size pl-0">File size should not exceed 2MB. File format should be png, jpg, or jpeg.</small>
              </div>
            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading">Success redirect URL:</label>
              <span class="error_sign" id="sucessurl">!</span>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <input class="form-control" [(ngModel)]="success_url" type="text" value="" name="sucessurl" id="sucessurl" data-toggle="tooltip" title="After successfull payment from checkout form, customer will be redirect to this url" formControlName="sucessurl" />
              </div>
              <div class="err_url" *ngIf="sucessurl.value && sucessurl.invalid">
                Not a valid URL
              </div>
            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 col-form-label table-heading">Failure redirect URL:</label>
              <span class="error_sign" id="failureurl">!</span>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <input class="form-control" [(ngModel)]="failure_url" type="text" value="" name="failureurl" id="failureurl" data-toggle="tooltip" title="If there is a failure in payment from, checkout form customer will be redirect to this url" formControlName="failureurl"
                />
              </div>
              <div class="err_url" *ngIf="failureurl.value && failureurl.invalid">
                Not a valid URL
              </div>
            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 col-form-label table-heading">Statement description:<i class="required-icon">*</i></label>
              <span class="error_sign" id="description1">!</span>
              <div class="col-md-6 col-12">
                <input class="form-control" [(ngModel)]="statement_descriptor" required type="text" value="" name="desc" id="desc" formControlName="desc" maxlength="25" />
                <div class="mt-1">
                <small class="max-file-size pl-0">This value will appear on your customer's credit card statement.</small>
                </div>
                </div>
            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 col-form-label table-heading">Customer Service Phone Number:</label>
              <div class="col-md-6 col-12">
                <input class="form-control" [(ngModel)]="csPhoneNumber" mask="(000) 000-0000" type="text" value="" name="cs_phone_number" id="cs_phone_number" formControlName="csPhoneNumber" maxlength="25" />
                <div class="mt-1">
                  <small class="max-file-size pl-0">This number will appear on receipts emailed to the customer.</small>
                  <div *ngIf="uploadForm.get('csPhoneNumber').invalid && uploadForm.get('csPhoneNumber').touched" class="text-danger">
                    <small *ngIf="uploadForm.get('csPhoneNumber').errors.pattern">Phone number is invalid. Please enter a valid 10-digit number.</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row padding-m">
              <label class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-9 table-heading col-form-label">Capture address information:</label>
              <div class="col-3 col-sm-1 ml-md-5 ml-lg-0 yes-align">
                <label class="check-box-container">

                  <input [(ngModel)]="is_address_optional"  type="radio" [value]=0  checked  name="addressyes" id="addressyes"
                         formControlName="addressyes" />
                  <span class="checkmark"></span>
                  Yes
                </label>
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 no-align">
                <label class="check-box-container">
                  <input  [(ngModel)]="is_address_optional" type="radio" [value]=1 name="addressno" id="addressno"
                          formControlName="addressno" />
                  <span class="checkmark"></span>
                  No
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bt-align">
                <app-loading-button class="float-right-m" (buttonClick)="save()" [disable]="disactiveimg || uploadForm.invalid"  [loadFlag]="load" [buttonWidth]="'111px'"  [buttonText]="'Save'"></app-loading-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
    </div>
