
<div class="new-campaign-bdy">
    <div class="heading-card">
        <div class="row left-heading no-margin">
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 padding-left-heading pl-4 title">
            <h1>Payee Details</h1>
            <h3>ID:  {{payeeData.id}}</h3>
            <h5 *ngIf="payeeData.campaigns!==undefined">Campaign: {{payeeData.campaigns.data[0].name}}</h5>
          </div>
          <!-- <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 padding-left-heading pl-4 title">
          <a (click)="deleteCampaign(campaign.id)"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffa8a8" viewBox="0 0 20 20" aria-labelledby="delete" role="presentation" class="fill-current"><path fill-rule="nonzero" d="M6 4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6H1a1 1 0 1 1 0-2h5zM4 6v12h12V6H4zm8-2V2H8v2h4zM8 8a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path></svg></a>
          </div> -->
        </div>
      </div>
      <div class="row d-flex b_shadow justify-content-center background_white m-0 p-4">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
          <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5">
            <button class="cancel_btn" (click)="cancelPayeeView()">Go Back</button>
           <!-- <app-loading-button  (buttonClick)="campaign.invalid ? print(campaign) : submitCampaignEdited()"   [buttonText]="buttonText">
           </app-loading-button>   -->
         </div>
          <!-- <ng-container *ngIf="topErrorMessage">
            <div class="row err" *ngFor="let obj of keys1 ">
               <div class="col-md-12 col-lg-12 col-12 col-sm-12 col-xl-12" *ngFor="let value of obj ">
                  <p class="error" id="err">{{value}}</p>
               </div>
            </div>
         </ng-container>
         <div class="row" *ngIf="!topErrorMessage">
            <div class="error "> {{errorMsg}}</div>
         </div> --> 
       
          <div class="form-group row pt-m-18 mt-3">
            <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="name">Payee First Name</label>
            <div class="col-sm-7 col-12 px-m-0">
                     {{payeeData.first_name | titlecase}}
                  </div>
                </div>
                <div class="form-group row pt-m-18 mt-3 mb-6">
                  <label class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="campaignDesc">Payee Last Name:</label>
                  <div class="col-sm-7 col-12 px-m-0">
                    {{payeeData.last_name | titlecase}}
                  </div>
            </div>
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Payee emeil:</label>
                <div class="col-sm-7 col-12 px-m-0">
                  {{payeeData.email}}
                  
                </div>
              </div>
              <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Type:</label>
                <div class="col-sm-7 col-12 px-m-0">   {{payeeData.type |titlecase}}            
                </div>
                
              </div>
            <div [ngSwitch]="payeeData.type" class="form-group row pt-m-18  pt-4 pt-sm-4">
              <label *ngSwitchCase="'percent'"  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Percent: </label>
              <div *ngSwitchCase="'percent'" class="col-sm-7 col-12 px-m-0"> 
                {{payeeData.percent}} %
              </div>
              <label *ngSwitchCase="'fixed'"  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Amount: </label>
              <div *ngSwitchCase="'fixed'" class="col-sm-7 col-12 px-m-0"> 
                $ {{payeeData.fixed_amount}}
              </div>    
                <label  *ngSwitchDefault class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Percent: </label>
                <div  *ngSwitchDefault  class="col-sm-7 col-12 px-m-0"> 
                  {{payeeData.percent}} %
                </div>
                <label *ngSwitchDefault  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Amount: </label>
                <div *ngSwitchDefault class="col-sm-7 col-12 px-m-0"> 
                  $ {{payeeData.fixed_amount}}
                </div> 
            </div> 
            <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Status:</label>
                <div class="col-sm-7 col-12 px-m-0">   {{payeeData.status | titlecase}}            
                </div>
              </div>
              <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Dashboard Access:</label>
                <div class="col-sm-7 col-12 px-m-0">   {{payeeData.dashboard_access? 'Yes' : 'No'}}            
                </div>
              </div>
              <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Created at:</label>
                <div class="col-sm-7 col-12 px-m-0">   {{payeeData.created_at ? (payeeData.created_at | date: 'MM/dd/yy hh:mm a') : 'No'}}  --- {{payeeData.readable_created_at  }}                   
                </div>
              </div>
              <div class="form-group row pt-m-18  pt-4 pt-sm-4">
                <label  class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12  col-form-label table-heading" for="notes">Updated at:</label>
                <div class="col-sm-7 col-12 px-m-0">   {{payeeData.created_at ? (payeeData.updated_at | date: 'MM/dd/yy hh:mm a') : 'No'}}    --- {{payeeData.readable_updated_at  }}         
                </div>
              </div>
          </div>
        </div>
        <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0 background_white p-5 col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8">
           <button class="cancel_btn" (click)="cancelPayeeView()">Go Back</button>
          <!-- <app-loading-button  (buttonClick)="campaign.invalid ? print(campaign) : submitCampaignEdited()"   [buttonText]="buttonText">
          </app-loading-button>   -->
        </div>
        
           
  </div>
  
