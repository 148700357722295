<app-details-loader *ngIf="loadFlag || errorMsg && !collectedfeesObj" [errorMsg]="errorMsg" [Height]="'70vh'" [LoaderMarginTop]="'19%'"
                    ></app-details-loader>
<ng-container *ngIf="!loadFlag && !errorMsg">
  <div class="containerx cust_detail_block" *ngIf="collectedfeesObj">

    <div class="container-fluid cust_padding">

      <div class="row">

        <div class="col-md-12 no-padding">
          <div class="row">
            <div class="col-md-12 no-padding">
              <span class="head-email">{{collectedfeesObj.fee_amount ? '$' + collectedfeesObj.fee_amount/100 + 'USD' : '---'}} </span>
              <span class="refunded">Refunded</span>
            </div>
          </div>

          <div class="row mb-4">

          </div>

          <mat-card class=" container-card">

            <mat-card class="heading-card col-md-12">

              <div class="row">

                <div class="col-md-9 left-heading">

                  <a>Application fee details</a>

                </div>

              </div>

            </mat-card>
            <div class="box details">
              <div class="row">
                <div class="col-3">
                  <span class="bold">Amount:</span>
                </div>
                <div class="col-3">
                  <span>{{collectedfeesObj.fee_amount ? '$' + collectedfeesObj.fee_amount/100 + 'USD' : '---'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <span class="bold">Refunded:</span>
                </div>
                <div class="col-3">
                  <span>{{collectedfeesObj.amount_refunded ? '$' + collectedfeesObj.amount_refunded/100 + 'USD' : '---'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <span class="bold">ID:</span>
                </div>
                <div class="col-3">
                  <span>{{collectedfeesObj.id ? collectedfeesObj.id : '---'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <span class="bold">Date:</span>
                </div>
                <div class="col-3">
                  <span class="date">{{collectedfeesObj.created_at ? collectedfeesObj.created_at : '-- / -- / --'}}</span>
                </div>
              </div>

            </div>
            <mat-card class="heading-card col-md-12">

              <div class="row">

                <div class="col-md-6 left-heading">

                  <a>Account details</a>

                </div>

              </div>

            </mat-card>
            <div class="box destination" >
              <div class="row">
                <div class="col-4">
                  <span class="bold">ID:</span>
                </div>
                <div class="col-8">
                  <span>{{collectedfeesObj.account.id}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <span class="bold">Email:</span>
                </div>
                <div class="col-8">
                  <span>{{collectedfeesObj.account.email}}</span>
                </div>
              </div>
            </div>

            <mat-card class="heading-card col-md-12">

              <div class="row">

                <div class="col-md-6 left-heading">

                  <a>Charge details</a>

                </div>

              </div>

            </mat-card>
            <div class="box charge" *ngIf="!collectedfeesObj.charge">
              <div class="row">
                <div class="col-md-12 center">

                  <div>
                    <span >No Charge details </span>
                  </div>

                </div>
              </div>
            </div>
            <div class="box charge" *ngIf="collectedfeesObj.charge">
              <div class="row">
                <div class="col-4">
                  <span class="bold">ID:</span>
                </div>
                <div class="col-8">
                  <span>{{collectedfeesObj.charge.id}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <span class="bold">Amount:</span>
                </div>
                <div class="col-8">
                  <span>{{collectedfeesObj.charge.amount ? '$' + collectedfeesObj.charge.amount + 'USD': '--'}} USD</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <span class="bold">Description:</span>
                </div>
                <div class="col-8">
                  <span class="text-muted"><em>{{collectedfeesObj.charge.charge_description}}</em></span>
                </div>
              </div>
            </div>


          </mat-card>


        </div>

      </div>

    </div>
  </div>
</ng-container>
