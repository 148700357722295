import {BaseComponent} from '../../abstract/base.component';
import {ErrorConstants} from '../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class ManualInvoicePaymentAction extends BaseComponent {
  protected _getManualInvoiceUrl: string;
  protected  _createCardUrl: string;

  protected getManualInvoice(url) {
    this.loadFlag = true;
    console.log(this._getManualInvoiceUrl);
    this.dataManager.getRequest(url, 0)
      .subscribe(
        (response) => {
          this.passManualInvoice(response);
          if (response.data.length === 0) {
            this.errorMsg = ErrorConstants.paymentDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.paymentDetailErrorMsg);
          this.passManualInvoice(error); }
      );
  }

  protected abstract passManualInvoice(paymentDetails);

  protected abstract passManualInvoiceError(error);

  protected cardApiCall(postBody) {
    this.dataManager.postRequest(this._createCardUrl, postBody, 0)
      .subscribe(
        (response) => this.passCardResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passCardError(error.error);
        }
      );
  }
  protected abstract passCardResponse(charge);
  protected abstract passCardError(error);


}
