import {BaseComponent} from '../../../abstract/base.component';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {EventModel} from '../../../data/model/EventModel/Event.model';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class EventListAction extends BaseComponent {

  protected _eventListUrl: string;

  getEventListData () {
    this.loadFlag = true;
    this.dataManager.getRequest(this._eventListUrl, 0)
      .subscribe(
        (response) => {
          this.passEventList(response);
          if (response.data.length === 0) {
            this.errorMsg = ErrorConstants.eventDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.eventDetailErrorMsg);
          this.passError(error);
        }
      );
  }

  protected abstract passEventList(eventResponse: ApiResponseModel<EventModel>);

  protected abstract passError(error: any);
}
