import { BaseComponent } from '../../abstract/base.component';
import { ErrorConstants } from '../../shared/constants/error-constants';
import {BaseListComponent} from '../../abstract/base-list.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class GetStatementsAction extends BaseListComponent {

  protected statementUrl: string;
  protected _getstatementPdf: string;
  protected _getstatementPdfView: string;
  protected abstract passStatementPdfResponse(res);
  protected abstract passStatementPdfError(err);
  protected abstract passStatementPdfViewResponse(res);
  protected abstract passStatementPdfViewError(err);
 // protected abstract passstatementPdfComplete();


  // protected getStatementsListData(url) {
  //   console.log(url)
  //   this.dataManager.getRequest(url, 0)
  //     .subscribe(
  //       (response) => {
  //         console.log("res", response)
  //         this.passStatementList(response);
  //         if (response.data == '') {
  //           this.errorMsg = ErrorConstants.statementErrorMsg[0];
  //         } else {
  //           this.errorMsg = null;
  //         }
  //       },
  //       error => {
  //         this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.statementErrorMsg);
  //         this.passStatementError(error);
  //       }
  //     );
  // }

  getStatementPdf() {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(this._getstatementPdf, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passStatementPdfResponse((new Blob([res], { type: 'application/pdf' })));
      },
      (err) => {
        this.loadFlag = false;
        this.passStatementPdfError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
  getStatementPdfView() {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(this._getstatementPdfView, 0).subscribe(
      (res) => {
        this.loadFlag = false;
        this.passStatementPdfViewResponse((new Blob([res], { type: 'application/pdf' })));
      },
      (err) => {
        this.loadFlag = false;
        this.passStatementPdfViewError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
}
