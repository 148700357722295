<app-details-loader *ngIf="loadFlag || errorMsg && !accountDetails" [Height]="'70vh'" [LoaderMarginTop]="'19%'"
                    ></app-details-loader>
<div class="row" *ngIf="!loadFlag && errorMsg ">
  <div class="col-md-12 error" >{{errorMsg}}</div><!-- / .col-sm-12 .col-md-6-->
</div>
<div class="containerx cust_detail_block" *ngIf="!loadFlag">

  <div class="container-fluid cust_padding">
      <!-- / .row -->
    <div class="row">
      <div class="col-md-12 no-padding">
        <div class="row">
          <div class="col-md-12 no-padding">
            <span class="head-email">Bridged account</span>
          </div>
        </div>

        <div class="row mb-4">

          <div class="col-md-8 no-padding">

            <span class="head-text" >{{accountDetails.account_name}}</span>

            <!--<span class="head-text" *ngIf="customer.id">{{customer.id}}</span>-->

          </div>

          <div class="col-md-4 text-right">
            <div class="">
              <span>{{accountDetails.id}}</span>
              <button class="btn ellipsis"><i class="fa fa-ellipsis-h"></i></button>
            </div>
          </div>

        </div>

        <mat-card class=" container-card">
          <!--<mat-card class="col-md-12 ">-->
          <!--<div class="row">-->
          <!--<div class="col-md-2 right-separator">-->
          <!--<div>-->
          <!--<span class="table-heading">Payments</span>-->
          <!--</div>-->
          <!--<div>-->
          <!--<span><i class="fa fa-check-circle" style="font-size:13px;color:green"></i> Enabled</span>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="col-md-2 right-separator">-->
          <!--<div>-->
          <!--<span class="table-heading">Payouts</span>-->
          <!--</div>-->
          <!--<div>-->
          <!--<span><i class="fa fa-check-circle" style="font-size:13px;color:green"></i> Daily</span>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="col-md-2 right-separator">-->
          <!--<div>-->
          <!--<span class="table-heading">Total balance</span>-->
          <!--</div>-->
          <!--<div>-->
          <!--&lt;!&ndash;<span>${{transferObj.amount}} usd</span>&ndash;&gt;-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="col-md-3">-->
          <!--<div>-->
          <!--<span class="table-heading">Life time total volume</span>-->
          <!--</div>-->
          <!--<div>-->
          <!--<span>$0.00 usd</span>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->

          <!--</mat-card>-->

          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-6 left-heading">

                <a>Balance</a>

              </div>

              <div class="col-md-6 right-heading text-right">
                <span class="right-heading green-active funds">
                  <img _ngcontent-c22="" class="icon" src="../../../../assets/images/exchange-blue.png">Send funds
                </span>
                <button class="btn ellipsis ell"><i class="fa fa-ellipsis-h"></i></button>
                <!--<span >-->
                <!---->

                <!--</span>-->

              </div>

            </div>

          </mat-card>

          <div class="row table1">

            <div class="col-md-6 no-padding col-sm-12">

              <div class="row">

                <div class="col-md-4 mb-c-1 no-padding">
                  <div>
                    <span class="table-heading">Total balance</span>
                  </div>
                  <div>
                    <span>$0.00 usd</span>
                  </div>


                </div>
                <div class="col-md-4 mb-c-1 no-padding">
                  <div>
                    <span class="table-heading">Future payouts</span>
                  </div>
                  <div>
                    <span>$0.00 usd</span>
                  </div>

                </div>
                <div class="col-md-4 mb-c-1 no-padding">
                  <div>
                    <span class="table-heading">In transit to bank</span>
                  </div>

                  <div>
                    <span>$0.00 usd</span>
                  </div>

                </div>

              </div>

            </div>


          </div>


          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-12 left-heading">

                <a>Activity</a>

              </div>

            </div>


          </mat-card>
        <div class="row table1">
          <!--<div class="row table1 mt-c-6">-->
            <!--<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">-->
              <!--<li class="nav-item">-->
                <!--<a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Home</a>-->
              <!--</li>-->
              <!--<li class="nav-item">-->
                <!--<a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a>-->
              <!--</li>-->
              <!--<li class="nav-item">-->
                <!--<a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</a>-->
              <!--</li>-->
            <!--</ul>-->
            <!--<div class="tab-content" id="pills-tabContent">-->
              <!--<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">asdsad</div>-->
              <!--<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">gdgfdgd</div>-->
              <!--<div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">rtrter</div>-->
            <!--</div>-->
          <ul class="nav nav-pills navbar-left" role="tablist">
            <li class="nav-item" id="allinvoice">
              <a class="nav-link active" id="invoice_link" data-toggle="pill"href="#pills-invoices2" routerLinkActive="active" >Payouts</a>
            </li>
            <li class="nav-item" id="paid">
              <a class="nav-link" id="paid_link" data-toggle="pill" href="#pills-invoices3" routerLinkActive="active" >Collected fees</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">



            <div class="tab-pane fade show active" id="pills-invoices2" role="tabpanel" aria-labelledby="pills-invoices-tab">

              <!--<ng-container *ngIf="!payoutList && !loadFlag">-->
              <!--<div>-->
              <!--<div class="col-md-12 center">-->

              <!--<div>-->
              <!--<span >No payouts </span>-->
              <!--</div>-->
              <!--<div>-->
              <!--<span>There are no payouts associated with this accounts</span>-->
              <!--</div>-->

              <!--</div>-->
              <!--</div>-->
              <!--</ng-container>-->
              <ng-container >
                <table class="table">
                  <thead>
                  <tr>
                    <th></th>
                    <th>AMOUNT</th>
                    <th>BANK</th>
                    <th>DATE</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="loadFlag">
                    <tr class="loader">
                      <td colspan="7">
                        <div class="loader-container">

                          <img class="table-loader" src="../../../../assets/images/loading.svg">
                        </div>


                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="payoutList.length <= 0">
                    <div>
                      <div class="col-md-12 center">

                        <div>
                          <span >No payouts </span>
                        </div>
                        <div>
                          <span>There are no payouts associated with this accounts</span>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="payoutList">
                    <tr *ngFor="let payout of payoutList;">
                      <td><a [routerLink]="redirect_link_payout" [queryParams]="{id: payout.id}"><img class="icon" src="../../../../assets/images/exchange-blue.png"></a></td>
                      <td><a [routerLink]="redirect_link_payout" [queryParams]="{id: payout.id}">{{payout.amount/100 | currency:'USD':'symbol': '1.2-2'}} USD</a></td>
                      <td><a [routerLink]="redirect_link_payout" [queryParams]="{id: payout.id}">
                        <span class="bankName mr-4">{{payout.bank_account.data.bank_name}}</span>
                        <span
                          class="accNo mr-4">{{payout.bank_account.data.last4? '****' + payout.bank_account.data.last4 : ''}}</span></a>
                      <td><a [routerLink]="redirect_link" [queryParams]="{id: payout.id}">{{payout.arrival_date | dateFormat: 'MM/dd/yyyy'}}</a></td>
                    </tr>
                  </ng-container>
                  <!--<app-message-on-list *ngIf="!loadFlag && errorMsg && payoutList.length <= 0" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>-->

                  <!--pagination start-->

                  <!--pagination end-->
                  </tbody>

                </table>
              </ng-container>
            </div>
            <div class="tab-pane fade" id="pills-invoices3" role="tabpanel" aria-labelledby="pills-invoices-tab">
              <!--<ng-container *ngIf="!loadFlag && !collectedFees">-->
              <!--<div>-->
              <!--<div class="col-md-12 center">-->

              <!--<div>-->
              <!--<span >No collected fees </span>-->
              <!--</div>-->
              <!--<div>-->
              <!--<span>There are no payouts associated with this accounts</span>-->
              <!--</div>-->

              <!--</div>-->
              <!--</div>-->
              <!--</ng-container>-->
              <ng-container >
                <table class="table">
                  <thead>
                  <tr>
                    <th class="event">AMOUNT</th>
                    <th class="id">DESCRIPTION</th>
                    <th class="date">DATE</th>
                  </tr>
                  </thead>
                  <tbody >
                  <ng-container *ngIf="loadFlag">
                    <tr class="loader">
                      <td colspan="7">
                        <div class="loader-container">

                          <img class="table-loader" src="../../../../assets/images/loading.svg">
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="collectedFees.length <= 0">
                    <div>
                      <div class="col-md-12 center">

                        <div>
                          <span >No collected fees </span>
                        </div>
                        <div>
                          <span>There are no collected fees associated with this accounts</span>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                  <!--<app-message-on-list *ngIf="!loadFlag && errorMsg && collectedFees.length <= 0" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>-->
                  <ng-container *ngIf="collectedFees.length > 0">
                    <tr *ngFor="let ApplicationFee of collectedFees;">
                      <td>
                        <a [routerLink]="redirect_link_fees" [queryParams]="{id: ApplicationFee.id}">
                          ${{ApplicationFee.fee_amount ? ApplicationFee.fee_amount/100 : '---'}} USD <span class="refunded">refunded</span>
                        </a>
                      </td>
                      <td>
                        <a [routerLink]="redirect_link_fees" [queryParams]="{id: ApplicationFee.id}">
                          {{ApplicationFee.charge_id ? ApplicationFee.charge_id : '---'}}
                        </a>
                      </td>
                      <td>
                        <a [routerLink]="redirect_link_fees" [queryParams]="{id: ApplicationFee.id}">
                          {{ApplicationFee.created_at ? ApplicationFee.created_at : '-- / -- / --'}}
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>

                </table>


              </ng-container>
            </div>
          </div>
        </div>
          <!-- <ng-container *ngIf="customer.card">

             NO Card for This Customer

           </ng-container>-->

          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-10 left-heading">

                <a>External accounts</a>

              </div>

            </div>

          </mat-card>
          <ng-container >
            <div class="box">
              <div id="external_accounts">
                <div class="col-12 acc">
                  <span class="bold"><img src="" alt="">U.S Bank ...6789 <button class="btn btn-sm">USD</button></span>
                </div>
                <div class="row ">
                  <div class="col-4 acc left">
                    <span class="bold">Fingerprint</span>
                  </div>
                  <div class="col-8">
                    <span>45ad5a55asd255asd55as5sd215as555</span>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-4 acc left">
                    <span class="bold">ID</span>
                  </div>
                  <div class="col-8">
                    <span>ba_asdadj4a565as55cacasjacca1sdvsdvsdfsdfsv4</span>
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <span id="foot">Charges for a currency without an external account will be  converted to the bridged account's default currency, USD</span>
              </div>
            </div>
          </ng-container>

          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-9 left-heading">

                <a>Details</a>

              </div>

            </div>

          </mat-card>
          <div class="box details">
            <div class="row">
              <div class="col-3">
                <span class="bold">ID:</span>
              </div>
              <div class="col-3">
                <span>awdaas445sd6asas_asdsa55</span>
              </div>
              <div class="col-3">
                <span class="bold">Statement descriptor:</span>
              </div>
              <div class="col-3">
                <span>None</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <span class="bold">Type:</span>
              </div>
              <div class="col-3">
                <span>Express</span>
              </div>
              <div class="col-3">
                <span class="bold">Payout Statement descriptor:</span>
              </div>
              <div class="col-3">
                <span>None</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <span class="bold">Email:</span>
              </div>
              <div class="col-3">
                <span>test@test.com</span>
              </div>
              <!--<div class="col-3">-->
              <!--<span>Statement Descriptor</span>-->
              <!--</div>-->
              <!--<div class="col-3">-->
              <!--<span>None</span>-->
              <!--</div>-->
            </div>
            <div class="row">
              <div class="col-3">
                <span class="bold">Country:</span>
              </div>
              <div class="col-3">
                <span>United States <img src="" alt=""></span>
              </div>
              <div class="col-3">
                <span class="bold">Payout Schedule:</span>
              </div>
              <div class="col-3">
                <span>Daily - 2 day rolling basis</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <span class="bold">Default Currency:</span>
              </div>
              <div class="col-3">
                <span>USD</span>
              </div>
              <div class="col-3">
                <span class="bold">Services agreement:</span>
              </div>
              <div class="col-3">
                <span>No recorded agreement</span>
              </div>
            </div>
          </div>

          <!--<mat-card class="heading-card col-md-12">-->

          <!--<div class="row">-->

          <!--<div class="col-md-6 left-heading">-->

          <!--<span>Metadata</span>-->

          <!--</div>-->

          <!--</div>-->

          <!--</mat-card>-->

          <!--<div class="col-md-12 no-data center ">-->
          <!--<span class="table-heading">No metadata</span>-->
          <!--</div>-->


          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-12 left-heading">

                <a>Logs</a>

              </div>


            </div>

          </mat-card>
          <div class="row">
            <div class="col-md-12 no-data table1">
              <div class="inner-details-subsc">
                <app-event [url]="accountEventUrl" [ComponentId]="accountDetails.id"
                           [ComponentIdKey]="'payout_id'"></app-event>
              </div><!-- /.col-md-6 -->
            </div>
          </div>
        </mat-card>


      </div>

    </div>

  </div>
</div>
