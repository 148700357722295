
    <mat-card class="container-card"  [style.height]="Height" [style.margin-top]="MarginFromTop">
      <div class="detail-loader-container" *ngIf="!errorMsg" [style.margin-top]="LoaderMarginTop">
        <img class="detail-loader" [style.width]="loaderImgWidth" src="../../../../assets/images/loading.svg">
      </div>

      <app-message-on-list *ngIf="errorMsg" [message]="errorMsg" [iconName]="errorIconName"></app-message-on-list>

    </mat-card>

