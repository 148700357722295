import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../shared/constants/app-constants';
import { AlertMessageDialogComponent } from '../alert-message-dialog/alert-message-dialog.component';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { SecurityDialogAction } from './security-dialog.action';


@Component({
  selector: 'app-security-code-dialog',
  templateUrl: './security-code-dialog.component.html',
  styleUrls: ['./security-code-dialog.component.css']
})
export class SecurityCodeDialogComponent extends SecurityDialogAction implements OnInit {
  security_code: number;
  user_id: any;
  otpUrl : string = '/2fa-otp';

  constructor(private dialogRef: MatDialogRef<SecurityCodeDialogComponent>,snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, dataManager: DataManagerService, private dialog: MatDialog) {
    super()
    this.dataManager = dataManager;
    this.snackBar = snackBar;    
    this.getOtpUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_SEND_OTP + this.otpUrl;
    this.verifyOtpUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_VERIFY_OTP + this.otpUrl;
    this.resendOtp();
  }
  ngOnInit() {

  }

  verify() {
    let requestPayload = {
      type : this.data.value ? this.data.value : console.log(this.data.value),
      auth_code : this.security_code ? this.security_code : console.log(this.security_code),
      send_address: this.data.response ? this.data.response : console.log(this.data.response)
    }
    this.verifyOtp(requestPayload);
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.verify();
    }
  }
  protected passSendOtpResponse(res) {
    this.loadFlag2 = false;
    this.showSnackBar('OTP sent successfully', 'OK', 3000);

  }
  protected passSendOtpError(error) {
    this.loadFlag2 = false;
    this.errorMsg = error.message;
    const data = {
      message: this.errorMsg, isIcoEnable: true
    };
    const dialog = this.dialog.open(AlertMessageDialogComponent, {data, disableClose: true, width: '500px'});
    dialog.afterClosed().subscribe(value => {
      if(value == true) {
      } else {
       
      }
    });
  }

  protected passverifyOtpResponse(res) {
    this.loadFlag = false;
    this.showSnackBar('OTP verified successfully', 'OK', 3000);
    this.dialogRef.close(res);

  }
  protected passverifyOtpError(error) {
    this.loadFlag =false;
    this.errorMsg = error.message;
  if(this.errorMsg === 'Invalid OTP') {
    const data = {
      message: this.errorMsg, confirmBtn: 'Resend code', cancelBtn: 'Reenter code', isIcoEnable: true
    };
    const dialog = this.dialog.open(MessageDialogComponent, {data, disableClose: true});
    dialog.afterClosed().subscribe(value => {
      if(value == true) {
        this.resendOtp();
      } else {
       
      }
    });
  } else {
    const data = {
      message: this.errorMsg, confirmBtn: 'Resend code', isIcoEnable: true
    };
    const dialog = this.dialog.open(AlertMessageDialogComponent, {data, disableClose: true, width:'500px'});
    dialog.afterClosed().subscribe(value => {
      if(value == true) {
        this.resendOtp();
      } else {    
      }
    });
  }
  }

  resendOtp() {
    this.security_code = null;
    if (isNaN(this.data.response)) {
      this.maskEmail(this.data.response);

    } else {    
      this.maskPhone(this.data.response);
    }
    let requestPayload = {
      type: this.data.value,
      send_address: this.data.response,
    }
    this.sendOtp(requestPayload);
  }
}