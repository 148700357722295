import {Component, EventEmitter, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {AccountListAction} from './account-list-action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {FilterConstants} from '../../../shared/constants/filter-constants';
import {PermissionConstants} from '../../../shared/constants/permission-constants';
import {UserAccountModel} from '../../../data/model/UserAccountModel/user_account.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {CreateNewAccountDialogComponent} from '../../../dialogs/create-new-account-dialog/create-new-account-dialog.component';
import {CreateNewBridgeAccountComponent} from '../../../dialogs/create-new-bridge-account/create-new-bridge-account.component';
import { Subject } from "rxjs";
import { BaseListComponent } from "../../../abstract/base-list.component";
import { ErrorConstants } from "../../../shared/constants/error-constants";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent extends BaseListComponent implements OnInit {
  accounts: ApiResponseModel<UserAccountModel>;
  perPage = 8;
  serviceName = '/caccounts';
  disablePrev: boolean;
  disableNext: boolean;
  totalRecords?: number;
  _mdDialog: MatDialog;
  newResult: EventEmitter<boolean> = new EventEmitter();
  constructor(dataManager: DataManagerService,
              router: Router, @Inject(PLATFORM_ID) private platformId: any, _mdDialog: MatDialog,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.router = router;
    this.errorMsg = null;
    this._mdDialog = _mdDialog;
    this.redirect_link = '/bridge/bridge-account-details';
    this.accounts = new ApiResponseModel<UserAccountModel>();
  // this._getaccount = AppConstants.BASE_URL + this.serviceName;
    this.dataManager = dataManager;
    this.searchHits = new Subject();
    this.getListUrl = AppConstants.BASE_URL + this.serviceName;

    // this.transferComponent.filter = FilterConstants.couponListFilter;
    // this.transferComponent.create_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_COUPON);
    // this.transferComponent.filter_storage_key = FilterConstants.COUPON_FILTER_STORAGE_KEY;
    // this.transferComponent.count_storage_key = FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY;
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      filterUrl => {
        console.log('lsit called', filterUrl);
        this.dataManager.prev_url = filterUrl;
        this.filterPostFix = filterUrl;
        this.setUrl();
      //  this.firstCallToList(filterUrl);
      },
      err => console.log(err)
    );
  }

  // firstCallToList(filterUrl = null) {
  //   this.loadFlag = true;
  //   const url = this._getaccount;
  //   this.getAccountData(url);
  // }
  createAccount() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'inventoryPanel';
    this._mdDialog.open(CreateNewBridgeAccountComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        res => {
          this.newResult.emit(res);
      //    this.firstCallToList();
      this.setUrl();
        }
      );
  }

  ngOnInit() {
    this.searchHits.pipe(debounceTime(600),).subscribe((res) => {
      if (!this.isNullOrUndefined(this.searchText)) {
        this.setUrl();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(this.filter_storage_key)
        && !this.localStorage.getItem(this.count_storage_key)) {
        console.log('first call for list');
      //  this.firstCallToList();
        this.setUrl();
      }
      // else if (this.transferComponent.back_press) {
      //   // console.log('prev url', this.prev_url);
      //   this.firstCallToList(this.dataManager.prev_url);
      // }
    }
    // this.transferComponent.createDialogActionResult.subscribe(
    //   (result) => {
    //     if (result) {
    //       if (!this.localStorage.getItem(FilterConstants.COUPON_FILTER_STORAGE_KEY)
    //         && !this.localStorage.getItem(FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY)) {
    //         console.log('first call for list');
    //         this.firstCallToList();
    //       } else  {
    //         this.firstCallToList(this.filterPostFix);
    //       }
    //     }
    //   }
    // );
  }

  paginate(paginateExtension) {
    this.getList(paginateExtension);
  }

  protected getListRes(response: any) {
    this.loadFlag = false;
    this.accounts = response;
    if (response.data.length === 0) {
      this.errorMsg = ErrorConstants.accountErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
    // this.totalRecords = response.meta.pagination.total;
    // if (response.meta.pagination.links.next) {
    //   this.disableNext = true;
    //   console.log(response.meta.pagination.links.next, this.disableNext);
    // } else {
    //   this.disableNext = false;
    // }
    // if (response.meta.pagination.links.previous) {
    //   this.disablePrev = true;
    //   console.log(response.meta.pagination.links.previous, this.disablePrev);
    // } else {
    //   this.disablePrev = false;
    // }

  }
  protected getListErr(error: any) {
    this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.accountErrorMsg);
    this.loadFlag = false;
  }
}

