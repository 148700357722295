<div class="container delete_block mr-o pl-0 pt-m-0">
  <div class="row d_head" *ngIf="data.head">
    <div class="col-md-12 col-lg-12 dialog-head text-center pl-m-0">
      <div class="heading pl-m-0">{{data.head}}</div>
    </div>
  </div>
  <div class="row justify-content-center mt-5" *ngIf="data.isIcoEnable">
    <img src="../../../assets/images/failure.svg" alt="Failure" title="Failure"/>
  </div>
  <mat-dialog-content class="body delete_cust" fxLayout="row wrap" fxLayoutAlign="center">
    {{data.message}}
  </mat-dialog-content>
  <mat-dialog-actions class="button-container pb-4" fxLayout="row wrap" fxFlexFill fxLayoutAlign="center"
    fxLayoutGap="2%">
    <button class="cancel_button" [ngClass]="{'w-25': data.cancelBtn == 'Reenter code'}" [mat-dialog-close]="false">{{data.cancelBtn ? data.cancelBtn : 'Cancel' }}</button>
    <button class="confirm_btn" [ngClass]="{'w-25': data.confirmBtn == 'Resend code'}" [mat-dialog-close]="true">{{data.confirmBtn ? data.confirmBtn : 'Ok' }}</button>
  </mat-dialog-actions>
</div>
