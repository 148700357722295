import { BaseComponent } from "../../../abstract/base.component";
import { CustomerV2Model } from "../../../data/model/CustomerV2Model/customer-v2.model";
import { Directive } from "@angular/core";


@Directive()
export abstract class CreateCustomerAction extends BaseComponent {
  protected _updateCustomerUrl: string;
  protected _updateDefaultCardUrl: string;
  protected _createCustomerUrl: string;
  protected _addCardToCustomerUrl: string;
  protected _getaccount: string;
  protected _createACHDetailsUrl: string;
  customerCreatedFlag: boolean;

  protected updateCustomer(putBody) {
    this.loadFlag = true;
    this.dataManager.patchRequest(this._updateCustomerUrl, putBody)
      .subscribe(
        (res) => {
          this.loadFlag = false;
          this.passUpdateResponse(res);
        },
        (error) => {
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passUpdatingError(error.error);
        }
      );

  }

  protected abstract passUpdateResponse(res);

  protected abstract passUpdatingError(error);

  protected updateDefaultCard(putBody) {
    this.loadFlag = true;
    this.dataManager.patchRequest(this._updateDefaultCardUrl, putBody)
      .subscribe(
        (res) => {
          this.loadFlag = false;
          this.passDefaultCardResponse(res);
        },
        (error) => {
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passDefaultCardError(error.error);
        }
      );

  }

  protected abstract passDefaultCardResponse(res);

  protected abstract passDefaultCardError(error);

  protected createACHDetails(postBody) {
    this.dataManager
      .postRequest(this._createACHDetailsUrl, postBody, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passDefaultCardResponse(response);
        },
        error => {
          this.networkErrorHandler(error.error);
          this.passError(error.error);
        }
      );
  }
  createNewCustomer (postBody) {
    this.loadFlag = true;
    this.customerCreatedFlag = false;
    this.dataManager
      .postRequest(this._createCustomerUrl, postBody, 0)
      .subscribe(

        (response) => {
          this.loadFlag = false;
          this.customerCreatedFlag = true;
          console.log('success', this.customerCreatedFlag);
          this.passResponse(response);
        },
        error => {
          this.loadFlag = false;
          this.customerCreatedFlag = null;
          console.log('fail', this.customerCreatedFlag);
          this.networkErrorHandler(error.error);
          this.passError(error.error);
        }
      );
  }

  protected abstract passResponse(res: CustomerV2Model);
  protected abstract passError(error: any);


  createCardOnApi(card) {
    this.loadFlag = true;
    this.dataManager
      .postRequest(this._addCardToCustomerUrl, card, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passCreateCardOnApiResponse(response);
        },
        (error) => {
          console.log(error);
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passCreateCardOnApiError(error.error);
        }
      );
  }

  protected abstract passCreateCardOnApiResponse(res);
  protected abstract passCreateCardOnApiError(err);
}
