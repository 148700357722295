import { Component, OnInit } from '@angular/core';
import { DataManagerService } from '../../data/services/DataManager/data-manager.service';
import { EventListAction } from '../event/ApiAction/EventListAction';
import { ApiResponseModel } from '../../data/model/ApiResponseModel/api-response.model';
import { EventModel } from '../../data/model/EventModel/Event.model';
import { AppConstants } from '../../shared/constants/app-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BaseListComponent } from "../../abstract/base-list.component";
import { ErrorConstants } from "../../shared/constants/error-constants";
import { GetEventListingAction } from '../event-listing/get-event-listing.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportConstants } from '../../shared/constants/export-constants';
import { ExportModel } from '../../data/model/ExportModel/export-model';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-event-listing',
  templateUrl: './event-listing.component.html',
  styleUrls: ['./event-listing.component.css']
})
export class EventListingComponent extends GetEventListingAction implements OnInit {
  exportExcel : ExportModel[] = ExportConstants.eventsListExport;
  showExport: boolean = false;
  exportData = {exportServiceName: AppConstants.SERVICE_NAME_EXPORT_DASHBOARD + '/event', redirectUrl : '/events-and-logs', fileName: 'Payarc_Events_Report', heading : 'Event & logs '};
  dataManager: DataManagerService;
  eventList: ApiResponseModel<EventModel>;
  perPage = 20;
  disablePrev: boolean;
  disableNext: boolean;
  eventListUrl: string;
  route: ActivatedRoute;
  excel = 1;
  blob: any;

  constructor(dataManager: DataManagerService, router: Router, route: ActivatedRoute,snackBar: MatSnackBar) {
    super();
    this.snackBar = snackBar;
    this.loadFlag = true;
    this.router = router;
    this.errorMsg = null;
    this.route = route;
    this.redirect_link = '/event-details';
    this.disableNext = false;
    this.disablePrev = false;
    this.searchHits = new Subject();

    if (this.fetchIdAndKeyFromRouteQueryParams()) {
      this.getListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT;
      this.includes = '?' + this.key + '=' + this.id;
      //  this.eventListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?' + this.key + '=' + this.id + '&limit=' + this.limit + '&page=1' ;
    } else {
      // this.eventListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?limit=' + this.limit + '&page=1';
      this.getListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT;
    }
    this.dataManager = dataManager;
    this.eventList = new ApiResponseModel();
    // this.firstEventListCall();
    this.setUrl();
  }

  ngOnInit() {
    this.searchHits.pipe(debounceTime(600),).subscribe((res) => {
      if (!this.isNullOrUndefined(this.searchText)) {
        this.setUrl();
      }
    });
  }
  protected getListRes(eventResponse: ApiResponseModel<EventModel>) {
    console.log('passEventList', eventResponse);
    this.loadFlag = false;
    this.eventList = eventResponse;
    this.metadata = eventResponse.meta.pagination;
    if (eventResponse.data.length === 0) {
      this.errorMsg = ErrorConstants.eventDetailErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
    console.log('eventList', this.eventList);
  }

  protected getListErr(error: any) {
    this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.eventDetailErrorMsg);
    this.loadFlag = false;
  }

  // firstEventListCall () {
  //   this._eventListUrl = this.eventListUrl ;
  //   this.getEventListData();
  // }

  paginate(paginateExtension) {
    this.loadFlag = true;
    this.getList(paginateExtension);
    //console.log(paginateExtension.replace('http:', 'https:'));
    // this._eventListUrl = paginateExtension;
    // this.getEventListData();

  }
  exportexcel() {
    this.showExport = true;
    // this.loadFlag = true;
    // this._geteventexcel = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT+'?limit=' + this.limit + '&page=1'+'&excel=' + this.excel;
    // this.geteventexcel();
    // this.showSnackBar('File is loading.', 'Ok', 3000);
  }

  protected passeventExcelResponse(res: any,) {
    this.blob = new Blob([res], { type: 'application/vnd.ms-excel' });
    var downloadURL = window.URL.createObjectURL(res);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = "Payarc_Event_Report.xls";
    link.click();

  }
  protected passeventExcelError(err: any) {
    console.log(err);
  }

}
