import {Data} from '../ReviewModel/review.model';
import {UploadModal} from '../UploadModel/upload.modal';

/**
 * Created by arun on 20/2/18.
 */
export class DisputeModel {
  reference_number: string;
  transaction_id?: string;
  received_date?: Date ;
  reply_before?: Date ;
  last_updated?: Date ;
  disputed_date?: string;
  accepted_date?: Date ;
  date_range?: string;
  custom_date?: Date;
  amount_won?: number;
  mercahnt_id?: string;
  to_date?: Date;
  notification_sent?: Date;
customer_id?: string;
  id?: string;
  charge_id?: string;
  kind?: string;
  case_id?: string;
  diputed_amount?: number;
  reason?: string;
  status?: string;
  dispute_status?: string;
  transaction_date?: Date ;
  received_on?: Date ;
  evidences?: UploadEvedence;
  dispute_subbmissions?: DisputeEvedenceSubmission;
  reply_by?: Date  ;
  created_at?: Date ;
  updated_at?: Date ;
  // for dispute evidence upload form
  chargeback_id?: string;
  dispute_evidence?: string;
  constructor() {
    this.evidences = new UploadEvedence;
    this.dispute_subbmissions = new DisputeEvedenceSubmission;
  }
}
export class DisputeContainerModel {
  primary_case?: Data<CasesModel>;
  related_cases?: Data<CasesModel[]>;

}
export class UploadEvedence {
  data: UploadModal;

  constructor() {
    this.data = new UploadModal();
  }
}
export class DisputeEvedenceSubmission {
  data: DisputeSubbmissions;

  constructor() {
    this.data = new DisputeSubbmissions();
  }
}
export class DisputeSubbmissions {
  created_at?: Date;
  description?: string;
  file_url?: string;
  id?: string;
  real_id?: string;
  updated_at?: Date;
  length?: number;
}

export class CasesModel {
  id?: string;
  case_number?: string;
  is_primary_case?: boolean;
  transaction_date?: Date;
  responded_date?: Date;
  last_updated_date?: Date;
  case_type?: string;
  merchant_amount?: string;
  reason_desc?: string;
  card_number?: string;
  card_type?: string;
  item_type?: string;
  status?: string;
  resolution_to?: string;
  case_submitted?: boolean;
  case_amount?: number;
  date_posted?: Date;
  report_date?: Date;
  file?: Data<FileModel[]>;
  evidence?: Data<FileModel[]>;
  rdr_indicator?: number;
}

export class FileModel {
  id?: string;
  case_id?: string;
  created_at?: Date;
  updated_at?: Date;
}
