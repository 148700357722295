import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args: string = 'MM/DD/YYYY HH:MM'): string {
    // "MM/dd/yyyy hh:mm"
    const localTime = moment.unix(value).utc().local().format('MM/DD/YYYY hh:mm A');

    return localTime;
      }

}
