import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { CustomersRoutingModule } from './customers-routing/customers.route';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { AddCustomerCardComponent } from './components/add-customer-card/add-customer-card.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { EditCustomerCardComponent } from './components/edit-customer-card/edit-customer-card.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {enGbLocale} from 'ngx-bootstrap/locale';
import { AddBankAccountComponent } from './components/add-bank-account/add-bank-account.component';
import { EditBankAccountComponent } from './components/edit-bank-account/edit-bank-account.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AgmCoreModule} from '@agm/core';
import {AppConstants} from '../shared/constants/app-constants';
defineLocale('en-gb', enGbLocale);
@NgModule({
  imports: [
    CommonModule,
    CustomersRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
          apiKey: AppConstants.GOOGLE_MAPS_API_KEY,
          libraries: ['places']
        }),
    GooglePlaceModule,
  ],
  declarations: [CustomersComponent, CustomerDetailsComponent ,CustomerListComponent, AddCustomerCardComponent, EditCustomerCardComponent, AddBankAccountComponent, EditBankAccountComponent ],
  providers: [CustomersComponent],

  exports: [EditCustomerCardComponent]
})
export class CustomersModule { }
