<mat-toolbar class="toolbar">

  <div class="offset-xl-6 offset-lg-6 offset-md-6 offset-sm-6 col-md-5 col-sm-5 col-10 unselectable switchAccountIcon" >
    <div class="loader loader--style8" title="loading" *ngIf="!currentAccountName && menuName == 'Home'"
         style="float: right;">
      <img src="../../../assets/images/uploading.svg">
    </div>
    <div class="dropdown pr-m-0" style=" z-index: 999999;" *ngIf="currentAccountName">
      <button class="btn text-truncate" type="button" id="dropdownMenu"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{activeUser.dba_name ? activeUser.dba_name : activeUser.mid}}

        <i class="fa fa-angle-down down_icon"></i>

      </button>
      <div class="dropdown-menu scrollable-menu shadow bg-white" aria-labelledby="dropdownMenu" id="switchMenu">
        <div class="main-head">
        {{activeUser.dba_name ? activeUser.dba_name + ' ' + '-' + ' ' : ''}}{{currentAccountName}}{{activeUser.mid ? ' ' + '-' + ' ' + activeUser.mid : ''}}
        </div>
          <input class="dropdown-item tool_filter" #myInput placeholder="Filter by name" [(ngModel)]="name"
               (focus)="name = null">
        <div *ngIf="this.currentResolution < 1024">
          <ng-container *ngFor="let account of currentUsers | search:'account_name,mid,dba_name':name">
            <a (touchstart)="switchAccount(account.id, account.account_name)"
               class="dropdown-item pointer position-relative merchant_list" [class.activeAccount]="account.id === currentAccountId">
              {{account.dba_name ? account.dba_name + ' ' + '-' + ' ' : ''}} {{account.account_name ? (account.account_name) : account.id}} {{account.mid ? ' ' + '-' + ' ' + account.mid : ''}}
            </a>
          </ng-container>
        </div>
        <div *ngIf="this.currentResolution > 1024">
        <ng-container *ngFor="let account of currentUsers | search:'account_name,mid,dba_name':name">
          <a (mousedown)="switchAccount(account.id, account.account_name)"
             class="dropdown-item pointer position-relative merchant_list" [class.activeAccount]="account.id === currentAccountId">
            {{account.dba_name ? account.dba_name + ' ' + '-' + ' ' : ''}} {{account.account_name ? (account.account_name) : account.id}} {{account.mid ? ' ' + '-' + ' ' + account.mid : ''}}
          </a>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1 col-2 profileIcon" >
    <div class="profile_col1 dropdown unselectable" >
      <div class="dropdown-container">
        <div class="profile_toggle mt-2 dropdown" data-toggle="dropdown">
          <span>
                    <span class="prfile_first" *ngIf="userName">{{userNameIcon | camalizeStr}}</span>
                </span>
        </div>
        <div class="dropdown-menu shadow bg-white" id="account">
          <div class="dropdown-body">
            <div class="row">
              <div class="col-12 mt-3 ml-xl-3 ml-lg-3" id="Profile_name">
                <ng-container>
                  <div class="profile_name pb-2">
                            <span *ngIf="userName">
                              {{userName | camalizeStr }}
                            </span>
                  </div>
                  <div class="profile_email">
                            <span>
                              {{userEmail}}
                            </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="dropdown-footer">
            <div class="row">
                  <div class="col-sm-4 col-6 pl-xl-4 pl-lg-4">
                    <button class="btn btn-default lg-btn profile" (click)="myprofile()">Profile</button>
                  </div>
                  <div class="col-sm-4 col-6">
                    <button class="btn btn-default lg-btn profile" (click)=" toUpdateContact()">Contact</button>
                  </div>
              <div class="col-sm-4 col-12 mob_logout">
                <button class="btn btn-default lg-btn p_signout p-0" (click)="signOut()">
                  <img src="../../../assets/images/sign_out_icon.png" class="siginout_icon pr-2" alt="sign out icon">
                  Signout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
