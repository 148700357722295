import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.css']
})
export class LoadingButtonComponent implements OnInit, DoCheck {
  @Input() disable: boolean;
  @Input() buttonText: string;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
  @Input() loadFlag: boolean;
  @Input() loadFlag2: boolean;
  @Input() buttonWidth: string;
  @Input() buttonFloat: string;
  @Input() permissionKey: string;
  /**
   * if true show btn else hide
   */
  permissionDenied: boolean;

  constructor(public dataManager: DataManagerService) {
    this.permissionDenied = false;
  }

  // TODO uncomment code for permission check
  ngOnInit() {
    /*if (this.checkPermission()) {
     this.permissionDenied = false;
     } else {
     this.permissionDenied = true;
     }*/
  }

  checkPermission(): boolean {
    for (const permission of this.dataManager.permissionList) {
      if (permission.permission_name = this.permissionKey) {
        return true;
      }
    }
    return false;
  }

  ngDoCheck() {
  }

  loaderButtonClick() {
    this.buttonClick.emit();
  }
}
