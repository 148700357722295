import { BaseComponent } from "../../../abstract/base.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class UpdateBankAccountAction extends BaseComponent {
  protected _editBankAccountToCustomerUrl: string;
  protected _addSource: string;

  editBankAccountOnApi(accountDetails) {
    this.loadFlag = true;
    this.dataManager
      .patchRequest(this._editBankAccountToCustomerUrl, accountDetails)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passResponse(response);
        },
        (error) => {
          this.loadFlag = false;
          this.passError(error.error);
        }
      );
  }
 

  protected getAddress(url) {
    this.loadFlag = true;
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => this.passResponseAccountInfo(res),
        err => {
          this.networkErrorHandler(err.error);
          this.passSourceError(err.error);
        }
      );
  }

  protected abstract passSourceResponse(res);
  protected abstract passSourceError(error);
  protected abstract passResponse(response);
  protected abstract passResponseAccountInfo(response);

  protected abstract passError(error);

}
