<div class="container-fluid main-div b_shadow">
  <div class="row">
    <div class="col-sm-12 col-12 col-md-12 col-lg-12 header create-header bg-infos">
      Contact
    </div>
  </div>
<div class="col-sm-12 col-12 col-md-12 col-lg-12">
  <div class="pt-4" id="dilog">
    <form #updateCustomerDetail='ngForm'>
      <div class="form-group row">
        <label class="col-sm-6 col-6 col-md-6 col-lg-6 col-form-label">Notification contact number:</label>
        <span class="error_sign" id="phone1">!</span>
        <div class="col-sm-5 col-6 col-md-6 col-lg-6 px-m-0">
          <div class="row">
            <span class="input-group-addon last-d col-sm-2 col-2 col-md-1 col-lg-2 pr-2 pl-sm-2 pl-1">+1</span>
            <input style="padding-right: 9px;" class="form-control col-sm-9 col-9 col-md-9 col-lg-9" id="phone" name="phone" #phoneInp="ngModel"
                   placeholder="(000) 000-0000" required pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                   [(ngModel)]="phone" type="tel" appPhoneMask maxlength="14"
                   [class.invalid]="updateCustomerDetail.submitted && phoneInp.invalid"
                   value=""/>
            <span class="validation-err-msg phn-err-msg" *ngIf="updateCustomerDetail.submitted && (phoneInp.errors?.required)">Phone number is required.</span>
            <span class="validation-err-msg phn-err-msg" *ngIf="updateCustomerDetail.submitted && (phoneInp.invalid && !phoneInp.errors?.required)">Phone number is invalid.</span>
          </div>
        </div>
      </div>
      <div class="row justify-content-end m-0 py-4">
        <button mat-button [mat-dialog-close]="false" class="cancel_btn">Cancel</button>
          <app-loading-button
          (buttonClick)="updateCustomerDetail.invalid ? '' : toUpdate()"
         [loadFlag]="loadFlag" type="submit" [buttonText]="'Update'"></app-loading-button>
      </div>
    </form>
  </div>
</div>
</div>
