<!--<app-details-loader *ngIf="loadFlag || errorMsg" [errorMsg]="errorMsg" [Height]="'70vh'" [LoaderMarginTop]="'19%'"-->
<!--</app-details-loader>-->
<div class="containerx cust_detail_block">

  <div class="container-fluid cust_padding">

    <div class="row">

      <div class="col-md-12 no-padding">

        <mat-card class=" container-card">

          <!--<mat-card class="heading-card col-md-12">-->

            <!--<div class="row">-->

              <!--<div class="col-md-9 left-heading">-->

                <!--<a>Availability</a>-->

              <!--</div>-->

            <!--</div>-->

          <!--</mat-card>-->

          <!--<div class="details">-->
            <!--<div class="row">-->
              <!--<div class="col-12">-->
                <!--<i class="fa fa-check-circle" style="font-size:13px;color:green"></i><span> Customers accounts are available for your platform</span>-->
              <!--</div>-->
              <!--<div class="col-12">-->
                <!--<i class="fa fa-check-circle" style="font-size:13px;color:green"></i><span> Express accounts based in the United States are available</span>-->
              <!--</div>-->
            <!--</div>-->

          <!--</div>-->
          <!--<mat-card class="heading-card col-md-12">-->

            <!--<div class="row">-->

              <!--<div class="col-md-6 left-heading">-->

                <!--<a>Client IDs</a>-->

              <!--</div>-->
              <!--<div class="col-md-6 right-heading text-right">-->
                <!--<img _ngcontent-c6="" class="add-img" src="../../../../assets/images/add.png">-->
                <!--<span class="right-heading green-active" id="oauth">Test OAuth</span>-->

              <!--</div>-->

            <!--</div>-->

          <!--</mat-card>-->
          <!--<div class="box destination">-->
            <!--<div class="col-md-12 no-data">-->
              <!--<div class="row left">-->
                <!--<span >Test mode client ID</span>-->
              <!--</div>-->
              <!--<div class="row right">-->
                <!--<span >asdadascsd45cas1cadac_cccacasc5a2</span>-->
              <!--</div>-->
              <!--<div class="separator">-->
                <!--<hr></div>-->
            <!--</div>-->
            <!--<div class="col-md-12 no-data">-->
              <!--<div class="row left">-->
                <!--<span >REDIRECT URIS</span>-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="col-md-12 center">-->
              <!--<div>-->
                <!--<span >No redirect URIs set </span>-->
              <!--</div>-->
              <!--<div>-->
                <!--<span>You must define a redirect URI to onboard users with Standard or Express</span>-->

              <!--</div>-->
              <!--<div class="">-->
                <!--<button class="right-heading green-active btn btn-default uri"><img _ngcontent-c6="" class="add-img plus" src="../../../../assets/images/add.png"> Add redirect URI...</button>-->

              <!--</div>-->
            <!--</div>-->
            <!--<hr>-->
            <!--<div class="space-left">-->
              <!--<span>Your users will be redirected to these URIs after onboarding with Standard or Express.<a href="#" style="color: #3367d6;"> learn more...</a></span>-->
            <!--</div>-->
          <!--</div>-->

          <mat-card class="heading-card col-md-12">

            <div class="row">

              <div class="col-md-6 left-heading">

                <a>Branding</a>

              </div>

            </div>

          </mat-card>

          <div class="box branding">
            <div class="row">
              <span class="business_heading">Business details</span>
            </div>
            <div class="row">
              <span class="">Options that affect what Stripe will show to users you onboard to Connect</span>
            </div>
            <hr>
            <div class="row">
              <span class="sub-heading">Name</span>
            </div>
            <div class="row">
              <span class="para">The name of your application, shown during user onboarding</span>
            </div>
            <div class="row">
              <input type="text" class="form-control col-lg-3">
            </div>

            <div class="row">
              <span class="sub-heading">Brand descriptor</span>
            </div>
            <div class="row">
              <span class="para">The bank descriptor is shown in connected accounts' bank statements when funds are paid out.</span>
            </div>
            <div class="row">
              <input type="text" class="form-control col-lg-3">
            </div>

            <div class="row">
              <span class="sub-heading">Website URL</span>
            </div>
            <div class="row">
              <input type="text" class="form-control col-lg-3">
            </div>

            <div class="row">
              <span class="sub-heading">Appearance</span>
            </div>
            <div class="row">
              <span class="para">Graphics and color choices that customize how Strip's onboarding users appear to your users</span>
            </div>
            <hr>


            <div class="row">
              <span class="sub-heading">Brand color</span>
            </div>
            <div class="row">
              <span class="para">The brand color is used to style the Express onboarding flow to match the color scheme of your application</span>
            </div>
            <div class="row">
              <input type="text" class="form-control col-lg-3" value="#FFFFF">
            </div>


            <div class="row logo">
              <span class="sub-heading">Logo</span>
            </div>
            <div class="row">
              <span class="para">Your application logo is shown during Standard account onboarding. Logos may be a maximum of 500x100px</span>
            </div>
            <div class="row">
              <span  class="btn btn-default btn-file"><i class="fa fa-arrow-up arrow"></i> Upload logo...<input type="file" ></span>
            </div>


            <div class="row">
              <span class="sub-heading icon">Icon</span>
            </div>
            <div class="row">
              <span class="para">Your application icon is shown during Standard and Express account onboarding. Logos may be a maximum of 75x75px</span>
            </div>
            <div class="row">
              <span  class="btn btn-default btn-file"><i class="fa fa-arrow-up arrow"></i> Upload icon...<input type="file" ></span>
            </div>
          </div>
          <div>
            <button class="btn btn-md save right">Save</button>
          </div>

        </mat-card>


      </div>

    </div>

  </div>
</div>
