/**
 * Updated by asutosh on 19/02/19.
 */
export class PermissionConstants {
  /*key for validations*/
  public static PERMISSION_CREATE_CUSTOMER = 'create-customer';
  public static PERMISSION_DELETE_CUSTOMER = 'delete-customer';
  public static PERMISSION_UPDATE_CUSTOMER = 'update-customer';
  public static PERMISSION_LIST_CUSTOMER = 'list-customers';
  public static PERMISSION_CREATE_PAYMENT = 'create-payment';
  public static PERMISSION_DELETE_PAYMENT = 'delete-payment';
  public static PERMISSION_UPDATE_PAYMENT = 'update-payment';
  public static PERMISSION_LIST_PAYMENT = 'list-payments';
  public static PERMISSION_VOID_PAYMENT = 'void-payment';
  public static PERMISSION_CREATE_REFUND = 'refund-payment';
  public static PERMISSION_CAPTURE_PAYMENT = 'capture-payment';
  public static PERMISSION_INVOICE_PAYMENT = 'create-invoice';
  public static PERMISSION_CREATE_TRANSACTION = 'create-transaction';
  public static PERMISSION_DELETE_TRANSACTION = 'delete-transaction';
  public static PERMISSION_UPDATE_TRANSACTION = 'update-transaction';
  public static PERMISSION_LIST_TRANSACTION = 'view-deposits';
  public static PERMISSION_DOWNLOAD_DISPUTE = 'manage-disputes';
  public static PERMISSION_CREATE_DISPUTE = 'manage-disputes';
  public static PERMISSION_DELETE_DISPUTE = 'manage-disputes';
  public static PERMISSION_UPDATE_DISPUTE = 'manage-disputes';
  public static PERMISSION_LIST_DISPUTE = 'manage-disputes';
  public static PERMISSION_CREATE_PAYOUT = 'create-payout';
  public static PERMISSION_DELETE_PAYOUT = 'delete-payout';
  public static PERMISSION_UPDATE_PAYOUT = 'update-payout';
  public static PERMISSION_LIST_PAYOUT = 'view-deposits';
  public static PERMISSION_CREATE_SUBSCRIPTION = 'manage-subscriptions';
  public static PERMISSION_DELETE_SUBSCRIPTION = 'manage-subscriptions';
  public static PERMISSION_UPDATE_SUBSCRIPTION = 'manage-subscriptions';
  public static PERMISSION_LIST_SUBSCRIPTION = 'manage-subscriptions';
  public static PERMISSION_CREATE_CYCLE = 'manage-plans';
  public static PERMISSION_DELETE_CYCLE = 'manage-plans';
  public static PERMISSION_UPDATE_CYCLE = 'manage-plans';
  public static PERMISSION_LIST_CYCLE = 'manage-plans';
  public static PERMISSION_CREATE_COUPON = 'manage-discounts';
  public static PERMISSION_DELETE_COUPON = 'manage-discounts';
  public static PERMISSION_UPDATE_COUPON = 'manage-discounts';
  public static PERMISSION_LIST_COUPON = 'manage-discounts';
  public static PERMISSION_CREATE_INVOICE = 'manage-subscriptions';
  public static PERMISSION_DELETE_INVOICE = 'manage-subscriptions';
  public static PERMISSION_UPDATE_INVOICE = 'manage-subscriptions';
  public static PERMISSION_LIST_INVOICE = 'manage-subscriptions';
  public static PERMISSION_DOWNLOAD_INVOICE = 'manage-subscriptions';
  public static PERMISSION_CREATE_CARD = 'create-card';
  public static PERMISSION_DELETE_CARD = 'de-attach-card';
  public static PERMISSION_UPDATE_CARD = 'update-card';
  public static PERMISSION_LIST_CARD = 'list-cards';
  public static PERMISSION_CREATE_USER = 'manage-users';
  public static PERMISSION_DELETE_USER = 'manage-users';
  public static PERMISSION_UPDATE_USER = 'manage-users';
  public static PERMISSION_LIST_USER = 'manage-users';
  public static PERMISSION_CREATE_REVIEW = 'create-review';
  public static PERMISSION_DELETE_REVIEW = 'delete-review';
  public static PERMISSION_UPDATE_REVIEW = 'update-review';
  public static PERMISSION_APPROVE_REVIEW = 'approve-review';
  public static PERMISSION_REJECT_REVIEW = 'reject-review';
  public static PERMISSION_LIST_REVIEW = 'list-reviews';
  public static PERMISSION_CREATE_ROLE = 'manage-roles';
  public static PERMISSION_ASSIGN_ROLE = 'manage-roles';
  public static PERMISSION_REVOKE_ROLE = 'manage-admins-access';
  public static PERMISSION_DELETE_ROLE = 'manage-roles';
  public static PERMISSION_UPDATE_ROLE = 'manage-roles';
  public static PERMISSION_LIST_ROLE = 'list-roles';
  public static PERMISSION_CREATE_EVENT = 'create-events';
  public static PERMISSION_DELETE_EVENT = 'delete-events';
  public static PERMISSION_UPDATE_EVENT = 'update-events';
  public static PERMISSION_LIST_EVENT = 'list-events';
  public static PERMISSION_FILTER_REVIEW = 'manage-risks';
  public static PERMISSION_AUTHORIZATION_LOG = 'authorization-log';
  /*messages for alet if any*/
  public static PERMISSIONS_CREATE_MESSAGE_CUSTOMER = 'Permission denied contact administrator.';
  public static PERMISSIONS_DELETE_MESSAGE_CUSTOMER = 'Permission denied contact administrator';
  public static PERMISSIONS_UPDATE_MESSAGE_CUSTOMER = 'Permission denied contact administrator';
  public static PERMISSIONS_LIST_MESSAGE_CUSTOMER = 'Permission denied contact administrator';
  public static PERMISSIONS_CREATE_MESSAGE_PAYMENT = 'Permission denied contact administrator.';
  public static PERMISSIONS_DELETE_MESSAGE_PAYMENT = 'Permission denied contact administrator';
  public static PERMISSIONS_UPDATE_MESSAGE_PAYMENT = 'Permission denied contact administrator';
  public static PERMISSIONS_LIST_MESSAGE_PAYMENT = 'Permission denied contact administrator';
}
