import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';


import { DecimalPipe } from '@angular/common';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../constants/app-constants';
import { debounceTime,distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-type-ahead-component',
  templateUrl: './type-ahead-component.component.html',
  styleUrls: ['./type-ahead-component.component.css']
})
export class TypeAheadComponentComponent implements OnInit, DoCheck {
  @Input() selectedValue: string;
  @Input() serviceName: string;
  @Output() selectedObject = <any>new EventEmitter();
  @Output() invalid = <any>new EventEmitter();
  @Input() disable: boolean;
  @Input() require: boolean;
  searchTerm = new Subject<void>();
  suggestionUrl: string;
  paramArray = {};
  message: string;
  dataManager: DataManagerService;
  suggestions: any[];
  flag: boolean[] = [];
  loadFlag: boolean;

  constructor(datamanager: DataManagerService, private _decimalPipe: DecimalPipe) {
    this.dataManager = datamanager;
    this.flag[0] = false;
    this.flag[1] = false;
    this.loadFlag = false;
    this.paramArray = {
      '/customers': [],
      '/plans': [],
      '/discounts': [],
    };
  }

  ngOnInit() {
    console.log('ServiceName', this.serviceName);
    this.suggestionUrl = AppConstants.BASE_URL + this.serviceName + '?search=';
    switch (this.serviceName) {
      case '/customers':
        this.message = 'Loading customers...';
        break;
      case '/plans':
        this.message = 'Loading plans...';
        break;
      case '/discounts':
        this.message = 'Loading discounts...';
        break;
    }
    this.searchTerm
    
    .pipe(debounceTime(600))
    .subscribe(
      next => this.searchSuggestion()
    );
  }

  ngDoCheck() {
    if (!this.flag[0] && !this.flag[1]) {
      this.suggestions = null;
    }

  }


  search(url: string) {
    console.log('search1 called');
    this.dataManager.getRequest(url, 0).subscribe(
      response => this.passSuggession(response),
      error => this.passError(error)
    );

  }

  protected passSuggession(response) {
    this.loadFlag = false;
    this.suggestions = response.data;
    console.log(this.suggestions);
  }

  protected passError(error) {
    this.loadFlag = false;
    console.log(error);
  }

  searchSuggestion() {
    this.loadFlag = true;
    console.log(this.selectedValue);
    let url = '';
    url = this.selectedValue;
    this.search(this.suggestionUrl + url);
  }

  textOnFocus() {
    this.flag[0] = true;
  }

  textOnBlur() {
    if (this.selectedValue === '') {

    }

    this.flag[0] = false;
  }

  containerOnMouseEnter() {
    this.flag[1] = true;
  }

  containerOnMouseLeave() {
    this.flag[1] = false;
  }

  itemSelected(suggestion) {
    this.selectedValue = this.selectedText(suggestion);
    this.suggestions = null;
    this.flag[1] = false;
    this.selectedObject.emit(suggestion);

  }

  selectedText(suggestion): string {
    switch (this.serviceName) {
      case '/customers':
        this.message = 'Loading customers...';
        return (suggestion.email ? suggestion.email : suggestion.customer_id);
      case '/plans':
        this.message = 'Loading plans...';
        return suggestion.interval_count > 1 ? (suggestion.name + ' ( $' + this._decimalPipe.transform((suggestion.amount / 100), '1.2-2')  + ' ' + suggestion.currency.toUpperCase() + ' / ' + suggestion.interval_count + ' ' + suggestion.interval + 's )') : (suggestion.name + ' ( $' + this._decimalPipe.transform((suggestion.amount / 100), '1.2-2') + ' ' + suggestion.currency.toUpperCase() + ' / ' + suggestion.interval + ' )' + ' - ' + suggestion.plan_id);
      case '/discounts':
        this.message = 'Loading discounts...';
        return ((suggestion.percent_off ? (suggestion.percent_off + '%') :
        '$' +  (this._decimalPipe.transform((suggestion.amount_off / 100), '1.2-2') + ' USD')) + ' off ' + suggestion.duration) + ' (' + suggestion.discount_id + ')';
      case '/campaigns':
        this.message = 'Loading campaigns...';
        return (suggestion.name ? suggestion.name : suggestion.customer_id);
    }
  }
}
