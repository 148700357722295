import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-details-loader',
  templateUrl: './details-loader.component.html',
  styleUrls: ['./details-loader.component.css']
})
export class DetailsLoaderComponent implements OnInit {
@Input() Height: string;
@Input() LoaderMarginTop: string;
@Input() MarginFromTop: string;
@Input() errorMsg: string;
@Input() loaderImgWidth: string;
@Input() errorIconName = 'warning';


  constructor() { }

  ngOnInit() {
  }

}
