import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class EditCampaignAction extends BaseComponent {
    //put variables here
    protected getListUrl: string;
    protected editCampaignPostUrl:string;
    protected campaignId:string;
    
    //put api calls here
    
    protected editCampaignPost(body){
        this.loadFlag = true;
        console.log('deyan',this.editCampaignPostUrl,body);
        this.dataManager.patchRequest(this.editCampaignPostUrl, body)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passEditResponse(res);
              
            if (res.data.length === 0) {
                this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
              } else {
                this.errorMsg = null;
              }
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        });

    }

    protected getEditCampaignData(url){
        this.loadFlag = true;
        console.log('deyan',url);
        this.dataManager.getRequest(url,0)
            .subscribe(res=>{
                this.loadFlag = false;
                this.passGetResponse(res);
            })
    }

    protected deleteCampaignApi(url){
        this.loadFlag = true;
        this.dataManager.deleteRequest(url)
            .subscribe((res)=>{
                this.loadFlag = false;
                this.passDeleteResponse(res);
            },
            error=>{
                this.loadFlag = false;
                this.passError(error.error);
                this.networkErrorHandler(error);
            })
        
    }
    protected deletePayeeApi(url){
        this.loadFlag = true;
        this.dataManager.deleteRequest(url)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passDeleteResponse(res);
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        })

    }
   
    protected abstract passEditResponse(response);
    protected abstract passGetResponse(response);
    protected abstract passDeleteResponse(response);
    protected abstract passGetPayeesResponse(response);
  
    protected abstract passError(error);
}