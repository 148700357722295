import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MerchantBridgeComponent } from '../merchant-bridge/merchant-bridge.component';
import { PayeeSummaryComponent } from '../components/payee-summary/payee-summary.component';
import { SplitCampaignRequestComponent } from '../components/split-campaign-request/split-campaign-request.component';
import { SplitFundingSummaryComponent } from '../components/split-funding-summary/split-funding-summary.component';
import { AddNewPayeeComponent } from '../components/add-new-payee/add-new-payee.component';
import { NewCampaignCreationComponent } from '../components/new-campaign-creation/new-campaign-creation.component';
import { SplitFundingDetailsComponent } from '../components/split-funding-details/split-funding-details.component';
import { CampaignSummaryComponent } from '../components/campaign-summary/campaign-summary.component';
import { EditCampaignComponent } from '../components/edit-campaign/edit-campaign.component';
import { PayeeDetailsComponent } from '../components/payee-details/payee-details.component';
import { CampaignsDetailsComponent } from '../components/campaigns-details/campaigns-details.component';
import { EditPayeeComponent } from '../components/edit-payee/edit-payee.component';



const merchantRoutes: Routes = [
  {
    path: '',
    component: MerchantBridgeComponent,
    children: [
      {path:'payee-summary', component: PayeeSummaryComponent},
      {path:'split-campaign', component: SplitCampaignRequestComponent},
      {path:'split-funding-summary/:id', component: SplitFundingDetailsComponent},
      {path:'split-funding-summary', component: SplitFundingSummaryComponent},
      {path:'add-new-campaign',component:NewCampaignCreationComponent},
      {path:'add-new-payee', pathMatch: 'full',component: AddNewPayeeComponent},
      {path:'campaign-summary',component:CampaignSummaryComponent},
      {path:'edit-campaign/:id',component:EditCampaignComponent},
      {path:'edit-campaign/',component:EditCampaignComponent},
      {path:'payee-details',component:PayeeDetailsComponent},
      {path:'campaign-details/:id',component:CampaignsDetailsComponent},
      {path:'edit-payee/:id',component:EditPayeeComponent}

    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(merchantRoutes)
  ],
  exports: [RouterModule]
})
export class MerchantBridgeRoutingModule { }
