import {BaseComponent} from '../../../abstract/base.component';
import {ApiErrorModel} from '../../../data/model/ApiErrorModel/api-error.model';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class CollectedFeesDetailsAction extends BaseComponent {
  protected _collectedFeesDetails: string;
  errorMessage: string;
  protected getCollectedFeesDetails(url) {
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => {
          this.passCollectedFeesDeatils(res);
          if (res.data.length === 0) {
            this.errorMsg = ErrorConstants.collectedFeesDetailErrorMsg[0];
          } else {
            this.errorMsg = null;
          }
        },
        error => {
          this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.collectedFeesDetailErrorMsg);
          this.errorMessage = this.errorMsg;
          this.passCollectedFeesDeatilsError(error);
        }
      );
  }

  protected abstract passCollectedFeesDeatils(res);
  protected abstract passCollectedFeesDeatilsError(err);
}
