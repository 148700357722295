<div class="cust_bdy">
  <div class="table_content mt-3"  *ngIf="showExport === false">
    <div class="row px-xl-4 px-lg-4 px-md-4 px-sm-0 py-xl-4 py-lg-4 py-md-4 py-sm-2 pt-4 table-header">
      <div class="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-12 form-group has-search px-sm-2 mb-xl-0 mb-lg-0">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2" [(ngModel)]="searchText" (input)="searchHits.next()"
               placeholder="Search" />
      </div>
      <div class="col-lg-7 col-xl-5 col-md-12 col-sm-12 col-12 mb-md-2 ml-m-11 mb-xl-0 mb-lg-0">
        <div class="row date-inputs">
          <input type="text" [(ngModel)]="from_date" class="form-control calander-input-icon col-sm-5 ml-lg-3 ml-md-1 mr-xl-3" #dp="bsDatepicker" placement="bottom" [bsConfig]="bsDateConfig" [maxDate]="today" placeholder="From" bsDatepicker [(bsValue)]="from_date" (bsValueChange)="checkdate($event);" (onHidden)="datereport()">
          <input type="text" [(ngModel)]="to_date" class="form-control calander-input-icon col-lg-5 col-md-6 col-sm-5 ml-lg-2 ml-md-3 ml-2" #dp1="bsDatepicker" [bsConfig]="bsDateConfig" placement="bottom" placeholder="To" [maxDate]="today" bsDatepicker [(bsValue)]="to_date" (bsValueChange)="checkdate2($event);" (onHidden)="datereport()">
        </div>
      </div>
      <div class="col-lg-3 col-xl-3 col-md-12 col-sm-3 text-right exp_div">
        <button class="export_btn" (click)="exportexcel()">Export</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12 mob_no_padding">
        <div (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag" class="top-scroll" id="TopScroll">
          <img src="" height=1 [style]="checkTablewidth()">
        </div>
        <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
          <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
        <table *ngIf="!errorMsg" class="table mb-0" id="table-width">
          <thead>
          <tr>

            <th class="name">
              <a (click)="setSorting('name')" >Name
                <i class="fa fa-thin " [ngClass]="orderBy === 'name' ? stylesortBy : 'fa-angle-down'"></i></a>
            </th>
            <th class="email">
              <a (click)="setSorting('email')" >Email address
                <i class="fa fa-thin "  [ngClass]="orderBy === 'email' ? stylesortBy : 'fa-angle-down'"></i></a>
            </th>
            <th class="details">
              <a (click)="setSorting('desc')" >
                Description
                <i class="fa fa-thin "  [ngClass]="orderBy === 'desc' ? stylesortBy : 'fa-angle-down'"></i>
              </a>
            </th>
            <th class="created_at"> <a (click)="setSorting('created_at')" >Created ON
              <i class="fa fa-thin "  [ngClass]="orderBy === 'created_at' ? stylesortBy : 'fa-angle-down'"></i>
            </a></th>
            <!-- <th class="created_at"> <a (click)="setSorting('created_at')" >Payment Type
              <i class="fa fa-thin "  [ngClass]="orderBy === 'created_at' ? stylesortBy : 'fa-angle-down'"></i>
            </a></th>-->
            <th class="payment"><a (click)="setSorting('card_type')">Payment method <i class="fa fa-thin "  [ngClass]="orderBy === 'card_type' ? stylesortBy : 'fa-angle-down'"></i>
            </a></th>
            <th class="id">Customer ID</th>
            <th class="charge"></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="loadFlag">
            <tr class="loader">
              <td colspan="7">
                <div class="loader-container">
                  <img class="table-loader" src="../../../../assets/images/loading.svg" />
                </div>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="!loadFlag && customerList.data.length > 0">
            <tr class="cus_list" *ngFor="let customer of customerList.data">

              <td class="email" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }"
                  data-th="Name">
                  <div [innerHTML]="highlight(customer.name)"></div>
              </td>
              <td class="email" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }"
                   data-th="Email address">
                   <div [innerHTML]="highlight(customer.email)"></div>
              </td>
              <td class="details" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }"
                  [title]="nullToDoubleMinus(customer.description)"  data-th="Description">
                  <div [innerHTML]="highlight(customer.description)">
                  </div>
              </td>
              <td class="created_at" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }"
                  [title]=" customer.created_at ? (customer.created_at | dateFormat : 'MM/dd/yyyy hh:mm' ) : '--' "
                  data-th="Created ON"
                  >
                  <div>{{customer.created_at ?  (customer.created_at | dateFormat : "MM/dd/yyyy hh:mm") : '--'}}</div>
              </td>
              <!-- <td class="payment_type" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }"
                  [title]=" customer.bank_account.data.length>0 ? customer.bank_account.data.length>0 : 'card' "
                  data-th="Payment Type"
                  >
                  <div>{{ customer.card.data.length>0 && customer.bank_account.data.length>0 ? 'ACH and Card': customer.bank_account.data.length>0 ?'ACH' : customer.card.data.length>0 ?'Card':'None'}}</div> -->
          <td class="payment" [ngClass]="{'multiple-pay-methods': (customer.bank_account !== undefined ? customer.bank_account.data.length>0:'' )}" [routerLink]="redirect_link" [queryParams]="{ id: customer.customer_id }" data-th="Payment method">
                <div class="card-container" *ngIf="customer.defaultCard && (customer.bank_account !==undefined ? customer.bank_account.data.length===0:true )">
                  <img class="card-img" [src]=" card_img_obj[customer.defaultCard.brand] ? card_img_obj[customer.defaultCard.brand] : '../../../../assets/images/card-default.png' " />
                  <!--<span>{{customer.defaultCard.last4digit ? customer.defaultCard.last4digit : '*******'}}</span>-->
                  <span class="card-number">
                      <a class="" *ngIf="customer.defaultCard?.first6digit"
                         [innerHTML]="highlight(customer.defaultCard?.first6digit | CreditCardMaskPipe)"></a>
                      <a class="" *ngIf="customer.defaultCard?.first6digit">** ****</a>
                      <b class="" *ngIf="!customer.defaultCard?.first6digit">**** **** ****</b>
                      <a class=""
                         [innerHTML]="customer.defaultCard?.last4digit ? highlight(customer.defaultCard?.last4digit) : '****'">{{
                      </a>
                    </span>
                  <span>{{
                    customer.defaultCard?.exp_month +
                    "/" +
                    customer.defaultCard?.exp_year
                    }}</span>
                </div>
              <tr [ngClass]="{'multiple-pay-methods':customer?.bank_account.data.length>0  }" *ngIf="customer?.bank_account.data.length>0 ">
                  <td class="payment pl-3" rowspan="2">
                    <div class="card-container" >
                      <div *ngIf="customer.bank_account.data.length>0">
                        <span *ngFor="let bank_account of customer.bank_account.data">
                          <p class="multiple-pay-methods">BA  {{bank_account.account_number}}</p>
                        </span>
                        <div  *ngIf="customer.card.data.length !==0" >
                            <div *ngFor="let card of customer.card.data">
                                <p class="multiple-pay-methods">CC
                                    <a class="" *ngIf="card.first6digit"
                                      [innerHTML]="highlight(card.first6digit | CreditCardMaskPipe)"></a>
                                    <a class="" *ngIf="card.first6digit">** ****</a>
                                    <b class="" *ngIf="!card.first6digit">**** **** ****</b>
                                    <a class=""
                                      [innerHTML]="card.last4digit ? highlight(card.last4digit) : '****'">
                                    </a>
                                </p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </td>
            </tr>
                    <span *ngIf="(customer.bank_account !==undefined ? customer.bank_account.data.length==0:true )&&customer.card.data.length===0">--</span>
            </td>
            <td class="id" [innerHTML]="highlight(customer.customer_id)" data-th="Customer ID"></td>
            <td class="text-success charge" (click)="chargeCustomer(customer)">
              <span class="charge_btn">Charge</span>
            </td>
          </tr>
        </ng-container>
        </tbody>

        </table>
        </div>
          <div class="row m-0 pt-sm-4 pt-2 border-2" *ngIf="!loadFlag && customerList.data.length > 0">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
              <span class="paginate">
                {{ 1 + (metadata['current_page'] - 1) * limit }} -
                <select [(ngModel)]="limit" (change)="limitChange()" class="page-limit"
                        *ngIf="metadata['current_page'] === 1">
                  <option *ngFor="let no of limitArray" [value]="no">{{
                    no
                    }}</option>
                </select>
                <span *ngIf="metadata['current_page'] !== 1">{{
                  metadata['count'] +
                  (metadata['current_page'] - 1) * limit
                  }}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
              </span>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0 px-md-m-0">
              <ul class="list-inline"  *ngIf="!loadFlag && customerList.data.length > 0 && objectKeys(metadata.links).length > 0">
                <li  class="list-inline-item"><button type="button" class="btn btn-default btn_prenext" [disabled]="!metadata.links['previous']"
                                               (click)="paginate( metadata.links['previous'])">
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                </button></li>
                <li class="list-inline-item" *ngFor="let number of numbers()">
                  <div *ngIf="+number.toString() ">
                    <a class="page-link" *ngIf="metadata.links.next" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links.next)" >{{number}}</a>
                    <a class="page-link" *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links['previous'])" >{{number}}</a>
                  </div>
                  <a class="page-link" *ngIf="!+number.toString()" >{{number}}</a>
                </li>
                <li  class="list-inline-item" ><button type="button" class="btn btn-default btn_prenext" [disabled]="!metadata.links['next']"
                                                (click)="
                  paginate(metadata.links['next']) ">
                  Next  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button></li>
              </ul>


          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-content export_content" *ngIf="showExport">
    <app-export-excel [exportExcel]="exportExcel" [serviceName]="exportData"[filterUrl]="filterPostFix" [searchData]="searchText"></app-export-excel>
  </div>
  </div>
