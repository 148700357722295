import { BaseComponent } from "../../../abstract/base.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class AddBankAccountAction extends BaseComponent {
  protected _addBankAccountToCustomerUrl: string;
  protected _addSource: string;

  createBankAccountOnApi(accountDetails) {
    this.loadFlag = true;
    this.dataManager
      .postRequest(this._addBankAccountToCustomerUrl, accountDetails, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          this.passResponse(response);
          
        },
        (error) => {
          this.loadFlag = false;
          this.passError(error);
        }
      );
  }
  addSourceToCustomer(postBody) {
    this.loadFlag = true;
    this.dataManager.patchRequest(this._addSource, postBody)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passSourceResponse(res);
        },
        error => {
          this.loadFlag = false;
          this.passSourceError(error);
        }
      );
  }
  protected abstract passSourceResponse(res);
  protected abstract passSourceError(error);
  protected abstract passResponse(response);

  protected abstract passError(error);

}
