<app-details-loader  [Height]="'85vh'" [LoaderMarginTop]="'21%'"  *ngIf="loadFlag" ></app-details-loader>
<div class="containerx">

  <div class="row mt-4 ml-0" *ngIf="!loadFlag ">
    <!-- && !errorMsg -->

    <div class="col-md-8 no-padding">

      <span class="head-email">Dispute form</span>

      <span class="head-text green-text">( {{dispute.id ? dispute.id : '--'}} )</span>

    </div>

  </div>

  <mat-card class="card" *ngIf="!loadFlag ">
<!--     && !errorMsg-->
    <div class="row">
      <div class="col-md-3">Case ID :</div>
      <div class="col-md-9">{{dispute.case_id ? dispute.case_id : '--'}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Transaction ID :</div>
      <div class="col-md-9">{{dispute.charge_id ? dispute.charge_id : '--'}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Reason for chargeback :</div>
      <div class="col-md-9">{{dispute.reason ? dispute.reason : '--'}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Dispute Evidence :</div>
      <div class="col-md-5" *ngIf="dispute.dispute_subbmissions.data.length > 0"><textarea class="form-control text-area" [disabled]="dispute.dispute_status === 'Disputed'" [(ngModel)]="dispute.dispute_subbmissions.data[0].description"></textarea>
      </div>
      <div class="col-md-5" *ngIf="dispute.dispute_subbmissions.data.length === 0"><textarea class="form-control text-area" [(ngModel)]="dispute.dispute_subbmissions.data.description"></textarea>
      </div>
    </div>
    <div class="row mb-4" >
      <p class="col-md-12 error" id="err"> {{errorMsg}}</p>
    </div>
    <div class="row">
      <div class="col-md-3" style="margin-top: 0.5%;">Attach Evidence :</div>
      <!--<div class="col-md-5">-->
        <!--<input type="file" #upload (change)="onSelectFile($event, upload)" name="file" accept=".jpg,.png,.pdf" placeholder="Upload file">-->
      <!--</div>-->
      <div class="col-md-5">
        <div>
          <button  class="btn btn-default btn-file" [disabled]="loading || dispute.dispute_status === 'Disputed'">
            <i class="fa fa-arrow-up arrow"></i> Upload
            <input type="file" #upload (change)="onSelectFile($event, upload)"  name="file" accept=".jpg,.png,.pdf" [disabled]="loading || dispute.dispute_status === 'Disputed'">
          </button>
        </div>
        <div class="loader loader--style8" title="loading" *ngIf="loading">
          <img src="../../../assets/images/uploading.svg">
        </div>
        <div>
          <small style="font-size: 10px">Each file should be at most <b>1MB</b> in size and either a <b>PNG,JPG or PDF</b></small>
        </div>
      </div>
      <!--<div class="col-md-1">-->
        <!--<button name="file" value="Upload" (click)="filepicker.click()" class="button">Upload</button>-->
      <!--</div>-->
      <div class="col-md-3"> </div>
    </div>
    <!--<div class="row">-->
      <!--<span id="err"></span>-->
    <!--</div>-->
    <div class="container">
      <div class="row">
        <div class="col-md-12 line1">
          <hr>
          <div>
            <span>{{totalSize === 0 ? totalSize + 'Bytes' : ( totalSize < 1000000) ? ((totalSize/1000) | number : '1.2-2') + 'KB' : ((totalSize/1000000) | number : '1.2-2') + 'MB'}} of 4MB used</span>
          </div>
          <div>
            <small style="font-size: 11px">You can upload a total of <b>4MB</b> for all evidence file</small>
          </div>
          <strong style="font-weight: 700">Note: Dispute Submissions are final and can not be changed</strong>
        </div>
        <div class="col-md-12 line1">
          <hr>
        </div>
        <div class="col-md-12 line" *ngFor='let file of dispute.evidences.data'>
          <div class="row padd">
            <div class="col-6 top1" style="">
              <a class="blue-style" [href]="file.uploaded_file_url" target="_blank">{{file.file_name}}</a><span style="color: #6c757d;">( {{file.file_size / 1000}} Kb)</span>
            </div>
            <div class="col-6" >
              <div class="loader ld" title="loading" [id]="file.id + 'loader'" >
                <img src="../../../assets/images/uploading.svg" width="75%">
              </div>
            <button class="btn btn-xs btn-default delete" [disabled]="dispute.dispute_status === 'Disputed'" (click)="deleteFile(file.id)">
              <i class="fa fa-trash" style="font-size:18px; color:#E06666"></i>
            </button>
            </div>
          </div>
        </div>
      </div>
  </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <app-loading-button (buttonClick)="onSave()" [disable]="dispute.dispute_status === 'Disputed'" [loadFlag]="loadFlag" [buttonWidth]="'100%'"
                            [buttonText]="'Submit evidence'"></app-loading-button>
      </div>
      <div class="col-2">
        <app-loading-button (buttonClick)="onCancel()" [disable]="false" [loadFlag]="false" [buttonWidth]="'100%'"
                            [buttonText]="'Cancel'"></app-loading-button>
      </div>
    </div>

  </mat-card>

</div>
<!--<input (change)="onSelectFile($event)" #filepicker type="file" name="file" accept=".jpg,.png"  value="Upload" (change)="selectFile($event.target.files)" style="display: none" multiple>-->
