import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CardModel } from '../../../data/model/CardModel/card.model';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../../shared/constants/app-constants';
import { AddCardAction } from './add-card.action';

@Component({
  selector: 'app-add-customer-card',
  templateUrl: './add-customer-card.component.html',
  styleUrls: ['./add-customer-card.component.css']
})
export class AddCustomerCardComponent extends AddCardAction implements OnInit {customerCard: CardModel;
    customerId: string;    
    errorMsgFlag: boolean;
    topErrorMessage: string;
    keys: String[];
    keys1: String[];
    errorMsg: string;
    demoForm: FormGroup;
    childFormGroup: FormGroup;
    card_source: string;
    cvcError: boolean;
    @ViewChild('addCardForm', { static: true }) childForm;
    //heading - controlls display of card details in component <customer-add-card-details-section.component.ts> set to the value of false
    heading: boolean;
  
    constructor(dataManager: DataManagerService, private _fb: FormBuilder, router: Router, route: ActivatedRoute) {
      super();
      this.route = route;
      this.customerId = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA))['customer_ID']
      this.router = router;
      this.customerCard = new CardModel();
      this.customerCard.authorize_card = true;
      this.dataManager = dataManager;
      this._addCardToCustomerUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CARDS;
      this.errorMsgFlag = false;
      this.card_source = 'PHONE';
      this.customerCard.country = null;
      this.customerCard.state = null;
      this.heading = true;
    }
  
    ngOnInit() {
      this.demoForm = this._fb.group({
        cardDetails: this.childFormGroup
      });
    }
  
  
    /**
     * this method is for adding  card
     */
    addCard() {  
      const postBody = {};
      postBody['card_holder_name'] = this.customerCard.name ? this.customerCard.name : null;
      postBody['authorize_card'] = this.customerCard.authorize_card ? 1 : 0;
      postBody['card_number'] = this.customerCard.card_number ? this.customerCard.card_number.replace(/\D+/g, '') : null;
      postBody['address_line1'] = this.customerCard.address_line1 ? this.customerCard.address_line1.trim() : null;
      postBody['address_line2'] = this.customerCard.address_line2 ? this.customerCard.address_line2.trim() : null;
      postBody['city'] = this.customerCard.city;
      postBody['zip'] = this.customerCard.zip ? this.customerCard.zip : null;
      if (this.customerCard.state != null) {
        postBody['state'] = this.customerCard.state ? this.customerCard.state : null;
      }
      if (this.customerCard.country != null) {
        postBody['country'] = this.customerCard.country ? this.customerCard.country.trim() : null;
      }
      const x = this.customerCard.exp.split('/');
      postBody['exp_month'] = x[0].replace(/\D+/g, '');
      console.log('exp_year', x[1].replace(/\D+/g, ''));
      console.log('exp_year length', x[1].replace(/\D+/g, '').length);
      if (x[1].replace(/\D+/g, '').length > 4) {
        postBody['exp_year'] = x[1].replace(/\D+/g, '').slice(0, -1);
      } else {
        postBody['exp_year'] = x[1].replace(/\D+/g, '');
      }
      postBody['cvv'] = this.customerCard.cvc_check_code;
      postBody['customer_id'] = this.customerId ? this.customerId : null;
      postBody['card_source'] = this.card_source;
      if (!this.cvcError) {
        this.createCardOnApi(postBody);
      }
    }
  
    protected passResponse(response) {
      this._addSource = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CUSTOMER + '/' + this.customerId;
      const postBody = {};
      postBody['token_id'] = response.data.id;
      this.addSourceToCustomer(postBody);
    }
  
    protected passError(error) {
      this.errorMsg = error.message;
      this.topErrorMessage = error.errors;
      this.keys = Object.keys(this.topErrorMessage);
      this.errorMsgFlag = true;
      this.keys1 = Object.values(this.topErrorMessage);
      console.log(this.keys, 'asutosh');
      let i;
      let j;
      const name = ['card_holder_name', 'card_number', 'address_line1', 'address_line2', 'zip', 'exp_month',
        'exp_year', 'customer_id', 'card_source'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + 1).style.display = 'block';
          }
        }
      }
    }
  
    protected passSourceResponse(res) {
      this.goBackToDetails();
    }
  
    protected passSourceError(error) {
      this.errorMsg = error.message;
      this.topErrorMessage = error.errors;
      this.keys = Object.keys(this.topErrorMessage);
      console.log(error.message);
      console.log(error['message']);
      console.log(this.errorMsg);
      this.errorMsgFlag = true;
      this.keys1 = Object.values(this.topErrorMessage);
      console.log(this.keys, 'asutosh');
      let i;
      let j;
      const name = ['card_holder_name', 'card_number', 'address_line1', 'address_line2', 'zip', 'exp_month',
        'exp_year', 'customer_id', 'card_source'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + 1).style.display = 'block';
          }
        }
      }
    }
  
    setChildForm(form) {
      this.childFormGroup = form;
      this.demoForm = this._fb.group({
        cardDetails: this.childFormGroup
      });
    }
  
    childSubmit() {
      if(this.childForm.addCustomerCard.status == 'INVALID') {
        this.childForm.addCardSubmitButton.nativeElement.click();
      } else {
        this.addCard();
      }      
    }
  
    print(obj) {
      console.log(obj);
    }
  
    cancelCard() {
      this.goBackToDetails();
    }
    goBackToDetails() {
      this.router.navigate(['../customer-details'], {queryParams: {id: this.customerId},relativeTo : this.route })
    }
  }
  