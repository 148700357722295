import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {DataManagerService} from '../data/services/DataManager/data-manager.service';
import {AlertMessageDialogComponent} from '../dialogs/alert-message-dialog/alert-message-dialog.component';
import {MessageDialogComponent} from '../dialogs/message-dialog/message-dialog.component';
import {AppConstants} from '../shared/constants/app-constants';
import {MultiFactorAuthenticaionAction} from './multi-factor-authentication.action';
import {MerchantSettingModel} from '../data/model/MerchantSettingModel/merchantsetting.model';

@Component({
  selector: 'app-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.css']
})
export class MultiFactorAuthenticationComponent extends MultiFactorAuthenticaionAction implements OnInit {
  user_id: string;
  show: boolean = false;
  isShowEmailField: Boolean = false;
  user_data: string[];
  isShowPhoneField: boolean = false;
  security_code: string;
  type: any;
  otpUrl: string = '/2fa-otp';
  send_address: any;
  selectedMethod: any;

  constructor(@Inject('LOCALSTORAGE') private localStorage: any, dataManager: DataManagerService, router: Router, private dialog: MatDialog, snackBar: MatSnackBar,) {
    super();
    this.dataManager = dataManager;
    this.router = router;
    this.snackBar = snackBar;
    this.sendOtpUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_SEND_OTP + this.otpUrl;
    this.verifyOtpUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_VERIFY_OTP + this.otpUrl;
  }

  ngOnInit() {
    this.dataManager.accessTokenExists() ? this.dataManager.createHeaderWithUersAccessToken() : '';
    this.user_data = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER));
    this.selectedMethod = 'email'; // JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER)).preferred_2fa_method;
    if (!this.isNullOrUndefined(this.selectedMethod)) {
      this.show = this.selectedMethod === 'email' ? true : false;
    }
    if (this.user_data) {
      this.user_data['email'] ? this.maskEmail(this.user_data['email']) : console.log();
      this.user_data['notification_phone_number'] ? this.maskPhone(this.user_data['notification_phone_number'].toString()) : console.log();
    }
  }

  changeVerification() {
    this.show = !this.show;
    if (this.show) {
      if (this.isShowEmailField && !this.isShowPhoneField) {
        this.show = false;
        this.isShowEmailField = false;
      } else {
        this.show = true;
        this.isShowPhoneField = false;
      }
    } else {
      this.show = false;
    }
  }

  sendInvite(param) {
    this.security_code = '';
    this.type = param;
    switch (param) {
      case 'sms':
        this.isShowEmailField = false;
        this.isShowPhoneField = true;
        this.show = false;
        break;
      case 'email':
        this.isShowEmailField = true;
        this.isShowPhoneField = false;
        this.show = false;
        break;
    }
    let requestPayload = {
      type: param,
      send_address: param == 'sms' ? this.user_data['notification_phone_number'].toString() : this.user_data['email'],
    };
    this.getOtp(requestPayload);
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.verifyOtp();
    }
  }

  verifyOtp() {
    let requestPayload = {
      type: this.type ? this.type : console.log(this.type),
      auth_code: this.security_code ? this.security_code : console.log(this.security_code),
      send_address: this.type == 'sms' ? this.user_data['notification_phone_number'].toString() : this.user_data['email']
    };
    this.VerifyOtp(requestPayload);
  }

  protected passSendOtpResponse(res) {
    if (res) {
      this.loadFlag = false;
      this.type = res.type;
      this.send_address = res.send_address;
      this.showSnackBar('OTP sent successfully', 'OK', 3000);
    }

  }

  protected passSendOtpError(error) {
    this.loadFlag = false;
    this.errorMsg = error.message;
    const data = {
      message: this.errorMsg, isIcoEnable: true
    };
    const dialog = this.dialog.open(AlertMessageDialogComponent, {data, disableClose: true, width: '500px'});
    dialog.afterClosed().subscribe(value => {
      if (value == true) {
      } else {

      }
    });

  }

  get currentAccountId(): any {
    return JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
  }

  protected passAccountInfoResponse(res) {
    this.loadFlag = false;
    this.dataManager.userAccountInfoObject = res.data;
    let currentAccount = '';
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id === this.currentAccountId) {
        currentAccount = res.data[i].account_name;
      }
    }
    localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_ID, JSON.stringify(res.data));
    localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(currentAccount));
  }

  protected passverifyOtpResponse(res) {
    if (res) {
      this.loadFlag2 = false;
      this.dataManager.user = res.user;
      this.dataManager.menuArray = res.menu ? res.menu : [];
      this.dataManager.permissionsArray = res.permissions;
      this.dataManager.accessToken = res.response_content.token_type + ' ' + res.response_content.access_token;
      this.localStorage.setItem('is_logged', 'true');
      this.dataManager.createHeaderWithUersAccessToken();
      this.SetUserData(res);
      this.getAccountInfo();
      this.getSetting();
      this.routeNavigate('/' + this.dataManager.menuArray[0].routerLink);
    }
  }

  protected passGetSettingResponse(res) {
    const settings = new MerchantSettingModel();
    res.data.forEach(obj => {
      if (obj.hasOwnProperty('display_transactional_metadata')) {
        settings.display_transactional_metadata_id = obj.id;
        settings.display_transactional_metadata = obj.display_transactional_metadata.toString() === '1';
      } else if (obj.hasOwnProperty('tokenize')) {
        settings.tokenize_id = obj.id;
        settings.tokenize = obj.tokenize.toString() === '1';
      } else if (obj.hasOwnProperty('disable_charge_notification_email')) {
        settings.disable_charge_notification_email = obj.disable_charge_notification_email.toString() === '1';
        settings.disable_charge_notification_email_id = obj.id;
      } else if (obj.hasOwnProperty('disable_charge_notification_sms')) {
        settings.disable_charge_notification_sms_id = obj.id;
        settings.disable_charge_notification_sms = obj.disable_charge_notification_sms.toString() === '1';
      } else if (obj.hasOwnProperty('enable_chargeback_notification_email')) {
        settings.enable_chargeback_notification_email_id = obj.id;
        settings.enable_chargeback_notification_email = obj.enable_chargeback_notification_email.toString() === '1';
      } else if (obj.hasOwnProperty('enable_chargeback_notification_sms')) {
        settings.enable_chargeback_notification_sms_id = obj.id;
        settings.enable_chargeback_notification_sms = obj.enable_chargeback_notification_sms.toString() === '1';
      } else if (obj.hasOwnProperty('enable_deposit_notification_email')) {
        settings.enable_deposit_notification_email_id = obj.id;
        settings.enable_deposit_notification_email = obj.enable_deposit_notification_email.toString() === '1';
      } else if (obj.hasOwnProperty('enable_deposit_notification_sms')) {
        settings.enable_deposit_notification_sms_id = obj.id;
        settings.enable_deposit_notification_sms = obj.enable_deposit_notification_sms.toString() === '1';
      } else if (obj.hasOwnProperty('display_signature_pad')) {
        settings.display_signature_pad_id = obj.id;
        settings.display_signature_pad = obj.display_signature_pad.toString() === '1';
      } else if (obj.hasOwnProperty('display_prepaid')) {
        settings.display_prepaid_id = obj.id;
        settings.display_prepaid = obj.display_prepaid.toString() === '1';
      } else if (obj.hasOwnProperty('Enable ACH payments')) {
        settings.enable_ach_payments = obj['Enable ACH payments'].toString() === '1';
      } else if (obj.hasOwnProperty('allowed_ach_types')) {
        settings.allowed_ach_types = obj.allowed_ach_types.toString();
      }
    });
    localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS, JSON.stringify(settings));
  }

  protected passverifyOtpError(error) {
    this.loadFlag2 = false;
    this.errorMsg = error.message;
    if (this.errorMsg === 'Invalid OTP') {
      const data = {
        message: this.errorMsg, confirmBtn: 'Resend code', cancelBtn: 'Reenter code', isIcoEnable: true
      };
      const dialog = this.dialog.open(MessageDialogComponent, {data, disableClose: true});
      dialog.afterClosed().subscribe(value => {
        if (value == true) {
          this.sendInvite(this.type);
        } else {
        }
      });
    } else {
      const data = {
        message: this.errorMsg, confirmBtn: 'Resend code', isIcoEnable: true
      };
      const dialog = this.dialog.open(AlertMessageDialogComponent, {data, disableClose: true, width: '500px'});
      dialog.afterClosed().subscribe(value => {
        if (value == true) {
          this.sendInvite(this.type);
        } else {
        }
      });
    }
  }
}
