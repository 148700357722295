<input type="text" name="customer" (focus)="textOnFocus()" (blur)="textOnBlur()"  (input)="searchTerm.next()"  id="customer" class="form-control" #select="ngModel" [(ngModel)]="selectedValue" autocomplete="off" [disabled] = "disable" [required]="require"  />
<div class="typeahead_container" (mouseenter)="containerOnMouseEnter()"  (mouseleave)="containerOnMouseLeave()" *ngIf="(loadFlag || suggestions) && (flag[0] || flag[1]) ">
 <div class="loader" *ngIf="loadFlag && flag[0]"><i class="fa fa-spinner fa-pulse"></i></div>
  <div class="message" *ngIf="loadFlag && flag[0]">{{message}}</div>
  <ul class="typeahead_list" [ngSwitch]="serviceName" *ngIf="!loadFlag && suggestions !== null">
    <ng-container *ngSwitchCase="'/customers'">
    <li *ngFor=" let suggestion of suggestions" (click)="itemSelected(suggestion)">{{ suggestion.email ? suggestion.email : suggestion.customer_id }} {{suggestion.name ? '- ' + suggestion.name : '' }}</li>
    </ng-container>
    <ng-container *ngSwitchCase="'/plans'">
      <li *ngFor=" let suggestion of suggestions" (click)="itemSelected(suggestion)">{{ suggestion.interval_count > 1 ? (suggestion.name+' ( $'+ ((suggestion.amount / 100)| number : '1.2-2' )  + ' ' + suggestion.currency + ' / ' + suggestion.interval_count + ' ' + suggestion.interval + 's )') : (suggestion.name+' ( $'+ ((suggestion.amount / 100)| number : '1.2-2' )  + ' ' + suggestion.currency + ' / ' + suggestion.interval + ' )' + ' - ' + suggestion.plan_id) }}</li>
    </ng-container>
    <ng-container *ngSwitchCase="'/discounts'">
      <li *ngFor=" let suggestion of suggestions" (click)="itemSelected(suggestion)">{{(suggestion.percent_off ? (suggestion.percent_off + '%') : (('$' + ((suggestion.amount_off / 100) | number : '1.2-2')) + ' USD')) + ' ' }} off {{suggestion.duration}} {{suggestion.discount_id? '(' + suggestion.discount_id + ')': ''}}</li>
    </ng-container>
    <ng-container *ngSwitchCase="'/campaigns'">
      <li *ngFor=" let suggestion of suggestions" (click)="itemSelected(suggestion)">{{ suggestion.name ? suggestion.name : suggestion.customer_id }} {{suggestion.status ? '- ' + suggestion.status : '' }}</li>
      </ng-container>

  </ul>
</div>


