<div class="row">
  <div class="col-xl-10 col-lg-8 col-md-9 col-sm-9 col-10">
    <h3 class="headings">Events & logs</h3>
  </div>
  <div class="col-xl-2 col-lg-4 col-md-3 col-sm-3 col-2 text-right pt-1 exp-m" [ngClass]="{'d-none': showExport === true}">
      <button class="export_btn" (click)="exportexcel()">Export</button>
  </div>
</div>
<div class="event-bdy">
<div class="table_content mt-3-6" *ngIf="showExport === false">
    <div class="row">
      <div class="col-md-12 col-xs-12 mob_no_padding">
        <div (scroll)='scrollcontent("TopScroll", "scrollContent")' *ngIf="!loadFlag" class="top-scroll" id="TopScroll">
          <img src="" height=1 [style]="checkTablewidth()">
        </div>
        <div class="table-responsive table-height"    (scroll)='scrollcontent("scrollContent", "TopScroll")'  id="scrollContent">
          <app-message-on-list *ngIf="!loadFlag && errorMsg" [message]="errorMsg" [iconName]="'warning'"></app-message-on-list>
<table *ngIf="!errorMsg" class="table m-0" id="table-width">
  <thead>
    <tr>
      <th class="event"><a (click)="setSorting('description')">Event
        <i class="fa fa-thin" [ngClass]="orderBy === 'description' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="id"><a (click)="setSorting('id')">ID
        <i class="fa fa-thin" [ngClass]="orderBy === 'id' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
      <th class="date"><a (click)="setSorting('created_at')">Date
        <i class="fa fa-thin" [ngClass]="orderBy === 'created_at' ? stylesortBy : 'fa-angle-down'"></i>
      </a></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="loadFlag">
      <tr class="loader">
        <td colspan="7">
          <div class="loader-container">

            <img class="table-loader" src="../../../../assets/images/loading.svg">
          </div>


        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="!loadFlag && eventList.data.length > 0">
      <tr *ngFor="let event of eventList.data " [routerLink]="redirect_link" [queryParams]="{id: event.id}">
        <td data-th="Event" class="event event-m">
          <div>{{event.description ? (event.description | camalizeStr : true).replace('usd', "USD") : '--'}}</div>
        </td>
        <td class="d-sm-none d-flex"></td>
        <td data-th="ID" class="id">
          <div>{{event.id}}</div>
        </td>
        <td data-th="Date" class="date">
           <div>{{event.created_at ? (event.created_at | dateFormat: 'MM/dd/yyyy hh:mm') : '--'}}</div>
        </td>
      </tr>
    </ng-container>
    <!--pagination start-->

    <!--pagination end-->
  </tbody>


</table>
</div>
          <div class="row m-0 pt-sm-4 pt-2 border-2" *ngIf="!loadFlag && eventList.data.length > 0">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-5" *ngIf="metadata['total']">
            <span class="paginate">
              {{ 1 + (metadata['current_page'] - 1) * limit }} -
              <select [(ngModel)]="limit" (change)="setUrl()" class="page-limit"
                      *ngIf="metadata['current_page'] === 1">
                <option *ngFor="let no of limitArray" [value]="no">{{
                  no
                  }}</option>
              </select>
              <span *ngIf="metadata['current_page'] !== 1">{{
                metadata['count'] +
                (metadata['current_page'] - 1) * limit
                }}</span> &nbsp;of &nbsp;{{ metadata['total'] }}
            </span>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 text-lg-right text-left px-m-0">
              <ul class="list-inline"  *ngIf="!loadFlag && eventList.data.length > 0 && objectKeys(metadata.links).length > 0">
                <li  class="list-inline-item"><button type="button" class="btn btn-default btn_prenext pl-m-2" [disabled]="!metadata.links['previous']"
                                                      (click)="paginate( metadata.links['previous'])">
                  <i class="fa fa-angle-double-left" aria-hidden="true"></i> Previous
                </button></li>
                <li class="list-inline-item" *ngFor="let number of numbers()">
                  <div *ngIf="+number.toString() ">
                    <a class="page-link" *ngIf="metadata.links.next" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links.next)" >{{number}}</a>
                    <a class="page-link" *ngIf="metadata.links['previous'] && metadata['current_page'] > (metadata['total_pages'] - 1)" [ngClass]="{ 'paginate_active': metadata['current_page'] === number }"
                       (click)="nextpage(number, metadata.links['previous'])" >{{number}}</a>
                  </div>
                  <a class="page-link" *ngIf="!+number.toString()" >{{number}}</a>
                </li>
                <li  class="list-inline-item" ><button type="button" class="btn btn-default btn_prenext px-m-0 px-md-0" [disabled]="!metadata.links['next']"
                                                       (click)="
                paginate(metadata.links['next']) ">
                  Next  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button></li>
              </ul>


          </div>
</div>
</div>
</div>
</div>
</div>
<div class="table-content" *ngIf="showExport">
  <app-export-excel [exportExcel]="exportExcel" [serviceName]="exportData"[filterUrl]="filterPostFix" [searchData]="searchText"></app-export-excel>
</div>
