import {Component, DoCheck, OnChanges, OnInit} from '@angular/core';
import {AppConstants} from '../../shared/constants/app-constants';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ProfileDialogComponent} from '../profile-dialog/profile-dialog.component';
import {UpdateContactDialogComponent} from '../update-contact-dialog/update-contact-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseComponent} from '../../abstract/base.component';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.css']
})
export class UserProfileDialogComponent extends BaseComponent implements OnInit {
  name: string;
  get userName(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_NAME));
    return currentUser1;
  }

  get userEmail(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_USER_EMAIL));
    return currentUser1;
  }
  constructor(snackBar: MatSnackBar, dataManager: DataManagerService, private  dialog2: MatDialogRef<UserProfileDialogComponent>, dialog: MatDialog) {
    super();
    this.snackBar = snackBar;
    this.dataManager = dataManager;
    this.alertDialog = dialog;

  }

  ngOnInit(): void {
  }
  myprofile() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(ProfileDialogComponent, dialogConfig)
      .afterClosed();
    // .subscribe(
    //   (res) => {
    //     if (res) {
    //       if (this.localStorage.getItem(FilterConstants.CUSTOMER_FILTER_STORAGE_KEY)
    //         && this.localStorage.getItem(FilterConstants.CUSTOMER_FILTER_COUNT_STORAGE_KEY)) {
    //         console.log(this.filterPostFix);
    //         this.firstCustomerListCall(this.filterPostFix);
    //       } else {
    //         this.firstCustomerListCall();
    //       }
    //     }
    //   }
    // );
  }
  toUpdateContact() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'inventoryPanel';

    this.alertDialog.open(UpdateContactDialogComponent, dialogConfig)
      .afterClosed().subscribe(
      res => {if (res){
        this.showSnackBar('Contact updated successfully.', 'Ok', 3000);
      }}
    );
  }

  signOut() {
    this.dialog2.close(true);
    this.dataManager.logOut();
    // this.assignCopy = null;
    // this.assignCopy = null;
    this.name = null;
    // sessionStorage.clear();
    // localStorage.clear();
  }

}
