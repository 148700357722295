import {Component, Inject, OnChanges, OnInit, DoCheck, PLATFORM_ID, SimpleChanges, ViewChild} from '@angular/core';
import {AppConstants} from '../../shared/constants/app-constants';
import {HomeAction} from './home.action';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Highcharts from 'highcharts';
import StockModule from 'highcharts/modules/stock';
import {Platform} from '@angular/cdk/platform';

StockModule(Highcharts);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent extends HomeAction implements OnInit, OnChanges, DoCheck {

  accountInfo: any;
  Highcharts: any;
  chartOptions: any;
  chartOptionsdonout: Highcharts.Options;
  donoutamnt = [];
  donouutcount = [];
  today = new Date();
  dateSet = AppConstants.homeDayDifference;
  dateUnit = AppConstants.homeDayUnitDifference;
  pieChartData: any;
  lineChartData: any;
  barChartData: any;
  fromDate;
  toDate: Date;
  criteria;
  daySetActive: string;
  daySetActiveOffset: number;
  totalSales: number;
  tv_disputes: number;
  pv_disputes: number;
  p_disputes: number;
  t_disputes: number;
  total_transactions: number;
  netSales: number;
  totalRefunds: number;
  avg_revenue: number;
  refundPercentage: number;
  ta_isv_campaigns: number;
  own_payees: number;
  t_sb_merchants: number;
  userInput: boolean;
  currentResolution: number;
  barchartres = [];
  piechartres = [];
  is_log: string = this.localStorage.getItem('is_logged');
  get userAccount(): any {
    const currentUser1 = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_ID));
    return currentUser1;
  }




  pieChartColorsLables = {
    'R': {color: '#CBCBCB', label: 'Discover'},
    'V': {color: '#4AD291', label: 'Visa'},
    'X': {color: '#121744', label: 'AMEX'},
    'M': {color: '#419DF1', label: 'Master Card'},
    'J': {color: '#92CCDC', label: 'JCB'},
    1: {color: '#419DF1', label: 'Master'},
    'U': {color: '#0eaeee', label: 'Unknown'},
    'I': {color: '#3168A9', label: 'Diners Club'},
    'E': {color: '#3166AC', label: 'EBT Card'},
    'D': {color: '#674EA7', label: 'Debit Card'},
    'A': {color: '#7F6006', label: 'ATM Card'},
    'G': {color: '#6AA84F', label: 'Private Label Gift Card'},
  };


  constructor(dataManager: DataManagerService, private _datePipe: DatePipe, snackBar: MatSnackBar,
              @Inject(PLATFORM_ID) private platformId: any, @Inject('LOCALSTORAGE') private localStorage: any, private platform: Platform) {
    super();
    this.snackBar = snackBar;
    this.Highcharts = Highcharts;
    this.dataManager = dataManager;
    this.daySetActiveOffset = 6;
    this.daySetActive = '1w';
    this.criteria = 'day';
    this.userInput = true;
    this.piechartres = [];
    this.fromDate = this.addDays(new Date(), this.daySetActiveOffset);
    this._getAllStatesUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_REPORTS;
    this.toDate = new Date();
    this.currentResolution = window.screen.width;
    this.loadFlag = true;
    console.log('in constructor', this.fromDate, this.toDate);

    this.setUrls();
    // let created_date;
    // created_date = this.userAccount[0].created_at.date;
    // alert(created_date);

  }

  ngOnInit() {
    this.Highcharts = Highcharts;
    const agent = window.navigator.userAgent.toLowerCase();

    if(this.is_log === 'true')
    {

      if (/iPhone/.test(navigator.userAgent) || (agent.indexOf('chrome') > -1 && !!(<any>window).chrome))
      {
        document.getElementById('body_contain').style.marginTop = '120px';
        this.localStorage.setItem('is_logged', 'false');
      }
      else if(agent.indexOf('chrome') > -1 && !!(<any>window).chrome){

        document.getElementById('body_contain').style.marginTop = '25px';
        this.localStorage.setItem('is_logged', 'false');
      }

      else {

        document.getElementById('body_contain').style.marginTop = '80px';
        this.localStorage.setItem('is_logged', 'false');
      }
    }
    else {
      if (/iPhone/.test(navigator.userAgent) || (agent.indexOf('chrome') > -1 && !!(<any>window).chrome))
      {
        document.getElementById('body_contain').style.marginTop = '0px';

      }
     else if(agent.indexOf('chrome') > -1 && !!(<any>window).chrome){

        document.getElementById('body_contain').style.marginTop = '0px';
      }
      else {

        document.getElementById('body_contain').style.marginTop = '0px';
      }
    }


  }
  ngDoCheck() {
    if (window.screen.width !== this.currentResolution) {
      this.currentResolution = window.screen.width;
    }
  }
  setUrls() {
    this.loadFlag = true;
    this.getAllStates(this._getAllStatesUrl + '?from='
      + this._datePipe.transform(this.fromDate, 'yyyy-MM-dd') +
      '&to=' + this._datePipe.transform(this.toDate, 'yyyy-MM-dd') + '&criteria=' + this.criteria);
  }

  dateSetFun(arg) {
    this.loadFlag = true;
    this.daySetActive = arg.text;
    this.dateUnit = arg.dateUnit;
    this.daySetActiveOffset = arg.offset;
    if (arg.is_today_start) {
      this.toDate = new Date();
      this.fromDate = this.addDaysTD(arg.text);
      this.userInput = false;
    } else if (arg.text === 'All') {
      // if (this.dataManager.userAccountInfoObject) {
      if (this.userAccount[0].created_at) {
        this.toDate = new Date();
        this.fromDate = new Date('2019-01-01');
        // this.fromDate = new Date(this.toDate.getFullYear());
        const diffDays = this.dateDifference(this.fromDate, this.toDate);
        diffDays <= 28 ? this.criteria = 'day' : diffDays <= 150 ? this.criteria = 'week' :
          this.criteria = 'month';
        console.log('all clicked', this.criteria, this.dateDifference(this.fromDate, this.toDate), diffDays);
      } else {
        this.showSnackBar('User account info issue', 'Ok', 3000);
      }
    } else if (arg.text === '1w') {
      this.fromDate = new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate() - 6);
    } else if (arg.text === '4w') {
      this.fromDate = new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate() - 27);
    } else if (arg.text === '1y') {
      console.log(this.toDate.getFullYear() - 1);
      this.fromDate = new Date(this.toDate.getFullYear() - 0.5, this.toDate.getMonth(), this.toDate.getDate() - 1);
    }
    console.log('to and from', this.toDate, this.fromDate);
    if (arg.dateUnit.length > 0) {
      for (const criteria of arg.dateUnit) {
        console.log('set criteria', criteria);
        if (criteria.is_default) {
          this.criteria = criteria.criteria;
        }
      }
    }
    console.log(this.criteria);
    this.setUrls();
  }

  setDateFrom() {
    if (this.toDate < this.fromDate) {
      this.toDate = new Date(this.fromDate);
    }
    console.log('from date', this.fromDate, this.daySetActiveOffset);
    // this.fromDate = this.addDays(this.toDate, this.toDate.getDate(), this.daySetActiveOffset);
    const diffDays = this.dateDifference(this.fromDate, this.toDate);
    diffDays <= 28 ? this.criteria = 'day' : diffDays <= 150 ? this.criteria = 'week' :
      this.criteria = 'month';
    this.dateUnit = [];
    this.daySetActive = '1';
    console.log('to date', this.toDate);
    this.setUrls();
  }

  /**
   * func to set date based on option selected
   * @param date
   * @param days
   * @returns {Date}
   */
  addDays(date: Date, days: number): Date {
    const d = date;
    console.log('no of days to add', days);
    console.log('date from', date);
    d.setDate(date.getDate() - days);
    console.log('after day added to date', date);
    return d;
  }

  addDaysTD(key) {
    const date = new Date();
    switch (key) {
      case 'Mtd':
        date.setDate(1);
        break;
      case 'Qtd':
        if (date.getMonth() <= 2) {
          date.setMonth(1);
        } else if (date.getMonth() > 2 && date.getMonth() <= 5) {
          date.setMonth(3);
        } else if (date.getMonth() > 5 && date.getMonth() <= 8) {
          date.setMonth(6);
        } else if (date.getMonth() > 8 && date.getMonth() <= 11) {
          date.setMonth(9);
        }
        date.setDate(1);
        break;
      case 'Ytd':
        date.setDate(1);
        date.setMonth(0);
        break;
    }
    return date;
  }


  protected passTotalSalesResponse(res) {
    this.totalSales = res.amount;
  }

  setSalesCardTypeResponse(res) {

    this.donoutamnt = [];
    this.donouutcount = [];
    if (!this.isNullOrUndefined(res)) {
      res.forEach(obj => {
        if(!this.isNull(obj.card_type))
        {
        this.donoutamnt.push({
          'name': this.pieChartColorsLables[obj.card_type].label,
          y: obj.card_tranx_percentage,
          'color': this.pieChartColorsLables[obj.card_type].color
        });
        }
        // this.donouutcount.push({
        //   'name': this.pieChartColorsLables[obj.card_type].label,
        //   'percentage': Number(obj.transaction_count),
        //   'color': this.pieChartColorsLables[obj.card_type].color
        // });
      });
    }


    console.log('amnt', this.donoutamnt);
    console.log('cnut', this.donouutcount);

    this.chartOptionsdonout = {

      tooltip: {
        // valueDecimals: 2,
        // valuePrefix: '$',
        formatter: function () {
          console.log('series datataaa', this.y);
          if (!Number.isInteger(this.y)) {
            return '<div>' + this.point.series.name + this.y + '%';
          } else {
            return this.point.series.name + this.y + '%';
          }
          }

      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: 0,
          endAngle: 360,
          center: ['50%', '50%'],
          point: {
            events: {
              legendItemClick: function () {
                const name = this.name;
                const series = this.series.chart.series;
                Highcharts.each(series, function (ob, j) {
                  Highcharts.each(ob.data, function (p, i) {
                    console.log('asdsfsd', p);
                    if (p.name === name) {
                      if (p.visible === true) {
                        try {
                          p.setVisible(false, true);
                        } catch (e) {
                          console.log('Its ok', e);
                        }

                      } else {
                        try {
                          p.setVisible(true, true);
                        } catch (e) {
                          console.log('Its ok', e);
                        }
                      }
                    }
                  });
                });


                return false;
              }
            }
          }

        }
      },

      title: {text: null},
      credits: {
        enabled: false
      },


      series: [{
        showInLegend: true,
        type: 'pie',
        name: '',
        size: '100%',
        innerSize: '70%',
        data: [...this.donoutamnt],

      }],
      legend: {
         // width: 100,
        // x: 20,
        // y: 30,
         // labelFormat: '<div class="container"><div class="row"><div class="col-8">{name}</div><div class="col-4">{y:.2f}</div></div></div>',
        useHTML: true,
        labelFormat: '<div class="pie_legend" ><div class="legend_name">{name}</div><div class="legend_value">{y:.2f}%</div></div>',
        floating: false,
        layout: 'vertical',
        align: 'center',
        x:30,
        itemMarginBottom: 15,
        itemStyle: {
          fontSize: '12px',
          fontFamily: 'Stolzl-Regular',
          fontWeight: 'normal',
          color: '#737376',
          lineWidth: 250,
          width: 250,
        },

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 768,
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
               align: 'center',
            }
          }
        }]
      },
    };

    // pie chart call
    const cardTypeAmount = {};
    cardTypeAmount['data'] = [];
    cardTypeAmount['backgroundColor'] = [];
    cardTypeAmount['tool_type'] = '$';
    const cardTypeTransactionCount = {};
    cardTypeTransactionCount['data'] = [];
    cardTypeTransactionCount['backgroundColor'] = [];
    cardTypeTransactionCount['tool_type'] = '#';
    const pieData = {};
    pieData['datasets'] = [cardTypeAmount, cardTypeTransactionCount];
    pieData['labels'] = [];
    console.log('check empty pie chart data', pieData);
    if (res) {
      if (res.length > 0) {
        for (const card of res) {
          if(!this.isNull(card.card_type)){
          pieData['labels'].push(this.pieChartColorsLables[card.card_type].label);
          pieData['datasets'][0]['data'].push(card.amount / 100);
          pieData['datasets'][0]['backgroundColor'].push(this.pieChartColorsLables[card.card_type].color);
          pieData['datasets'][1]['data'].push(card.transaction_count);
          pieData['datasets'][1]['backgroundColor'].push(this.pieChartColorsLables[card.card_type].color);
          }
        }
      }
    }
    this.pieChartData = pieData;
    console.log(res);
    console.log('response mapped for pie chart', this.pieChartData);
  }

  protected passError(error) {
    this.loadFlag = false;
    console.log(error);
  }


  setVNetGrossResponse(res) {
    console.log('bara arary', res);

    this.chartOptions = {
      title: {
        text: null
      },
      legend: {
        verticalAlign: 'top',
        width: 300,
        margin: 30,
        itemStyle: {
          fontSize: '12px',
          fontFamily: 'Stolzl-Regular',
          fontWeight: 'normal',
          color: '#737376',
        },
      },
      series: [
        {
          name: 'Gross sales',
          data: [],
          type: 'column',
          color: '#4AD291',
          pointWidth: 15,

        },
        {
          name: 'Refunds',
          data: [],
          type: 'column',
          color: '#737376',
          pointWidth: 15,

        },{
          name: 'Dispute',
          data: [],
          type: 'column',
          color: '#89ABF2',
          pointWidth: 15,

        },
      ],

      credits: {
        enabled: false
      },
      xAxis: {
        labels: {
          style: {
            color: '#737376',
            fontSize: '9px',
            fontFamily: 'Stolzl-Regular'
          }
        },
        categories: [],
        title: {
          text: ''
        },
      },
      tooltip: {
        // valueDecimals: 2,
        // valuePrefix: '$',
        formatter: function () {
          return this.series.name + ': $' + Highcharts.numberFormat(this.y, 2, '.', ',') + ' USD';
        },
        style: {
          color: '#737376',
          fontSize: '12px',
          fontFamily: 'Stolzl-Regular'
        }

      },
      yAxis: {
        labels: {
          enabled: this.currentResolution > 767 ? true : false,
          formatter: function () {
            return '$' + Highcharts.numberFormat(this.value, 2, '.', ',') + ' USD';
          },
          style: {
            color: '#419DF1',
            fontSize: '12px',
            fontFamily: 'Stolzl-Medium'
          }
          // format: '${value:,.0f} USD',
          // pointFormat: '{point.percentage:,,.1.f}',

        },
        title: {
          text: '',
        },
      },

    };
    const barChart = {};
    barChart['labels'] = [];

    const gBarChart = {};
    // gBarChart['label'] = 'Gross Sales';
    gBarChart['data'] = [];

    // gLineChart['lineTension'] = 2;
    // gBarChart['backgroundColor'] = 'rgba(30, 169, 224, 0.8)';
    // gBarChart['fill'] = true;

    const nBarChart = {};
    // nBarChart['label'] = 'Refunds';
    nBarChart['data'] = [];

    // nLineChart['borderDash'] = [5, 5];
    // nBarChart['backgroundColor'] = 'rgba(77,83,96,0.2)';
    // nBarChart['fill'] = true;
    const dBarChart = {};
    dBarChart['data'] = [];
    if (res) {
      if (res.length > 0) {
        for (const data of res) {
          console.log('check for type', data.label, (typeof data.label === 'number'));
          if (typeof data.label === 'number') {
            console.log('last of number', data.label.toString().split('').pop());
            if (data.label > 3 && data.label < 21) {
              barChart['labels'].push(data.label + 'th Week');
              this.chartOptions.xAxis.categories = barChart['labels'];
            } else if (data.label.toString().split('').pop() === '1') {
              barChart['labels'].push(data.label + 'st Week');
              this.chartOptions.xAxis.categories = barChart['labels'];

            } else if (data.label.toString().split('').pop() === '2') {
              barChart['labels'].push(data.label + 'nd Week');
              this.chartOptions.xAxis.categories = barChart['labels'];
            } else if (data.label.toString().split('').pop() === '3') {
              barChart['labels'].push(data.label + 'rd Week');
              this.chartOptions.xAxis.categories = barChart['labels'];
            } else {
              barChart['labels'].push(data.label + 'th Week');
              this.chartOptions.xAxis.categories = barChart['labels'];
            }
          } else {
            barChart['labels'].push(data.label);
            // console.log('labref', barChart['labels']);
            this.chartOptions.xAxis.categories = barChart['labels'];
          }

          nBarChart['data'].push(data.refund_amount / 100);
          // console.log('ref', nBarChart['data']);
          gBarChart['data'].push(data.gross_sales / 100);
          dBarChart['data'].push(data.dispute_amount / 100);
          // console.log('gross', gBarChart['data']);

          if (this.chartOptions.series[0].type === 'column') {
            this.chartOptions.series[0].data = gBarChart['data'];
          }
          if (this.chartOptions.series[1].type === 'column') {
            this.chartOptions.series[1].data = nBarChart['data'];
          }
          if (this.chartOptions.series[2].type === 'column') {
            this.chartOptions.series[2].data = dBarChart['data'];
          }
        }
      }
    }

    // barChart['datasets'] = [gBarChart, nBarChart];
    //   this.chartOptions = barChart;
    console.log('data for bar chart', res);
  }


  protected passAllStatesResponse(res) {
    console.log('passAllStatesResponse', res);
    this.loadFlag = false;
    this.netSales = res.n_sales;
    this.avg_revenue = res.avg_tranx;
    this.totalRefunds = res.t_refunds;
    this.total_transactions = res.t_tranx;
    this.netSales = res.n_sales;
    this.refundPercentage = res.p_refunds;
    this.totalSales = res.t_sales;
    this.t_disputes = res.t_disputes;
    this.p_disputes = res.p_disputes;
    this.tv_disputes = res.tv_disputes;
    this.pv_disputes = res.pv_disputes;
    this.barchartres = res.v_net_gross ? res.v_net_gross : [];
    this.piechartres = res.s_card_type ? res.s_card_type : [];
    this.ta_isv_campaigns = res.ta_isv_campaigns;
    this.own_payees = res.own_payees;
    this.t_sb_merchants = res.t_sb_merchants;
    this.setVNetGrossResponse(res.v_net_gross);
    console.log('barchart result', this.barchartres);

    this.setSalesCardTypeResponse(res.s_card_type);

  }




  ngOnChanges(changes: SimpleChanges): void {
  }
}
