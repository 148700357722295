<div class="container delete_block rounded">
  <div class="row">
    <div class="col-md-12 col-lg-12 dialog-head text-center">
      <div class="heading">{{data.head}}</div>
    </div>
  </div>
  <mat-dialog-content class="body delete_cust" fxLayout="row wrap" fxLayoutAlign="center">
    {{data.message}}
  </mat-dialog-content>
  <mat-dialog-content class="body info mb-4" fxLayout="row wrap" fxLayoutAlign="center">
    {{data.info}}
  </mat-dialog-content>
  <mat-dialog-actions class="button-container pb-4" fxLayout="row wrap" fxFlexFill fxLayoutAlign="center"
    fxLayoutGap="2%">
    <button class="settings_btn" [mat-dialog-close]="true">Go to Settings</button>
  </mat-dialog-actions>
</div>
