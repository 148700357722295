import { Directive } from '@angular/core';
import {SettingsAction} from '../../merchant/components/settings/settings.action';
import {BaseComponent} from '../../abstract/base.component';

@Directive()
export  abstract class AdminAction extends BaseComponent {
  protected _sigInUrl: string;
  protected _getAccountInfoUrl: string;

  protected abstract adminSiginResponse(res);
  protected abstract adminSignInError(error);
  protected abstract passAccountInfoResponse(res);
  protected  abstract passError(error);
  getAccountInfo() {
    this.dataManager.getRequest(this._getAccountInfoUrl, 0)
      .subscribe(
        (res) => this.passAccountInfoResponse(res),
        (err) => {
          this.networkErrorHandler(err.error);
          this.passError(err.error);
        }
      );
  }
  adminsigin(postBody) {
    this.dataManager.postRequest(this._sigInUrl, postBody, 0)
      .subscribe(
        res => this.adminSiginResponse(res),
        error => {
          this.networkErrorHandler(error.error);
          this.adminSignInError(error.error);
        }
      );
  }


}
