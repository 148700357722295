import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {AccountDetailsAction} from './account-details.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserAccountModel} from '../../../data/model/UserAccountModel/user_account.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {CollectedfeesModel, CollectedfeesV2Model} from '../../../data/model/CollectedFeesModel/collectedfees.model';
import {ChargeModel, ChargeV2Model} from '../../../data/model/ChargeModel/charge.model';
import {PayoutsModel, PayoutSummaryModel} from '../../../data/model/PayoutsModel/payouts.model';
import {TransactionModel} from '../../../data/model/TransactionModel/transaction.model';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent extends AccountDetailsAction implements OnInit {

  background = '';
  accountDetails: UserAccountModel;
  payoutList: PayoutsModel[] = [];
  collectedFees: CollectedfeesModel[] = [];
  servicenamefees = '/application_fees';
  redirect_link_fees: string;
  redirect_link_payout: string;
  accountEventUrl: string;
  errorMsg: string;
  constructor(route: ActivatedRoute, dataManager: DataManagerService) {
    super();
    this.dataManager = dataManager;
    // this.errorMsg = null;
    this.route = route;
    this.redirect_link_fees = '/collected-fees/collected-fees-details';
    this.redirect_link_payout = '/balance/payout-details';
    // this.payoutList = new ApiResponseModel<PayoutsModel>();
    if ( this.fetchIdFromRouteQueryParams()) {
      this._accountDetailsUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '/' + this.id + '?include=bankaccounts,payoutschedule';
      this._getPayoutsUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PAYOUTS + '/bridge_account_id=' + this.id + '?limit=' + this.limit + '&page=1';
      this._getConnectedFeesUrl = AppConstants.BASE_URL + this.servicenamefees + '/' + this.id;
      // this.payoutEventUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?payout_id=' + this.id;
      this.accountDetails = new UserAccountModel();
      this.accountEventUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?related_id=' + this.id + '&type=account';
      // this.payoutSummary = new PayoutSummaryModel();
    }
    this.getAcountDetails();

  }

  protected passAcountDetailsResponse(res) {
    this.loadFlag = true;
    this.accountDetails = res.data;
    this.getPayout();
    this.getConnectedFess();
  }

  protected passPayout(res) {
    this.loadFlag = false;
    console.log('response on payout', res);
    this.payoutList = res.data;
  }

  protected passConnectedFess(res) {
    this.loadFlag = false;
    this.collectedFees = res.data;
    console.log('response on collectedfees', res);
  }

  protected passAcountDetailsError(err) {
    this.loadFlag = false;
    console.log(err);
    this.errorMsg = err.error.message;
  }
  protected passPayoutError(err) {
    this.loadFlag = false;
    console.log(err);
    this.errorMsg = err.error.message;
  }
  protected passConnectedFessError(err) {
    this.loadFlag = false;
    console.log(err);
    this.errorMsg = err.error.message;
  }
  toggleBackground() {
    this.background = this.background ? '' : 'primary';
  }

  ngOnInit() {
  }

}
