import {BaseComponent} from "../../abstract/base.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class UpdateContactDialogAction extends BaseComponent{
  protected _patchContactUrl: string

  protected patchContact(postBody) {
    this.loadFlag = true;
    this.dataManager.patchRequest(this._patchContactUrl, postBody)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.passPatchContactRes(res);
        },
        err => {
          this.networkErrorHandler(err.error);
          this.loadFlag = false;
          this.passPatchContactErr(err);
        });
  }

  protected abstract passPatchContactRes(res);
  protected abstract passPatchContactErr(err);

}
