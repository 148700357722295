import {BaseModel} from '../BaseModel/base.model';

export class MerchantActivationModel extends BaseModel {
  id: string;
  account_type?: string;
  product_desc?: string;
  website_url?: string;
  business_type?: string;
  tax_id?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  dob_month?: string;
  dob_date?: string;
  dob_year?: string;
  ssnlast4?: string;
  business_name?: string;
  phone_country_code?: string;
  phone_number?: string;
  routing_number?: string;
  account_number?: string;
  country_code?: string;
  dob?: Date;
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
}

export class MerchantProfile extends BaseModel {
  id?: string;
  name?: string;
  address?: string;
  tax_id?: string;
  owner_info?: string;
  banking_info?: string;
  mcc_code?: string;
  settlement_schedule?: string;
  rate?: string[] = [];
  transaction_dollar_volume_ceiling?: number;
  daily_total_transactions_limit?: number;
  daily_total_transaction_dollar_volume_ceiling?: number;
  monthly_total_transaction_dollar_volume ?: number;
  ceiling?: number;
  monthly_total_transactions_limit?: number;
  delayed_funding?: string;
}
