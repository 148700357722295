/**
 * Created by abhijeet on 10/1/18.
 */
import {ApiWrapperMeta} from '../APiWrapper/response-wrapper';

export class ApiResponseModel<T> {
  data?: T[] = [];
  meta?: ApiWrapperMeta;
  status?: string;
  total?: number;

  constructor() {
    this.meta = new ApiWrapperMeta();
  }
}
/**
 * Created by asutosh on 02/01/19.
 */
export class ApiAccessToken {
  status?: string;
  id?: string;
  total?: number;
  client_id?: string;
  client_secret?: any;
  use_count?: any;
  last_used?: Date;
  secret_key?: any;
}
