<div class="container-fluid">
  <div class="row mt-1 icon-div" *ngIf="iconName">
    <div class="col-md-12">
      <div class="icon-container text-center">
        <mat-icon class="caution material-icons-round">{{iconName}}</mat-icon>
      </div>
    </div>
    <div class="col-md-12">
      <div class="mt-3" *ngIf="message">
        <div>
          <div class="msg-text text-center">Oops! {{message}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
