import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CardModel} from '../../data/model/CardModel/card.model';

@Component({
  selector: 'app-default-select',
  templateUrl: './default-select.component.html',
  styleUrls: ['./default-select.component.css']
})
export class DefaultSelectComponent implements OnInit {
dropFlag: boolean;
  @Input() defaultObject: CardModel = new CardModel();
  @Input() cardList: CardModel[] = [];
  @Output() selectedCard: EventEmitter<any> = new EventEmitter<any>();
  card_img_obj = {'R': '../../../../assets/images/disc.png', 'V': '../../../../assets/images/visa.png',
    'X': '../../../../assets/images/amex.png', 'M': '../../../../assets/images/master.png',
    'J': '../../../../assets/images/jcb.png'};

  constructor() {
this.dropFlag = false;


  }



  ngOnInit() {
    console.log('card list: ', this.cardList);

  }

  clickedCard(card: CardModel) {
    console.log(card);
    this.defaultObject = card;
    this.selectedCard.emit(card);
    this.dropFlag = false;
  }
}
