import { ExportModel } from '../../data/model/ExportModel/export-model';


export class ExportConstants {

  public static customerListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      visible: true,
      required: false,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      visible: true,
      required: false,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      visible: true,
      required: false,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      required: false,
      visible: true,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      visible: false,
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'name', value: 'Name'},
        {key: 'email', value: 'Email address'},
        {key: 'desc', value: 'Description' },
        {key: 'created_at', value: 'Created on' },
        {key: 'payment_info', value: 'Payment methods'},
        {key: 'customer_id', value: 'Customer ID' },
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static subscriptionListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      selectedSubOption: null,
      visible: true,
      required: false,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      required: false,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      required: false,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      required: false,
      checked: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'customer', value: 'Customer'},
        {key: 'status', value: 'Status'},
        {key: 'billing_type', value: 'Billing' },
        {key: 'plan', value: 'Plans'},
        {key: 'created_at', value: 'Created on'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static planListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'plan_id', value: 'Plan ID'},
        {key: 'name', value: 'Plan name'},
        {key: 'trial_period_days', value: 'Trial period' },
        {key: 'amount', value: 'Cost'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static discountListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      required: false,
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      required: false,
      checked: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'name', value: 'Discount name'},
        {key: 'terms', value: 'Terms'},
        {key: 'redemptions', value: 'Redemptions' },
        {key: 'discount_id', value: 'Discount ID'},
        {key: 'status', value: 'Status'},
        {key: 'redeem_by', value: 'Expires on'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static roleListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      required: false,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      required: false,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      required: false,
      checked: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      required: false,
      checked: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'name', value: 'Name'},
        {key: 'display_name', value: 'Display name'},
        {key: 'description', value: 'Description' },
        {key: 'created_at', value: 'Created on'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static eventsListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Month',
      fieldKey: 'created_at',
      checked: false,
      key: 'month',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Year',
      fieldKey: 'created_at',
      checked: false,
      required: false,
      key: 'year',
      selectedOption: null,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Date range',
      fieldKey: 'created_at',
      checked: false,
      key: 'dateRate',
      selectedOption: null,
      required: false,
      visible: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'description', value: 'Event' },
        {key: 'id', value: 'ID'},
        {key: 'created_at', value: 'Date'}

      ],
      selectedOption: null
    }
  ];

  public static reviewListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      required: false,
      checked: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'customer', value: 'Customer'},
        {key: 'review_description', value: 'Review description'},
        {key: 'amount', value: 'Amount' },
        {key: 'source_type', value: 'Source type'},
        {key: 'charge_id', value: 'Charge ID'},
        {key: 'action', value: 'Action'},
        {key: 'created_at', value: 'Date'}

      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static invoiceListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      required: false,
      checked: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'amount_paid', value: 'Amount'},
        {key: 'status', value: 'Status'},
        {key: 'billing', value: 'Billing' },
        {key: 'customer', value: 'Customer'},
        {key: 'invoice_number', value: 'Number'},
        {key: 'created_at', value: 'Created on'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static batchTransactionListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Month',
      fieldKey: 'from_date',
      checked: false,
      key: 'month',
      required: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Year',
      fieldKey: 'from_date',
      checked: false,
      required: false,
      key: 'year',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Date range',
      fieldKey: 'from_date',
      checked: false,
      key: 'dateRate',
      selectedOption: null,
      required: false,
      visible: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'settlement_date', value: 'Batch date' },
        {key: 'amounts', value: 'Amounts'},
        {key: 'transactions', value: 'Transactions'},
        {key: 'batch_reference_number', value: 'Batch reference number'}

      ],
      selectedOption: null
    }
  ];

  public static AuthorizationLogListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Month',
      fieldKey: 'from_date',
      checked: false,
      key: 'month',
      selectedOption: null,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Year',
      fieldKey: 'from_date',
      checked: false,
      key: 'year',
      selectedOption: null,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Date range',
      fieldKey: 'from_date',
      checked: false,
      key: 'dateRate',
      selectedOption: null,
      required: false,
      visible: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'report_date', value: 'Date' },
        {key: 'amount', value: 'Authorized amount'},
        {key: 'total_auth', value: 'Authorizations'},
        {key: 'approved', value: 'Approved'},
        {key: 'declined', value: 'Declined'},
        {key: 'declined_percent', value: 'Declined %'}
      ],
      selectedOption: null
    }
  ];
  public static paymentInvoiceListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      required: false,
      checked: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'name', value: 'Name'},
        {key: 'amount', value: 'Amount'},
        {key: 'description', value: 'Description' },
        {key: 'invoice_type', value: 'Invoice type'},
        {key: 'status', value: 'Status'},
        {key: 'created_at', value: 'Created date'}

      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static transactionListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'amount', value: 'Amount'},
        {key: 'currency', value: 'Currency'},
        {key: 'type', value: 'Type'},
        {key: 'id', value: 'Payment ID' },
        {key: 'status', value: 'Status'},
        {key: 'customer_id', value: 'Customer ID'},
        {key: 'customer_email', value: 'Customer Email'},
        {key: 'card_holder_name', value: 'Cardholder name'},
        {key: 'brand', value: 'Card Type'},
        {key: 'payment_info', value: 'Payment information'},
        {key: 'card_level', value: 'Card Level'},
        {key: 'terminal_name', value: 'Terminal ID'},
        {key: 'created_by', value: 'User name'},
        {key: 'created_at', value: 'Transaction date'},
        {key: 'charge_description', value: 'Description'},
        {key: 'transaction_metadata', value: 'Meta data'},
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static AchTransactionListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Today',
      fieldKey: 'created_at',
      checked: false,
      key: 'equals',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Current month',
      fieldKey: 'created_at',
      checked: false,
      key: 'between',
      selectedOption: null,
      visible: true,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous 7 days',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousdays',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Previous month',
      fieldKey: 'created_at',
      checked: false,
      key: 'previousmonths',
      selectedOption: null,
      required: false,
      visible: true,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'all',
      checked: false,
      required: false,
      key: 'all'
    },
    {
      componentType: 'date',
      heading: 'Custom',
      fieldKey: 'created_at',
      visible: false,
      checked: false,
      required: false,
      key: 'custom'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'amount', value: 'Amount'},
        {key: 'type', value: 'Type'},
        {key: 'id', value: 'Charge ID' },
        {key: 'account_type', value: 'Account Type' },
        {key: 'response_message', value: 'Response Message'},
        {key: 'authorization_id', value: 'Authorization ID'},
        {key: 'account_number', value: 'Account Number'},
        {key: 'routing_number', value: 'Routing Number'},
        {key: 'account_holder_name', value: 'Account Holder Name'},
        {key: 'created_at', value: 'Transaction Date'},
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Applied filter',
      key: 'filters',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static disputesListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Month',
      fieldKey: 'report_date',
      checked: false,
      key: 'month',
      selectedOption: null,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Year',
      fieldKey: 'report_date',
      checked: false,
      key: 'year',
      selectedOption: null,
      required: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Date range',
      fieldKey: 'report_date',
      checked: false,
      key: 'dateRate',
      selectedOption: null,
      required: false,
      visible: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'report_date', value: 'Chargeback date' },
        {key: 'case_number', value: 'Case number'},
        {key: 'is_primary', value: 'Primary / Secondary'},
        {key: 'transaction_date', value: 'Transaction date'},
        {key: 'case_amount', value: 'Amount'},
        {key: 'reason_desc', value: 'Reason'},
        {key: 'case_type', value: 'Case type'},
        {key: 'status', value: 'Status'},
        {key: 'card_number', value: 'Card number'},
        {key: 'case_submitted', value: 'Responded'},
        {key: 'responded_date', value: 'Respond by'},
        {key: 'last_updated_date', value: 'Last updated'}
      ],
      selectedOption: null
    },
    {
      componentType: 'general',
      heading: 'All',
      key: 'general',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'general',
      heading: 'Search',
      key: 'search',
      checked: false,
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom: null,
      dateTo: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
  ];
  public static depositSummaryListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Month',
      fieldKey: 'from_date',
      checked: false,
      key: 'month',
      selectedOption: null,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Year',
      fieldKey: 'from_date',
      checked: false,
      key: 'year',
      selectedOption: null,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Date range',
      fieldKey: 'from_date',
      checked: false,
      key: 'dateRate',
      selectedOption: null,
      required: false,
      visible: false,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'settlement_date', value: 'Settlement date'},
        {key: 'amount', value: 'Amount'},
        {key: 'transaction_count', value: 'Transactions'},
        {key: 'line_item', value: 'Line Item'},
        {key: 'batch_reference_number', value: 'Batch reference number'}
      ],
      selectedOption: null
    }
  ];
  public static depositDetailsListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Batch date',
      fieldKey: 'processing_date',
      required: false,
      checked: true,
      key: 'select_date'
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'amount', value: 'Amount'},
        {key: 'trans_type', value: 'Type'},
        {key: 'transaction_date', value: 'Transaction date'},
        {key: 'card_number', value: 'Card Number'},
        {key: 'pos_entry_mode', value: 'Entry Model'},
        {key: 'auth_code', value: 'Auth code invoice'},
        {key: 'batch_ref_num', value: 'Batch reference number'},
      ],
      selectedOption: null
    }
  ];

  public static batchTransactionDetailsListExport: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Batch date',
      fieldKey: 'date',
      checked: false,
      key: 'select_date',
      selectedOption: null,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      visible: true,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Batch reference number',
      fieldKey: 'date',
      checked: false,
      key: 'reference_number',
      required: true,
      selectedOption: null,
      selectedSubOption: null,
      options: [
      ],
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'amount', value: 'Transaction amount' },
        {key: 'trans_type', value: 'Transaction type'},
        {key: 'transaction_date', value: 'Transaction date'},
        {key: 'card_number', value: 'Payment information'},
        {key: 'pos_entry_mode', value: 'Entry mode'},
        {key: 'batch_ref_num', value: 'Batch reference number'}
      ],
      selectedOption: null
    }
  ];
  public static authorizationLogDateDetail: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Authorization date',
      fieldKey: 'date',
      checked: false,
      key: 'select_date',
      selectedOption: null,
      selectedSubOption: null,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      required: false,
      visible: true,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Time',
      fieldKey: 'date',
      checked: false,
      key: 'time',
      required: true,
      selectedOption: null,
      selectedSubOption: null,
      options: [

      ],
      dayCount: null,
      date: null,
      fromTime: null,
      toTime: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'date',
      checked: false,
      required: false,
      key: 'all',
      date: null
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'trans_date', value: 'Date' },
        {key: 'batch_time', value: 'Time'},
        {key: 'amount', value: 'Batch amount'},
        {key: 'total_auth', value: 'Authorization'},
        {key: 'approved', value: 'Approved'},
        {key: 'declined', value: 'Declined'},
        {key: 'declined_percent', value: 'Declined%'},
        {key: 'batch_number', value: 'File reference number'}
      ],
      selectedOption: null
    }
  ];
  public static authorizationLogDetail: ExportModel[] = [
    {
      componentType: 'date',
      heading: 'Authorization date',
      fieldKey: 'date',
      checked: false,
      key: 'select_date',
      selectedOption: null,
      selectedSubOption: null,
      required: false,
      dayCount: null,
      date: null,
      dateFrom : 'from_date',
      dateTo: 'to_date',
      visible: true,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      heading: 'Transaction date',
      fieldKey: 'Transaction_Date_&_Time',
      checked: false,
      key: 'Transaction_date_time',
      selectedOption: null,
      subOption : [],
      selectedSubOption: null,
      options: [

      ],
      dayCount: null,
      date: null,
      fromTime: null,
      toTime: null,
      required: true,
      fromDateTime: null,
      toDateTime: null,
      inputValue: null,
      number: null,
      numberFrom: null,
      numberTo: null
    },
    {
      componentType: 'date',
      required: true,
      heading: 'Status',
      fieldKey: 'status',
      checked: false,
      key: 'status',
      selectedOption: null,
      selectedSubOption: null,
      options: [

      ]
    },
    {
      componentType: 'date',
      heading: 'All',
      fieldKey: 'date',
      checked: false,
      key: 'all',
      date: null,
      visible: false
    },
    {
      componentType: 'list',
      heading: 'Columns',
      fieldKey: 'selected_columns',
      checked: false,
      options: [
        {key: 'date', value: 'Date' },
        {key: 'time', value: 'Time'},
        {key: 'authorized_amount', value: 'Amount'},
        {key: 'status', value: 'Status'},
        {key: 'captured', value: 'Captured'},
        {key: 'approval_code', value: 'Authorization number'},
        {key: 'merchant_name', value: 'Descriptor'},
        {key: 'pos_entry_mode', value: 'POS entry mode'},
        {key: '3d_secure', value: '3D Secure'},
        {key: 'primary_account_number', value: 'Card number'}
      ],
      selectedOption: null
    }
  ];
}
