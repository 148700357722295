import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {CollectedfeesModel} from '../../../data/model/CollectedFeesModel/collectedfees.model';
import {AppConstants} from '../../../shared/constants/app-constants';
import {CollectedFeesListAction} from './collected-fees-list.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {FilterConstants} from '../../../shared/constants/filter-constants';
import {PermissionConstants} from '../../../shared/constants/permission-constants';
import { Subject } from "rxjs";
import { BaseListComponent } from "../../../abstract/base-list.component";
import { ErrorConstants } from "../../../shared/constants/error-constants";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-collected-fees-list',
  templateUrl: './collected-fees-list.component.html',
  styleUrls: ['./collected-fees-list.component.css']
})
export class CollectedFeesListComponent extends BaseListComponent implements OnInit {

  collected_fees: ApiResponseModel<CollectedfeesModel>;
  perPage = 8;
  serviceName = '/application_fees';
  disablePrev: boolean;
  disableNext: boolean;
  totalRecords?: number;

  constructor(dataManager: DataManagerService,
              router: Router, @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.router = router;
    this.errorMsg = null;
    this.redirect_link = '/collected-fees/collected-fees-details';
    this.collected_fees = new ApiResponseModel<CollectedfeesModel>();
    //this._getcollectedfees = AppConstants.BASE_URL + this.serviceName;
    this.searchHits = new Subject();
    this.getListUrl = AppConstants.BASE_URL + this.serviceName;
    this.dataManager = dataManager;
    // this.transferComponent.filter = FilterConstants.couponListFilter;
    // this.transferComponent.create_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_COUPON);
    // this.transferComponent.filter_storage_key = FilterConstants.COUPON_FILTER_STORAGE_KEY;
    // this.transferComponent.count_storage_key = FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY;
    this.filterSubscription = this.dataManager.filterUrl.subscribe(
      filterUrl => {
        console.log('lsit called', filterUrl);
        this.dataManager.prev_url = filterUrl;
        this.filterPostFix = filterUrl;
        //this.firstCallToList(filterUrl);
        this.setUrl();
      },
      err => console.log(err)
    );
  }

  // firstCallToList(filterUrl = null) {
  //   this.loadFlag = true;
  //   const url = this._getcollectedfees ;
  //   this.getCollectedFeesData(url);
  // }


  ngOnInit() {
    this.searchHits.pipe(debounceTime(600),).subscribe((res) => {
      if (!this.isNullOrUndefined(this.searchText)) {
        this.setUrl();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(this.filter_storage_key)
        && !this.localStorage.getItem(this.count_storage_key)) {
        console.log('first call for list');
      //  this.firstCallToList();
      this.setUrl();
      }
      // else if (this.transferComponent.back_press) {
      //   // console.log('prev url', this.prev_url);
      //   this.firstCallToList(this.dataManager.prev_url);
      // }
    }
    // this.transferComponent.createDialogActionResult.subscribe(
    //   (result) => {
    //     if (result) {
    //       if (!this.localStorage.getItem(FilterConstants.COUPON_FILTER_STORAGE_KEY)
    //         && !this.localStorage.getItem(FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY)) {
    //         console.log('first call for list');
    //         this.firstCallToList();
    //       } else  {
    //         this.firstCallToList(this.filterPostFix);
    //       }
    //     }
    //   }
    // );
  }

  paginate(paginateExtension) {
    this.getList(paginateExtension);
  }

  protected getListRes(response: any) {
    this.loadFlag = false;
    this.collected_fees = response;
    if (response.data.length === 0) {
      this.errorMsg = ErrorConstants.collectedFeesErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
    // this.totalRecords = response.meta.pagination.total;
    // if (response.meta.pagination.links.next) {
    //   this.disableNext = true;
    //   console.log(response.meta.pagination.links.next, this.disableNext);
    // } else {
    //   this.disableNext = false;
    // }
    // if (response.meta.pagination.links.previous) {
    //   this.disablePrev = true;
    //   console.log(response.meta.pagination.links.previous, this.disablePrev);
    // } else {
    //   this.disablePrev = false;
    // }

  }
  protected getListErr(error: any) {
    this.errorMsg = this.networkErrorHandler(error.error, ErrorConstants.collectedFeesErrorMsg);
    this.loadFlag = false;
  }
}
