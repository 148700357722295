import {BaseListComponent} from '../../../abstract/base-list.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";

@Directive()
export abstract class PayeeSummaryAction extends BaseListComponent {
    //put variables here
    protected getListUrl: string;
    protected editCampaignPostUrl:string;
    protected campaignId:string;
    



    protected deletePayeeApi(url){
        this.loadFlag = true;
        this.dataManager.deleteRequest(url)
        .subscribe((res)=>{
            this.loadFlag = false;
            this.passDeleteResponse(res);
        },
        error=>{
            this.loadFlag = false;
            this.passError(error);
            this.networkErrorHandler(error);
        })

    }
   
    protected abstract passDeleteResponse(response);
  
    protected abstract passError(error);
}