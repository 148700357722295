/**
 * Created by abhijeet on 9/2/18.
 */
export class CardModel {
  id?: number;
 name?: string;
 // TODO remove & replace address_line1 and address_line2 from project
  card_holder_name?: string;
  card_number?: string;
  address_line1?: string;
  address1?: string;
  address_line2?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: number;
  card_source: string;
  country?: string;
  card_brand?: string;
  brand?: string;
  exp_month?: string;
  is_default?: boolean;
  exp_year?: number;
  token?: string;
  ext_token?: string;
  last4?: any;
  cvc_check_code?: any;
  avs_check_code?: any;
  customer_id?: number;
  type?: string;
  last_four_digits?: string;
  first6digit?: string;
  last4digit?: string;
  zip_check_passed?: boolean;
  authorize_card?: boolean;
  address_check_passed?: boolean;
  email_to_send?: string;
  fingerprint?: string;
  is_verified?: number;
  exp?: string;
  bank_name?: string;
  bank_phone?: string;
  bank_website?: string;
  bin_country?: string;
  card_type?: string;
}
