import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminAction} from './admin.action';
import {AppConstants} from '../../shared/constants/app-constants';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {isPlatformBrowser} from '@angular/common';
import {AlertMessageDialogComponent} from '../../dialogs/alert-message-dialog/alert-message-dialog.component';
import {SettingsComponent} from '../../merchant/components/settings/settings.component';

@Component({
  selector: 'app-admin',
  template: ''
})
export class AdminComponent extends AdminAction implements OnInit {
  token: string;
  urlBatchRedirect: string;
  name: string;
  currentUserName: string;
  settings: SettingsComponent;
  constructor(protected route: ActivatedRoute, dataManager: DataManagerService, private dialog: MatDialog,
              @Inject('LOCALSTORAGE') private localStorage: any, snackBar: MatSnackBar, router: Router,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
    this.snackBar = snackBar;
    this._sigInUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_LOGIN;
    this.dataManager = dataManager;
    this.router = router;
    this.urlBatchRedirect='';

  }

  get currentAccountId(): any {
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    return currentAccountid;
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params['redirect_url'] !== undefined) {
      this.urlBatchRedirect = params['redirect_url'];
        this.urlBatchRedirect = atob(this.urlBatchRedirect)
        console.log('deyan',this.urlBatchRedirect);
      }
    });
    this.dataManager.logOut();
    this.name = null;
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      console.log(this.token);
      const postBody = {};
      postBody['token'] = this.token;
      this.adminsigin(postBody);
    });
  }

  protected passError(error) {
    this.loadFlag = false;
    console.log(error);
    if (error) {
      const data = {
        message: error.message,
        disableClose: true
      };

      const dialog = this.dialog.open(AlertMessageDialogComponent, {data});

      dialog.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          console.log();
          this.dataManager.logOut();
        }
      });

    }
  }

  protected passAccountInfoResponse(res) {
    console.log('account info', res);
    this.loadFlag = false;
    this.dataManager.userAccountInfoObject = res.data;
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id === this.currentAccountId) {
        this.currentUserName = res.data[i].account_name;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_ID, JSON.stringify(res.data));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME, JSON.stringify(this.currentUserName));

      // this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_MENU, JSON.stringify(res.menu));
      // this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS, JSON.stringify(res.permissions));
      // this.localStorage.setItem(AppConstants.STORAGE_KEY_USER, JSON.stringify(res.user));
    }
  }

  protected adminSiginResponse(res) {

    this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN, res.response_content.token_type
      + ' ' + res.response_content.access_token);
    if(res.menu[4]) {
      res.menu[4].subMenu = [{routerLink: 'batch-detail'}];
    }
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_MENU, JSON.stringify(res.menu));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_FIRST_MENU, JSON.stringify(res.menu[0].name));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS, JSON.stringify(res.permissions));
    res.user.login_as_merchant = res.login_as_merchant;
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER, JSON.stringify(res.user));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_ID, JSON.stringify(res.user_id));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(res.current_account_id));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_NAME, JSON.stringify(res.user.name));
    this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_EMAIL, JSON.stringify(res.user.email));
    this.dataManager.accessToken = res.response_content.token_type + ' ' + res.response_content.access_token;
    this.dataManager.createHeaderWithUersAccessToken();
    this._getAccountInfoUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '?limit=100&page=1&preferred=' + res.current_account_id;
    if (res.impersonating_user_id !== undefined) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_IMPERSONATE_USER_ID, JSON.stringify(res.impersonating_user_id));
      this._getAccountInfoUrl += '&impersonating_user_id=' + JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_IMPERSONATE_USER_ID));
    }
    this.getAccountInfo();
    this.settings = new SettingsComponent(this.dataManager, this.localStorage);
    this.showSnackBar('Successfully logged ln', 'OK', 3000);
    this.dataManager.menuArray = res.menu ? res.menu : [];
    if(this.dataManager.menuArray[4]){
      this.dataManager.menuArray[4].subMenu =[{routerLink: 'batch-detail'}]
    }
    // console.log('menu',this.dataManager.menuArray[4] && this.dataManager.menuArray[4].subMenu)
    this.dataManager.permissionsArray = res.permissions;
    this.dataManager.user = res.user;
    console.log('70240126 menu', this.dataManager.menuArray,this.urlBatchRedirect);
    if (this.urlBatchRedirect !== '') {
      const pattern = /reference_number=(\d+)&date=(\d{4}-\d{2}-\d{2})/;
      const result = this.urlBatchRedirect.match(pattern);
      let reference_number = result[1];
      let date = result[2];
      this.router.navigate(['/payment/'+ this.dataManager.menuArray[4].subMenu[0].routerLink],{queryParams:{reference_number,date}});

    } else {
      this.routeNavigate('/'+ this.dataManager.menuArray[0].routerLink );

    }


  }
  protected passGetSettingError(error) {

  }

  protected adminSignInError(error) {
  }

}
