<form [formGroup]="addCustomerCard" #addCardForm="ngForm">
  <div *ngIf="showCreditCardFields">
      <div *ngIf="showCreditCardFields"  class="form-group row align-items-center">
        <label for="card_number" class="col-sm-5 col-12 col-form-label">Card number:<i class="required-icon">*</i></label>
        <span class="error_sign" id="card_number1">!</span>
        <div class="col-sm-7 col-12">
          <!--<input class="form-control" placeholder="•••• •••• •••• ••••" [(ngModel)]="customerCard.card_number"-->
          <!--type="tel" name="card_number" id="card_number" required autocomplete="cc-number"-->
          <!--value="" ccNumber appAmountValidator [maxLength]="23" formControlName="creditCard" (input)="getCardType($event)"/>-->
          <input class="form-control" value="" name="card_number" id="card_number" required autocomplete="cc-number"
                (keypress)="numberOnly($event)" type="text" placeholder="•••• •••• •••• ••••"
                [(ngModel)]="customerCard.card_number" (input)="getCardType($event); onCardDataChange()" formControlName="creditCard"
                maxlength="23" [class.invalid]="addCustomerCard.controls['creditCard'].invalid && addCardForm.submitted">
          <img class="create-card-img" [src]="card_img_obj[type] ? card_img_obj[type] : card_img_obj['default']"/>
          <span class="validation-err-msg"
                *ngIf="addCardForm.submitted && addCustomerCard.controls['creditCard'].errors?.required">Card number is required.</span>
          <span class="validation-err-msg"
                *ngIf="addCardForm.submitted && addCustomerCard.controls['creditCard'].errors?.maxLength">Card number limit exceeds.</span>
          <span class="validation-err-msg"
                *ngIf="addCardForm.submitted && !addCustomerCard.controls['creditCard'].errors?.required && addCustomerCard.controls['creditCard'].invalid">Card number is invalid.</span>
        </div>

      </div>
      <div  class="form-group row align-items-center mt-sm-0 mt-4">
        <label class="col-sm-5 col-12 col-form-label">Expiry<i class="required-icon">*</i> /CVV:<i class="required-icon"
                                                                                        *ngIf="cvvoptional === 0">*</i></label>
        <div class="col-sm-4 col-4 pr-0">
          <input class="form-control" [(ngModel)]="customerCard.exp" type="tel" (input)="onCardDataChange()"
                [class.invalid]="addCustomerCard.controls['expDate'].invalid && addCardForm.submitted"
                name="expiry" id="expiry" required formControlName="expDate"
                autocomplete="cc-exp" placeholder="MM/YYYY" ccExp/>
          <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['expDate'].errors?.required">Expiry date is required.</span>
          <span class="validation-err-msg" *ngIf="addCardForm.submitted && !addCustomerCard.controls['expDate'].errors?.required
              && addCustomerCard.controls['expDate'].invalid">Invalid expiry.</span>

        </div>
        <div class="col-sm-3 col-3 pr-3">
          <input class="form-control" [(ngModel)]="customerCard.cvc_check_code" type="text"
                [class.invalid]="addCustomerCard.controls['cvc'].invalid && addCardForm.submitted"
                (keyup)="checkCvc(customerCard.cvc_check_code)" (input)="onCardDataChange()"
                name="cvc" id="cvc" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                required (keypress)="numericOnly($event)" [required]="cvvoptional === 0"
                autocomplete="off" formControlName="cvc" placeholder="•••" appAmountValidator maxLength="4" ccCvc/>
          <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['cvc'].errors?.required">Cvv is required.</span>
          <span class="validation-err-msg"
                *ngIf="addCardForm.submitted && !addCustomerCard.controls['cvc'].errors?.required && addCustomerCard.controls['cvc'].invalid">Invalid cvv.</span>
          <span class="validation-err-msg"
                *ngIf="!addCustomerCard.controls['cvc'].invalid && cvcError === true || addCustomerCard.controls['cvc'].errors?.minLength">Invalid cvv.</span>
        </div>
      </div>
      <div class="form-group row align-items-center mt-sm-0 mt-4">
        <label for="card_holder_name" class="col-sm-5 col-12 col-form-label">Authorize:</label>
        <div class="col-md-7">
          <input type="checkbox" class="form-check-input" name="authInp " [(ngModel)]="customerCard.authorize_card"
                (input)="onCardDataChange()" formControlName="auth"/><label class="form-check-label auth-label">By checking this box, you will authorize
          the customer's card for $0.00 which may result in additional fees. Failure to authorize card may result in failed
          subscriptions.</label>
        </div>
      </div>
      <div  class="row align-items-center mt-sm-0 mt-4">
        <label for="card_holder_name" class="col-sm-5 col-12 col-form-label">Cardholder name:<i class="required-icon">*</i></label>
        <span class="error_sign" id="card_holder_name1">!</span>
        <div class="col-sm-7 col-12">
          <input class="form-control" name="uname" required formControlName="uname" [(ngModel)]="customerCard.name" (input)="onCardDataChange()"
                type="text"
                value="" maxlength="200" [class.invalid]="addCustomerCard.controls['uname'].invalid && addCardForm.submitted"
                id="card_holder_name"/>
          <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['uname'].errors?.required">Cardholder name is required.</span>
          <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['uname'].errors?.maxLength">Cardholder name limit exceed.</span>
        </div>
      </div>
  </div>
  <div class="row mt-sm-0 mt-4">
    <div class="col-md-12 col-lg-12 col-sm-12 col-12">
      <p class="head">Address details</p>
    </div>
  </div>
  <div class="form-group row align-items-center">
    <label for="address_line1" class="col-sm-5 col-12 col-form-label">Billing address:<i class="required-icon" *ngIf="addresopt === 0">*</i></label>
    <span class="error_sign" id="address_line11">!</span>
  

    <div class="col-sm-7 col-12">
      <div *ngIf="isApiLoaded">
        <input class="form-control" type="text" id="address_line1" name="address_line1" placeholder="Address line 1"
               [(ngModel)]="customerCard.address1" maxlength="200"
               [required]="addresopt === 0"
               [class.invalid]="addCustomerCard.controls['Street'].invalid && addCardForm.submitted"
               formControlName="Street"
               ngx-google-places-autocomplete
               [options]="options"
               (onAddressChange)="handleAddressChange($event)" />
        <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['Street'].errors?.required">Address line 1 is required.</span>
        <span class="validation-err-msg-green" *ngIf="flagAddressChange">Caution you have touched the fields below.</span>
        <span class="validation-err-msg-green" *ngIf="flagAddressChange">No worries. We will record both changes</span>
      </div>
    </div>
  </div>
  <div class="form-group row align-items-center">
    <label for="address_line2" class="col-sm-5 col-12 col-form-label"></label>
    <span class="error_sign" id="address_line21">!</span>
    <div class="col-sm-7 col-12">
      <input class="form-control"  [(ngModel)]="customerCard.address_line2" type="text" value="" id="address_line2"
             maxlength="200" (input)="onCardDataChange()"
             formControlName="line2" name="address_line2"
             [class.invalid]="addCustomerCard.controls['line2'].invalid && addCardForm.submitted">
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['line2'].errors?.maxLength">Address line 2 limit exceed.</span>
    </div>
  </div>
  <div class="form-group row align-items-center">
    <label for="City" class="col-sm-5 col-12 col-form-label">City:<i class="required-icon" *ngIf="addresopt === 0">*</i></label>
    <div class="col-sm-7 col-12">
      <input class="form-control"  name="City"
             [class.invalid]="addCustomerCard.controls['City'].invalid && addCardForm.submitted"
             [(ngModel)]="customerCard.city" type="text" value="" id="City" maxlength="200"
             [required]="addresopt === 0" formControlName="City" (input)="onCardDataChange()"/>
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['City'].errors?.required">City is required.</span>
    </div>
  </div>
  <div class="form-group row align-items-center">
    <label for="zip" class="col-sm-5 col-12 col-form-label">Zip code:<i class="required-icon"
                                                              *ngIf="addresopt === 0">*</i></label>
    <span class="error_sign" id="zip1">!</span>
    <div class="col-sm-7 col-12">
      <input class="form-control"  [(ngModel)]="customerCard.zip" type="text" value="" name="zip" id="zip"
             pattern="^[0-9]{5}(?:-[0-9]{4})?$" (input)="onCardDataChange()"
             [required]="addresopt === 0" placeholder="Zip code"
             [class.invalid]="addCustomerCard.controls['zip'].invalid && addCardForm.submitted" formControlName="zip"
             maxlength="10" (keypress)="numericOnly($event)"/>
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['zip'].errors?.maxLength">Zip code limit exceed.</span>
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['zip'].errors?.required">Zip code is required.</span>

    </div>
  </div>
  <div class="form-group row align-items-center mt-sm-0 mt-2">
    <label for="state" class="col-sm-5 col-12 col-form-label">State/Province:<i class="required-icon"
                                                                      *ngIf="addresopt === 0">*</i></label>
    <div class="col-sm-7 col-12">
      <!--          <input class="form-control" [(ngModel)]="customerCard.state" type="text" value="" id="state" name="state" maxlength="200"-->
      <!--                 formControlName="state"/>-->
      <select  class="form-control" id="state" name="state" [required]="addresopt === 0"
              formControlName="state" [(ngModel)]="customerCard.state" (input)="onCardDataChange()"
              [class.invalid]="addCustomerCard.controls['state'].invalid && addCardForm.submitted">
        <option [value]="null" disabled selected>Choose a state</option>
        <option [value]="null"  >No selection</option>

        <option *ngFor="let state of states" [value]="state.key">{{state.name}}</option>
      </select>
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['state'].errors?.required">State/Province is required.</span>

    </div>
  </div>
  <div class="form-group row align-items-center mt-sm-0 mt-2">
    <label for="Country" class="col-sm-5 col-12 col-form-label">Country:<i class="required-icon"
                                                                 *ngIf="addresopt === 0">*</i></label>
    <div class="col-sm-7 col-12">
      <!--          <input class="form-control" [(ngModel)]="customerCard.country" type="text" value="" id="Country" maxlength="200"-->
      <!--                 name="Country"-->
      <!--                 formControlName="Country"/>-->
      <select  class="form-control" id="country" name="country" formControlName="Country"
              [(ngModel)]="customerCard.country"
              [required]="addresopt === 0" (input)="onCardDataChange()"
              [class.invalid]="addCustomerCard.controls['Country'].invalid && addCardForm.submitted" type="text">
          <option [value]="null" disabled>Choose a country</option>
          <option [value]="null" >No selection</option>

        <option *ngFor="let country of countries"
                [value]="country.key">{{country.name}}
        </option>
      </select>
      <span class="validation-err-msg" *ngIf="addCardForm.submitted && addCustomerCard.controls['Country'].errors?.required">Country is required.</span>
    </div>
  </div>
  <button style="display: none"  type="submit" #addCardSubmitButton>Submit</button>
</form>
