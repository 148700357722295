import {BaseComponent} from '../../abstract/base.component';
import { Directive } from "@angular/core";
/**
 * Created by arun on 28/6/18.
 */
@Directive()
export abstract class HomeAction extends BaseComponent {


  protected _getAllStatesUrl: string;



  getAllStates(url) {
    this.dataManager.getRequest(url, 0)
      .subscribe(
        (res) => this.passAllStatesResponse(res),
        (err) => {
          this.networkErrorHandler(err.error);
          this.passError(err.error);
        }
      );
  }






  protected abstract passAllStatesResponse(res);

  protected abstract passError(error);
}
