/**
 * Created by arun on 30/5/18.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot} from '@angular/router';
import {DataManagerService} from '../DataManager/data-manager.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  protected _router: Router;
  protected _dataManager: DataManagerService;

  constructor(router: Router, dataManager: DataManagerService) {
    this._router = router;
    this._dataManager = dataManager;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._dataManager.accessTokenExists()) {
      return true;
    }
    this._router.navigate(['/signin']);
    return false;
  }
}
