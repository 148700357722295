import { BaseComponent } from "../../../abstract/base.component";
import { Directive } from "@angular/core";
import { AppConstants } from "../../../shared/constants/app-constants";

@Directive()
export abstract class CreateNewSusbcriptionAction extends BaseComponent {
  protected _subscriptionPostUrl: string;
  protected _subscriptionUpdateUrl: string;
  protected  _getCustomerDetailsUrl: string;
  protected  _getCouponDetailsUrl: string;
  brandgetAddress = '/accounts';
  protected account_name: string;

  postSubscription(postBody) {
    this.dataManager.postRequest(this._subscriptionPostUrl, postBody, 0)
      .subscribe(
        response => this.passSubscriptionResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passSubscriptionError(error);
        }
      );
  }

  protected abstract passSubscriptionResponse(response);
  protected abstract passSubscriptionError(error);

  getAccountDetails() {
    //this method is called only to get is the ACH payment is enabled or not
    this.account_name = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    this.dataManager.getRequest(AppConstants.BASE_URL + this.brandgetAddress + '/' + this.account_name + '?include=enableAchPayments', 0)
    .subscribe(
      res => {
        this.assignFlagEnableAchPayments(res);
      },
      err => {
        this.loadFlag = false;
        console.log('PROBLEM getting data for charges metadata');
      }
    );

  }
  protected abstract assignFlagEnableAchPayments(res);

  updateSubscriptionDetails(object) {
    console.log('updateSubscriptionDetails',this._subscriptionUpdateUrl, object);
    
    this.dataManager.patchRequest(this._subscriptionUpdateUrl, object)
      .subscribe(
        res => this.passUpdateResponse(res),
        error => {
          this.networkErrorHandler(error);
          this.passUpdateError(error);
        }
      );
  }
  protected abstract passUpdateResponse(res);
  protected abstract passUpdateError(error);


  getCouponDetails() {
    this.dataManager.getRequest(this._getCouponDetailsUrl, 0)
      .subscribe(
        res => this.passCouponDetailsResponse(res),
        err => {
          this.networkErrorHandler(err);
          this.passCouponDetailsError(err);
        });
  }
  protected abstract passCouponDetailsResponse(res);
  protected abstract passCouponDetailsError(error);
}
