import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
// import {CreatNewAccountAction} from './creat-new-account.action';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {CreatNewAccountAction} from './creat-new-account.action';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {ToolBarComponent} from '../../components/tool-bar/tool-bar.component';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-create-new-account-dialog',
  templateUrl: './create-new-account-dialog.component.html',
  styleUrls: ['./create-new-account-dialog.component.css']
})
export class CreateNewAccountDialogComponent extends CreatNewAccountAction implements OnInit {
  errorMsgFlag: boolean;
  source_id: string;
  account_name: string;
  errorMsg: string;
  topErrorMessage: string;
  errMsg: string;
  keys: String[];
  keys1: String[];
  serviceName: string;
  switchAccountId: string;
  constructor(private dialogRef: MatDialogRef<CreateNewAccountDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, dataManager: DataManagerService,
              @Inject('LOCALSTORAGE') private localStorage: any, _router: Router,
              @Inject(PLATFORM_ID) private platformId: any) {
    super();
    console.log(data);
    this.router = _router;
    this.dataManager = dataManager;
    this.errorMsgFlag = false;
    this._getAccountInfoUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '?limit=100' + '&page=1' ;
    this.serviceName = '/switch';
  }

  ngOnInit() {
  }
  createAccount() {
    this.loadFlag = true;
    const postBody = {};
    postBody['name'] = this.account_name ? this.account_name : null;
    this.createAccountUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT ;
    this.postAccount(this.createAccountUrl, postBody);
    localStorage.removeItem(AppConstants.STORAGE_KEY_ACCOUNT_ID);
    localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID);
    localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    // this._compiler.clearCache();
  }
  switchaccount(value) {
    this._getaccount = AppConstants.BASE_URL + this.serviceName + '/' + value;
    this.getSwitchAccount(this._getaccount);
  }

  protected passResponse(response) {
    console.log(response, 'asu');
    this.loadFlag = false;
    this.dialogRef.close(true);
    this.getAccountInfo();
    this.switchAccountId = response.data.id;
    this.router.navigate(['loading..']);
  }

  protected passError(error) {
    this.errorMsgFlag = true;
    this.loadFlag = false;
    this.errMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    let i;
    let j;
    let name = ['name'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }
  protected passAccountInfoResponse(response) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_ID, JSON.stringify(response.data));
    }
    this.switchaccount(this.switchAccountId);
    console.log(response);
  }
  protected passAccountInfoError(error) {
    console.log(error);
  }
  protected passAccounts(res) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID, JSON.stringify(res.current_account_id));
    }
    console.log('response on accounts', res);
    this.loadFlag = true;
    this.dataManager.createHeaderWithUersAccessToken();
    this.dataManager.menuArray = res.menu ? res.menu : [];
    this.dataManager.permissionsArray = res.permissions;
    this.routeNavigate('home');
    this.dataManager.appLoaderFlag(true);
  }

  protected passAcountError(err) {
    this.loadFlag = false;
    this.routeNavigate('home');
    console.log(err);
  }
}
