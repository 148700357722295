import {BaseComponent} from '../../abstract/base.component';
import { Directive } from '@angular/core';

@Directive()
export abstract class ToolBarAction extends BaseComponent {
  protected _logoutUrl: string;
  protected _getaccount: string;
  protected _registerUrl: string;

  protected abstract passLogOutErrorResponse(err);
  protected abstract passLogOutResponse(res);
  protected abstract passAccounts(res);
  protected abstract passAcountError(error);
  protected abstract passcreatNewAccount(response);
  protected abstract passcreatNewAccountError(error);

  getSwitchAccount(postBody) {
    this.dataManager.postRequest(this._getaccount, postBody, 0)
      .subscribe(
        res => {
          this.passAccounts(res);
        },
        error => {
          this.networkErrorHandler(error.error);
          this.passAcountError(error.error);
        }
      );
  }
  creatNewAccount(postBody) {
    this.dataManager.login(this._registerUrl, postBody)
      .subscribe(
        response => this.passcreatNewAccount(response),
        error => {
          this.networkErrorHandler(error.error);
          this.passcreatNewAccountError(error.error);
        }
      );
  }
}
