import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {AppConstants} from '../../../shared/constants/app-constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {CardModel} from '../../../data/model/CardModel/card.model';
import {GetCustomerDetailsAction} from './get-customer-details.action';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {CustomerACHCharge, CustomerCharge, CustomerV2Model} from '../../../data/model/CustomerV2Model/customer-v2.model';
import {MessageDialogComponent} from '../../../dialogs/message-dialog/message-dialog.component';
import {AlertMessageDialogComponent} from '../../../dialogs/alert-message-dialog/alert-message-dialog.component';
import {SubscriptionModel} from '../../../data/model/SubscriptionModel/subscription.model';
import {ApiResponseModel} from '../../../data/model/ApiResponseModel/api-response.model';
import {InvoicesModel} from '../../../data/model/InvoicesModel/invoices.model';
import {PermissionConstants} from '../../../shared/constants/permission-constants';
import {MatSnackBar} from '@angular/material/snack-bar';



@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent extends GetCustomerDetailsAction implements OnInit {


  customerCardList: CardModel[]; // = AppConstants.card_list;
  customerCardShow: CardModel[];
  card: CardModel;
  create_payment_permission: boolean;
  list_payment_permission: boolean;
  add_subscription_permission: boolean;
  add_invoice_permission: boolean;
  errorMsgFlag: boolean;
  list_invoice_permission: boolean;
  list_subscription_permission: boolean;
  list_card_permission: boolean;
  // list_cardpermission: boolean;
  add_card_permission: boolean;
  update_card_permission: boolean;
  delete_card_permission: boolean;
  selectedCustomerId: string;
  customer_id: string;
  deletedCard: any;
  defaultCard: any;
  eventListUrl: string;
  customer: CustomerV2Model;
  customerCharge: CustomerCharge[];
  customerACHCharge: CustomerACHCharge[];
  customerSubscriptionList: SubscriptionModel[];
  InvoiceDetails: ApiResponseModel<InvoicesModel>;
  serviceName = '/customers';
  cardServiceName = '/cards';
  invoiceFlag: boolean;
  invoicepath: string;
  public orderBy: string;
  public sortBy = 'asc';
  public stylesortBy = 'fa-angle-up';
  card_img_obj = {
    'R': '../../../../assets/images/disc.png', 'V': '../../../../assets/images/visa.png',
    'X': '../../../../assets/images/amex.png', 'M': '../../../../assets/images/master.png',
    'J': '../../../../assets/images/jcb.png'
  };
  noCardAdded = true;
  noBankAccAdded = true;
  actionActive: boolean;
  redirect_link: string;

  constructor(private dialog: MatDialog,
              dataManager: DataManagerService, router: Router, route: ActivatedRoute,snackBar:MatSnackBar) {
    super();
    this.customerCharge = [];
    this.customerACHCharge = [];
    this.errorMsg = null;
    this.invoiceFlag =false;
    this.customerSubscriptionList = [];
    this.InvoiceDetails = new ApiResponseModel();
    this.actionActive = false;
    this.card = new CardModel();
    this.route = route
    this.router = router;
    this.dataManager = dataManager;
    this.checkForPermissions();
    this.redirect_link = '/customer/edit-bank-account';
    this.route.queryParams.subscribe((params) => {
        console.log(params['id']);
        if (params['id']) {
          this.setCustomerId(params['id']);
          console.log(params['id']);
          this.callCustomer();
        }
      }
    );
    this.customer = new CustomerV2Model();
    this.invoicepath = '/subscription/invoice-details';
    // this.ifSourceEmpty();
    this._getCustomerInvoicesDetailsUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_INVOICES +
      '?customer_id=' + this.selectedCustomerId + '&perPage=' + 4 + '&page=1';

    this.eventListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '?related_id=' + this.selectedCustomerId + '&type=customer';
    this._getCustomerSubscriptionDetailsUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_SUBSCRIPTIONS +
      '?customer_id=' + this.selectedCustomerId + '&status=active' + '&perPage=' + 4 + '&page=1';

    this.snackBar=snackBar;
  }

  callCustomer() {
    this.loadFlag = true;
    this._getCustomerDetailsUrl = AppConstants.BASE_URL + this.serviceName + '/' + this.selectedCustomerId + '?include=invoice,bank_account,ach_charge' ;
    this._deleteCustomerUrl = AppConstants.BASE_URL + this.serviceName + '/' + this.selectedCustomerId;
    this.getCustomerDetails();
  }
  checkForPermissions() {
    this.list_card_permission = this.permissionCheck(PermissionConstants.PERMISSION_LIST_CARD);
    this.update_permission = this.permissionCheck(PermissionConstants.PERMISSION_UPDATE_CUSTOMER);
    this.delete_permission = this.permissionCheck(PermissionConstants.PERMISSION_DELETE_CUSTOMER);
    this.create_payment_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_PAYMENT);
    this.list_payment_permission = this.permissionCheck(PermissionConstants.PERMISSION_LIST_PAYMENT);
    this.add_subscription_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_SUBSCRIPTION);
    this.add_invoice_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_INVOICE);
    this.add_card_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_CARD);
    this.update_card_permission = this.permissionCheck(PermissionConstants.PERMISSION_UPDATE_CARD);
    this.delete_card_permission = this.permissionCheck(PermissionConstants.PERMISSION_DELETE_CARD);
    this.list_invoice_permission = this.permissionCheck(PermissionConstants.PERMISSION_LIST_INVOICE);
    this.list_subscription_permission = this.permissionCheck(PermissionConstants.PERMISSION_LIST_SUBSCRIPTION);
  }

  ngOnInit() {
    console.log(this.customer);
  }

  setCustomerId(customerId: string) {
    if (customerId !== 'None') {
      this.selectedCustomerId = customerId;
    }
  }

  /**
   * func to add new card for customer
   */
  addCard() {
    if (!this.actionActive) {
      return;
    }
    const data = {
      customer_ID: this.customer.customer_id
    }
   localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data))
   this.router.navigate(['../add-card'], {relativeTo: this.route, queryParams : {id: this.customer.customer_id}})
  }

  /**
   * func to open edit customer
   */


  protected passCustomerDetails(customer: any) {
    this.loadFlag = false;
    this.customer = customer['data'];
    console.log('customer', this.customer);

    this.actionActive = true;
    customer['data']['card']['data'].length > 0 ? this.noCardAdded = false : this.noCardAdded = true;
    customer['data']['bank_account']['data'].length > 0 ? this.noBankAccAdded = false : this.noBankAccAdded = true;


    if (this.customer.charge.hasOwnProperty('data')) {
      this.customerCharge = [];
      for (let i = 0; i < this.customer.charge.data.length; i++) {
        if (i < 4) {
          console.log('customer charge iterated', i);
          this.customerCharge.push(this.customer.charge.data[i]);
        }
      }
      console.log('customerCharge', this.customerCharge);
    }
    if (this.customer.ach_charge.hasOwnProperty('data')) {
      this.customerACHCharge = [];
      for (let i = 0; i < this.customer.ach_charge.data.length; i++) {
        if (i < 4) {
          console.log('customer ach charge iterated', i);
          this.customerACHCharge.push(this.customer.ach_charge.data[i]);
        }
      }
      console.log('customerachCharge', this.customerACHCharge);
    }
    console.log('customerDetail', this.customer);
    if (this.list_subscription_permission) {
      this.getCustomerSubscriptionDetails();
    }
    if (this._getCustomerInvoicesDetailsUrl !== null && this.list_invoice_permission) {
      this.getCustomerInvoicesDetails(this._getCustomerInvoicesDetailsUrl);
    }
  }

  protected passError(error: any) {
    this.loadFlag = false;
  }

  createCharge() {
    if (!this.actionActive) {
      return;
    }
    if (this.customer.card.data.length <= 0) {
      const data = {};
      data['head'] = 'Alert';
      data['message'] = 'This customer doesn\'t have any cards to charge on';
      this.dialog.open(AlertMessageDialogComponent, {data: data});
    } else {
      const data = {
                customer_id: this.customer.customer_id ? this.customer.customer_id  : null,
                customer_email: this.customer.email ? this.customer.email : null,
                customer_phone: this.customer.phone ? this.customer.phone : null,
                source: this.customer.source_id ? this.customer.source_id : null,
                sourceList: this.customer.card.data ? this.customer.card.data : null,
                customer_name: this.customer.name ? this.customer.name: null,
                page: 'customer-details'
              };
      localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
      this.router.navigate(['../capture-payment'], {queryParams: {id: data.customer_id},relativeTo: this.route})

    }
  }
  createAch() {
    if (!this.actionActive) {
      return;
    }
    if (this.customer.bank_account.data.length <= 0) {
      const data = {};
      data['head'] = 'Alert';
      data['message'] = 'This customer doesn\'t have any bank accounts to charge on';
      this.dialog.open(AlertMessageDialogComponent, {data: data});
    } else {
      const data = {
                customer_id: this.customer.customer_id ? this.customer.customer_id : null,
                customer_email: this.customer.email ? this.customer.email : null,
                customer_phone: this.customer.phone ? this.customer.phone : null,
                bank_account: this.customer.bank_account.data ? this.customer.bank_account.data : null,
                customer_name: this.customer.name ? this.customer.name : null,
                page: 'customer-details'
              };
      localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
      this.router.navigate(['/payment/customer-ach-direct-charge'], {queryParams: {id: data.customer_id},relativeTo: this.route})
    }

  }

  saveInLSCustomerData() {
    const data = {
      customer_id: this.customer.customer_id ? this.customer.customer_id : null,
      customer_email: this.customer.email ? this.customer.email : null,
      customer_phone: this.customer.phone ? this.customer.phone : null,
      bank_account: this.customer.bank_account.data ? this.customer.bank_account.data : null,
      customer_name: this.customer.name ? this.customer.name : null,
      page: 'customer-details'
    };
localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
  }

  updateCustomer() {
    if (!this.actionActive) {
      console.log('actionActive');
      return;
    }
    localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(this.customer));
    this.router.navigate(['../update-customer'], {queryParams: {id: this.customer.customer_id}, relativeTo: this.route})

  }

  /**
   * function to open delete
   * confirmation dialog
   */
  onDelete() {
    if (!this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Customer Account', message: 'This will permanently remove the customer\'s billing info and ' +
        'immediately cancel any current subscriptions. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        this.deleteCustomerFromApi();
      }
    });
  }

  protected passDeleteConfirmation(response: any) {
    console.log('response on delete customer');
    this.dataManager.appLoaderFlag(false);
    this.router.navigate(['/customer']);
  }

  protected passCustomerDeleteError(error: any) {
    this.dataManager.appLoaderFlag(true);
    console.log('error on delete custiomer');
  }

  redirectToChargeDetails(charge) {
    const navigationExtras: NavigationExtras = {
      queryParams: {'id': charge.id},
    };
    console.log(charge);
    console.log(navigationExtras);
    this.router.navigate(['/payment/payment-details'], navigationExtras);

  }

  redirectToAchDetails(ach) {
    const navigationExtras: NavigationExtras = {
      queryParams: {'id': ach.id,'category': 'ach'},
    };
    console.log(ach);
    console.log(navigationExtras);
    this.router.navigate(['/payment/payment-details'], navigationExtras);

  }
  addBankAccount(customer_id) {
    if (!this.actionActive) {
      return;
    }
    const data = {
      customer_ID: this.customer.customer_id
    }
   localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data))
   this.router.navigate(['../add-bank-account'], {relativeTo: this.route, queryParams : {id: this.customer.customer_id}})
  }

  deleteCard(card) {
    this.deletedCard = {};
    this.deletedCard = card;
    const data = {
      head: 'Delete customer card', message: 'Delete this card '
    };
    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        const url = AppConstants.BASE_URL + '/customers/' + this.customer.customer_id + this.cardServiceName + '/' + card.id;
        this.deleteCustomerCard(url);
        this.loadFlag = true;
      }
    });
  }
  deleteBankAccount(id: string) {
    if (!this.actionActive) {
      this.dataManager.appLoaderFlag(false);
      return;
    }
    const data = {
      head: 'Delete Bank Account', message: 'This will permanently remove the customer\'s bank account. ' +
        'You won\'t be able to undo this action. '
    };

    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      console.log(value);
      if (value) {
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACH_DETAILS+'/'+id;
        this.deleteBankAccountFromApi(url);
        this.loadFlag = true;

      }
    });
  }

  protected passDeletedBankAccountConfirmation(response: any) {

    this.customer.bank_account.data.splice(this.customer.bank_account.data.indexOf(this.deletedCard), 1);
    console.log('deyan',response);
    this.loadFlag = false;
    this.showSnackBar('Bank Account deleted successfully.', 'Ok', 3000);
  }

  protected passDeletedCardConfirmation(response: any) {
    this.customer.card.data.splice(this.customer.card.data.indexOf(this.deletedCard), 1);
    this.getCustomerDetails();
    this.loadFlag = false;
  }
  protected passCustomerCardDeleteError(error: any) {
  }

  editCard(card: CardModel) {
    const data = {
      id: card.id,
      card_holder_name: card.card_holder_name,
      card_number: card.card_number,
      address1: card.address1,
      address2: card.address2,
      city: card.city,
      state: card.state,
      zip: card.zip,
      country: card.country,
      card_brand: card.brand,
      exp_month: card.exp_month,
      exp_year: card.exp_year,
      token: card.token,
      ext_token: card.ext_token,
      customer_id: card.customer_id,
      type: card.type,
      last_four_digits: card.last_four_digits
    }
      localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data))
      this.router.navigate(['../edit-card'], {relativeTo: this.route, queryParams : {id: this.customer.customer_id}})

  }

  isRedClass(status) {
    return (this.classRedList.indexOf(status) >= 0);
  }

  redirectToPaymentList() {
    this.routerWithIdNewTab('/payment/payment-customer-list', this.customer.customer_id);

  }

  redirectToSubscriptionList() {
    console.log('going to subscription ');
    this.routerWithIdNewTab('subscription/subscription-list', ('customer_id=' + this.customer.customer_id));

  }

  openAddSubscription() {
    let data = {customer: this.customer, subscription: null};
    localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
    this.router.navigate(['../create-subscription'],{queryParams: {id: this.customer.customer_id}, relativeTo: this.route})

  }

  protected passCustomerSubscriptionDetails(response) {
    console.log('customer specific active subscription', response);
    this.customerSubscriptionList = response.data;
    console.log('customer specific active subscription', this.customerSubscriptionList);
  }

  protected passCustomerSubscriptionDetailsError(error) {
    console.log(error);
  }

  openSubscriptionDetails(subscription) {
    this.routeWithQueryParams('/subscription/subscription-details', {'id': subscription.id});
  }


  protected passCustomerInvoicesDetails(response) {
    this.InvoiceDetails = response;
    this.invoiceFlag = false;
    console.log('this.InvoiceDetails', this.InvoiceDetails);
  }

  protected passCustomerInvoicesDetailsError(error) {
  }

  redirectToInvoicesList() {
    this.routeWithKeyAndId('/subscription/invoice-list', 'customer_id', this.customer.customer_id);

  }
  protected passDefaultcardResponse(response) {
    this.getCustomerDetails();
  }

  changeDefaultCard(card) {
    if (this.customer.card.data.length <= 0) {
      const data = {};
      data['head'] = 'Alert';
      data['message'] = 'This customer doesn\'t have any cards';
      this.dialog.open(AlertMessageDialogComponent, {data: data});
    } else {
      this.defaultCard = {};
      this.defaultCard = card;
      const data = {
        head: 'Make default card', message: 'Are you sure you want to make this card as your default card?'
      };
      const dialog = this.dialog.open(MessageDialogComponent, {data});
      dialog.afterClosed().subscribe(value => {
        if (value) {
          console.log('changeDefaultCard');
          this.loadFlag = true;
          const patchBody = {};
          patchBody['default_card_id'] = this.defaultCard;
          const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CUSTOMER + '/' + this.selectedCustomerId;
          this.changeDefaultCards(url , patchBody);
        }
      });
    }
  }
  public setSorting(orderBy) {
    this.invoiceFlag = true;
    let url = '';
    let sorting_url = '';
    if (orderBy === this.orderBy) {
      this.sortBy = this.sortBy === 'asc' ? 'desc' : 'asc';
      this.stylesortBy = (this.stylesortBy === 'fa-angle-down') ? 'fa-angle-up' : 'fa-angle-down';
    } else {
      this.orderBy = orderBy;
      this.sortBy = 'asc';
      this.stylesortBy = 'fa-angle-up';
    }

    url = (this.isNullOrUndefined(this.orderBy)
        ? ''
        : '&orderBy=' + this.orderBy + '&sortedBy=' + this.sortBy);
    sorting_url = this._getCustomerInvoicesDetailsUrl + url;
    if (this._getCustomerInvoicesDetailsUrl !== null) {
      this.getCustomerInvoicesDetails(sorting_url);
    }
  }

  makeDefaultBankAccount(bank_account_id) {
    if (this.customer.bank_account.data.length <= 0) {
      const data = {};
      data['head'] = 'Alert';
      data['message'] = 'This customer doesn\'t have any bank accounts';
      this.dialog.open(AlertMessageDialogComponent, {data});

    } else {
    let postBody = {};
    postBody['default_bank_acc_id'] = bank_account_id


    const data = {
      head: 'Make default bank account', message: 'Are you sure you want to make this account as your default account?'
    };
    const dialog = this.dialog.open(MessageDialogComponent, {data});
    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.loadFlag = true;
        const url = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CUSTOMER+'/'+this.customer.customer_id;
        this.postDefaultBankAccount(url , postBody);
      }
    });
  }
  }

  protected passDefaultBankAccountResponse(response) {
    // this.loadFlag = false;
    this.getCustomerDetails();
    // this.dialogRef.close(true);
    this.loadFlag = false;
    this.showSnackBar('Default Bank Account changed successfully.', 'Ok', 3000);
  }
  protected passCustomerBankAccountPostError(response: any) {

    this.showSnackBar('Default Bank Account not changed.', 'Ok', 3000);

  }

  // changeDefault() {
  //   this.loadFlag = true;
  //   const patchBody = {};
  //   patchBody['default_card_id'] = this.source_id ? this.source_id: this.card.id;
  //   this.changeDefaultCard();
  // }

}

