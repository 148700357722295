import {DatePipe, DOCUMENT} from '@angular/common';
import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponseModel } from '../../../data/model/ApiResponseModel/api-response.model';
import { CardModel } from '../../../data/model/CardModel/card.model';
import { ChargeModel } from '../../../data/model/ChargeModel/charge.model';
import { TerminalModel } from '../../../data/model/TerminalModel/terminal.model';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../constants/app-constants';
import { CaptureChargeAction } from './capture-charge.action';
import {MapsAPILoader} from '@agm/core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-capture-payment',
  templateUrl: './capture-payment.component.html',
  styleUrls: ['./capture-payment.component.css']
})
export class CapturePaymentComponent extends CaptureChargeAction implements OnInit {
  /* Google maps autocomplete */
  private API_KEY = AppConstants.GOOGLE_MAPS_API_KEY;
  isApiLoaded = false;
  options: any = {
    componentRestrictions: { country: ['US', 'CA'] }
  };
  /* End Google maps autocomplete */
  charge: ChargeModel;
  currencyList = AppConstants.currencyList;
  service_name = '/charges';
  states = AppConstants.stateList;
  countries = AppConstants.countryList;
  countryCodeList = AppConstants.countryCodes;
  avsParameters = AppConstants.avsParameters;
  binLocationParameters = AppConstants.binLocationParameters;
  binCardTypeParameters = AppConstants.binCardTypeParameters;
  cardKeyBrand = {'V': 'visa', 'X': 'amex', 'M': 'mastercard'};
  errorMsg: string;
  type: string;
  card_level: string;
  errorMsgFlag: boolean;
  cvv_optional: number;
  terminalList: ApiResponseModel<TerminalModel>;
  card: CardModel;
  is_health_care: boolean;
  is_debt_repayment: boolean;
  is_kount: boolean;
  is_prepaid: string;
  hasSettings = false;
  enable_test_ui: boolean;
  disableAmount: boolean;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  brandgetAddress: string;
  account_name: string;
  statement_desc: any;
  maxError = false;
  minError = false;
  addopt: number;
  surcharge_value: any;
  surcharge_type: string;
  surcharge_applied_on_subtotal: number;
  surcharge_label: string;
  surcharge_display_value: string;
  surcharge_check = true;
  total_amount: number;
  data: any;
  hasPrepaid: boolean;
  debtRepaymentEnabled: boolean;


  get currentAccountId(): any {
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    return currentAccountid;
  }

  constructor(dataManager: DataManagerService, private datePipe: DatePipe, snackBar: MatSnackBar, router: Router,
              route: ActivatedRoute, @Inject(DOCUMENT) private document: Document, private mapsAPILoader: MapsAPILoader,
              private elementRef: ElementRef) {
    super();
    this.data = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA));
    this.is_health_care = false;
    this.is_debt_repayment = false;
    this.is_kount = false;
    this.is_prepaid = null;
    this.card_level = 'level1';
    this.snackBar = snackBar;
    this.charge = new ChargeModel();
    this.terminalList = new ApiResponseModel();
    this.dataManager = dataManager;
    this._createChargeUrl = AppConstants.BASE_URL + this.service_name;
    if (this.data) {
      this.charge = this.data;
      this.disableAmount = this.data.hasOwnProperty('amount');
    }
    this.charge.currency = this.charge.currency ? this.charge.currency : this.currencyList[0].name;
    this.charge.captured = 1;
    this.charge.healthcare = 0;
    this.charge.healthcaretype = 'HSA';
    this.enable_test_ui = AppConstants.ENABLE_TESTING_UI;
    console.log('charge var', this.charge);
    this.errorMsgFlag = false;
    this.card = new CardModel();
    this.charge.prescription = null;
    this.charge.vision = null;
    this.charge.clinic = null;
    this.charge.dental = null;
    this.brandgetAddress = '/accounts';
    this.account_name = this.currentAccountId;
    this._getTerminalListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_TERMINALREGISTRIES;
    this.surcharge_display_value = '$0';
    this.charge.avs_parameters = '0';
    this.charge.bin_card_type_parameters = '0';
    this.charge.bin_location_parameters = '0';
    this.route = route;
    this.router = router;
    this._getSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_KOUNT + '/' + this.currentAccountId;
    this._setSettingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTING + AppConstants.SERVICE_NAME_KOUNT + '/' + this.currentAccountId;
    this.getSetting();
    this.hasPrepaid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)) ?
      JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)).display_prepaid : false;
    this.debtRepaymentEnabled = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)) ?
      JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_ACCOUNT_SETTINGS)).debt_repayment_enabled : false;
  }

  ifSourceEmpty() {
    if (this.data && this.data.sourceList.length <= 0) {
      this.errorMsg = 'You dot have any Source to charge on';
      this.errorMsgFlag = true;
    } else {
      this.card = this.data.sourceList.find(card => card.is_default === 1);
      this.type = this.cardKeyBrand[this.card.brand];
      console.log('this.card', this.cardKeyBrand[this.data.sourceList.find(card => card.is_default === 1).brand], this.card);
    }
  }

  ngOnInit() {
    this.ifSourceEmpty();
    this.switchaccount(this.account_name);
    /* Google maps api */
    this.mapsAPILoader.load().then(() => {
      this.isApiLoaded = true;
    });
    /* End Google maps api */
  }


  cardSelect(card) {
    console.log('card', card);
    this.card = card;
    this.charge.source_id = card.id;
    this.type = this.cardKeyBrand[this.card.brand];
    this.checkCardLvl();
    console.log('card', card);

  }

  protected passGetSettingError(err: any) {

  }

  protected passGetSettingResponse(res) {
    res.data.forEach(obj => {
      if (obj.hasOwnProperty('kount_enable')) {
        this.hasSettings = obj.hasSettings;
      }
    });
  }
  /* Google maps api */
  loadScript() {
    return new Promise((resolve, reject) => {
      const element = this.document.createElement('script');
      element.type = 'text/javascript';
      element.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places&language=en`;
      element.onload = resolve;
      element.onerror = reject;
      this.elementRef.nativeElement.appendChild(element);
    });
  }
  handleAddressChange(address: Address) {
    console.log(typeof(address.address_components));
    let addr: any;
    for (addr of address.address_components) {
      console.log(addr);
      if (addr.types[0] === 'country') {
        this.card.country = addr.short_name;
      }
      if (addr.types[0] === 'administrative_area_level_1') {
        this.card.state = addr.long_name;
      }
      if (addr.types[0] === 'locality') {
        this.card.city = addr.long_name;
      }
      if (addr.types[0] === 'postal_code') {
        this.card.zip = addr.long_name;
      }
    }
  }
  /* End Google maps api */
  createCharge() {
    const postBody = {};
    postBody['currency'] = 'usd';
    postBody['amount'] = ((this.total_amount * 100) + (this.charge.tip ? this.charge.tip * 100 : 0)).toFixed();
    if (this.surcharge_check) {
      postBody['surcharge'] = ((this.total_amount - this.charge.amount) * 100).toFixed();
    }
    postBody['kount_enable'] = this.is_kount;
    postBody['is_prepaid'] = this.is_prepaid ? this.is_prepaid : null;
    postBody['statement_description'] = this.charge.statement_description ? this.charge.statement_description.trim() : null;
    postBody['email'] = this.charge.receipt_email ? this.charge.receipt_email : console.log(this.charge.receipt_email);
    postBody['phone_number'] = this.charge.receipt_phone ? this.charge.receipt_phone.replace(/\D+/g, '') : console.log(this.charge.receipt_phone);
    this.charge.charge_description ? postBody['charge_description'] = this.charge.charge_description.trim() :
      console.log(this.charge.charge_description);
    postBody['capture'] = this.charge.captured;
    postBody['card_id'] = this.charge.source_id ? this.charge.source_id : this.card.id;
    postBody['customer_id'] = this.charge.customer_id ? this.charge.customer_id : this.card.id;
    postBody['health_care'] = this.charge.healthcare;
    postBody['industry_type'] = this.charge.industryType;
    postBody['address_line1'] = this.card.address1 ? this.card.address1.trim() : console.log(this.card.address1);
    postBody['address_line2'] = this.card.address2 ? this.card.address2.trim() : console.log(this.card.address2);
    if (this.card.city != null) {
      postBody['city'] = this.card.city ? this.card.city.trim() : console.log(this.card.city);
    }
    if (this.card.state != null) {
      postBody['state'] = this.card.state ? this.card.state : console.log(this.card.state);
    }
    postBody['zip'] = this.card.zip ? this.card.zip : console.log(this.card.zip);
    if (this.card.country != null) {
      postBody['country'] = this.card.country ? this.card.country : console.log(this.card.country);
    }
    postBody['card_level'] = this.card_level !== 'level1' ? this.card_level.toUpperCase() : console.log(this.card_level);
    postBody['sales_tax'] = this.charge.sales_tax ? (this.charge.sales_tax * 100).toFixed() : 0;
    postBody['purchase_order'] = this.charge.purchase_order ? this.charge.purchase_order : console.log(this.charge.purchase_order);
    postBody['cvv'] = this.charge.cvv ? this.charge.cvv : console.log(this.charge.cvv);
    postBody['supplier_reference_number'] = this.charge.supplier_reference_number ? this.charge.supplier_reference_number : console.log(this.charge.supplier_reference_number);
    postBody['customer_ref_id'] = this.charge.customer_ref_id ? this.charge.customer_ref_id : console.log(this.charge.customer_ref_id);
    postBody['ship_to_zip'] = this.charge.ship_to_zip ? this.charge.ship_to_zip : console.log(this.charge.ship_to_zip);
    postBody['amex_descriptor'] = this.charge.amex_descriptor ? this.charge.amex_descriptor : console.log(this.charge.amex_descriptor);
    postBody['customer_vat_number'] = this.charge.customer_vat_number ? this.charge.customer_vat_number : console.log(this.charge.customer_vat_number);
    postBody['summary_commodity_code'] = this.charge.summary_commodity_code ? this.charge.summary_commodity_code : console.log(this.charge.summary_commodity_code);
    postBody['shipping_charges'] = this.charge.shipping_charges ? (this.charge.shipping_charges * 100).toFixed() : 0;
    postBody['duty_charges'] = this.charge.duty_charges ? (this.charge.duty_charges * 100).toFixed() : 0;
    postBody['ship_from_zip'] = this.charge.ship_from_zip ? this.charge.ship_from_zip : console.log(this.charge.ship_from_zip);
    postBody['destination_country_code'] = this.charge.destination_country_code ? this.charge.destination_country_code : console.log(this.charge.destination_country_code);
    postBody['vat_invoice'] = this.charge.vat_invoice ? this.charge.vat_invoice : console.log(this.charge.vat_invoice);
    postBody['order_date'] = this.charge.order_date ? this.datePipe.transform(this.charge.order_date, 'yyyy-MM-dd') : console.log(this.charge.order_date);
    postBody['tax_category'] = this.charge.tax_category ? this.charge.tax_category : console.log(this.charge.tax_category);
    postBody['tax_type'] = this.charge.tax_type ? this.charge.tax_type : console.log(this.charge.tax_type);
    postBody['tax_rate'] = this.charge.tax_rate ? this.charge.tax_rate : (this.card_level === 'level3' && (this.type === 'visa')) ? this.charge.tax_rate : console.log(this.charge.tax_rate);
    postBody['tax_amount'] = this.charge.tax_amount ? (this.charge.tax_amount * 100).toFixed() : 0;
    postBody['terminal_id'] = this.terminalList.data.find(x => x.type === 'GATEWAY').id;
    if (this.charge.avs_parameters !== '0') {
      postBody['avs_parameters'] = this.charge.avs_parameters;
    }
    if (this.charge.bin_location_parameters !== '0') {
      postBody['bin_location_parameters'] = this.charge.bin_location_parameters;
    }
    if (this.charge.bin_card_type_parameters !== '0') {
      postBody['bin_card_type_parameters'] = this.charge.bin_card_type_parameters;
    }
    this.charge.healthcare === 1 ? postBody['health_care_type'] = this.charge.healthcaretype : console.log(this.charge.healthcaretype);
    this.charge.healthcare === 1 ?
      this.charge.prescription ? postBody['prescription_amount'] = this.charge.prescription * 100 : console.log(this.charge.prescription) :
      console.log(this.charge.healthcare);
    this.charge.healthcare === 1 ?
      this.charge.vision ? postBody['vision_amount'] = this.charge.vision * 100 : console.log(this.charge.vision) :
      console.log(this.charge.healthcare);
    this.charge.healthcare === 1 ?
      this.charge.clinic ? postBody['clinic_amount'] = this.charge.clinic * 100 : console.log(this.charge.clinic) :
      console.log(this.charge.healthcare);
    this.charge.healthcare === 1 ?
      this.charge.dental ? postBody['dental_amount'] = this.charge.dental * 100 : console.log(this.charge.dental) :
      console.log(this.charge.healthcare);
    console.log(postBody);
    console.log(this.charge.captured);
    if (!this.minError && !this.maxError) {
      // if (this.validateAmount(postBody['amount'])) {
      this.chargeApiCall(this._createChargeUrl, postBody);
      // }
    }
  }

  updateemail(values: any) {
    if (values.currentTarget.checked) {
      this.charge.receipt_email = this.charge.customer_email ? this.charge.customer_email : '';
      const phone = this.charge.customer_phone ? this.charge.customer_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) : '';
      this.charge.customer_phone = !phone[2] ? phone[1] : '(' + phone[1] + ') ' + phone[2] + (phone[3] ? '-' + phone[3] : '');
      this.charge.receipt_phone = this.charge.customer_phone ? this.charge.customer_phone : '';
    } else {
      this.charge.receipt_email = '';
      this.charge.receipt_phone = '';
    }
  }

  protected passResponse(charge) {
    this.backToPage();
  }

  protected passError(error) {
    console.log(error);
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    this.errorMsgFlag = true;
    let i;
    let j;
    const name = ['currency', 'amount', 'statement_description', 'charge_description',
      'capture', 'card_id', 'customer_id', 'health_care', 'health_care_type', 'tip_amount', 'prescription_amount',
      'vision_amount', 'clinic_amount', 'dental_amount'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  checkAmount() {
    if (this.charge.amount < 0) {
      this.charge.amount = 0;
    }
  }

  _keyPress(event: any) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || keypressed === 46 // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onlyNumber(event: any) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  checkTextField() {
    let cnt = 0;

    if (this.charge.dental != null) {
      cnt++;
    }
    if (this.charge.vision != null) {
      cnt++;
    }
    if (this.charge.clinic != null) {
      cnt++;
    }
    if (this.charge.prescription != null) {
      cnt++;
    }
    if (cnt > 3) {
      // document.getElementById('error1').innerText = 'Only three fields allowed';
      return true;
    } else {
      // document.getElementById('error1').innerText = '';
      return false;
    }
  }

  /**
   * func to handle health care type
   */
  toggleHealthCareType() {
    // console.log(event.target.checked);
    console.log('toggleHealthCareType, enter', this.charge.healthcaretype);
    // this.charge.healthcaretype !== 'FSA' ? this.charge.healthcaretype = 'FSA' : this.charge.healthcaretype = 'HSA';
    console.log('toggleHealthCareType, exit', this.charge.healthcaretype);
  }

  protected getAddressResponse(res) {
    console.log('authlogiee', res);
    this.charge.statement_description = res.data.default_descriptor;
    this.addopt = res.data.address_optional;
    this.cvv_optional = res.data.cvv_optional;
    for (let i = 0; i < res.data.surchargesettings.data.length; i++) {
      console.log('sur length', res.data.surchargesettings.data[i].key);
      if (res.data.surchargesettings.data[i].key === 'surcharge_applied_on_subtotal') {
        // console.log("sssvalue", res.data.surchargesettings.data[i].value);
        this.surcharge_applied_on_subtotal = res.data.surchargesettings.data[i].value;
      }
      if (res.data.surchargesettings.data[i].key === 'surcharge_label') {
        this.surcharge_label = res.data.surchargesettings.data[i].value;
      }
      if (res.data.surchargesettings.data[i].key === 'surcharge_type') {
        this.surcharge_type = res.data.surchargesettings.data[i].value;
      }
      if (res.data.surchargesettings.data[i].key === 'surcharge_value') {
        this.surcharge_value = Number(res.data.surchargesettings.data[i].value);
      }
    }
    this.surcharge_display_value = this.surcharge_value ? (this.surcharge_type === 'percentage') ? this.surcharge_value + '%' : '$' + ((this.surcharge_value * 10) / 1000).toFixed(2) : '$0';

  }

  protected getAddressError(err) {
    console.log(err);
  }

  switchaccount(account_name) {
    // localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    this._getaccount = AppConstants.BASE_URL + this.brandgetAddress + '/' + this.account_name + '?include=surchargesettings';
    this.getAddress(this._getaccount);
    // this.router.navigate(['loading..']);
  }

  minMaxCheck(amount) {
    amount != null ? amount = Number(document.getElementById('amount')['value']) : amount;
    console.log('billlllllllllll', amount);
    if (amount < 0.5) {
      this.maxError = false;
      this.minError = true;
    } else {
      this.minError = false;
      this.maxError = false;
    }
    if (this.surcharge_check) {
      console.log('total_amount1', amount);
      if (amount !== null) {
        switch (this.surcharge_type) {
          case 'percentage': {

            this.total_amount = parseFloat(amount) + (parseFloat(amount) * (this.surcharge_value / 100));
            break;
          }
          case 'fixed': {
            this.total_amount = (parseFloat(amount) + (this.surcharge_value / 100));
            break;
          }
          default: {
            this.total_amount = parseFloat(amount) ? parseFloat(amount) : 0;
            break;
          }
        }
      } else {
        //  console.log('total_amount2', typeof this.total_amount);
        this.total_amount = 0;
      }
    } else {
      this.total_amount = parseFloat(amount) ? parseFloat(amount) : 0;
    }

    if (this.total_amount >= 1000000) {
      this.maxError = true;
      this.minError = false;
    }
    // this.setTwoNumberDecimal(amount)

  }

  sur_change() {
    console.log('checkboac', this.surcharge_check);
    if (this.surcharge_check === true) {
      this.total_amount = this.charge.amount;
    } else {
      if (this.charge.amount) {
        console.log('checkboac', typeof this.charge.amount);
        this.total_amount = (this.surcharge_type === 'percentage') ? parseFloat(this.charge.amount.toString()) + (parseFloat(this.charge.amount.toString()) * (this.surcharge_value / 100)) : (parseFloat(this.charge.amount.toString()) + (this.surcharge_value / 100));
      }
    }
    this.minMaxCheck(this.charge.amount);
  }

  levelChange(lv) {
    this.card_level = lv;
    this.checkCardLvl();

  }

  checkCardLvl() {
    switch (this.card_level) {
      case'level1':
        this.removeLv3Defaults();
        break;
      case'level2':
      if (this.isNullOrUndefined(this.type)) {
          this.card_level = 'level1';
          this.showSnackBar('This card does not support Level 2 transaction.', 'Ok', 3000);
        }
        this.removeLv3Defaults();
        break;
      case'level3':
      if (this.isNullOrUndefined(this.type)) {
          this.card_level = 'level1';
          this.showSnackBar('This card does not support Level 3 transaction.', 'Ok', 3000);
        }
        this.Lv3Defaults();
        break;

    }
  }

  callTerminalList() {
    this.getTerminal();
  }

  protected getTerminalError(err) {
  }

  protected getTerminalResponse(res) {
    this.terminalList = res;
    if (this.terminalList.data.findIndex(x => x.type === 'GATEWAY') >= 0) {
      this.createCharge();
    } else {
      this.showSnackBar('Terminal not available.', 'Ok', 3000);
    }
  }

  updateSelectedPhone(event) {
    this.charge.receipt_phone = this.charge.receipt_phone.length != 11 ? event.target.value : this.charge.receipt_phone;
  }

  cancelCharge() {
    this.backToPage();
  }

  backToPage() {
    if (this.data.page === 'customer-details') {
      this.router.navigate(['../customer-details'], {queryParams: {id: this.data.customer_id}, relativeTo: this.route});
    } else {
      this.router.navigate(['../'], {relativeTo: this.route});
    }
  }

  private removeLv3Defaults() {
    this.charge.sales_tax = undefined;
    this.charge.purchase_order = undefined;
    this.charge.ship_to_zip = undefined;
    this.charge.ship_from_zip = undefined;
    this.charge.tax_category = undefined;
    this.charge.tax_type = undefined;
    this.charge.tax_amount = undefined;
    this.charge.duty_charges = undefined;
    this.charge.shipping_charges = undefined;
    this.charge.destination_country_code = undefined;
    this.charge.customer_vat_number = undefined;
    this.charge.summary_commodity_code = undefined;
    this.charge.vat_invoice = undefined;
    this.charge.order_date = undefined;
    this.charge.tax_rate = undefined;
  }

  private Lv3Defaults() {
    this.charge.sales_tax = 0;
    this.charge.purchase_order = '0';
    this.charge.ship_to_zip = '00000';
    this.charge.ship_from_zip = '89030';
    this.charge.tax_category = 'TAX_EXEMPT';
    this.charge.tax_type = '0';
    this.charge.tax_amount = 0.00;
    this.charge.duty_charges = 0.00;
    this.charge.shipping_charges = 0.00;
    this.charge.destination_country_code = 'USA';
    this.charge.customer_vat_number = '0';
    this.charge.summary_commodity_code = '0';
    this.charge.vat_invoice = '0';
    this.charge.order_date = new Date();
    this.charge.tax_rate = 1.00;
  }

  public setDebtRepayment(value: boolean) {
    if (value) {
      this.setHealthcare(false);
    }

    this.is_debt_repayment = value;
    this.charge.industryType = value ? 'DEBT_REPAYMENT' : null;
  }

  public setHealthcare(value: boolean) {
    if (value) {
      this.setDebtRepayment(false);
    }

    this.is_health_care = value;
    this.charge.healthcare = value ? 1 : 0;
  }
}
