<div class="container-fluid overlay">

  <div class="row">

    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 signLogo" *ngIf="signin">
      <img class="logo-align img-fluid"  src="{{dataManager.loginlogo}}" />
    </div>
  </div>
  <div class="row image">
    <img class="left-image" src="../../../assets/images/signin-left-image.png">
    <img class="right-image" src="../../../assets/images/signin-right-image.png">
  </div>
  <div class="row">
    <div *ngIf="signin" class="container-card col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ipad_iphone">
        <ng-container *ngIf="topErrorMessage">
          <div class="row" *ngFor="let obj of keys1 " >
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let value of obj " >
              <p class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error" id="err" >{{value}}</p>
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="!topErrorMessage">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error mb-1"> {{errorMsg}}</div>
        </div>
        <h3 class="text-center heading2">Welcome back!</h3>
        <div class="form-group row">
          <span class="error_sign col-2" id="username1">!</span>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 landscape_view">
            <div class="row">
              <label for="username" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 l_label">Username:</label>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                <input class="form-control" type="text" name="username" (keydown)="onKeydown($event)"  required
                [(ngModel)]="username"  id="username" placeholder="Username" >
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2">
          <span class="error_sign col-2" id="password1">!</span>
          <div class="col-sm-12 landscape_view">
            <div class="row">
              <div class="col-md-4">
                <label for="password" class="l_label">Password:</label>
              </div>
              <div class="col-md-8 text-right text-blue forgot-pass-m">
                  <a (click)="redirectToForgotPassword()" class="pointer" >Forgot your password?</a><br>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <input type="password" class="form-control" (keydown)="onKeydown($event)"  name="password" required [(ngModel)]="password" id="password" placeholder="********"
                       >
              </div>
            </div>
          </div>

        </div>
        <div class="row m-0 pl-2">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mt-1 mb-1 remember-m">
            <input id="remember-me" name="remember-me" type="checkbox" class="form-check-input" [(ngModel)]="rememberMe" [value]="rememberMe">
            <label for="remember-me" class="l_label ml-2 transy-2">Remember me</label>
          </div>
        </div>
        <div class="form-group row pt-3 pb-2 continue-btn-m">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <button type="submit" [disabled]="loadFlag"  class="bt_signin form form-control" (click)="signIn()">Continue<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
          </div>

    </div>

    </div>
    </div>
    <div class="row privacy-m">
        <div class="col-lg-5 col-md-5 col-sm-12 pt-3 container justify-content-center text-center" >
<div class="col bottom-align-m">
    <a href="https://www.payarc.com/privacy-policy" class="privacy" target="_blank">Privacy Policy</a><span class="p_line"> | </span><a href="https://www.payarc.com/company/terms-and-conditions" class="privacy" target="_blank">Terms &amp; Conditions</a>
    </div>

        </div>
    </div>

    </div>
