import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MerchantBridgeComponent } from './merchant-bridge/merchant-bridge.component';
import { PayeeSummaryComponent } from './components/payee-summary/payee-summary.component';
import { SplitCampaignRequestComponent } from './components/split-campaign-request/split-campaign-request.component';
import { SplitFundingSummaryComponent } from './components/split-funding-summary/split-funding-summary.component';
import { MerchantBridgeRoutingModule } from './merchant-bridge-routing/merchant-bridge-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddNewPayeeComponent } from './components/add-new-payee/add-new-payee.component';
import { NewCampaignCreationComponent } from './components/new-campaign-creation/new-campaign-creation.component';
import { SplitFundingDetailsComponent } from './components/split-funding-details/split-funding-details.component';
import { DataService } from './components/data.service';
import { CampaignSummaryComponent } from './components/campaign-summary/campaign-summary.component';
import { EditCampaignComponent } from './components/edit-campaign/edit-campaign.component';
import { PayeeDetailsComponent } from './components/payee-details/payee-details.component';
import { CampaignsDetailsComponent } from './components/campaigns-details/campaigns-details.component';
import { EditPayeeComponent } from './components/edit-payee/edit-payee.component';



@NgModule({
  declarations: [
    MerchantBridgeComponent,
    PayeeSummaryComponent,
    SplitCampaignRequestComponent,
    SplitFundingSummaryComponent,
    AddNewPayeeComponent,
    NewCampaignCreationComponent,
    SplitFundingDetailsComponent,
    CampaignSummaryComponent,
    EditCampaignComponent,
    PayeeDetailsComponent,
    CampaignsDetailsComponent,
    EditPayeeComponent
  ],
  providers: [
    // DataService
  ],
  imports: [
    CommonModule,
    MerchantBridgeRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
  ]
})
export class MerchantBridgeModule { }
