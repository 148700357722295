import {BaseComponent} from '../../abstract/base.component';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import { Directive } from "@angular/core";

@Directive()
export abstract class CreateNewBridgeAccountAction extends BaseComponent {
  protected createAccountUrl: string;
  protected _getaccount: string;
  postAccount(url, postBody) {
    this.dataManager.postRequest(url, postBody, 0)
      .subscribe(
        response => this.passResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passError(error.error);
        }
      );
  }

  protected abstract passResponse(response);

  protected abstract passError(error);
}
