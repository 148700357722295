import {CustomDateModel} from '../CustomDate/custom-date.model';
import {CustomerACHCharge, CustomerACHChargeData, CustomerV2Model} from '../CustomerV2Model/customer-v2.model';
import {CardModel} from '../CardModel/card.model';
import {PlanModel, SubscriptionModel} from '../SubscriptionModel/subscription.model';
import {DiscountModel} from '../DiscountModel/discount.model';
import {CouponModel} from '../CouponModel/coupon.model';
export class InvoicesModel {
  /*created for view*/
  id?: number;
  amount_due?: number;
  plan_amount?: number;
  customer_id?: string;
  amount_remaining?: number;
  amount_paid?: number;
  payment_due_date?: Date;
  status?: number;
  billing?: number;
  customer?: InvoiceCustomer;
  subscription_name?: string;
  subscription_id?: string;
  invoice_number?: string;
  account_name?: string;
  customer_name?: string;
  created_at?: Date;
  /*adding fields required in add invoices in customer details*/
  description?: string;
  tax_amount?: number;
  qty?: number;
  unit?: number;
  subtotal?: number;
  period_end?: number;
  period_start?: number;
  quantity?: number;
  discount_amount?: number;
  tax_percent?: number;
  total?: number;
  plan_name?: string;
  paid?: number;
  attempted?: number;
  failure_message?: string;
  plan?: InvoicePlanModel = new InvoicePlanModel();
  discount?: InvoiceDiscountModel = new InvoiceDiscountModel();
  discounts?: InvoiceDiscountModel = new InvoiceDiscountModel();
  /*adding fields required in add invoices in customer details*/
  /*created for view*/
  subscriptions?: InvoiceSubscriptionModel = new InvoiceSubscriptionModel();
  created_by?: string;
  achcharge?: AchChargeCustomer;
  constructor() {
    this.customer = new InvoiceCustomer();
    this.achcharge = new AchChargeCustomer();
  }

}
export class AchChargeCustomer {

  data: CustomerACHChargeData;

  constructor() {
    this.data = new CustomerACHChargeData();
  }
}
export class InvoiceCustomer {
  data: CustomerV2Model;

  constructor() {
    this.data = new CustomerV2Model();
  }
}
export class InvoicePlanModel {
  data?: PlanModel = new PlanModel();
}
export class InvoiceDiscountModel {
  data?: CouponModel = new CouponModel();
}
export class InvoiceSubscriptionModel {
  data?: SubscriptionModel = new SubscriptionModel();
}

export class InvoicePostModel {
  customer_id?: string;
  tax_percent?: number;
  discount_id?: string;
  statement_description?: string;
  quantity?: number;
  unit?: number;
  private _amount?: number;
  percent_off?: number;
  amount_off?: number;
  description?: string;
  private _discountAmount?: number;
  private _taxAmount: number;
  private _totalAmount: number;


  constructor() {
    this.tax_percent = 0;
    this.percent_off = 0;
    this.tax_percent = 0;
    this._totalAmount = 0;

  }

  get discountAmount(): number {
    this._discountAmount = 0;
    this._discountAmount = this.percent_off !== 0 ? (this._amount * this.percent_off / 100) : (this.amount_off !== 0 ? this.amount_off : 0);
    return this._discountAmount;
  }


  get taxAmount(): number {
    this._taxAmount = 0;
    this._taxAmount = (this._amount - (this._discountAmount ? this._discountAmount : 0)) * this.tax_percent / 100;
    return this._taxAmount;
  }

  get totalAmount(): number {
    this._totalAmount = 0;
    this._totalAmount = this._amount - (this._discountAmount ? this._discountAmount : 0) + this._taxAmount;
    return this._totalAmount;
  }

  get amount(): number {
    this._amount = this.quantity * this.unit;
    return this._amount;
  }
}

