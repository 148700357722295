import {Component, Inject, OnInit} from '@angular/core';
import {ProfileDialogAction} from './profile-dialog.action';
import {ActivatedRoute, Router} from '@angular/router';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {AppConstants} from '../../shared/constants/app-constants';
import {AppUserModel} from '../../data/model/AppUser/app-user.model';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MultiFactorAuthenticationComponent} from '../../multi-factor-authentication/multi-factor-authentication.component';
import {SecurityCodeDialogComponent} from '../security-code-dialog/security-code-dialog.component';
import {NgForm} from '@angular/forms';


@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent extends ProfileDialogAction implements OnInit {
  show: boolean = false;

  inputType = 'password';
  inputType1 = 'password';
  errorMsg: any;
  topErrorMessage: string;
  errorMsgFlag: boolean;
  keys: String[];
  errorFlag: boolean;
  is_edit: boolean;
  emailDisabled: boolean;
  addNumberDisable: boolean;
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
  values: String[];
  keys1: any;
  sms: string;
  email: string;
  verifyForm: FormGroup;
  submitted: boolean = false;
  radio: boolean = true;
  is_setting_optional: number = 0;
  user_data: any;

  // const currentUser1 = JSON.parse(localStorage.getItem('current_user_name'));
  get userName(): any {
    const currentUser1 = JSON.parse(localStorage.getItem('current_user'));
    return currentUser1.name;
  }

  get userEmail(): any {
    const currentUser1 = JSON.parse(localStorage.getItem('current_user'));
    return currentUser1.email;
  }

  // @localStorage() public comments:any = [];

  constructor(route: ActivatedRoute, router: Router, dataManager: DataManagerService, public dialogRef: MatDialogRef<ProfileDialogComponent>, private _fb: FormBuilder, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private passedData: any, @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.emailDisabled = true;
    this.addNumberDisable = true;
    this.dataManager = dataManager;
    this.route = route;
    this.router = router;
    this.user_data = JSON.parse(this.localStorage.getItem(AppConstants.STORAGE_KEY_USER));
    this.is_setting_optional = this.user_data.preferred_2fa_method === 'sms' ? 0 : 1;
    this.preferedMethodUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_PREFERED_METHOD + '/2fa-method';
    // this.name = this.dataManager.currentUser1.name;
    // console.log(currentUser1.name);
    // this.user = JSON.parse(localStorage.getItem('current_user_email'));
    if (this.fetchIdFromRouteQueryParams()) {
      this.is_edit = true;
      // this.editUser = new AppUserModel();
      // this._getUserDataUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_USERS + '/' + this.id;
      // this.getUserData();
    }// else {
    //   this.is_edit = false;
    //   this._newUserUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_USERS;
    //   this.newUser = new AppUserModel();
    // }

  }

  //
  ngOnInit() {
    this.verifyForm = this._fb.group({
      // sms: ['', [Validators.required, Validators.pattern(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/)]],
      email: ['', [Validators.required, Validators.email]],
      // isPhone: [''],
      isEmail: ['']
    });
    // if (this.is_setting_optional == 0) {
    //   this.changeVerification('isPhone');
    // } else {
      this.changeVerification('isEmail');
    // }
    this.setValue();
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.dialogRef.close(true);
      }
    });

  }

  setValue() {
    this.email = this.user_data['email'];
    if (!this.isNullOrUndefined(this.user_data['notification_phone_number'])) {
      var x = this.user_data['notification_phone_number'].toString().match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.sms = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    } else {
      this.sms = '';
    }

    this.verifyForm.setValue({sms: this.sms, email: this.email, isPhone: '', isEmail: ''});
    this.checkVerified();
  }

  // Get Verfyform controls
  get f() {
    return this.verifyForm.controls;
  }

  save() {
    this.loadFlag = true;
    this._changePasswordUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_FORGOT_PASSWORD + '/change';
    const postbody = {};
    postbody['current-password'] = this.current_password;
    postbody['new-password'] = this.new_password;
    postbody['new-password_confirmation'] = this.new_password_confirmation;
    this.changePassword(postbody);
  }

  // changeinputtype(key) {
  //   console.log(key);
  //   if (key === 1) {
  //     this.inputType = this.inputType === 'password' ? 'text' : 'password';
  //   } else {
  //     this.inputType1 = this.inputType1 === 'password' ? 'text' : 'password';
  //   }
  // }

  /**
   * func to handle final request POST/PATCH
   */
  // saveUser() {
  //   this.loadFlag = true;
  //   const body = {};
  //   if (!this.is_edit) {
  //     body['name'] = this.newUser.name;
  //     body['phone_number'] = this.newUser.phone_number;
  //     body['email'] = this.newUser.email;
  //     body['password'] = this.newUser.password;
  //     this.createNew(body);
  //   } else {
  //     console.log(this.editUser)
  //     body['name'] = this.editUser.name;
  //     body['current_password'] = this.editUser.current_password;
  //     body['new_password'] = this.editUser.password;
  //     body['phone_number'] = this.editUser.phone_number;
  //     body['email'] = this.editUser.email;
  //   }
  // }

  protected passUserResponse(response, key) {
    console.log(response);
    this.loadFlag = false;
    // switch (key) {
    //   case 0:
    //     this.editUser = response.data;
    //     break;
    //   case 1:
    //     this.routeNavigate('/merchant/user-list');
    //     break;
    //   case 2:
    //     this.routeWithId('/merchant/user-details', this.id);
    //     break;
    // }
  }

  /**
   * func to validate user
   * input for name pwd
   */
  // validateUser() {
  //   if (this.is_edit) {
  //   } else {
  //   }
  // }

  // cancelRequest() {
  //   if (this.is_edit) {
  //     this.routeWithId('/merchant/user-details', this.id);
  //   } else {
  //     this.routeNavigate('/merchant/user-list');
  //   }
  // }

  protected passError(error) {
    this.loadFlag = false;
    this.errorMsg = error.error.message ? error.error.message : error.error.errors;
  }

  protected changePasswordResponse(response) {
    this.dialogRef.close(true);
  }

  protected changePasswordError(error: any) {
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    this.errorMsgFlag = true;
    let i;
    let j;
    let name = ['oldpassword', 'newpassword', 'confirmpassword'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
    // this.loadFlag = false;
    // console.log(error.message);
    // if (error.error.message){
    //   console.log(error.error.message);
    //   this.errorMsg2 = error.error.message;
    // }
    // if (error.error.errors != null) {
    //   this.errorMsg = error.error.errors;
    //   this.errorMsg = Object.values(this.errorMsg);
    //   this.values = Object.values(this.errorMsg);
    //   this.keys1 = Object.keys(this.errorMsg);
    // }

  }

  verifyUser() {
    this.submitted = true;
    // this.verifyForm.value.sms = this.verifyForm.value.sms ? this.verifyForm.value.sms.replace(/\D+/g, '') : console.log();
    // if (this.verifyForm.valid) {
    //   for (let x in this.verifyForm.value) {
    //     if ((this.verifyForm.value[x]) && data == x && data == 'sms') {
    //       const data = {value: x, response: this.verifyForm.value[x]};
    //       const profileDialog = this.dialog.open(SecurityCodeDialogComponent, {data, width: '630px', height: 'auto'});
    //       profileDialog.afterClosed().subscribe(value => {
    //         if (value) {
    //           this.SetUserData(value);
    //           this.dialogRef.close(true);
    //         }
    //       });
    //     } else if ((this.verifyForm.value[x]) && data == x && data == 'email') {
    //       const data = {value: x, response: this.verifyForm.value[x]};
    //       const profileDialog = this.dialog.open(SecurityCodeDialogComponent, {data, width: '630px', height: 'auto'});
    //       profileDialog.afterClosed().subscribe(value => {
    //         if (value) {
    //           this.SetUserData(value);
    //           this.dialogRef.close(true);
    //         }
    //       });
    //     }
    //   }
    // } else {
    //   return;
    // }

    const data = {value: 'email', response: this.user_data['email']};
    const profileDialog = this.dialog.open(SecurityCodeDialogComponent, {data, width: '630px', height: 'auto'});
    profileDialog.afterClosed().subscribe(value => {
      if (value) {
        this.SetUserData(value);
        this.dialogRef.close(true);
      }
    });
  }

  changeVerification(params) {
    if (params == 'isPhone') {
      // document.getElementById('sms').removeAttribute('disabled');
      // document.getElementById('emailOtp').setAttribute('disabled', 'disabled');
      // document.getElementById('verifyEmail').setAttribute('disabled', 'disabled');
      // document.getElementById('verifySms').removeAttribute('disabled');
      // this.verifyForm.controls['sms'].setValidators([<any>Validators.required, Validators.pattern(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/)]);
      // this.verifyForm.controls['sms'].updateValueAndValidity();
      // this.verifyForm.controls['email'].clearValidators();
      // this.verifyForm.controls['email'].updateValueAndValidity();
    } else {
      // document.getElementById('emailOtp').removeAttribute('disabled');
      // document.getElementById('sms').setAttribute('disabled', 'disabled');
      // document.getElementById('verifySms').setAttribute('disabled', 'disabled');
      // document.getElementById('verifyEmail').removeAttribute('disabled');
      // this.verifyForm.controls['email'].setValidators([<any>Validators.required, Validators.email]);
      // this.verifyForm.controls['email'].updateValueAndValidity();
      // this.verifyForm.controls['sms'].clearValidators();
      // this.verifyForm.controls['sms'].updateValueAndValidity();
    }
    this.checkVerified();
    const requestPayload = {
      // preferred_2fa_method: params == 'isPhone' ? 'sms' : 'email'
      preferred_2fa_method: 'email'
    };
    this.callPreferMethod(requestPayload);
  }

  updateSelectedPhone(event) {
    this.sms = this.sms.length != 11 ? event.target.value : this.sms;
  }

  checkVerified() {
    // if (this.user_data.is_2fa_phone_verified == 1) {
    //   if (this.user_data['notification_phone_number'].toString() === this.verifyForm.value.sms.replace(/\D/g, '')) {
    //     document.getElementById('verifySms').innerHTML = 'Verfied';
    //     document.getElementById('verifySms').setAttribute('disabled', 'disabled');
    //   } else {
    //     document.getElementById('verifySms').innerHTML = 'Verify number';
    //     document.getElementById('verifySms').removeAttribute('disabled');
    //   }
    // }
    if (this.user_data.is_2fa_email_verified == 1) {
      if (this.user_data['email'] === this.verifyForm.value.email) {
        document.getElementById('verifyEmail').innerHTML = 'Verfied';
        document.getElementById('verifyEmail').setAttribute('disabled', 'disabled');
      } else {
        document.getElementById('verifyEmail').innerHTML = 'Verify email';
        document.getElementById('verifyEmail').removeAttribute('disabled');
      }
    }
  }

  showPasswordPanel(profileForm: NgForm) {
    this.show = !this.show;
    if (!this.show) {
      this.keys1 = [];
      this.resetForm(profileForm);
    }
  }

  resetForm(data) {
    for (let x in data.form.value) {
      data.form.controls[x].reset();
    }
  }

  PreferMethodResponse(res) {
    console.log(res);
  }

  PreferMethodError(error) {

  }
}
