import { Component, Inject, OnInit,PLATFORM_ID,DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import { AddNewPayeeAction } from './add-new-payee.action';
import { AppConstants } from '../../../shared/constants/app-constants';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import {ExportConstants} from '../../../shared/constants/export-constants';
import { Campaigns, ResponseWrapper } from '../../../data/model/APiWrapper/response-wrapper';
import { debounceTime,distinctUntilChanged } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { FilterConstants } from '../../../shared/constants/filter-constants';
import { Subject } from 'rxjs';
import { SubscriptionModel } from '../../../data/model/SubscriptionModel/subscription.model';
import { CampaignsModel } from '../../../data/model/Campaign/campaign.model';
@Component({
  selector: 'app-add-new-payee',
  templateUrl: './add-new-payee.component.html',
  styleUrls: ['./add-new-payee.component.css']
})
export class AddNewPayeeComponent extends AddNewPayeeAction implements OnInit {

  heading:string='Add New Payee';
  topErrorMsg:string[];
  errorMsg:string='';
  errorMsgFlag:boolean;
  buttonText:string='Add Payee';
  data: any;
  addPayee: FormGroup;
  typePayeePercentage:boolean=true;
  typePayeeAmount:boolean=false;
  selectedPayeeType:string;
  protected _addPayeeToCustomerUrl: string;
  protected _addSource: string;
  keys:String[];
  keys1:String[];
  listofCampaigns:ResponseWrapper<Campaigns>;
  _service_name:string = '/campaigns';
  campaign_id:string;
  public searchHits: Subject<null>;
  selectedCampaign: Campaigns;
  alreadySelectedPayee:Campaigns;
  

  constructor(router:Router,route:ActivatedRoute ,private fb: FormBuilder, dataManager: DataManagerService,snackBar:MatSnackBar,@Inject('LOCALSTORAGE') private localStorage: any, @Inject(PLATFORM_ID) private platformId: any) {
    super();
    this._addPayeeToCustomerUrl=AppConstants.BASE_URL + '/payees'+'?limit=' + this.limit + '&page=1';
    this.dataManager = dataManager;
    this.snackBar = snackBar;
    this.errorMsgFlag = false;
    this.router=router;
    this.route = route;
    this.listofCampaigns=new ResponseWrapper<Campaigns>();
    this.getListUrl = AppConstants.BASE_URL + this._service_name+'?orderBy=updated_at&sortedBy=desc';
    this.searchHits = new Subject<null>();
    this.selectedCampaign=new Campaigns();
    // this.alreadySelectedPayee = this.data.payee;
    if (this.alreadySelectedPayee) {
      this.selectedCampaign = this.data[0];
    }
  }
  ngOnInit(): void {
    this.searchHits.pipe(debounceTime(600),distinctUntilChanged()).subscribe((res) => {
      if (this.searchText) {
        // this.setUrl();
      }
    }); 

    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_STORAGE_KEY)
        && !this.localStorage.getItem(FilterConstants.PAYMENT_FILTER_COUNT_STORAGE_KEY)) {
        // this.setUrl();
      } else  {
        // this.setUrl();
      }
    }

    this.route.queryParams.subscribe(params=>{
      this.campaign_id = params['id'];
    });
    this.addPayee=this.fb.group({
      first_name: ['',[Validators.required,Validators.minLength(3)]],
      last_name: ['',[Validators.required,Validators.minLength(3)]],
      email: ['',[Validators.required,Validators.email]],
      type: ['',[Validators.required]],
      percent: ['0'],
      fixed_amount: ['0'],
      dashboard_access: ['',[Validators.required,,Validators.minLength(1)]],
      campaign_id:[''] 
   });
    this.callCampaignListApi();
  }


  cancelAddPeyee() {
    if (this.campaign_id === undefined ) {
      this.router.navigate(['merchant-bridge/payee-summary']);
     } else {
      this.router.navigate(['../edit-campaign'+'/'+this.campaign_id],{relativeTo : this.route,queryParams:{id:this.campaign_id}})
     }
    
  }

  createNewPayee() {
    if (this.campaign_id === undefined) {
      this.addPayee.value.campaign_id=this.selectedCampaign.id;
      this.campaign_id=this.selectedCampaign.id;
      this.createPayeeOnApi(this.addPayee.value);
      if (this.addPayee.value.campaign_id !== undefined) {
        this.router.navigate(['merchant-bridge/edit-campaign'+'/'+this.addPayee.value.campaign_id],{queryParams:{id:this.addPayee.value.campaign_id,page:'payee-summary'}})
      }
    } else {
        this.addPayee.value.campaign_id=this.campaign_id;
        console.log(this.addPayee.value,'deyan');
    this.createPayeeOnApi(this.addPayee.value);
    }
  }
   protected passSourceResponse(res) {

    this.listofCampaigns = res.data;
   };

   protected passResponse(response) {

    this.showSnackBar('Payee is created', 'Ok', 3000);
    this.errorMsgFlag = false;
    if (this.campaign_id === "undefined") {
     this.router.navigate(['merchant-bridge/payee-summary']);
    }
    this.router.navigate(['merchant-bridge/edit-campaign'+'/'+this.campaign_id],{queryParams:{id:this.campaign_id}});
   };
  onSelected(value){
    this.typePayeePercentage=false;
      this.typePayeeAmount=false;
    if (value==='percent') {
      this.typePayeePercentage=true;
      this.addPayee.controls['fixed_amount'].clearValidators();
      this.addPayee.controls['percent'].setValidators([Validators.required,Validators.minLength(1),Validators.max(100)]);
    } else if(value==='fixed'){
      this.typePayeeAmount=true;
      this.addPayee.controls['percent'].clearValidators();
      this.addPayee.controls['fixed_amount'].setValidators([Validators.required,Validators.minLength(1)]);
    } else {
      this.typePayeePercentage=true;
      this.typePayeeAmount=true;
      this.addPayee.controls['fixed_amount'].clearValidators();
      this.addPayee.controls['percent'].clearValidators();
      this.addPayee.controls['percent'].setValidators([Validators.required,Validators.minLength(1),Validators.max(100)]);
      this.addPayee.controls['fixed_amount'].setValidators([Validators.required,Validators.minLength(1)]);

    }
  }
    onlyNumber(event: any) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === 'Delete') // delete
      || keypressed ===46 //dot decimal point
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  print(obj) {
    console.log(obj);
  }


   protected passError(error) {

    this.showSnackBar('Not created', 'Ok', 3000);
    this.errorMsg = error.message;
    this.topErrorMsg = error.errors;
    this.keys = Object.keys(this.topErrorMsg);
    this.errorMsgFlag = true;
    this.keys1 = Object.values(this.topErrorMsg);
    console.log(this.keys, 'asutosh',this.topErrorMsg);
    let i;
    let j;
    const name = ['first_name', 'last_name', 'percent', 'campaign_id', 'status', 'type', 'email', 'fixed_amount'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
   };

   protected getListErr(err: any) {
       
      this.showSnackBar('Not created', 'Ok', 3000);
      this.errorMsg = err.message;
      this.topErrorMsg = err.errors;
      this.keys = Object.keys(this.topErrorMsg);
      this.errorMsgFlag = true;
      this.keys1 = Object.values(this.topErrorMsg);
      console.log(this.keys, 'asutosh',this.topErrorMsg);
      let i;
      let j;
      const name = ['first_name', 'last_name', 'percent', 'campaign_id', 'status', 'type', 'email', 'fixed_amount'];
      for (i = 0; i < this.keys.length; i++) {
        for (j = 0; j < name.length; j++) {
          if (this.keys[i].match(name[j])) {
            document.getElementById(name[j]).style.borderColor = '#a94442';
            document.getElementById(name[j] + 1).style.display = 'block';
          }
        }
      }
   };
   payeeSelected(event) {
    this.selectedCampaign = event;
    console.log('payeeSelected', this.selectedCampaign);
  }
     deletePayee() {
      this.selectedCampaign.id = null;
      this.selectedCampaign.name = null;
    }
}
