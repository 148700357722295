import {BaseComponent} from '../../abstract/base.component';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import { Directive } from "@angular/core";

@Directive()
export abstract class ForgotPasswordAction extends BaseComponent {
  // protected _sigInUrl: string;
  protected _forgotPassword: string;
  protected abstract passForgotPasswordResponse(res);
  protected abstract passForgotPasswordError(error: ApiErrorModel);
  // userSignIn(postBody) {
  //   this.dataManager.login(this._sigInUrl, postBody)
  //     .subscribe(
  //       res => this.passSiginResponse(res),
  //       error => {
  //         this.networkErrorHandler(error.error);
  //         this.passSignInError(error.error);
  //       }
  //     );
  // }
  resetPassword(postBody) {
    this.dataManager.postRequest(this._forgotPassword, postBody, 0)
      .subscribe(
        res => this.passForgotPasswordResponse(res),
        error => {
          this.networkErrorHandler(error.error);
          this.passForgotPasswordError(error.error);
        }
      );
  }
}
