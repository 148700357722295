<app-details-loader *ngIf="loadFlag || errorMsg" [errorMsg]="errorMsg" [Height]="'70vh'" [LoaderMarginTop]="'19%'"
></app-details-loader>
<div class="container-fluid cust_detail_block" *ngIf="!loadFlag && !errorMsg ">
   <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-12">
         <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12 name-div">
               <span class="head-email" *ngIf="customer.name">{{customer.name}}</span>
               <br class="d-sm-none d-flex">
               <span class="head-text customer_id" *ngIf="customer.customer_id">{{customer.customer_id}}</span>
            </div>
         </div>
         <div class="row mb-4">
            <div class="col-md-8 col-lg-8 col-sm-12 col-12 cust-email">
               <span class="head-text" *ngIf="customer.email">{{customer.email}}</span>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 col-12 text-right top-align">
               <span class="detail-btn" *ngIf="delete_permission" (click)="onDelete()">
               <img class="update-img" src="../../../../assets/images/delete.png">Delete</span>
            </div>
         </div>
         <!-- Customer Details start here-->
         <div class="b_shadow mb-4">
            <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
                  <span>Details</span>
               </div>
               <div class="col-md-6 col-lg-6 col-sm-12 col-7 right-heading text-right">
                  <span class="right-heading green-active detail-btn" *ngIf="update_permission" (customerData)="customer($event)" (click)="updateCustomer()">
                  Update details
                  </span>
               </div>
            </div>
            <!-- Account information start here-->
            <div class="row m-0 px-2 py-3">
               <div class="col-md-12 col-lg-6 col-12 col-sm-12 padding-m">
                  <div class="row">
                     <div class="col-md-12 my-md-2">
                        <span class="table-heading det_head">Account information</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc">ID:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data">{{customer.customer_id? customer.customer_id : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6  col-5">
                        <span class="table-data detail_desc">Created:</span>
                     </div>
                     <div class="col-sm-6 col-7 pr-0">
                        <span class="table-data">{{customer.created_at ? (customer.created_at | dateFormat: 'MM/dd/yyyy hh:mm') : '--' }}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6  col-5 text-nowrap">
                        <span class="table-data detail_desc">Email address:</span>
                     </div>
                     <div class="col-sm-6 col-7 email-div">
                        <span class="table-data inform">{{customer.email ? customer.email : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-5">
                        <span class="table-data detail_desc ">Description:</span>
                     </div>
                     <div class="col-sm-6 col-7">
                        <span class="table-data inform">{{customer.description ? customer.description : '--'}}</span>
                     </div>
                  </div>
               </div>
               <div class="col-md-12 col-lg-6 col-12 col-sm-12 padding-m mt-sm-0 mt-4">
                  <div class="row">
                     <div class="col-sm-12 col-12 my-md-2">
                        <span class="table-heading det_head">Invoicing settings</span>
                     </div>
                  </div>
                  <div class="row pt-sm-0 pt-2">
                     <div class="col-sm-6  col-6 text-nowrap">
                        <span class="table-data detail_desc">Send invoices to:</span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{customer.send_email_address ? customer.send_email_address : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc">Address:</span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{customer.address_1 ? customer.address_1 + ', ' : '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{customer.address_2 ? customer.address_2 + ', ': '--'}}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6 col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{(customer.city ? customer.city + ', ' : '--') + (customer.state ? (customer.state ==="null") ? '--' : customer.state : '--') }}</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-6  col-6">
                        <span class="table-data detail_desc"></span>
                     </div>
                     <div class="col-sm-6 col-6 pl-0 pr-0 pl-m-15">
                        <span class="table-data inform">{{(customer.zip ? (customer.zip !=="null") ? '': + ', ' : '--' ) + (customer.country ? (customer.country ==="null") ? '--': ', ' + customer.country : '--')}}</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="b_shadow mb-4">
            <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-6 left-heading pl-4">
                  <span>Cards</span>
               </div>
               <div class="col-md-6 col-lg-6 col-sm-12 col-6 right-heading text-right">
                  <span class="right-heading green-active detail-btn" *ngIf="update_permission && create_payment_permission" (click)="addCard()">&nbsp;Add card</span>
               </div>
            </div>
            <!-- Account information start here-->
            <ng-container *ngIf="customer.card.data.length == 0">
               <div class="table1 text-center" >
                  <span class="table-head ">No card is added</span>
               </div>
            </ng-container>
            <ng-container *ngIf="customer.card.data.length > 0">
               <div class="row m-0 px-2">
                  <div class="col-md-12 col-lg-6 col-sm-12 col-xl-6 col-12 mb-4 mt-4" *ngFor="let card of customer.card.data">
                     <div class="row m-0">
                        <div class="col-md-8 col-sm-12 col-xs-12  col-6 card_box padding-m" [ngClass]="{
                           ' shadow bg-white': card.is_default}">
                           <div class="row p-2">
                              <div class="col-md-12 text-right p-0">
                                 <div class="">
                                    <img class="visa"  [src]="card_img_obj[card.brand] ? card_img_obj[card.brand] : '../../../../assets/images/card-default.png'"/>
                                 </div>
                              </div>
                              <div class="col-md-12 mt-4 text-center cust_card">
                                 <span class="table-heading pt-2 l-spacing" style="margin-right: 0">{{card.first6digit ? (card.first6digit | CreditCardMaskPipe) : '**** **** ****'}}</span>
                                 <span class="table-heading l-spacing " *ngIf="card.first6digit" style="padding-top: 2.5%;">** ****</span>
                                 <span class="table-heading pt-2 l-spacing">{{card.last4digit ? card.last4digit : '****'}}</span>
                              </div>
                              <div class="col-md-12 mt-3 mb-3 text-right p-0">
                                 <span class="badge text-wrap cus_thru">VALID
                                 THRU</span>
                                 <span class="table-heading l-spacing card_cvv ml-2">{{card.exp_month + '/' + card.exp_year}}</span>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12 col-6 px-4">
                           <div class="row">
                              <span class="d-a-btn xs-hide col-md-12 mb-4 text-center" *ngIf="card.is_default">
                                 Default
                              </span>
                              <span class="mr-1 mb-4 col-md-12 payment-right green-active" [ngClass]="{
                              'card_notdefault': !card.is_default}"  *ngIf="update_card_permission && !card.is_default;" (click)="changeDefaultCard(card.id)">
                              Make default</span>
                              <span class="col-md-12 mb-4 payment-right green-active" [ngClass]="{
                              'card_notdefault': !card.is_default}" *ngIf="delete_card_permission"><span
                                 class="xs-hide" (click)="deleteCard(card); loadFlag"> Delete</span></span>
                              <span class="col-md-12 mb-4 payment-right green-active"  [ngClass]="{
                              'card_notdefault': !card.is_default}" *ngIf="update_card_permission" (click)="editCard(card)">Edit</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </ng-container>
         </div>
         <div class="b_shadow mb-4">
            <div class="row m-0 heading-card">
               <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
                  <span>Bank Accounts {{(customer.bank_account !==undefined ? customer.bank_account.data.length:'' )}}</span>
               </div>
                 <div class="col-md-6 col-lg-6 col-sm-12 col-7 right-heading text-right">
                  <span class="detail-btn" *ngIf="create_payment_permission" (click)="addBankAccount()">&nbsp;Add Bank Account</span>
               </div>  
            </div>
            <div class="row m-0">
               <div class="col-md-12 no-data" *ngIf="(customer.bank_account !==undefined ? customer.bank_account.data.length==0:'' )">
                  <span class="table-head">{{false ? 'Permission denied for payment listing' : 'No bank account is added'}}</span>
               </div>
               <div class="table_content-detail table-responsive" *ngIf="(customer.bank_account !==undefined ? customer.bank_account.data.length>0:'' )">
                  <table class="table pl-0">
                     <thead class="thead">
                        <tr >
                           <th >Routing Number</th>
                           <th >Bank Account Ending</th>
                           <th >Account Type</th>
                           <th >Account Holder Name</th>
                           <th class="text-center">Edit</th>
                           <th class="text-center">Default</th>

                        </tr>
                     </thead>
                     <tbody>
                        <tr class="cus_list" *ngFor="let bankAccount of customer.bank_account.data" >
                           <td data-th="Routing"><div>{{bankAccount.routing_number}} </div></td>
                        <td data-th="Bank account">
                          <div> {{bankAccount.account_number ? bankAccount.account_number : '----'}}</div>
                        </td>
                        <td data-th="Payment ID"><div>{{bankAccount.account_type}}</div></td>
                        <!-- <td data-th="Status"><div><span class="capture"><i class="fa fa-circle " [class.c_green]="bankAccount.status === 'settled'"  [class.c_a_others]="bankAccount.status ==='authorized' || bankAccount.status === 'submitted_for_settlement' || bankAccount.status === 'void' "  [class.c_red]="isRedClass(bankAccount.status)" [class.c_grey]="(bankAccount.status !== 'settled' && bankAccount.status === 'failed_to_gateway') || bankAccount.status === 'authorization_expired' || bankAccount.status === 'refunded' || bankAccount.status === 'partial_refund'"  [class.c_blue]="bankAccount.status === 'partial_refund'" aria-hidden="true"></i>{{bankAccount.status ? (bankAccount.status | camalizeStr : true) : '----'}}</span></div></td> -->
                        <td data-th="Account Holder"><div>{{bankAccount.first_name}} {{bankAccount.last_name}}</div></td>
                        <!-- <td data-th="Sec"><div>{{bankAccount.first_name}} </div></td>    -->
                        <td class="text-center" data-th="Payment ID"> 
                            <span [routerLink]="redirect_link" [queryParams]="{id:bankAccount.id,cust_id:customer.customer_id}">        
                              <svg v-if="editEnable" xmlns="http://www.w3.org/2000/svg"
                                    width="30" height="30" viewBox="0 0 30 30"
                                    style="cursor: pointer; fill: #58dc8bbf;"
                                    aria-labelledby="edit" class="fill-current">
                              <path d="M4.3 10.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM6 14h2.59l9-9L15 2.41l-9 9V14zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h6a1 1 0 1 1 0 2H2v14h14v-6z">
                              </path>
                           </svg>
                           </span>
                               &nbsp;&nbsp;&nbsp;  
                           <span (click)="deleteBankAccount(bankAccount.id)"> 
                              <svg v-if="deleteEnable" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer; fill:rgba(224, 114, 114, 0.712);"
                              width="30" height="30" viewBox="0 0 30 30" aria-labelledby="delete"
                              class="fill-current">
                              <path fill-rule="nonzero"
                                d="M6 4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2h5a1 1 0 0 1 0 2h-1v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6H1a1 1 0 1 1 0-2h5zM4 6v12h12V6H4zm8-2V2H8v2h4zM8 8a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path>
                              </svg>
                           </span>
                             
                          
                        </td>
                        <td class="right-heading text-right" data-th="Account Holder"><div><span class="detail-btn" *ngIf="create_payment_permission" (click)="makeDefaultBankAccount(bankAccount.id)">&nbsp;{{bankAccount.is_default === 0 ? 'Make' :'Is' }} Default</span></div></td>
                     </tr>
                     </tbody>
                    
                  </table>
               </div>
            </div>
         </div>
         <!-- Card and Active subscription start here-->
         <div class="b_shadow mb-4 col-11 col-sm-12 col-md-12 col-lg-12 padding-m">
            <div class="row heading-card">
               <div class="col-md-6   col-5 left-heading  pr-0 pl-4 text-nowrap">
                  Subscriptions
               </div>
               <div class="col-md-6 col-7 right-heading text-right">
                  <span class="right-heading detail-btn"  (click)="openAddSubscription()">Add subscriptions </span>
               </div>
            </div>
            <div class="col-12 text-center py-4 " *ngIf="customerSubscriptionList.length <= 0">
               <span class="table-head text-center">No active subscriptions</span>
            </div>
            <div class="col-md-12 no-data"
               *ngIf="customerSubscriptionList.length > 0 && !list_subscription_permission">
               <span class="table-head">Permisison Denied</span>
            </div>

            <ng-container *ngIf="list_subscription_permission && customerSubscriptionList.length > 0">
                  <div class="col-md-12 px-4 py-3 sub_cnt"  *ngFor="let subscription of customerSubscriptionList; let i = index">
                     <div  *ngIf="i < 5" class="row align-items-center justify-content-between" (click)="openSubscriptionDetails(subscription)">
                     <div class="sub_title col-lg-8 col-md-5 col-12 col-sm-5 col-xl-8 pl-1">{{subscription.plan.data.name ? subscription.plan.data.name : '-'}} </div>
                     <p class="sub_type col-12 col-lg-2 col-md-4 col-sm-4 col-xl-2">(${{subscription.plan.data.amount ? subscription.plan.data.amount/100 : '-'}} USD/ {{subscription.plan.data.interval ? subscription.plan.data.interval : '-'}})</p>
                     <span class="w-full">
                        <div class="text-center d-a-btn xs-hide sub_btn subscription-status" [ngClass]="{'sub_active_btn' : subscription.status ==='active', 'sub_cancel_btn' : subscription.status ==='canceled', 'sub_trial_pending_btn': (subscription.status === 'pending' || subscription.status ==='trial')}">
                        {{subscription.status | camalizeStr : true}}
                        </div>
                     </span>
                  </div>
               </div>
         </ng-container>
         <div class="row py-3" *ngIf="customerSubscriptionList && customerSubscriptionList.length > 4">
            <div class=" col-md-12 text-center view-more-button-container">
               <button mat-raised-button class="view_more" *ngIf="list_subscription_permission"
               (click)="redirectToSubscriptionList()">View more
               subscriptions
               </button>
            </div>
         </div>
      </div>

      <!-- payment transaction list start here-->
      <div class="b_shadow mb-4">
         <div class="row m-0 heading-card">
            <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
               <span>Card Payments</span>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-7 right-heading text-right">
               <span class="detail-btn" *ngIf="create_payment_permission" (click)="createCharge()">&nbsp;Create payment</span>
            </div>
         </div>
         <div class="row m-0">
            <div class="col-md-12 no-data" *ngIf="customerCharge.length === 0">
               <span class="table-head">{{!list_payment_permission ? 'Permission denied for payment listing' : 'No payment is added'}}</span>
            </div>
            <div class="table_content-detail table-responsive" *ngIf="customerCharge.length > 0 && list_payment_permission">
               <table class="table pl-0">
                  <thead class="thead">
                     <tr>
                        <th >Email address</th>
                        <th >Amount</th>
                        <th >Payment ID</th>
                        <th >Status</th>
                        <th >Created</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="cus_list" *ngFor="let charge of customerCharge" (click)="redirectToChargeDetails(charge)">
                     <td data-th="Email address">
                       <div> {{customer.email ? customer.email : '----'}}</div>
                     </td>
                     <td data-th="Amount"><div>${{charge.amount/100 | number: '1.2-2'}} USD</div></td>
                     <td data-th="Payment ID"><div>{{charge.id}}</div></td>
                     <td data-th="Status"><div><span class="capture"><i class="fa fa-circle " [class.c_green]="charge.status === 'settled'"  [class.c_a_others]="charge.status ==='authorized' || charge.status === 'submitted_for_settlement' || charge.status === 'void' "  [class.c_red]="isRedClass(charge.status)" [class.c_grey]="(charge.status !== 'settled' && charge.status === 'failed_to_gateway') || charge.status === 'authorization_expired' || charge.status === 'refunded' || charge.status === 'partial_refund'"  [class.c_blue]="charge.status === 'partial_refund'" aria-hidden="true"></i>{{charge.status ? (charge.status | camalizeStr : true) : '----'}}</span></div></td>
                     <td data-th="Created"><div>{{charge.created_at | dateFormat: 'MM/dd/yyyy hh:mm'}}</div></td>
                     </tr>
                  </tbody>
                  <div class="text-center view-more-button-container py-2" *ngIf="customer.charge.data && customer.charge.data.length > 4 && list_payment_permission">
                     <button class="view_more" (click)="redirectToPaymentList()">View more payments </button>
                  </div>
               </table>
            </div>
         </div>
      </div>
      <div class="b_shadow mb-4">
         <div class="row m-0 heading-card">
            <div class="col-md-6 col-lg-6 col-sm-12 col-5 left-heading pl-4">
               <span>ACH Payments</span>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 col-7 right-heading text-right">
               <span class="detail-btn" *ngIf="create_payment_permission" (click)="createAch()">&nbsp;Create ACH</span>
            </div>
         </div>
         <div class="row m-0">
            <div class="col-md-12 no-data" *ngIf="customerACHCharge.length === 0">
               <span class="table-head">{{false ? 'Permission denied for payment listing' : 'No payment is added'}}</span>
            </div>
            <div class="table_content-detail table-responsive" *ngIf="customerACHCharge.length > 0 && list_payment_permission">
               <table class="table pl-0">
                  <thead class="thead">
                     <tr>
                        <th >Account Number</th>
                        <th >Amount</th>
                        <th >Payment ID</th>
                        <th >Status</th>
                        <th >Created</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="cus_list" *ngFor="let charge of customerACHCharge" (click)="redirectToAchDetails(charge)">
                     <td data-th="Email address">
                       <div> {{charge.bank_account.data.account_number ? charge.bank_account.data.account_number : '----'}}</div>
                     </td>
                     <td data-th="Amount"><div>${{charge.amount/100 | number: '1.2-2'}} USD</div></td>
                     <td data-th="Payment ID"><div>{{charge.authorization_id}}</div></td>
                     <td data-th="Status"><div><span class="capture"><i class="fa fa-circle " [class.c_green]="charge.status === 'settled'"  [class.c_a_others]="charge.status ==='authorized' || charge.status === 'submitted_for_settlement' || charge.status === 'void' "  [class.c_red]="isRedClass(charge.status)" [class.c_grey]="(charge.status !== 'settled' && charge.status === 'failed_to_gateway') || charge.status === 'authorization_expired' || charge.status === 'refunded' || charge.status === 'partial_refund'"  [class.c_blue]="charge.status === 'partial_refund'" aria-hidden="true"></i>{{charge.status ? (charge.status | camalizeStr : true) : '----'}}</span></div></td>
                     <td data-th="Created"><div>{{charge.created_at | date: 'MM/dd/yyyy hh:mm a'}}</div></td>
                     </tr>
                  </tbody>
                  <div class="text-center view-more-button-container py-2" *ngIf="customer.charge.data && customer.charge.data.length > 4 && list_payment_permission">
                     <button class="view_more" (click)="redirectToPaymentList()">View more payments </button>
                  </div>
               </table>
            </div>
         </div>
      </div>
      <div class="b_shadow mb-4">
         <div class="row m-0 heading-card">
            <div class="col-md-6 col-lg-6 col-sm-12 col-12 left-heading pl-4">
               <span>Invoices</span>
            </div>
         </div>
         <div class="row m-0">
            <div class="col-md-12 table1 no-data center" *ngIf="InvoiceDetails.data.length === 0">
               <span class="table-head">No invoices</span>
            </div>
            <div class="col-md-12  p-0" *ngIf="InvoiceDetails.data.length > 0 ">
               <div class="table_content-detail table-responsive">
                  <table class="table ">
                     <thead class="thead">
                        <tr>
                           <th class="customer"><a (click)="setSorting('name')">Customer <i class="fa fa-thin" [ngClass]="orderBy === 'name' ? stylesortBy : 'fa-angle-down'"></i>
                           </a></th>
                           <th class="amount"><a (click)="setSorting('total')">Amount <i class="fa fa-thin" [ngClass]="orderBy === 'total' ? stylesortBy : 'fa-angle-down'"></i></a> </th>
                           <th class="payment"><a (click)="setSorting('amount_due')">Payment due <i class="fa fa-thin" [ngClass]="orderBy === 'amount_due' ? stylesortBy : 'fa-angle-down'"></i></a> </th>
                          <th class="invoice_number"><a (click)="setSorting('invoice_number')">Invoice number <i class="fa fa-thin" [ngClass]="orderBy === 'invoice_number' ? stylesortBy : 'fa-angle-down'"></i></a> </th>
                           <th class="status">Status</th>
                           <th class="created"><a (click)="setSorting('created_at')">Created <i class="fa fa-thin" [ngClass]="orderBy === 'created_at' ? stylesortBy : 'fa-angle-down'"></i>
                           </a></th>
                        </tr>
                     </thead>
                     <tbody>
                     <ng-container *ngIf="invoiceFlag">
                       <tr class="loader">
                         <td colspan="7">
                           <div class="loader-container">

                             <img class="table-loader" src="../../../../assets/images/loading.svg">
                           </div>


                         </td>
                       </tr>
                     </ng-container>
                     <ng-container *ngIf="!invoiceFlag && InvoiceDetails.data.length > 0">
                        <tr *ngFor="let invoice of InvoiceDetails.data" [routerLink]= "invoicepath" [queryParams]="{id: invoice.id}">
                        <td class="customer" data-th="Customer"><div>{{invoice.customer.data.name?invoice.customer.data.name:(invoice.customer.data.email ?invoice.customer.data.email: (invoice.customer.data.id ? invoice.customer.data.id : invoice.customer_id))}}</div></td>
                        <td class="amount" data-th="Amount"><div>${{invoice.total/100 | number: '1.2-2'}}</div></td>
                        <td class="payment" data-th="Payment due"><div>{{invoice.amount_due ? '$' + invoice.amount_due/100 : '$0.00 '}}</div></td>
                        <td class="invoice_number" data-th="Invoice number"><div>{{invoice.invoice_number ? invoice.invoice_number : '---'}}</div>
                        </td>
                        <td class="status " data-th="Status" [title]="invoice.failure_message ? (invoice.failure_message | camalizeStr : true) : ''"><div>
                           <span> <i class="fa fa-circle " [ngClass]="!invoice.paid ? (!invoice.attempted ? 'status_open' : 'status_failed') : 'status_paid'" aria-hidden="true"></i> {{!invoice.paid ? (!invoice.attempted ? 'Open' : 'Failed') : 'Paid' }}</span></div>
                        </td>
                        <td data-th="Created" class="created"><div>{{invoice.created_at ? (invoice.created_at | dateFormat: 'MM/dd/yyyy') : '---'}}</div>
                        </td>
                        </tr>
                     </ng-container>
                     </tbody>
                     <div class="text-center view-more-button-container py-2" *ngIf="customer.invoice.data.length > 5 && !invoiceFlag">
                        <button class="view_more" (click)="redirectToInvoicesList()">View more invoices</button>
                     </div>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <!-- Event list start here-->
      <div class="b_shadow mb-4">
         <div class="row m-0 heading-card ">
            <div class="col-md-12 left-heading pl-4">
               <span>Events</span>
            </div>
         </div>
         <div class="col-md-12 padding-m">
            <app-event *ngIf="customer" [url]="eventListUrl" [ComponentIdKey]="'customer_id'"
            [ComponentId]="customer.id"></app-event>
         </div>
      </div>
   </div>
</div>
