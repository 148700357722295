import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class DisputeFormAction extends BaseComponent {
  protected _getDisputeDetailUrl: string;
  protected _postEvidenceUrl: string;
  protected _deleteCustomerUrl: string;
  protected _submitEvidenceUrl: string;
  getDisputeDetail() {

    this.dataManager.getRequest(this._getDisputeDetailUrl, 0)
      .subscribe(
        res => this.getDisputeResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.getDisputeError(err);
        }
      );
  }

  protected abstract getDisputeResponse(res);

  protected abstract getDisputeError(error);

  postEvidence(postBody) {

    this.dataManager.fileUpload(this._postEvidenceUrl, postBody, 0)
      .subscribe(
        res => this.postEvidenceResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.postEvidenceError(err.error);
        }
      );
  }

  protected abstract postEvidenceResponse(res);

  protected abstract postEvidenceError(error);

  protected submitApiCall(postBody) {
    this.dataManager.postRequest(this._submitEvidenceUrl, postBody, 0)
      .subscribe(
        (response) => this.passSubmitResponse(response),
        error => {
          this.networkErrorHandler(error);
          this.passSubmitError(error.error);
        }
      );
  }
  protected abstract passSubmitResponse(res);

  protected abstract passSubmitError(error);
  protected deleteCustomerFromApi() {
    this.dataManager.appLoaderFlag(true);
    this.dataManager.deleteRequest(this._deleteCustomerUrl)
      .subscribe(
        (res) => {
          // console.log(res);
          this.passDeleteFile(res);
        },
        error => {
          console.log('error on delete cus');
          this.networkErrorHandler(error.error);
          this.passFileDeleteError(error);
        }
      );
  }
  protected abstract passDeleteFile(res);

  protected abstract passFileDeleteError(error);

}
