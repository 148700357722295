import { Component, ElementRef, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardModel } from '../../../data/model/CardModel/card.model';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { AppConstants } from '../../../shared/constants/app-constants';
import { UpdateCardAction } from './update-card.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapsAPILoader } from '@agm/core';
import {DOCUMENT} from '@angular/common';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-edit-customer-card',
  templateUrl: './edit-customer-card.component.html',
  styleUrls: ['./edit-customer-card.component.css']
})
export class EditCustomerCardComponent extends UpdateCardAction implements OnInit {
    /* Google maps autocomplete */
    private API_KEY = AppConstants.GOOGLE_MAPS_API_KEY;
    isApiLoaded = false;
    options: any = {
      componentRestrictions: { country: ['US', 'CA'] }
    };
    /* End Google maps autocomplete */
  months = AppConstants.monthList;
  date: Date;
  updateCard: CardModel = new CardModel();
  years: number[];
  topErrorMsg: string;
  keys: String[];
  keys1: String[];
  moreOption: boolean;
  errorMsgFlag: boolean;
  errorMsg: string;
  loadFlag: boolean;
  brandgetAddress: string;
  account_name: string;
  cvvoptional: number;
  addresopt: number;
  countries = AppConstants.countryList;
  states = AppConstants.stateList;
  data: any;
  get currentAccountId(): any {
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    return currentAccountid;
  }
  googleAddress:string;
  constructor(@Inject(DOCUMENT) private document: Document,dataManager: DataManagerService, router: Router, route: ActivatedRoute ,private mapsAPILoader: MapsAPILoader,private elementRef: ElementRef) {
    super();
    this.dataManager = dataManager;
    this.data = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA));
    this.route = route;
    this.router = router;
    this.date = new Date();
    this.updateCard = this.data;//
    this.years = [];
    this._updateCardUrl = AppConstants.BASE_URL + '/cards/' + this.data.id;//
    for (let i = 0; i < 21; i++) {
      this.years[i] = this.date.getFullYear() + i;
    }
    this.brandgetAddress = '/accounts';
    this.account_name = this.currentAccountId;
  }

  ngOnInit() {
    this.switchaccount(this.account_name);
    this.mapsAPILoader.load().then(() => {
      this.isApiLoaded = true;
    });
  }

  /* Google maps api */

  loadScript() {
    return new Promise((resolve, reject) => {
      const element = this.document.createElement('script');
      element.type = 'text/javascript';
      element.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places&language=en`;
      element.onload = resolve;
      element.onerror = reject;
      this.elementRef.nativeElement.appendChild(element);
    });
  }
  handleAddressChange(address: Address) {
    let addr: any;
    for (addr of address.address_components) {
      if (addr.types[0] === 'country') {
        this.updateCard.country = addr.short_name;
      }
      if (addr.types[0] === 'administrative_area_level_1') {
        this.updateCard.state = addr.long_name;
      }
      if (addr.types[0] === 'locality') {
        this.updateCard.address_line1 = addr.long_name;
        this.updateCard.city = addr.long_name;
      }
      if (addr.types[0] === 'postal_code') {
        this.updateCard.zip = addr.long_name;
      }
    }
    //assigning the formatted address to the model
    this.updateCard.address1 = address.address_components[0].long_name + ', ' + address.address_components[1].long_name +', ' + address.address_components[2].long_name+', ' + address.address_components[4].short_name;
  }
  /* End Google maps api */
  updateCardFunc() {
    this.loadFlag = true;
    const patchBody = {};
    patchBody['card_holder_name'] = this.updateCard.card_holder_name ? this.updateCard.card_holder_name : null;
    patchBody['exp_year'] = this.updateCard.exp_year ? this.updateCard.exp_year : null;
    patchBody['exp_month'] = this.updateCard.exp_month ? this.updateCard.exp_month : null;
    patchBody['zip'] = this.updateCard.zip ? this.updateCard.zip : null;
    patchBody['city'] = this.updateCard.city ? this.updateCard.city : null;
    patchBody['state'] = this.updateCard.state ? this.updateCard.state : null;
    patchBody['address_line1'] = this.updateCard.address1 ? this.updateCard.address1 : null;
    patchBody['address_line2'] = this.updateCard.address2 ? this.updateCard.address2 : null;
    patchBody['country'] = this.updateCard.country ? this.updateCard.country : null;
    this.updateCustomerCard(patchBody);
  }

  protected passUpdatedResponse(res: any) {
    this.goBackToDetails();
  }
  numericOnly(event) {
    const keypressed = event.which || event.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) // digits
      || keypressed === 8 // backspace
      || keypressed === 9 // tab
      || keypressed === 27 // escape
      || (event.key === '-') // dash
      || (event.key === 'Delete') // delete
      || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
      || (event.key === 'ArrowRight') // RightArrow
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  protected passError(error: any) {
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMsg = error.errors;
    this.keys = Object.keys(this.topErrorMsg);
    this.keys1 = Object.values(this.topErrorMsg);
    console.log(this.keys, 'asutosh');
    this.errorMsgFlag = true;
    let i;
    let j;
    const name = ['card_holder_name', 'exp_year', 'exp_month', 'zip'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  protected getAddressResponse(res) {
    console.log('authlogiee', res);
    this.cvvoptional = res.data.cvv_optional;
    this.addresopt = res.data.address_optional;
    this.loadFlag = false;

  }
  protected getAddressError(err) {
    this.loadFlag = false;
    console.log(err);
  }
  switchaccount(account_name) {
    // localStorage.removeItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_NAME);
    this._getaccount = AppConstants.BASE_URL + this.brandgetAddress + '/' + this.account_name;
    console.log(this.brandgetAddress, 'account_name');
    this.getAddress(this._getaccount);
    // this.router.navigate(['loading..']);
  }

  cancelBtn() {
    this.goBackToDetails();
  }
  goBackToDetails() {
    this.router.navigate(['../customer-details'], { queryParams: { id: this.data.customer_id }, relativeTo: this.route })
  }
}
