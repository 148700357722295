import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-split-campaign-request',
  templateUrl: './split-campaign-request.component.html',
  styleUrls: ['./split-campaign-request.component.css']
})
export class SplitCampaignRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
