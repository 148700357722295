import {Component, OnInit} from '@angular/core';
import {InvoicesSettingModel} from '../../../data/model/InvoicesSettingModel/invoices-setting.model';
import {AccountSettingsAction} from './account-settings.action';
import {AppConstants} from '../../../shared/constants/app-constants';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent extends AccountSettingsAction implements OnInit {

  postPatchFlag: boolean;
  settingUrl: string;
  settingVal: InvoicesSettingModel;

  constructor(dataManager: DataManagerService, private dialog: MatDialog) {
    super();
    this.dataManager = dataManager;
    // this.settingUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT + '/' + this.id + '/' + 'branding';
    // this.settingVal = new InvoicesSettingModel();
    // this.getSetting(this.settingUrl);
  }

  ngOnInit() {
  }
  //
  // save() {
  //   this.loadFlag = true;
  //   // this.dataManager.appLoaderFlag(true);
  //   const postbody: any = {};
  //   postbody['action'] = this.settingVal.action;
  //   postbody['retry_1'] = this.settingVal.retry_1;
  //   if (this.settingVal.retry_1 > 0) {
  //     postbody['retry_2'] = this.settingVal.retry_2;
  //     if (this.settingVal.retry_2 > 0) {
  //       postbody['retry_3'] = this.settingVal.retry_3;
  //     }
  //   }
  //   this.postSetting(this.settingUrl, postbody);
  // }

  protected getSettingResponse(res) {
    console.log('getSettingResponse', res);
    // this.settingVal = res.data[0];
    // this.postPatchFlag = this.settingVal.id ? true : false;
    // console.log('postPatchFlag', this.postPatchFlag);
    // console.log(this.settingVal);
  }

  protected getSettingError(err) {
    console.log(err);
  }

  protected postSettingResponse(res) {
    // console.log('postSettingResponse', res);
    // this.settingVal = res.data;
  }

  protected postSettingError(err) {
    console.log(err);
  }
}
