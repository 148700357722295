<div class="container-fluid mt-5 b_shadow">
  <div class="row">
    <span class="col-sm-12 col-12 col-md-12 col-lg-12 create-header header bg-infos">
      Add new card
    </span>
  </div>
  <ng-container *ngIf="topErrorMessage">
    <div class="row err" *ngFor="let obj of keys1 ">
      <div class="col-md-12 col-lg-12 col-sm-12 col-12 mb-1" *ngFor="let value of obj ">
        <p class="col-md-12 col-lg-12 col-sm-12 col-12 error" id="err">{{value}}</p>
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="!topErrorMessage">
    <div class="col-md-12 col-lg-12 col-sm-12 col-12 error"> {{errorMsg}}</div>
  </div>
  <div class="col-lg-9 col-sm-12 col-12 col-md-12 col-xl-8 pt-4">
    <form [formGroup]="demoForm" (ngSubmit)="demoForm.invalid ? print(demoForm) : addCard()">
      <app-customer-add-card-details-section #addCardForm (onFormGroupChange)="setChildForm($event)"
        [customerCard]="customerCard" [heading]="heading" (onCustomerCardChange)="customerCard" (onCvvError)="cvcError = $event">
      </app-customer-add-card-details-section>
    </form>
  </div>
  <div class="row justify-content-end px-4 py-4">
    <button class="update_cancel" (click)="cancelCard()">Cancel</button>
    <app-loading-button (buttonClick)="childSubmit();" [loadFlag]="loadFlag" [buttonText]="'Add card'">
    </app-loading-button>
    
    </div>
</div>
