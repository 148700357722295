import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponModel } from '../../../data/model/CouponModel/coupon.model';
import { CustomerV2Model } from '../../../data/model/CustomerV2Model/customer-v2.model';
import { CycleModel } from '../../../data/model/CycleModel/cycle.model';
import { PlanModel, SubscriptionModel } from '../../../data/model/SubscriptionModel/subscription.model';
import { DataManagerService } from '../../../data/services/DataManager/data-manager.service';
import { SubscriptionComponent } from '../../../subscriptionModule/subscription/subscription.component';
import { AppConstants } from '../../constants/app-constants';
import { PermissionConstants } from '../../constants/permission-constants';
import { CreateNewSusbcriptionAction } from './create-new-susbcription.action';

@Component({
  selector: 'app-create-new-subscription',
  templateUrl: './create-new-subscription.component.html',
  styleUrls: ['./create-new-subscription.component.css']
})
export class CreateNewSubscriptionComponent extends CreateNewSusbcriptionAction implements OnInit {
    heading = 'Add new subscription';
    buttonText = 'Save';
    customerList: CustomerV2Model[] = [];
    planList: CycleModel[] = [];
    couponList: CouponModel[] = [];
    service_name = '/subscriptions';
    palnService_name: string;
    couponService_name: string;
    customerService_name: string;
    selectedCustomerObj: CustomerV2Model;
    alreadySelectedCustomerObj: CustomerV2Model;
    selectedPlanObj: PlanModel;
    selectedCouponObj: CouponModel;
    amountToPay: number;
    tax_percent: number;
    include_trial: any;
    start_after_days: number;
    end_after_cycles: number | string | 'canceled' | 'Expired';
    payment_due_days: number;
    edit_trial: any;
    customerSuggessionUrl: string;
    couponSuggessionUrl: string;
    planSuggessionUrl: string;
    description: string;
    checkbox: boolean;
    topErrorMsg: string;
    topError: any;
    billing_type: any;
    payment_type: any;
    values: String[];
    keys1: any;
    textBoxDisabledFlag: boolean;
    loadFlag: boolean;
    newSubscription: SubscriptionModel;
    show: boolean;
    data: any;
    start_after_days_disabled = true;
    end_after_cycles_disabled = true;
    billing_type_disabled = false;
    setEnableAchPayments: boolean = false;

    constructor(dataManager: DataManagerService, private subscriptionComponent: SubscriptionComponent, route: ActivatedRoute, router: Router) {
      super();
      this.route = route;
      this.router= router
      this.errorMsg = null;
      this.data = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA));
      this.dataManager = dataManager;
      this.palnService_name = '/plans';
      this.couponService_name = '/discounts';
      this.customerService_name = '/customers';
      this.selectedPlanObj = new PlanModel();
      this.selectedCustomerObj = new CustomerV2Model();
      this.checkbox = true;
      this.selectedCouponObj = new CouponModel();
      this.newSubscription = new SubscriptionModel();
      this.newSubscription = this.data.subscription;
      this.start_after_days = null;
      this.end_after_cycles = null;
      this.payment_due_days = null;
      this.show = false;
      this.billing_type = 1;
      this.payment_type = 'card';
      this.selectedPlanObj.trial_period_days = null;
      this.alreadySelectedCustomerObj = this.data.customer;
      if (this.alreadySelectedCustomerObj) {
        this.selectedCustomerObj = this.data.customer;
      }

      if (this.newSubscription !== null) {
        this.selectedCouponObj = this.newSubscription.discount.data;
        this.selectedCustomerObj = this.newSubscription.customer.data;
        this.billing_type_disabled = this.newSubscription.billing_type === 0 ? false : true;
        this.assignUpdateSubscriptionValues();
      }   else {
        // this.newSubscription = new SubscriptionModel();
      }
      this.amountToPay = 0.0;
      this.dataManager = dataManager;
      this._subscriptionPostUrl = AppConstants.BASE_URL + this.service_name;
      this.subscriptionComponent.create_permission = this.permissionCheck(
        PermissionConstants.PERMISSION_CREATE_SUBSCRIPTION ? PermissionConstants.PERMISSION_CREATE_SUBSCRIPTION : PermissionConstants.PERMISSION_CREATE_CUSTOMER
      );
      this.getAccountDetails();
      console.log('newSubscription',this.newSubscription);
      if (this.newSubscription) {

        this.billing_type_disabled = this.newSubscription.billing_type === 0 ? false : true;
      }
    }

    ngOnInit() {
      // this.selectedPlanObj = event;
      if (this.newSubscription) {
        this.setAmount();
      }

      if (this.newSubscription !== null) {
        this.selectedPlanObj.trial_period_days = this.newSubscription.trial_days;

        function numberOfCyclesToCharge(end_at,  interval , interval_count = 1, end_after_cycles = null,status = null,start_at = null) {
          return  Math.floor((end_at-start_at)/(interval*interval_count));
        }
        if (this.newSubscription.end_at !== null ) {
          switch (this.selectedPlanObj.interval) {
            case 'day':
              let end_after_cycles = this.end_after_cycles;
              let day = 86400
              end_after_cycles = numberOfCyclesToCharge(this.newSubscription.end_at, day, this.selectedPlanObj.interval_count, end_after_cycles,this.newSubscription.status,this.newSubscription.start_at);
              this.end_after_cycles = end_after_cycles;
              break;
            case 'week':
              let week = 604800
              let end_after_cycles_week = this.end_after_cycles;
              end_after_cycles_week = numberOfCyclesToCharge(this.newSubscription.end_at, week, this.selectedPlanObj.interval_count, end_after_cycles_week,this.newSubscription.status,this.newSubscription.start_at);
              this.end_after_cycles = end_after_cycles_week;
              break;
            case 'month':
              let month = 2629743;
              let end_after_cycles_month = this.end_after_cycles;
              end_after_cycles_month = numberOfCyclesToCharge(this.newSubscription.end_at, month, this.selectedPlanObj.interval_count, end_after_cycles_month ,this.newSubscription.status,this.newSubscription.start_at);
              this.end_after_cycles = end_after_cycles_month;
              break;

          }
        } else {
          this.end_after_cycles = null;
        }

      } else {
        this.selectedPlanObj.trial_period_days = this.selectedPlanObj.trial_period_days;
      }
      console.log('newSubscriptionj',this.newSubscription)
    }

    billingType(val) {
      this.billing_type = val;
        if (val === 1 ) {
          if (this.payment_type === 'both') {
            this.payment_type = 'card';
          }
          this.payment_due_days = null;
        }
    }
    paymentType(val) {
      this.payment_type=val;
    }

    customerSelected(event) {
      this.selectedCustomerObj = event;
      console.log('customerSelected', this.selectedCustomerObj);
    }

    planSelected(event) {
      this.selectedPlanObj = event;
      console.log('planSelected', this.selectedPlanObj);
      this.setAmount();
    }

    edittrail() {
      this.edit_trial = this.selectedPlanObj.trial_period_days;
    }

    couponSelected(event) {
      this.selectedCouponObj = event;
      // alert(event);
      console.log('couponSelected', this.selectedCouponObj);
      this.setAmount();
    }

    taxSelected(event) {
      this.selectedCouponObj = event;
      console.log('taxSelected', this.selectedCouponObj);
      this.setAmount();
    }

    deleteCoupon() {
      this.selectedCouponObj.discount_id = null;
      this.selectedCouponObj.amount_off = null;
      this.selectedCouponObj.percent_off = null;
      this.selectedCouponObj.duration = null;
    }

    deletePlan() {
      this.selectedPlanObj.name = null;
      this.selectedPlanObj.plan_id = null;
      this.selectedPlanObj.amount = null;
      this.selectedPlanObj.interval_count = null;
      this.selectedPlanObj.interval = null;
      this.selectedPlanObj.trial_period_days = null;
    }

    deleteCustomer() {
      this.selectedCustomerObj.customer_id = null;
      this.selectedCustomerObj.email = null;
    }

    _keyPress(event) {
      const keypressed = event.which || event.keyCode;

      if ((keypressed >= 48 && keypressed <= 57) // digits
        || keypressed === 8 // backspace
        || keypressed === 27 // escape
        || keypressed === 9 // tab
        || (event.key === 'Delete') // delete
        || (keypressed >= 35 && keypressed <= 38) // end, home, Leftarrows
        || (event.key === 'ArrowRight') // RightArrow
      ) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    onCheckboxChange() {
      if (!this.checkbox) {
        setTimeout(() => {
          this.checkbox = false;
          this.selectedPlanObj.trial_period_days = null;
        });
      }
    }

    createSubscription() {
      this.loadFlag = true;
      console.log('objects', this.selectedCouponObj, this.selectedCustomerObj, this.selectedPlanObj);
      if (!this.selectedCustomerObj.customer_id) {
        this.errorMsg = 'No customer selected';
        // document.getElementById('err').style.cssText = 'margin-left: 13.5% !important; position: absolute ; margin-top: 5%;'
        this.loadFlag = false;
      } else if (!this.selectedPlanObj.plan_id) {
        this.errorMsg = 'No plan selected';
        // document.getElementById('err').style.cssText = 'margin-left: 13.5% !important; position: absolute ; margin-top: 11%;'
        this.loadFlag = false;
      } else {
        const postBody = {};
        if (this.newSubscription === null) {
          this.loadFlag = true;
          this.selectedCustomerObj.customer_id !== null ? postBody['customer_id'] = this.selectedCustomerObj.customer_id :
            console.log(this.selectedCustomerObj.customer_id);
          this.selectedCouponObj.discount_id !== null ? postBody['discount_id'] = this.selectedCouponObj.discount_id : console.log(this.selectedCouponObj.discount_id);
          this.selectedPlanObj.plan_id !== null ? postBody['plan_id'] = this.selectedPlanObj.plan_id : console.log(this.selectedPlanObj.plan_id);
          if (this.tax_percent != null) {
            postBody['tax_percent'] = this.tax_percent;
          }
          if (this.start_after_days) {
            console.log('to check', this.start_after_days);
            this.start_after_days != null && this.checkbox === false ? postBody['start_after_days'] = this.start_after_days : console.log(this.start_after_days);
          }
          if (this.end_after_cycles) {
            this.end_after_cycles != null ? postBody['end_after_cycles'] = this.end_after_cycles : console.log(this.end_after_cycles);
          }
          if (this.billing_type === 0) {
            this.payment_due_days != null ? postBody['payment_due_days'] = this.payment_due_days : console.log(this.payment_due_days);
          }
          postBody['billing_type'] = this.billing_type;
          postBody['payment_type'] = this.payment_type ? this.payment_type : '';

          const edittrial = (document.getElementById('trial_days') as HTMLInputElement).value;
          if (this.selectedPlanObj.trial_period_days !== null) {
            postBody['trial_days'] =  edittrial;
          } else {
            postBody['trial_days'] =  '0';
          }

          this.description !== null ? postBody['description'] = this.description : console.log(this.description);
          console.log('postBody', postBody);
          if (this.validatePostBody()) {
            this.postSubscription(postBody);
          }
        } else {

          this._subscriptionUpdateUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_SUBSCRIPTIONS + '/' + this.newSubscription.id;
          // postBody['customer_id'] = this.selectedCustomerObj.customer_id !== null ? this.selectedCustomerObj.customer_id : this.newSubscription.customer_id;
          postBody['discount_id'] = this.selectedCouponObj.discount_id !== null ? this.selectedCouponObj.discount_id : this.newSubscription.discount_id;
          // postBody['plan_id'] = this.selectedPlanObj.plan_id !== null ? this.selectedPlanObj.plan_id : this.newSubscription.plan_id;
          postBody['tax_percent'] = this.tax_percent !== null ? this.tax_percent : 0;
          postBody['start_after_days'] = this.start_after_days !== null ? this.start_after_days : console.log(this.newSubscription.start_after_days);
          // NOTE: end_at is calculated from the cysles and interval, interval_count according to the interval and added to the current time in UNIX format.
          postBody['end_after_cycles'] = this.end_after_cycles !== null ? this.end_after_cycles : console.log(this.newSubscription.end_after_cycles);
          postBody['payment_due_days'] = this.payment_due_days !== null ? this.payment_due_days : console.log(this.payment_due_days);
          if (this.billing_type === 0) {
            this.payment_due_days != null ? postBody['payment_due_days'] = this.payment_due_days : console.log(this.payment_due_days);
          }
          postBody['billing_type'] = this.billing_type;
          postBody['payment_type'] = this.payment_type ? this.payment_type : '';
           postBody['description'] = this.description ?  this.description : null;
          this.edit_trial !== this.selectedPlanObj.trial_period_days && this.checkbox === true && this.edit_trial !== '' ?
            postBody['trial_days'] = this.edit_trial : console.log(this.edit_trial);
          if (this.validatePostBody()) {
            this.updateSubscriptionDetails(postBody);
          }

        }
      }
    }

    /**
     * Method to check Post Body
     * @param postBody
     * @returns {boolean}
     */
    validatePostBody(): boolean {
      //console.log('update request');inc_trial
      const rendradio = ((document.getElementById('endradio') as HTMLInputElement).checked);

      if (rendradio === true && this.end_after_cycles == null) {
        this.loadFlag = false;
        this.errorMsg = 'Enter Billing Cycle Days';
        //  this.errorMsgFlag = true;
        return false;
      }

      const rinc_trial = ((document.getElementById('inc_trial') as HTMLInputElement).checked);

      if (rinc_trial === true && this.edit_trial === '') {
        this.loadFlag = false;
        this.errorMsg = 'Enter Duration Days';
        //  this.errorMsgFlag = true;
        return false;
      }
      return true;
    }

    protected passCouponDetailsResponse(res) {
      console.log('response', res);
      this.selectedCouponObj = res.data;
      console.log('selected coupon', this.selectedCouponObj);
    }

    protected passCouponDetailsError(error) {
      this.selectedCouponObj = this.selectedCouponObj.discount_id ? this.selectedCouponObj : null;
      console.log('selected coupon error', error);
      this.errorMsg = error.error.message;
      this.topErrorMsg = error.error.errors;
      this.values = Object.values(this.topErrorMsg);
      this.keys1 = Object.keys(this.topErrorMsg);
    }


    protected passSubscriptionError(error) {
      this.loadFlag = false;
      this.errorMsg = error.error.message;
      this.topErrorMsg = error.error.errors;
      this.keys1 = Object.keys(this.topErrorMsg);
      this.values = Object.values(this.topErrorMsg);
      // this.keys1 = Object.values(this.values);
      console.log(this.values, 'asu');
      // let i;
      // let j;
      // let name = ['tax_percent', 'start_after_days', 'end_after_cycles', 'trial_days', 'memo'];
      // for (i = 0; i < this.keys1.length; i++ ) {
      //   for (j = 0; j < name.length; j++) {
      //     if (this.keys1[i].match(name[j])) {
      //       document.getElementById(name[j]).style.borderColor = '#a94442';
      //       document.getElementById(name[j] + 1).style.display = 'block';
      //     }
      //   }
      // }
    }

    protected passSubscriptionResponse(response) {
      this.cancelSubscription();
    }


    protected passUpdateResponse(res) {
      this.cancelSubscription();
    }

    protected passUpdateError(error) {
      this.loadFlag = false;
      this.errorMsg = error.error.message;
      this.topErrorMsg = error.error.errors;
      this.values = Object.values(this.topErrorMsg);
      this.keys1 = Object.keys(this.topErrorMsg);
    }
    onSubmit(form) {
      console.log('form', form);
    }
    setAmount() {
      const amount = 0.0;
      this.amountToPay = this.selectedCouponObj.percent_off ?
        (this.selectedPlanObj.amount - (this.selectedPlanObj.amount * this.selectedCouponObj.percent_off / 100)) :
        (this.selectedCouponObj.amount_off ? ((this.selectedPlanObj.amount - this.selectedCouponObj.amount_off) > 0 ? (this.selectedPlanObj.amount - this.selectedCouponObj.amount_off) : 0)
          : this.selectedPlanObj.amount);
      this.tax_percent ? this.amountToPay = (this.amountToPay * this.tax_percent / 100)
        + this.amountToPay : this.amountToPay = this.amountToPay + 0;
      // alert(this.selectedPlanObj.amount);
    }

    assignUpdateSubscriptionValues() {
      this.heading = 'Update subscription';
      this.buttonText = 'Update';
      if (this.newSubscription.plan) {
        this.selectedPlanObj.object = this.newSubscription.plan.data.object ? this.newSubscription.plan.data.object : null;
        this.selectedPlanObj.plan_id = this.newSubscription.plan.data.plan_id ? this.newSubscription.plan.data.plan_id : null;
        this.selectedPlanObj.real_id = this.newSubscription.plan.data.real_id ? this.newSubscription.plan.data.real_id : null;
        this.selectedPlanObj.amount = this.newSubscription.plan.data.amount ? this.newSubscription.plan.data.amount : null;
        this.selectedPlanObj.interval = this.newSubscription.plan.data.interval ? this.newSubscription.plan.data.interval : null;
        this.selectedPlanObj.interval_count = this.newSubscription.plan.data.interval_count ? this.newSubscription.plan.data.interval_count : null;
        this.selectedPlanObj.name = this.newSubscription.plan.data.name ? this.newSubscription.plan.data.name : null;
        this.selectedPlanObj.trial_period_days = this.newSubscription.plan.data.trial_period_days ? this.newSubscription.plan.data.trial_period_days : null;
        this.selectedPlanObj.currency = this.newSubscription.plan.data.currency ? this.newSubscription.plan.data.currency : null;
        this.selectedPlanObj.created_at = this.newSubscription.plan.data.created_at ? this.newSubscription.plan.data.created_at : null;
        this.selectedPlanObj.updated_at = this.newSubscription.plan.data.updated_at ? this.newSubscription.plan.data.updated_at : null;
      }

      this.selectedCustomerObj.customer_id = this.newSubscription.customer_id ? this.newSubscription.customer_id : null;
      this.selectedCouponObj.discount_id = this.newSubscription.discount_id ? this.newSubscription.discount_id : null;
      this.tax_percent = this.newSubscription.tax_percent ? this.newSubscription.tax_percent : null;
      this.description = this.newSubscription.description ? this.newSubscription.description : null;
      this.start_after_days = this.newSubscription.start_after_days ? this.newSubscription.start_after_days : null;
      this.end_after_cycles = this.newSubscription.end_after_cycles ? this.newSubscription.end_after_cycles : null;
      this.payment_due_days = this.newSubscription.billing_type === 0 ? this.newSubscription.payment_due_days : null;
      // this.billing_type = this.newSubscription.billing_type ? this.newSubscription.billing_type : null;
    }

    cancelSubscription() {
      if(this.data.subscription === null) {
        if(this.router.routerState.snapshot.url != "/subscription/create-subscription") {
          this.router.navigate(['../customer-details'], {queryParams: {id: this.data.customer.customer_id},relativeTo : this.route })
          }
          else {
          this.router.navigate(['../'], {relativeTo:  this.route});
          }
      } else {
        this.router.navigate(['../subscription-details'], {queryParams: {id: this.data.subscription.id},relativeTo : this.route })
      }
    }

    assignFlagEnableAchPayments(res) {
      if (res['data']['enableAchPayments'].data.length > 0) {
        this.setEnableAchPayments = res['data']['enableAchPayments']['data'][0]['value'] === '1';
      }
    };

  }
