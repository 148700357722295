import {replaceNgsp} from '@angular/compiler/src/ml_parser/html_whitespaces';

export class ResponseWrapper<T> {
  data: T[] = [];
  meta: ApiWrapperMeta;

  constructor() {
    this.meta = new ApiWrapperMeta();
  }
}

export class ApiWrapperMeta {
  pagination: Pagination;

  constructor() {
    this.pagination = new Pagination();
  }
}

export class Pagination {

  total?: number;
  count?: number;
  per_page?: number;
  current_page?: number;
  total_pages?: number;
  links?: PaginationLinks;
  constructor() {
    this.links = new PaginationLinks();
  }
}


export class PaginationLinks {
  previous?: string;
  next?: string;
}

export class Campaigns {
            object?: string;
            id?: string;
            name?: string;
            description?: string;
            status?: ('active' | 'inactive' );
            created_at?: string;
            updated_at?: string;
            readable_created_at?: string;
            readable_updated_at?: string;
            account?: Account
            agent?: Agent;
            payees?: Payees[];
            constructor() {
              this.account = new Account();
              this.agent = new Agent();
              this.payees = new Array<Payees>();

            }
        
}

export class Payees {
    id: string;
    object: string;
    first_name: string;
    last_name: string;
    email: string;
    type: string;
    percent: string;
    fixed_amount: string;
    access: string;
    status: string;
    created_at: string;
    updated_at: string;
    readable_created_at: string;
    readable_updated_at: string;
    campaign: {
        data: {
            object: string;
            id: string;
            name: string;
            description: string;
            status: string;
            created_at: string;
            updated_at: string;
            readable_created_at: string;
            readable_updated_at: string;
        }
    }
}

class Agent {
    id: string;
    object: string;
    company_dba: string;
    created_at: string;
    updated_at: string;
    readable_created_at: string;
    readable_updated_at: string;
}

class Account {
    id: string;
    object: string;
    legal_name: string;
    dba: string;
}