<div class="container-fluid overlay">


  <div class="row">

    <div class="heading-payarc col-md-4 col-sm-8 col-xs-12">
    </div>

    <div class="heading-payarc col-md-4 col-sm-8 col-xs-12">
    </div>
    <div class="col-md-4 col-sm-8 col-xs-12">

    </div>
  </div>
  <br>

  <div class="row">

    <mat-card class="container-card col-md-4 col-sm-8 col-xs-12">
      <div class="row">
        <div class="col-sm-12" style="margin-top: -25px">
          <h1 class="heading-text">PayArc</h1>

        </div>

        <ng-container *ngIf="topErrorMessage">
          <div class="row err" *ngFor="let obj of keys1 " >
            <div class="col-md-12" *ngFor="let value of obj " >
              <p class="col-md-12 error" id="err" >{{value}}</p>
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="!topErrorMessage">
          <div class="col-md-12 error mb-1"> {{errorMsg}}</div>
        </div>
      </div>
      <br>
      <div class="form-group row">
        <span class="error_sign col-2" id="name1">!</span>
        <div class="col-sm-12">
          <input type="text" class="form-control" name="Name"   required
                 [(ngModel)]="name"  id="name"
                 placeholder="Your Name"  >
        </div>
      </div>
      <div class="form-group row">
        <span class="error_sign col-2" id="email1">!</span>
        <div class="col-sm-12">
          <input type="email" class="form-control" name="Email"   required
                 pattern="[^ @]*@[^ @]*"  [(ngModel)]="email"  id="email"
                 placeholder="email@example.com"  >
        </div>
      </div>
      <div class="form-group row">
        <span class="error_sign col-2" id="password1">!</span>
        <div class="col-sm-12">
          <input type="password" class="form-control" name="password" required [(ngModel)]="password" id="password"
               autocomplete="off"  placeholder="Password">
          <div class="tooltip2"><span class="fa fa-info-circle errspan tooltip2"></span>

            <span class="tooltiptext">Your password must be between 8 and 14 characters.
Your password must contain at least one uppercase letter (ex: A, B, etc.).
Your password must contain at least one lowercase letter.
Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.).
Your password must contain at least one special character (eg. _, $, % etc)</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <button type="submit" class=" btn-block btn btn-primary" (click)="register()">Register</button>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <a (click)="routeToSigin()"><div class="create_acc">Already have an account</div></a>
        </div>
      </div>
    </mat-card>
  </div>
</div>


