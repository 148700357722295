import {BaseComponent} from './base.component';
import {Subject} from 'rxjs';
import { Directive } from "@angular/core";
import { ErrorConstants } from '../shared/constants/error-constants';

@Directive()
export abstract class BaseListComponent extends BaseComponent {
  protected getListUrl: string;
  public searchText: string;
  public searchTerm: string;
  public batchType: string;
  public orderBy: string;
  public sortBy = 'asc';
  public stylesortBy = 'fa-angle-up';
  localSearchObjCopy = [];
  public noPagination = false;
  public localSearch = false;
  public searchHits: Subject<null>;
  public hasAdvancefilter = false;
  public hasCampaigns;
  public hasPayees;
  protected searchFields: string[] = [];
  protected includes: string;
  public totalRecords?: number;
  public disablePrev: boolean;
  public disableNext: boolean;
  public metapaginate: boolean;
  public metadata: object;
  public limitArray = [25, 50, 100, 1000];
  public limitSelected:boolean=false;

  protected getList(url) {

    this.loadFlag = true;
    this.dataManager.getRequest(url, 0).subscribe(
      (res) => {
        this.loadFlag = false;
        this.getListRes(res);
        this.setPagination(res);
        if (res.data.length === 0) {
          this.errorMsg = ErrorConstants.payeeErrorMsg[0];
        } else {
          this.errorMsg = null;
        }
      },
      (err) => {
        this.loadFlag = false;
        this.getListErr(err);
      }
    );
  }

  public setUrl() {
    let url = this.getListUrl;

    url =

    url + (this.noPagination ? '' : (isNaN(this.limit) ? '?limit=0' : '?limit=' + this.limit + '&page=1')) +
    ((this.isNullOrUndefined(this.searchText) || this.localSearch)
    ? ''
    : '&search=' + this.searchTextFormat()) +
    (this.isNullOrUndefined(this.orderBy)
    ? ''
    : '&orderBy=' + this.orderBy + '&sortedBy=' + this.sortBy) +
    (this.isNullOrUndefined(this.searchTerm)
    ? ''
    : '&searchTerm=' + this.searchTerm)
    ;
    url=(this.hasCampaigns ? url +'&include=campaigns':url);
    url=(this.hasPayees ? url +'&include=payees':url);
    url =(this.is_filter ? url + this.filterPostFix : url)

    url =
      url +
      (this.hasAdvancefilter && !this.isNullOrUndefined(this.filterPostFix)
        ? this.filterPostFix
        : '') +
      (this.isNullOrUndefined(this.includes) ? '' : this.includes);


      url =
      url +
      (this.isNullOrUndefined(this.from) ? '' : '&from_date=' + this.from) +
        (this.isNullOrUndefined(this.to) ? '' : '&to_date=' + this.to)

    this.limitSelected = true;

        if (url.includes('achcharges')) {
          if (url.includes('search')) {
            this.loadFlag = true;
            this.getList(url);

          }
          return url;
        }


    this.getList(url);
    return url;

  }

  private searchTextFormat(): string {
    let text = '';
    if (this.searchFields.length === 0) {
      return this.searchText;
    } else {
      this.searchFields.forEach((field) => {
        text += field + ':' + this.searchText + ';';
      });
    }
    console.log(text);
    return text.substring(0, text.length - 1);
  }

  public setSorting(orderBy) {
    if (orderBy === this.orderBy) {
      this.sortBy = this.sortBy === 'asc' ? 'desc' : 'asc';
      this.stylesortBy = (this.stylesortBy === 'fa-angle-down') ? 'fa-angle-up' : 'fa-angle-down';
    } else {
      this.orderBy = orderBy;
      this.sortBy = 'asc';
      this.stylesortBy = 'fa-angle-up';
    }
    this.setUrl();
  }

  private setPagination(res) {
    if (res.meta && res.meta.pagination) {
      this.metapaginate = true;
      this.totalRecords = res.meta.pagination.total;
      if (res.meta.pagination.links.next) {
        this.disableNext = true;
      } else {
        this.disableNext = false;
      }
      if (res.meta.pagination.links.previous) {
        this.disablePrev = true;
      } else {
        this.disablePrev = false;
      }
    } else {
      this.metapaginate = false;
    }
  }

  protected abstract getListRes(res);

  protected abstract getListErr(err);

  protected localSorting(key, array = [], type = null) {
    if (this.isNullOrUndefined(array) || array.length < 2) {
      return;
    }
    if (key === this.orderBy) {
      this.sortBy = this.sortBy === 'fa-angle-down' ? 'fa-angle-up' : 'fa-angle-down';
    } else {
      this.orderBy = key;
      this.sortBy = 'fa-angle-up';
    }
    let comp: number;
    switch (this.sortBy) {
      case 'fa-angle-down':
        return array.sort((a, b) => {
          if (typeof a[key] === typeof b[key] && typeof a[key] !== 'object') {
            switch (typeof a[key]) {
              case 'string':
                comp = a[key].localeCompare(b[key]);
                break;
              case 'number':
              case 'boolean':
                comp = a[key] - b[key];
                break;
            }
          } else if (typeof a[key] === 'object' && typeof b[key] === 'object') {
            comp = 0;
          } else if (typeof a[key] === 'object') {
            comp = 1;
          } else if (typeof b[key] === 'object') {
            comp = -1;
          }
          return comp;
        });
      case 'fa-angle-up':
        return array.sort((a, b) => {
          if (typeof a[key] === typeof b[key] && typeof a[key] !== 'object') {
            switch (typeof a[key]) {
              case 'string':
                comp = b[key].localeCompare(a[key]);
                break;
              case 'number':
              case 'boolean':
                comp = b[key] - a[key];
                break;
            }
          } else if (typeof a[key] === 'object' && typeof b[key] === 'object') {
            comp = 0;
          } else if (typeof a[key] === 'object') {
            comp = 1;
          } else if (typeof b[key] === 'object') {
            comp = -1;
          }
          return comp;
        });
    }


  }

  localSearchFunc() {
    if (!this.isNullOrUndefined(this.searchText) || this.searchText !== '') {
      return this.localSearchObjCopy.filter(
        obj => Object.keys(obj).some(key => {
          const value = String(obj[key]);
          return value.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
        })
      );
    } else {
      this.orderBy = null;
      this.sortBy = 'asc';
      return JSON.parse(JSON.stringify(this.localSearchObjCopy));
    }
  }

  public numbers() {
    const current = this.metadata['current_page'],
      last = this.metadata['total_pages'],
      delta = 1,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [];
    let l = 0;

    for (let i = 1; i <= last; i++) {
      if (i <= 3 || i >= last - 2 || i >= left && i < right) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  }

 public nextpage(currentpage: number, url) {
  console.log('next page', currentpage, url);
    const meta_url = url.split('?');
    const set_metaurl = meta_url[1].split('&');
    let params = meta_url[0] + '?';
    set_metaurl.forEach(values => {

      if(!values.includes('page='))
      {
        params += values + '&';
      }
    });
    params += 'page=' + currentpage;
    this.getList(params);
  }
  public objectKeys(obj) {
    if (obj === null) { return null }
    else {
      return Object.keys(obj);
    }
  }
}
