import { BaseComponent } from "../../../abstract/base.component";
import { Directive } from "@angular/core";


@Directive()
export abstract class UpdateCardAction extends BaseComponent {
  protected _updateCardUrl: string;
  protected _getaccount: string;

  protected updateCustomerCard(body) {
    this.dataManager
      .patchRequest(this._updateCardUrl, body)
      .subscribe(
        res => this.passUpdatedResponse(res),
      error => {
        this.networkErrorHandler(error.error);
        this.passError(error.error);
      }
  );
  }

  protected abstract passUpdatedResponse(res);

  protected abstract passError(error);
  protected getAddress(url) {
    this.dataManager.getRequest(url, 0)
      .subscribe(
        res => this.getAddressResponse(res),
        err => {
          this.networkErrorHandler(err.error);
          this.getAddressError(err.error);
        }
      );
  }
  protected abstract getAddressResponse(res);
  protected abstract getAddressError(err);
}
