import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateCustomerAction} from './create-customer-action';
import {CustomerV2Model} from '../../../data/model/CustomerV2Model/customer-v2.model';
import {CardModel} from '../../../data/model/CardModel/card.model';
import {AppConstants} from '../../constants/app-constants';
import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {CustomersComponent} from '../../../customers/customers/customers.component';

import {BankAccountAchModel} from '../../../data/model/BankAchModel/bankaccountAch.model';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.css']
})
export class CreateCustomerComponent extends CreateCustomerAction implements OnInit {
  [x: string]: any;

  card_img_obj = {
    'discover': '../../../../assets/images/disc.png', 'visa': '../../../../assets/images/visa.png',
    'amex': '../../../../assets/images/amex.png', 'mastercard': '../../../../assets/images/master.png',
    'jcb': '../../../../assets/images/jcb.png', 'default': '../../../../assets/images/card-default.png'
  };
  heading: string;
  snackBar;
  buttonText: string;
  customer: CustomerV2Model;
  card: CardModel;
  serviceName = '/customers';
  errorMsgFlag: boolean;
  errorMsg = '';
  card_source = 'PHONE';
  updateCustomerDetailForm: FormGroup;
  childForm: FormGroup;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;
  type: string;
  cvcError = false;
  countries = AppConstants.countryList;
  states = AppConstants.stateList;
  arrow: string;
  option: string;
  account_Id: string;
  disable: boolean;
  public data: CustomerV2Model;
  @Input() isVisible: boolean;
  @ViewChild('addCardForm') childFormEle;
  _createCustomerUrl: string;
  customerCreatedFlag: any;
  loadFlag: boolean;
  paymentTypes: string[] = [];
  flagShowHideCard: boolean = true;
  flagShowHideAch: boolean = false;
  enableAch: boolean = false;
  ach_data: BankAccountAchModel;
  brandgetAddress: string;
  private _getSettingsUrl: string;
  showCreditCardFields: boolean;


  categories: string[] = ['Personal Checking', 'Personal Savings', 'Business Checking', 'Business Savings'];

  get currentAccountId(): any {
    const currentAccountid = JSON.parse(localStorage.getItem(AppConstants.STORAGE_KEY_CURRENT_ACCOUNT_ID));
    return currentAccountid;
  }

  constructor(private _fb: FormBuilder, public dataManager: DataManagerService, snackBar: MatSnackBar, router: Router, route: ActivatedRoute, private sub: CustomersComponent) {
    super();
    this.customer = new CustomerV2Model();
    this.snackBar = snackBar;
    this.route = route;
    this.card = new CardModel();
    this.card.authorize_card = true;
    this.isVisible = false;
    this.router = router;
    this.customer.country = this.countries[0].key;
    this.customer.state = null;
    this.account_Id = this.currentAccountId;
    this._addCardToCustomerUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CARDS;
    this._createACHDetailsUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACH_DETAILS;
    this.brandgetAddress = '/accounts';
    this._getSettingsUrl = AppConstants.BASE_URL + this.brandgetAddress + '/' + this.currentAccountId + '?include=enableAchPayments';
    this.card_source = 'PHONE';
    this.arrow = 'close';
    this.option = 'close';
    this.errorMsgFlag = false;
    this.card.state = null;
    this.card.country = null;
    this.ach_data = new BankAccountAchModel();
    // this.sub.create_permission = this.permissionCheck(
    //   PermissionConstants.PERMISSION_CREATE_CUSTOMER
    // );
    // this.create_permission = this.permissionCheck(PermissionConstants.PERMISSION_CREATE_CUSTOMER)
    this.data = JSON.parse(localStorage.getItem(this.CUSTOMER_DATA));
    if (this.data) {
      this.heading = 'Update customer details';
      this.showCreditCardFields = false;
      this.buttonText = 'Update details';
      this.customer = this.data;
      this.setCardData();
      this.customer.country = '' ? this.customer.country : '';
      // if (this.customer.phone) {
      //   const phone = this.customer.phone.match(/(\d{3})(\d{3})(\d{4})/);
      //   this.customer.phone = '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
      // }
      this._updateCustomerUrl = AppConstants.BASE_URL + this.serviceName + '/' + this.customer.customer_id;
    } else {
      this.heading = 'Create customer';
      this.buttonText = 'Create customer';
      this._createCustomerUrl = AppConstants.BASE_URL + this.serviceName;
      this.showCreditCardFields = true;
    }

  }


  ngOnInit() {
    this.paymentTypes.push('card');
    this.updateCustomerDetailForm = this._fb.group({
      name: ['', [<any>Validators.maxLength(50), <any>Validators.minLength(3)]],
      email: ['', [<any>Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/), <any>Validators.email, <any>Validators.required]],
      desc: ['', [<any>Validators.maxLength(100)]],
      to_email: ['', [<any>Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/)]],
      cc: ['', [<any>Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/)]],
      // phone: ['', [<any>Validators.pattern(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/)]],
      phone: [''],
      account_number: [''],
      first_name: [''],
      last_name: [''],
      routing_number: [''],
      account_type: ['']
    });
    this.getSettings();
  }

  moreOption() {
    this.arrow = this.option = this.arrow === 'close' ? 'open' : 'close';
  }

  setCustomerUpdate() {
    const patchBody = {};
    patchBody['address'] = {}
    const addrFields = {
      'address_1': 'address_line1',
      'address_2': 'line2',
      'city': 'city',
      'country': 'country',
      'state': 'state',
      'zip': 'zip'
    };

    for (const addr in addrFields) {
      patchBody['address'][addr] = this.card[addrFields[addr]];
    }

    patchBody['email'] = this.customer.email;
    patchBody['name'] = this.customer.name;
    patchBody['description'] = this.customer.description
      ? this.customer.description.trim()
      : this.data ? null : console.log(this.customer.description);
    patchBody['send_email_address'] = this.customer.send_email_address
      ? this.customer.send_email_address
      : this.data ? null : console.log(this.customer.send_email_address);
    patchBody['cc_email_address'] = this.customer.cc_email_address
      ? this.customer.cc_email_address
      : this.data ? null : console.log(this.customer.cc_email_address);
    patchBody['phone'] = this.customer.phone
      ? this.customer.phone.replace(/\D+/g, '')
      : this.data ? null : console.log(this.customer.phone);
    patchBody['payment_types'] = this.paymentTypes ? this.paymentTypes : null;

    this.customerCreatedFlag ? this.createCard() : this.data ? this.updateCustomer(patchBody) : this.createNewCustomer(patchBody);
  }

  setCardData() {
    this.card.country = this.customer.country;
    this.card.card_number = this.customer.card_number;
    this.card.cvc_check_code = this.customer.cvc;
    this.card.card_holder_name = this.customer.name;
    this.card.address1 = this.customer.address_1;
    this.card.address2 = this.customer.address_2;
    this.card.zip = this.customer.zip;
    this.card.state = this.customer.state;
    this.card.city = this.customer.city;
  }

  /** @function setAchDetails() used to set invoice payment options
   * @param event
   * @param data
   * */
  setAchDetails(event) {
    this.ach_data[event.target.id] = event.target.value;
  }

  /** @function setPaymentTypes() used to set invoice payment options
   * @param event
   * @param data
   * */


  setPaymentTypes(event, data: string) {
    if (event.target.checked === true) {
      if (event.target.value === 'card') {
        this.paymentTypes.push(data);
        this.updateCustomerDetailForm.addControl('cardDetails', this.childForm);
        this.flagShowHideCard = !this.flagShowHideCard;
      }
      if (event.target.value === 'ach') {
        this.paymentTypes.push(data);
        this.flagShowHideAch = !this.flagShowHideAch;
        this.setAchValidators();
      }
    } else {
      if (this.paymentTypes.length > 0) {
        for (let i = 0; i < this.paymentTypes.length; i++) {
          if (this.paymentTypes[i] === data) {
            if (data === 'ach') {
              this.resetAchValidators();
              this.flagShowHideAch = !this.flagShowHideAch;
            }
            if (data === 'card') {
              this.flagShowHideCard = !this.flagShowHideCard;
              this.updateCustomerDetailForm.removeControl('cardDetails');

            }
            this.paymentTypes.splice(i, 1);
            this.paymentTypes.length === 0 ? this.showError = true : this.showError = false;
          }
        }
      }
    }
  }

  protected passUpdateResponse(res) {
    this.goToCustomerList();
    this.loadFlag = false;
  }

  protected passUpdatingError(error) {
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.keys, 'asutosh');
    this.errorMsgFlag = true;
    let i;
    let j;
    const name = ['name', 'email', 'description', 'send_email_address',
      'cc_email_address', 'country', 'address_1', 'address_2', 'city', 'state', 'zip', 'phone'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  protected passResponse(res) {
    this.customer = res.data;
    this.showSnackBar('Customer created successfully.', 'Ok', 3000);
    if (this.isNullOrUndefined(this.card.card_number) || this.card.card_number === '') {
      this.goToCustomerList();
    } else {
      this.createCard();
    }

    if (this.paymentTypes.includes('ach')) {
      this.ach_data.customer_id = this.customer.customer_id;
      this.createACHDetails(this.ach_data);
    }
  }

  onClose() {
    this.dialogRef.close(this.customerCreatedFlag);
  }

  protected passCreateCardOnApiError(err) {
    this.errorMsg = err.message;
    this.topErrorMessage = err.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    this.errorMsgFlag = true;
    let i;
    let j;
    const name = ['card_holder_name', 'card_number', 'address_line1', 'address_line2', 'zip', 'exp_month',
      'exp_year', 'customer_id', 'card_source'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  protected passCreateCardOnApiResponse(res) {
    this._updateDefaultCardUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_CUSTOMER + '/' + this.customer.customer_id;
    const postBody = {};
    postBody['token_id'] = res.data.id;
    this.updateDefaultCard(postBody);

  }

  protected passDefaultCardResponse(res) {
    this.goToCustomerList();
  }

  protected passDefaultCardError(err) {
  }

  protected passError(error: any) {
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    this.errorMsgFlag = true;
    let i;
    let j;
    const name = ['name', 'email', 'description', 'send_email_address',
      'cc_email_address', 'country', 'address_1', 'address_2', 'city', 'state', 'zip', 'phone'];
    for (i = 0; i < this.keys.length; i++) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

  setChildForm(form) {
    this.childForm = form;
  }

  childSubmit() {
    this.childFormEle.addCardSubmitButton.nativeElement.click();
  }

  onCardData(card) {
    this.card = card;
    if (this.isNullOrUndefined(this.card.card_number) || this.card.card_number === '') {
      this.updateCustomerDetailForm.removeControl('cardDetails');
    } else {
      this.updateCustomerDetailForm.addControl('cardDetails', this.childForm);
    }
    console.log('deyan', this.card.card_number, 'updateCustomerDetailForm', this.updateCustomerDetailForm);

  }

  protected getSettings() {
    this.loadFlag = true;
    this.dataManager
      .getRequest(this._getSettingsUrl, 0)
      .subscribe(
        (response) => {
          this.loadFlag = false;
          if (response.data.enableAchPayments.data.length > 0 && response.data.enableAchPayments.data[0].value.toString() !== '0') {
            this.enableAch = true;
          }
        },
        (error) => {
          console.log(error);
          this.loadFlag = false;
          this.networkErrorHandler(error.error);
          this.passError(error.error);
        }
      );
  }

  protected setAchValidators() {
    this.updateCustomerDetailForm.controls['first_name'].setValidators(
      [<any>Validators.required, Validators.maxLength(50), <any>Validators.minLength(3)]
    );
    this.updateCustomerDetailForm.controls['last_name'].setValidators(
      [<any>Validators.required, Validators.maxLength(50), <any>Validators.minLength(3)]
    );
    this.updateCustomerDetailForm.controls['account_number'].setValidators(
      [<any>Validators.required, Validators.pattern(/^[0-9]{3,17}$/)]
    );
    this.updateCustomerDetailForm.controls['routing_number'].setValidators(
      [<any>Validators.required, Validators.pattern(/^[0-9]{9}$/)]
    );
    this.updateCustomerDetailForm.controls['account_type'].setValidators(
      [<any>Validators.required, Validators.maxLength(50), <any>Validators.minLength(3)]
    );
  }

  protected resetAchValidators() {
    this.updateCustomerDetailForm.controls['first_name'].clearValidators();
    this.updateCustomerDetailForm.controls['last_name'].clearValidators();
    this.updateCustomerDetailForm.controls['account_number'].clearValidators();
    this.updateCustomerDetailForm.controls['routing_number'].clearValidators();
    this.updateCustomerDetailForm.controls['account_type'].clearValidators();
  }

  createCard() {
    const postBody = {};
    postBody['payment_types'] = this.paymentTypes;
    postBody['card_holder_name'] = this.card.name ? this.card.name : null;
    postBody['authorize_card'] = this.card.authorize_card ? 1 : 0;
    postBody['card_number'] = this.card.card_number ? this.card.card_number.replace(/\D+/g, '') : null;
    postBody['address_line1'] = this.card.address_line1 ? this.card.address_line1.trim() : null;
    postBody['address_line2'] = this.card.address_line2 ? this.card.address_line2.trim() : null;
    postBody['city'] = this.card.city;
    postBody['zip'] = this.card.zip ? this.card.zip : null;
    postBody['ach_details'] = this.ach_test;
    if (this.card.state != null) {
      postBody['state'] = this.card.state ? this.card.state : null;
    }
    if (this.card.country != null) {
      postBody['country'] = this.card.country ? this.card.country.trim() : null;
    }
    const x = this.card.exp.split('/');
    postBody['exp_month'] = x[0].replace(/\D+/g, '');
    console.log('exp_year', x[1].replace(/\D+/g, ''));
    console.log('exp_year length', x[1].replace(/\D+/g, '').length);
    if (x[1].replace(/\D+/g, '').length > 4) {
      postBody['exp_year'] = x[1].replace(/\D+/g, '').slice(0, -1);
    } else {
      postBody['exp_year'] = x[1].replace(/\D+/g, '');
    }
    postBody['cvv'] = this.card.cvc_check_code;
    postBody['card_source'] = this.card_source;
    postBody['ach_details'] = this.ach_test;
    if (!this.cvcError) {
      this.createCardOnApi(postBody);
    }
  }

  cancelBtn() {
    if (this.data) {
      this.router.navigate(['../customer-details'], {queryParams: {'id': this.customer.customer_id}, relativeTo: this.route});
    } else {
      this.router.navigate(['../'], {relativeTo: this.route});
    }
  }

  goToCustomerList() {
    if (this.data) {
      this.router.navigate(['../customer-details'], {queryParams: {'id': this.customer.customer_id}, relativeTo: this.route});
    } else {
      this.router.navigate(['../'], {relativeTo: this.route});
    }
  }
}
