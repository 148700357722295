import {Component, DoCheck, EventEmitter, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {BaseComponent} from '../../abstract/base.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FilterConstants} from '../../shared/constants/filter-constants';
import {isPlatformBrowser, PlatformLocation} from '@angular/common';
import {FilterModel} from '../../data/model/filteModel/filter-model';
import {Subject} from 'rxjs';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent extends BaseComponent implements DoCheck, OnChanges, OnInit {
  createDialogActionResult: Subject<boolean> = new Subject<boolean>();
  back_press: boolean;
  top_headings: string;
  // public filter;
  @Input() filter: FilterModel[];

  constructor(private dialog: MatDialog, _router: Router, locationPlatform: PlatformLocation,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any, route: ActivatedRoute, private localeService: BsLocaleService) {
    super();
    this.router = _router;
    this.route = route
    locationPlatform.onPopState(() => {
      this.back_press = true;
    });
  }
  ngOnInit() {
    this.localeService.use('en-gb');
  }
  ngDoCheck() {
    // if (!this.back_press) {}
    // this.changeFilter();
    if (location.pathname === '/subscription') {
      this.routeNavigate('/subscription/subscription-list');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('called ngOnChanges');
  }
  headingSubscription() {
    if (this.matchUrl('/subscription/subscription-details') || this.matchUrl('/subscription/subscription-list') || this.matchUrl('/subscription/create-customer') || this.matchUrl('/subscription/create-subscription') || this.matchUrl('/subscription/update-subscription')  ) {
      return 'Subscriptions';
    } else if (this.matchUrl('/subscription/cycle-details') || this.matchUrl('/subscription/plan-list') || this.matchUrl('/subscription/create-plan') || this.matchUrl('/subscription/update-plan')) {
      return 'Plans';
    }
    else if (this.matchUrl('/subscription/discount-details') || this.matchUrl('/subscription/discount-list') || this.matchUrl('/subscription/create-discount')) {
      return 'Discounts';
    } else if (this.matchUrl('/subscription/invoice-details') || this.matchUrl('/subscription/invoice-list') || this.matchUrl('/subscription/resend-invoice')) {
      return 'Invoices';
    } else if (this.matchUrl('/subscription/setting')) {
      return 'Settings';
    }

  }
  openDialog() {
    if (this.matchUrl('plan-list')) {
      this.openCycleDialog();
    } else if (this.matchUrl('discount-list')) {
      this.openCouponDialog();
    } else {
      this.openCreateSubscription();
    }
  }

  openCycleDialog() {
    this.localStorage.removeItem(this.CUSTOMER_DATA);
    this.router.navigate(['create-plan'], {relativeTo: this.route})
  }

  openCouponDialog() {
    this.router.navigate(['create-discount'], {relativeTo: this.route})
  }

  openCreateSubscription() {
    let data = {customer: null, subscription: null};
    localStorage.setItem(this.CUSTOMER_DATA, JSON.stringify(data));
    this.router.navigate(['create-subscription'], {relativeTo: this.route})
  }
  clearFiltersFromLocal() {
      if (isPlatformBrowser(this.platformId)) {
      this.localStorage.removeItem(FilterConstants.SUBSCRIPTION_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.SUBSCRIPTION_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.COUPON_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.COUPON_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CYCLE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.CYCLE_FILTER_STORAGE_KEY);
      this.localStorage.removeItem(FilterConstants.DISPUTE_FILTER_COUNT_STORAGE_KEY);
      this.localStorage.removeItem(this.CUSTOMER_DATA);
    }
  }

  toNewCust() {
    this.localStorage.removeItem(this.CUSTOMER_DATA);
    this.router.navigate(['create-customer'], {relativeTo: this.route});
  }

  changeRoute(event){
    const route = event.target.value;
    this.router.navigateByUrl(route) 
  }

}
