import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class CustomerAddCardDetailsSectionAction extends BaseComponent{
  protected _getaccount: string;

  protected getAddress() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getaccount, 0)
      .subscribe(
        res => {
          this.loadFlag = false;
          this.getAddressResponse(res);
        },
        err => {
          this.loadFlag = false;
          this.networkErrorHandler(err.error);
          this.getAddressError(err.error);
        }
      );
  }
  protected abstract getAddressResponse(res);
  protected abstract getAddressError(err);
}
