import { NgModule } from '@angular/core';

import {appRoutes} from './app.routes';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: true, relativeLinkResolution: 'legacy',  onSameUrlNavigation: "reload"  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
