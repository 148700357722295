export class AccountModel {

  object?: string;
  id?: string;
  mid?: string;
  status?: string;
  account_type?: string;
  account_name?: string;
  dba_name?: string;
  default_descriptor?: string;
  country_code?: string;
  product_desc?: string;
  tax_id?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  first_name?: string;
  last_name?: string;
  dob_month?: string;
  dob_date?: string;
  dob_year?: string;
  ssn_last4?: string;
  business_name?: string;
  phone_number?: number;
  cvv_optional?: number;
  address_optional?: number;
  is_bridge_account?: number;
  created_at?: Date;
  updated_at?: Date;
}
