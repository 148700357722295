import {BaseComponent} from '../../abstract/base.component';
import { Directive } from "@angular/core";
@Directive()
export abstract class ProfileDialogAction extends BaseComponent {

  protected _getUserDataUrl: string;
  protected _changePasswordUrl: string;
  protected preferedMethodUrl: string;

  protected abstract passUserResponse(response, type);

  protected abstract passError(error);
  protected abstract changePasswordResponse(response, type);

  protected abstract changePasswordError(error);
  protected abstract PreferMethodResponse(response, type);
  protected abstract PreferMethodError(error);

  /**
   * func to crate new user
   * @param body
   */
  changePassword(body) {
    this.dataManager.postRequest(this._changePasswordUrl, body, 0)
      .subscribe(
        (response) => this.changePasswordResponse(response, 1),
        (error) => {
          this.changePasswordError(error.error);
          this.networkErrorHandler(error.error);
        }
      );
  }

  /**
   * func to edit user
   * @param body
   */
  // edit(body) {
  //   this.dataManager.putRequest(this._editUerUrl, body)
  //     .subscribe(
  //       (response) => this.passUserResponse(response, 2),
  //       (error) => {
  //         this.passError(error);
  //         this.networkErrorHandler(error.error);
  //       }
  //     );
  // }

  getUserData() {
    this.dataManager.getRequest(this._getUserDataUrl, 0)
      .subscribe(
        (response) => this.passUserResponse(response, 0),
        (error) => {
          this.passError(error);
          this.networkErrorHandler(error.error);
        }
      );
  }

  callPreferMethod(body) {
    this.dataManager.PreferMethod(this.preferedMethodUrl, body)
      .subscribe(
        (response) => this.PreferMethodResponse(response, 0),
        (error) => {
          this.PreferMethodError(error);
          this.networkErrorHandler(error.error);
        }
      );
  }
}
