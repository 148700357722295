
import { Component, OnDestroy,Inject, OnInit,PLATFORM_ID,Input } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app-constants';
import {FilterConstants} from '../../../shared/constants/filter-constants';
import {ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';

import {DatePipe} from '@angular/common';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Highlight} from '../../../shared/highlights/highlight';
import {ExportModel} from '../../../data/model/ExportModel/export-model';
import {ExportConstants} from '../../../shared/constants/export-constants';
import {debounceTime} from 'rxjs/operators';
import {Subscription, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {BaseComponent} from '../../../abstract/base.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';

import {DataManagerService} from '../../../data/services/DataManager/data-manager.service';
import {MatDialog} from '@angular/material/dialog';
import { SplitFundingAction } from './split-funding-summary.action';
import {FilterModel} from '../../../data/model/filteModel/filter-model';

@Component({
  selector: 'app-split-funding-summary',
  templateUrl: './split-funding-summary.component.html',
  styleUrls: ['./split-funding-summary.component.css']
})
export class  SplitFundingSummaryComponent extends SplitFundingAction
 implements OnInit, OnDestroy {
  displayedColumns = ['id', 'status', 'created_at', 'payees', 'request_date', 'request_status', 'update_at', 'action_items', 'campaign', 'assigned_to', 'description', 'actions'];
  listCallFlag: boolean;
  exportData: Object;
  n = 0;
  filter:FilterModel[] = FilterConstants.splitFundingFilter;
  filter_storage_key: string;
  count_storage_key: string;
  private sortDirection: string;
  today: Date = new Date();
  public searchHits: Subject<null>;
  _router: Router;
  public searchText: string;
  showMeta = false;
  chargeList: ResponseWrapper<any>;
  metadata:any;
  _getSplitFundingList: string;
 exampleData = [
    {name: 'Baboon Mohon',
    accountNumber: '123456789',
    routingNumber: '123456789',
    amount: '1000',
    amountPercent: '50',
    type: 'Bank Account',
 status: 'Inactive',
 creationDate: '12/12/2019',
 payees: '2',
 requestDate: '12/12/2019',
 requestStatus: 'Active',
 updateDate: '12/12/2019',
 actionItems: '200',
  campaign: 'Campaign 1',
  id: '1234567890',
  assignedTo: 'Miranda',
  description: ' Chicago,IL  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
},{
  name: 'Raboon Mohon',
      accountNumber: '123456789',
      routingNumber: '123456789',
      amount: '5000',
      amountPercent: '50',
      type: 'Bank Account',
   status: 'Inactive',
   creationDate: '12/12/2019',
   payees: '2',
   requestDate: '12/12/2019',
   requestStatus: 'Active',
   updateDate: '12/12/2019',
   actionItems: '200',
    campaign: 'Campaign 3',
    id: '1234567894',
    assignedTo: 'Miranda',
    description: ' Chicago,IL  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
  },{
    name: 'Jhon Mohon',
    accountNumber: '123456789',
    routingNumber: '123456789',
    amount: '1000',
    amountPercent: '50',
    type: 'Bank Account',
    status: 'Active',
    creationDate: '12/12/2019',
    payees: '2',
    requestDate: '12/12/2019',
    requestStatus: 'Active',
    updateDate: '12/12/2019',
    actionItems: 'one',
    assignedTo: 'Humbolt',
     campaign: 'Campaign 2',
     id: '1234567892',
     description: ' New York , Manhattan  ====Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.',
  }
  ]
   

  constructor(private _route: ActivatedRoute, router: Router,  private datePipe: DatePipe,    private dialog: MatDialog, dataManager: DataManagerService, @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any, snackBar: MatSnackBar) {
    super();
    this.errorMsg = null;
    this.searchHits = new Subject();
    this._router = router;
    this.router = router;
    this.dataManager = dataManager;
    this.chargeList = new ResponseWrapper<any>();
    this.loadFlag =false;
    this.redirect_link = '/merchant-bridge/split-funding-summary';
    this._getSplitFundingList = AppConstants.BASE_URL + 'nova-api/merchant-split-funding-summaries';
    this.filter_storage_key = FilterConstants.SPLIT_FUNDING_FILTER_STORAGE_KEY;
    this.count_storage_key = FilterConstants.SPLIT_FUNDING_FILTER_COUNT_STORAGE_KEY;

   }

  ngOnInit(): void {
    this.searchHits.pipe(debounceTime(600), ).subscribe((res) => {
      if (this.searchText) {
        this.getSplitFundingList();
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.localStorage.getItem(FilterConstants.SPLIT_FUNDING_FILTER_STORAGE_KEY)
        && !this.localStorage.getItem(FilterConstants.SPLIT_FUNDING_FILTER_COUNT_STORAGE_KEY)) {
    //rempve the below line once the api is ready

        // this.getSplitFundingList();
      } else  {
    //rempve the below line once the api is ready

        // this.getSplitFundingList();
      }
    }


  }
  // clearFiltersFromLocal() have it implemented when you change the page
  clearFiltersFromLocal() {
    if (isPlatformBrowser(this.platformId)) {
    this.localStorage.removeItem(FilterConstants.SUBSCRIPTION_FILTER_COUNT_STORAGE_KEY);
    this.localStorage.removeItem(FilterConstants.SPLIT_FUNDING_FILTER_STORAGE_KEY);
    this.localStorage.removeItem(FilterConstants.SPLIT_FUNDING_FILTER_COUNT_STORAGE_KEY);
  
    this.localStorage.removeItem(this.CUSTOMER_DATA);
  }
}

  navigateToDetails(id: any) {
    this.router.navigate(['/merchant-bridge/split-funding-summary',id]);
  }

  createNewPayee() {
   console.log('createNewPayee');
  }

  protected passSplitFundingList(res) {
    this.loadFlag = false;
    //rempve the below line once the api is ready
    // this.chargeList = res;
    this.metadata = res.meta.pagination;
    if (res.data.length === 0) {
      this.errorMsg = ErrorConstants.paymentErrorMsg[0];
    } else {
      this.errorMsg = null;
    }
  }

  highlight(value) {
    return Highlight.highlight(value, this.searchText);
  }

  protected passError(err) {
    this.loadFlag = false;
    this.errorMsg = err;
  }
  
}
