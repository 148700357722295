<div class="container-fluid cus_block">
  <app-details-loader
    [Height]="'100%'"
    [LoaderMarginTop]="'19%'"
    *ngIf="loadFlag || errorMsg"
    [errorMsg]="errorMsg"
  ></app-details-loader>
  <div  class="row">
    <div  class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
      <h3  class="headings">Disputes</h3>
    </div>
    <!---->
  </div>
  <div class="dispute-bdy">
  <div class="row py-3">
    <div class="subheadings col-12">Disputes report details</div>
  </div>
  <div class="b_shadow">
  <div class="heading-card col-md-12 mat-card" *ngIf="!loadFlag && !errorMsg">

    <div  class="row">

      <div  class="col-md-6 col-5 left-heading pl-sm-5 pl-2">

        <span>Amount:</span>
        <span
        class="d_amount">{{dispute.primary_case?.data.case_amount ? ('-' + ((dispute.primary_case?.data.case_amount) | currency:'USD':'symbol': '1.2-2')) : '--'}}</span>
      </div>
      <div  class="col-md-6 col-7 right-heading text-right pr-sm-5 pr-2">
        <span>Status: </span>
        <span class="d_amount">
          <i aria-hidden="true" class="fa fa-circle"
              [class.c_red]="dispute.primary_case?.data.status === 'Currently resolved in Bank\'s favor'"
              [class.c_green]="dispute.primary_case?.data.status === 'Currently resolved in Merchant\'s favor'"
              [class.c_orange]="dispute.primary_case?.data.status === 'Pending'">
          </i>
          <span class="sts_text">
            {{dispute.primary_case?.data.status ? dispute.primary_case?.data.status : '--'}}
          </span>
        </span>
      </div>
    </div>
  </div>
  <mat-card class="" *ngIf="!loadFlag && !errorMsg">
    <div class="row">
      <div class="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-12 pl-sm-4 pl-0 pt-m-13">
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6 pl-m-13 pr-m-0 text-left-m">
            <span class="table-data">Chargeback Date:</span>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m pl-m-1 ">
            <span  class="table-data">{{dispute.primary_case?.data.report_date ? (dispute.primary_case?.data.report_date | date : 'MM/dd/yyyy') : '--'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6 pl-m-14 pr-m-0 text-left-m">
          <label class="table-data">Transaction date:</label>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m">
          <span class="table-data">{{dispute.primary_case?.data.transaction_date ? (dispute.primary_case?.data.transaction_date | date : 'MM/dd/yyyy') : '--'}}</span>
          </div>
          </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6  pr-m-0 text-left-m">
            <label class="table-data">Reason:</label>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m pr-m-0">
            <span
          class="table-data">{{dispute.primary_case?.data.reason_desc ? dispute.primary_case?.data.reason_desc : '--'}}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-7 col-md-12 col-sm-12 col-12 pl-sm-4 pl-0">
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 text-left-m">
          <label class="table-data ">View response:</label>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-12">
            <ng-container *ngIf="dispute.primary_case?.data?.evidence?.data.length !== 0">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-6 p-0 mr-lg-3 mt-lg-2 pb-m-11"
                     *ngFor="let file of dispute.primary_case?.data?.evidence?.data; let i = index" [class.doc-m]="i%2 != 0"
                >
              <a class="table-data nowrap view_doc mr-1" (click)="viewEvidence(file)">View document {{i + 1}}</a>
                  <ng-container *ngIf="file.text !== ''">
                    <a class="table-data nowrap view_doc mr-1" (click)="showComment(file.text, i)">
                      <i class="fa fa-comment plus-icon mr-sm-1 mr-0"></i>
                    </a>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <span class="table-data blank-m" *ngIf="dispute.primary_case?.data?.evidence?.data.length === 0">--</span>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m">
          <label class="table-data">Case type:</label>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-6 pl-sm-0 pl-3 case-m">
            <span
          class="table-data">{{dispute.primary_case?.data.case_type ? ( dispute.primary_case?.data.case_type  | camalizeStr : true) : '--'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m">
          <label class="table-data">Responded:</label>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-6 pl-sm-0 pl-3 text-left-m">
          <span class="table-data" *ngIf="dispute.primary_case?.data?.evidence?.data.length === 0">Not responded</span>
          <span class="table-data"
                *ngIf="dispute.primary_case?.data?.evidence?.data.length > 0">{{dispute.primary_case?.data?.evidence?.data[0].updated_at ? ((dispute.primary_case?.data?.evidence?.data[0].updated_at * 1000) | date : 'MM/dd/yyyy') : '--'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-6 text-left-m">
          <label class="table-data">Card number:</label>
          </div>
            <div class="col-xl-8 col-lg-12 col-md-6 col-sm-12 col-6 pl-lg-3 pl-xl-0 p-md-0 text-left-m card-m pl-m-16">
          <img class="card-img" alt="card image"
               [src]="card_img_obj[dispute.primary_case?.data?.card_type] ? card_img_obj[dispute.primary_case?.data?.card_type] : '../../../../assets/images/card-default.png'"/><span
          class="table-data">{{dispute.primary_case?.data.case_number ? dispute.primary_case?.data.card_number : '--'}}</span>
            </div>
        </div>
      </div>


    </div>
    <hr>
    <div class="row">
      <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 text-left-m">
        <label class="table-data">View chargeback:</label>
      </div>

      <div class="col-xl-10 col-lg-8 col-md-12 col-sm-9 col-6 pb-m-11">
        <ng-container *ngIf="dispute.primary_case?.data?.file?.data.length !== 0">
          <a class="table-data nowrap view_doc "
             *ngFor="let file of dispute.primary_case?.data?.file?.data; let i = index"
             (click)="viewFile(file)">View document {{i + 1}} &nbsp;</a>
        </ng-container>

        <span class="table-data" *ngIf="dispute.primary_case?.data?.file?.data.length === 0">--</span>
      </div>
    </div>
  </mat-card>
  </div>
  <div class="b_shadow mt-4">
  <div class="" *ngIf="!loadFlag && !errorMsg">

    <div class="row heading-card m-0">
      <div class="col-12 response pl-4">{{response}}</div>

    </div>
    <form class="row" #evidenceForm="ngForm">
      <div class="col-md-12 py-sm-4 py-3 pl-m-7">
        <ng-container *ngIf="topErrorMessage">
          <div class="row err" *ngFor="let obj of keys1 " >
            <div class="col-md-12 mt-3" *ngFor="let value of obj " >
              <p class="col-md-12 error m-0" id="err" >{{value}}</p>
            </div>
          </div>
        </ng-container>
        <div
          class="row form-group mt-sm-4 mt-1 pl-sm-4 pl-0"
          *ngFor="let file of fileList; let i = index"
        >
          <div class="col-xl-3 col-lg-3 col-md-12 col-12 pr-0">
            <label class="row-form-label d_upload_label">Upload supporting document:</label>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-12">
            <button class="btn btn-outline-info btn-sm file_up mr-sm-2 mr-0 w-m-full" (click)="openFilePicker(i)">
              <svg  viewBox="0 0 384 512" class="svg"><path fill="#FFF" d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg>
              {{
              fileList[i] === undefined ? "Choose your file" : fileList[i].name
              }}
            </button>
            <input
              style="display: none;"
              type="file"
              multiple="false"
              [id]="i"
              (change)="addFile($event.target.files, i)"
            />
            <button class="re_icon" *ngIf="fileList[i] !== undefined"   (click)="removeFiles(i)"
            > <i class="fa fa-times-circle" aria-hidden="true"></i>
            </button>
            <button
              class="btn add_file w-m-full mt-m-13"
              (click)="addfilePicker()"
              [disabled]="fileList.length > 2"
            >
              <svg  viewBox="0 0 384 512" class="svg"><path fill="#FFF" d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg>
              Select an other file
            </button>
          </div>

        </div>
        <div class="row form-group m-0">
          <div class="col-md-2 pr-0">
            <label for="disputeText" class="row-form-label d_upload_label">Comment:</label>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-12">
            <textarea style="width: 100%; height: 100%"
                      id="disputeText"
                      name="disputeText"
                      #disputeText="ngModel"
                      [(ngModel)]="uploadModel.text"
            ></textarea>
          </div>
        </div>

        <div class="row form-group mt-2 pl-sm-4 pl-0">
          <div class="col-md-9 mt-m-3">
            <button class="btn btn-primary dis_submit w-m-full" type="submit" [disabled]="!evidenceForm.form.valid" (click)="onSubmit()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  </div>

  <div class="b_shadow" *ngIf="dispute.related_cases?.data.length > 0">
    <div class="row heading-card m-0" *ngIf="!loadFlag && !errorMsg">
      <div class="left-heading col-12">Related cases:</div>
    </div>
    <mat-card class="pt-0" *ngIf="!loadFlag && !errorMsg">
      <div class="row">
        <mat-vertical-stepper class="col-md-12" #stepper>
          <ng-container *ngFor="let case of dispute.related_cases?.data; let i = index ">
            <mat-step [completed]="case.id === primaryCaseId" class="col-md-12 " *ngIf="i<3">
              <ng-template matStepLabel
              ><span class="table-data">Charge back: </span
              ><span class="table-data">{{(case.report_date | date : 'MM/dd/yyyy')}}</span></ng-template
              >
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-4 col-md-6 no-padding"
                    >Case no:</label
                    ><label class="table-data col-xl-8 col-lg-8 col-md-6" [class.primaryCase]="case.id === primaryCaseId"
                            (click)="switchCases(case)">{{case.case_number ? case.case_number : '--'}}</label>
                  </div>

                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-5 col-lg-4 col-md-6 no-padding"
                    >Transaction date: </label
                    ><span
                    class="table-data col-xl-7 col-lg-8 col-md-6">{{ case.transaction_date ? (case.transaction_date | date : 'MM/dd/yyyy') : '--' }}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-4 col-md-6 no-padding"
                    >Responded: </label
                    ><span class="table-data col-xl-8 col-lg-8 col-md-6" *ngIf="case.evidence?.data.length === 0">Not responded</span>
                    <span class="table-data col-xl-8 col-lg-8 col-md-6"
                          *ngIf="case.evidence?.data.length > 0">{{case.evidence?.data[0].updated_at ? ((case.evidence?.data[0].updated_at * 1000) | date : 'MM/dd/yyyy') : '--'}}</span>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-4 col-md-6 no-padding"
                    >Case type: </label
                    ><span class="table-data col-xl-8 col-lg-8 col-md-6">{{case.case_type ? ( case.case_type   | camalizeStr : true) : '--'}}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-5 col-lg-4 col-md-6 no-padding"
                    >Status: </label
                    >
                    <span class="table-data col-xl-7 col-lg-8 col-md-6"><i  aria-hidden="true" class="fa fa-circle" [class.c_red]="case.status === 'Lost'" [class.c_green]="case.status === 'Won'" [class.c_orange]="case.status === 'Pending'"></i>{{case.status ? case.status : '--'}}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-4 col-md-6 no-padding"
                    >Card number: </label>
                    <div class="col-xl-8 col-lg-8 col-md-6 no-padding nowrap">
                      <img class="card-img" [src]="card_img_obj[case.card_type] ? card_img_obj[case.card_type] : '../../../../assets/images/card-default.png'"/>
                      <span class="table-data">{{case.card_number ? case.card_number : '--'}}</span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-4 col-md-6 no-padding">Reason:</label>
                    <span class="table-data col-xl-8 col-lg-8 col-md-6">
                      {{case.reason_desc ? case.reason_desc : '--' }}
                    </span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <label class="table-data col-xl-5 col-lg-4 col-md-6 no-padding"
                    >Amount: </label
                    ><span
                    class="table-data col-xl-7 col-lg-8 col-md-6">{{case.case_amount ? ('-' + ((case.case_amount) | currency:'USD':'symbol': '1.2-2')) : '--'}}</span>
                  </div>
                </div>

              </div>

              <div class="row step_border">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="row">
                    <label class="table-data col-xl-5 col-lg-12 col-md-12 no-padding"
                    >View chargeback: </label
                    >
                    <div class="col-xl-7 col-lg-12 col-md-12 pl-0">
                      <ng-container *ngIf="case.file?.data.length !== 0">
                        <div class="row">
                        <a class="table-data nowrap view_doc p-2-11" *ngFor="let file of case.file?.data; let i = index"
                           (click)="viewFile(file)">View document {{i + 1}} &nbsp;</a>
                        </div>
                      </ng-container>
                      <span class="table-data" *ngIf="case.file?.data.length === 0">--</span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="row">
                    <label class="table-data col-xl-4 col-lg-12 col-md-12 no-padding">
                      View response:
                    </label>
                    <div class="col-xl-8 col-lg-12 col-md-12 no-padding">
                      <ng-container *ngIf="case.evidence?.data.length !== 0">
                        <div class="row ng-star-inserted">
                          <div class="col-xl-12 col-lg-12 col-md-12 p-0 mb-2 mr-5" *ngFor="let file of case.evidence?.data; let i = index">
                            <a class="table-data nowrap view_doc mr-1"  (click)="viewEvidence(file)" [name]="i">View document {{i + 1}}</a>
                            <ng-container *ngIf="file.text !== ''">
                              <a class="table-data nowrap view_doc mr-1" (click)="showComment(file.text, i)">
                                <i class="fa fa-comment plus-icon mr-sm-1 mr-0"></i>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <span class="table-data" *ngIf="case.evidence?.data.length === 0">--</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-step>
          </ng-container>
          <ng-template matStepperIcon="edit">
            <mat-icon>local_parking</mat-icon>
          </ng-template>
        </mat-vertical-stepper>
      </div>
    </mat-card>
  </div>
</div>
</div>



