<div class="container p-0">
<h3 class="text-center heading2">2-Step Verification</h3>
<div class="row">
<p class="text-center sub-heading px-0 py-4 col-12 mb-m-0">Security Code Required</p>
</div>
<ng-container *ngIf="data.value == 'email'">
  <div class="row">
    <p class="text-center col-12 mt-3 info mb-m-0">Your one-time security code was sent by mail to </p>
  </div>
  <div class="row">
    <p class="col-12 text-center info mt-4">{{user_email.replaceAll('_', '&#9679;')}}</p>
    <p class="col-12 text-center info">This code will expire in few minutes. If you have not received an e-mail within one minute, please click Send Email
      to have another one sent.</p>
  </div>
  <div class="form-group row pl-5 pr-5 mb-4">
    <span class="error_sign col-2" id="security code">!</span>
    <div class="col-sm-12">
      <div class="row">
        <label class="col-md-12 pl-0 m-0">Enter security code</label>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
      <input type="text" class="form-control security_input" name="security_code" (keydown)="onKeydown($event)"  (keypress)="numericOnly($event)" maxlength="6" required
               [(ngModel)]="security_code"  id="security_code"
             placeholder="Type code here"  >
    </div>
    <div class="col-sm-12 mt-2 text-center">
      <button type="submit" [disabled]="loadFlag || security_code == null" class="submit_invite form-control" (click)="verify()" >Enter<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
    </div>
    <div class="col-sm-12 mt-2 text-center mt-4">
      <p class="mb-0 send-code">Send a new code to {{user_email.replaceAll('_', '&#9679;')}}</p>
      </div>
  </div>
  <div class="form-group row justify-content-center" >
    <div class="col-sm-4 mt-2 mb-3 text-center p-0">
      <button type="submit" [disabled]="loadFlag2" class="bt_forgot form-control max-w-210" (click)="resendOtp('email')" >Send Email<i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse ml-2"></i></button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="data.value == 'sms'">
  <div class="row">
    <p class="text-center col-12 mt-3 info">Your one-time security code was sent by <b>text message</b> to</p>
    <p class="col-12 text-center info mt-4">{{user_mobile.replaceAll('_', '&#9679;')}}</p>
  </div>
  <div class="row">
    <p class="col-12 text-center info">This code will expire in few minutes</p>
  </div>
  <div class="form-group row pl-5 pr-5 mb-4">
    <span class="error_sign col-2" id="security code">!</span>
    <div class="col-sm-12">
      <div class="row">
        <label class="col-md-12 pl-0 m-0">Enter security code</label>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
      <input type="text" class="form-control security_input" name="security_code" (keydown)="onKeydown($event)" (keypress)="numericOnly($event)"  maxlength="6" required
               [(ngModel)]="security_code"  id="security_code"
             placeholder="Type code here"  >
    </div>
    <div class="col-sm-12 mt-2 text-center">
      <button type="submit" [disabled]="loadFlag || security_code == null" class="submit_invite form-control" (click)="verify()" >Enter<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
    </div>
    <div class="col-sm-12 mt-2 text-center mt-4">
      <p class="mb-0 send-code">Send a new code to {{user_mobile.replaceAll('_', '&#9679;')}}</p>
      </div>
  </div>
  <div class="form-group row justify-content-center" >
    <div class="col-sm-4 mt-2 text-center">
      <button type="submit" [disabled]="loadFlag2" class="bt_forgot form-control" (click)="resendOtp('sms')" >Text me<i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse ml-2"></i></button>
    </div>
  </div>
  <div class="row">
    <p class="text-center col-12 mt-3 message-info">
      Standard wireless carrier message and data rates may apply.
    </p>
  </div>
</ng-container>
</div>
