import {BaseComponent} from '../../../abstract/base.component';
import { Directive } from "@angular/core";

@Directive()
export abstract class CreateConnectAccountAction extends BaseComponent {
  protected _merchantActivationUrl: string;
  protected _merchantGetUrl: string;
  protected _merchantUpdateUrl: string;
  activateMerchant(postBody) {
    this.dataManager.authorizedPostCall(this._merchantActivationUrl, postBody)
      .subscribe(
        response => this.passResponse(response),
        error => {this.networkErrorHandler(error.error);
          this.passError(error);
        }
      );
  }
  protected abstract passResponse(res);
  protected abstract passError(error);


}
