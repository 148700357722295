<div class="button_col" [style.float]="buttonFloat" [style.width] = 'buttonWidth'>
  <!--TODO add *ngIf="permissionDenied" for permissions check-->
  <button  (click)="loaderButtonClick()"  class="update-btn" [disabled] = "disable || loadFlag || loadFlag2" >
    <ng-container *ngIf="loadFlag">
      <tr class="loader">
        <td colspan="7">
          <div class="loader-container">
            <img class="table-loader" src="../../../../assets/images/loading.svg">
          </div>
        </td>
      </tr>
    </ng-container>
    <span>{{buttonText}}</span>
    <i *ngIf="loadFlag || loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
</div>
