import { BaseComponent } from "../../../abstract/base.component";
import { Directive } from "@angular/core";

@Directive()
export abstract class SplitFundingAction extends BaseComponent {
  protected _getSplitFundingList: string;

  getSplitFundingList() {
    this.loadFlag = true;
    this.dataManager.getRequest(this._getSplitFundingList, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passSplitFundingList(res);
      },
      (err) => {
        this.loadFlag = false;
        this.passError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
  protected abstract passSplitFundingList(response);

  protected abstract passError(error);
}
