<div class="main-div">
<div class="container pt-m-0">
  <div class="row">
    <div class="col-md-12 dialog-head">
      Profile
    </div>

  </div>

</div>

<div class="container">

  <ng-container *ngIf="topErrorMessage && show">
    <div class="row err" *ngFor="let obj of keys1 " >
      <div class="col-12 mb-1 mt-1" *ngFor="let value of obj " >
        <p class="col-12 error" id="err" >{{value}}</p>
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="!topErrorMessage">
    <div class="col-12 error">
      {{errorMsg}}
    </div>
  </div>

  <mat-dialog-content class="dialog-content cust_desc">

    <form #createCustomer="ngForm" class="profile_form" autocomplete="off">
      <div class="form-group row">
        <label for="email" class="col-sm-4 col-12 col-form-label">Email:</label>
        <div class="col-sm-8 col-12" *ngIf="userEmail">
          <input class="form-control" type="email" name="email" id="email" #email="ngModel" [(ngModel)]="userEmail" disabled value="">
        </div>
      </div>
      <div class="form-group row">
        <label for="name" class="col-sm-4 col-12 col-form-label">Name:</label>
        <div class="col-sm-8 col-12" *ngIf="userName">
          <input id="name" type="text" name="name" #name="ngModel" class="form-control" [(ngModel)]="userName" disabled value="">
        </div>
        <!--<div class="col-9">-->
          <!--<input class="form-control" type="text" value="" name="name" id="name">-->
        <!--</div>-->
      </div>
      <div class="form-group row">
        <div class="col-4 d-sm-flex d-none"></div>
        <div class="col-lg-8 col-xl-6 col-md-12 col-7 col-sm-12 mt-m-11">
          <input class="form-control" (click)="showPasswordPanel(createCustomer)" type="button" value="Change password" name="change_password" id="change_password">
        </div>
      </div>
      <ng-container *ngIf="show">
        <div class="form-group row">
          <label for="oldpassword" class="col-4 col-form-label">Current password:</label>
          <div class="col-8">
            <input class="form-control" type="password" value="" name="oldpassword" id="oldpassword" #oldpassword="ngModel" [(ngModel)]="current_password" autocomplete="new-password">
          </div>
        </div>
        <div class="form-group row">
          <label for="newpassword" class="col-4 col-form-label">New password:</label>
          <div class="col-8">
            <input class="form-control" type="password" value="" name="newpassword" id="newpassword" #newpassword="ngModel" [(ngModel)]="new_password" autocomplete="new-password">
          </div>
        </div>
        <div class="form-group row">
          <label for="confirmpassword" class="col-4 col-form-label">Confirm password:</label>
          <div class="col-8">
            <input class="form-control" type="password" value="" name="confirmpassword" id="confirmpassword" #confirmpassword="ngModel" [(ngModel)]="new_password_confirmation" autocomplete="new-password">
          </div>
        </div>
        <!--<label *ngIf="(confirmpassword.touched || confirmpassword.dirty) && (newUser.password !== newUser.confirm_password)"-->
        <!--class="error">Passwords don't match. Try again.</label>-->
      </ng-container>
      <ng-container>
        <div class="row form-group">
          <div class="col-sm-12 col-12 col-md-12 col-lg-12 pl-3 step-methods">Select the method for login</div>
        </div>
        <div class="form-group row" [formGroup]="verifyForm">
          <label class="col-sm-4 col-12 col-form-label">2-Step Verification methods</label>
<!--          <div class="col-sm-4 col-12 mt-m-4">-->
<!--            <label class="check-box-container mb-2">-->
<!--              <input  [(ngModel)]="is_setting_optional" type="radio" checked [value]=0  name="isPhone" id="isPhone" formControlName="isPhone" (click)="changeVerification('isPhone')"/>-->
<!--              <span class="checkmark"></span>-->
<!--              Phone<i class="required-icon">*</i>-->
<!--            </label>-->
<!--            <div class="row m-0">-->
<!--              <span class="input-group-addon last-d col-2 text-center pl-1 pr-2 pt-2">+1</span>-->
<!--              <input type="tel" class="form-control col-10" id="sms" name="sms" formControlName='sms' placeholder="(000) 000-0000" type="tel" appPhoneMask maxlength="14" autocomplete="off"-->
<!--              value="" [class.invalid]="submitted && verifyForm.controls['sms'].invalid" appPhoneMask maxlength="14" (blur)="updateSelectedPhone($event)" (keyup)="checkVerified()" [(ngModel)]="sms"/>-->
<!--              <div class="col-12 p-0" *ngIf="submitted && f.sms.errors">-->
<!--              <span class="validation-err-msg" *ngIf="f.sms.errors.required">Phone is required.</span>-->
<!--              <span class="validation-err-msg"*ngIf="!f.sms.errors.required && verifyForm.controls['sms'].invalid">Invalid phone.</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="button mt-3 pl-m-0">-->
<!--              <button id="verifySms" (click)="verifyUser('sms')" class="update-btn">Verify number</button>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-sm-8 col-12 mt-m-4">
            <label class="mb-2">
<!--              <input  [(ngModel)]="is_setting_optional" type="radio" [value]=1 name="isEmail" id="isEmail" formControlName="isEmail" (click)="changeVerification('isEmail')"/>-->
<!--              <span class="checkmark"></span>-->
              Email
            </label>
            <div class="row m-0" [innerHTML]="this.user_data['email']">
            </div>
            <div class="button mt-3 pl-m-0">
              <button id="verifyEmail" (click)="verifyUser()" class="update-btn">Verify email</button>
            </div>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-4 d-sm-flex d-none"></div>-->
<!--          <div class="col-sm-8 col-12 pl-m-29">-->
<!--            <p class="text-left mb-0 message-info">-->
<!--              Standard wireless carrier message and data rates may apply-->
<!--            </p>-->
<!--            <p class="text-left mt-1 setup-info">-->
<!--              *Please load both methods for backup-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->

      </ng-container>
      <div class="row d_footer">

        <div class="col-12 pt-2 mb-4 void_btn">
          <button mat-button [mat-dialog-close]="true" class="update_cancel">Cancel</button>
          <app-loading-button [buttonText]="'Save'"  [loadFlag]="loadFlag" [disable]="(createCustomer.invalid || !show)" [buttonWidth]="'39%'" (buttonClick)="save()"></app-loading-button>
          <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

        </div>
      </div>

    </form>
  </mat-dialog-content>


</div>
</div>
