import { BaseListComponent } from "../../abstract/base-list.component";
import { Directive } from "@angular/core";


@Directive()
export abstract class GetEventListingAction extends BaseListComponent {
  protected _geteventexcel: string;
  protected abstract passeventExcelResponse(res);
  protected abstract passeventExcelError(err);

  geteventexcel() {
    this.loadFlag = true;
    this.dataManager.getRequestPdf(this._geteventexcel, 0).subscribe(
      (res) => {
        this.loadFlag = false;

        this.passeventExcelResponse((new Blob([res], {type: 'application/vnd.ms-excel'})));
      },
      (err) => {
        this.loadFlag = false;
        this.passeventExcelError(err);
      },
      // () => this.passstatementPdfComplete()
    );
  }
}
