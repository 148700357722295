<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <h3 class="headings">Settings</h3>
  </div>
</div>
<div class="sett-bdy">
  <div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
    <div class="b_shadow">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 heading-card left-heading">
            <span class="pl-4">Enable Swipe/EMV tokenization</span>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
      <mat-slide-toggle [(ngModel)]="settings.tokenize" [disabled]="loadFlag" [color]="'primary'"
      (ngModelChange)="setTokenize()">Tokenize
      </mat-slide-toggle>
    </div>
  </div>
  </div>
</div>
<app-kount></app-kount>

<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
    <div class="b_shadow">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
        <span class="pl-sm-4 pl--0">Enable Metadata columns to Transaction list</span>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
        <mat-slide-toggle [(ngModel)]="settings.display_transactional_metadata" [disabled]="loadFlag" [color]="'primary'"
                          (ngModelChange)="setMetadata()">Add Metadata
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
      <div class="b_shadow">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
          <span class="pl-sm-4 pl--0">Disable Transaction Notifications Sent To Customer (Email)</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
          <mat-slide-toggle [(ngModel)]="settings.disable_charge_notification_email" [disabled]="loadFlag" [color]="'primary'"
                            (ngModelChange)="setChargeEmailNotification()"> On
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
      <div class="b_shadow">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-m-5 heading-card left-heading">
          <span class="pl-sm-4 pl--0">Disable Transaction Notifications Sent To Customer (SMS)</span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
          <mat-slide-toggle [(ngModel)]="settings.disable_charge_notification_sms" [disabled]="loadFlag" [color]="'primary'"
                            (ngModelChange)="setChargeSmsNotification()"> On
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
