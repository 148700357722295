<div class="container-fluid overlay">
  <div class="row image">
    <img class="left-image" src="../../../assets/images/signin-left-image.png">
    <img class="right-image" src="../../../assets/images/signin-right-image.png">
  </div>
  <div class="row">

    <div class="col-md-12 signLogo" >
      <img class="logo-align"  src="{{dataManager.loginlogo}}" />
    </div>
  </div>

  <div class="row">
    <div class="container-card ipad_iphone card-m">


      <!-- <ng-container *ngIf="topErrorMessage">
        <div class="row err" *ngFor="let obj of keys1 " >
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let value of obj " >
            <p class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error" id="err" >{{value}}</p>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="!topErrorMessage">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 error mb-1"> {{errorMsg}}</div>
      </div> -->
      <br>
        <h3 class="text-center heading2">2-Step Verification</h3>
        <div class="row">
        <p class="text-center sub-heading px-0 py-4 col-12 ">Security Code Required</p>
        </div>
        <ng-container *ngIf="show && !isShowEmailField && !isShowPhoneField">
          <div class="row">
            <p class="text-center col-12 mt-3 info">For security purposes we want to make sure it's you</p>
            <p class="text-center col-12 info">Your one-time security code will be send to: </p>
          </div>
          <div class="row">
            <p class="col-12 text-center info mt-4">{{user_email.replaceAll('_', '&#9679;')}}</p>
            <p class="col-12 text-center info">Send security code</p>
          </div>
          <div class="form-group row justify-content-center" >
            <div class="col-sm-5 mt-2 text-center">
              <button type="submit" [disabled]="loadFlag" class="bt_forgot form-control" (click)="sendInvite('email', true)" >Send Email<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isShowEmailField && !show && !isShowPhoneField">
          <div class="row">
            <p class="text-center col-12 mt-3 info">Your one-time security code was sent by mail to </p>
          </div>
          <div class="row">
            <p class="col-12 text-center info mt-4">{{user_email.replaceAll('_', '&#9679;')}}</p>
            <p class="col-12 text-center info">This code will expire in few minutes. If you have not received an e-mail within one minute, please click Send Email
            to have another one sent.</p>
          </div>
          <div class="form-group row pl-5 pr-5 mb-4">
            <span class="error_sign col-2" id="security code">!</span>
            <div class="col-sm-12">
              <div class="row">
                <label class="col-md-12 pl-0 m-0">Enter security code</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
              <input type="tel" class="form-control security_input" name="security_code" (keydown)="onKeydown($event)" (keypress)="numericOnly($event)"  required
                       [(ngModel)]="security_code"  id="security_code" maxlength="6"
                     placeholder="Type code here"  >
            </div>
            <div class="col-sm-12 mt-2 text-center p-0">
              <button type="submit" [disabled]="loadFlag2 || security_code == ''" class="submit_invite form-control" (click)="verifyOtp()" >Enter<i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
            <div class="col-sm-12 mt-2 text-center mt-4">
              <p class="mb-0 send-code">Send a new code to {{user_email.replaceAll('_', '&#9679;')}}</p>
              </div>
          </div>
          <div class="form-group row justify-content-center" >
            <div class="col-sm-4 mt-2 text-center p-0">
              <button type="submit" [disabled]="loadFlag" class="bt_forgot form-control" (click)="sendInvite('email', true)" >Send Email<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!show && !isShowEmailField && !isShowPhoneField">
          <div class="row">
            <p class="text-center col-12 mt-3 info">For security purposes we want to make sure it's you</p>
            <p class="text-center col-12 info">Your one-time security code will be send to: </p>
          </div>
          <div class="row">
            <p class="col-12 text-center info mt-4">{{user_mobile.replaceAll('_', '&#9679;')}}</p>
            <p class="col-12 text-center info">Send security code by</p>
          </div>
          <div class="form-group row justify-content-center" >
            <div class="col-sm-4 mt-2 text-center">
              <button type="submit" [disabled]="loadFlag" class="bt_forgot form-control" (click)="sendInvite('sms')" >Text me<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
          </div>
          <div class="row">
            <p class="text-center col-12 mt-3 mb-0 message-info">
              Standard wireless carrier message and data rates may apply.
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="!show && !isShowEmailField && isShowPhoneField">
          <div class="row">
            <p class="text-center col-12 mt-3 info">Your one-time security code was sent by <b>text message</b> to</p>
            <p class="col-12 text-center info mt-4">{{user_mobile.replaceAll('_', '&#9679;')}}</p>
          </div>
          <div class="row">
            <p class="col-12 text-center info">This code will expire in few minutes</p>
          </div>
          <div class="form-group row pl-5 pr-5 mb-4">
            <span class="error_sign col-2" id="security code">!</span>
            <div class="col-sm-12">
              <div class="row">
                <label class="col-md-12 pl-0 m-0">Enter security code</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 p-0">
              <input type="tel" class="form-control security_input" name="security_code" (keydown)="onKeydown($event)" (keypress)="numericOnly($event)" required
                       [(ngModel)]="security_code"  id="security_code"  maxlength="6"
                     placeholder="Type code here"  >
            </div>
            <div class="col-sm-12 mt-2 text-center p-0">
              <button type="submit" [disabled]="loadFlag2 || security_code == ''" class="submit_invite form-control" (click)="verifyOtp()" >Enter<i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
            <div class="col-sm-12 mt-2 text-center mt-4">
              <p class="mb-0 send-code">Send a new code to {{user_mobile.replaceAll('_', '&#9679;')}}</p>
              </div>
          </div>
          <div class="form-group row justify-content-center" >
            <div class="col-sm-4 mt-2 text-center">
              <button type="submit" [disabled]="loadFlag" class="bt_forgot form-control" (click)="sendInvite('sms')" >Text me<i *ngIf="loadFlag" class="fa fa-spinner fa-pulse ml-2"></i></button>
            </div>
          </div>
<!--          <div class="row">-->
<!--            <p class="text-center col-12 mt-3 mb-0 message-info">-->
<!--              Standard wireless carrier message and data rates may apply.-->
<!--            </p>-->
<!--          </div>-->
        </ng-container>


<!--      <div class="col-12 mt-3 mb-3 text-center back_button">-->
<!--        <span (click)="changeVerification()" class="b_back pointer">Swith verification methods</span>-->
<!--      </div>-->
    </div>
  </div>
</div>
<div class="dot" style="display: none;" id="dot"></div>

