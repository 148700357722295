import {Component, Inject, OnInit} from '@angular/core';
import {RegisterAction} from './register.action';
import {Router} from '@angular/router';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {ApiErrorModel} from '../../data/model/ApiErrorModel/api-error.model';
import {AppConstants} from '../../shared/constants/app-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent extends RegisterAction implements OnInit {
  email: string;
  password: string;
  name: string;
  keys: String[];
  keys1: String[];
  topErrorMessage: string;

  constructor(router: Router, dataManager: DataManagerService, snackBar: MatSnackBar,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject('LOCALSTORAGE') private localStorage: any) {
    super();
    this.snackBar = snackBar;
    this._registerUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_REGISTER;
    this.router = router;
    this.dataManager = dataManager;
  }

  ngOnInit() {
  }

  register() {
    const postBody = {};
    postBody['email'] = this.email;
    postBody['name'] = this.name;
    postBody['password'] = this.password;
    this.registerUser(postBody);
  }

  routeToSigin() {
    this.routeNavigate('/signin');
  }

  protected passResponse(res: any) {
      console.log('registered user data', res.data);
    console.log(res.data.response_content);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCESS_TOKEN, res.data.response_content.token_type
        + ' ' + res.data.response_content.access_token);
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_MENU, JSON.stringify(res.menu));
      this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS, JSON.stringify(res.permissions));
      // this.localStorage.setItem(AppConstants.STORAGE_KEY_ACCOUNT_ID, res.account_id);
      // this.dataManager.account_id = res.account_id;
      // this.localStorage.setItem(AppConstants.STORAGE_KEY_USER_ROLES, res.roles);
      // this.dataManager.setPermissions(res.roles);
    }
    this.dataManager.accessToken = res.data.response_content.token_type + ' ' + res.data.response_content.access_token;
    this.dataManager.menuArray = res.menu ? res.menu : [];
    this.dataManager.createHeaderWithUersAccessToken();
    this.showSnackBar('Registered Successfully', 'OK', 30000);
    this.routeNavigate('/signin');
  }

  protected passError(error: ApiErrorModel) {
    this.showSnackBar(error.message, 'Registration Error', 30000);
    console.log(error);
    this.loadFlag = false;
    this.errorMsg = error.message;
    this.topErrorMessage = error.errors;
    this.keys = Object.keys(this.topErrorMessage);
    this.keys1 = Object.values(this.topErrorMessage);
    console.log(this.errorMsg);
    let i;
    let j;
    let name = ['email', 'name', 'password'];
    for (i = 0; i < this.keys.length; i++ ) {
      for (j = 0; j < name.length; j++) {
        if (this.keys[i].match(name[j])) {
          document.getElementById(name[j]).style.borderColor = '#a94442';
          document.getElementById(name[j] + 1).style.display = 'block';
        }
      }
    }
  }

}
