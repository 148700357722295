import sanitizeHtml from 'sanitize-html';

export class Highlight {

  static highlight(value, searched) {
    const diffMatches = [];

    if (value) {
      value = String(value);
      value = sanitizeHtml(value, {
        allowedTags: [],
        allowedAttributes: []
      });
      if (searched) {
        const escapes = new RegExp('[.+*?^$(){}=!<>|:\\-#]');
        const pattern = new RegExp(
          searched.trim()
            .split('')
            .map(x => x.replace(escapes, `\\${x}`))
            .join('\\W?'),
          'gi'
        );

        (value.match(pattern) || []).forEach((x) => {
          if (!diffMatches.some(y => x === y)) {
            diffMatches.push(x);

            const p = new RegExp(
              x.split('').map(z => z.replace(escapes, `\\${z}`)).join(''),
              'g'
            );

            value = value.replace(p, `<mark class="search-mark">${x}</mark>`);
          }
        });
      }
      return value;
    } else {
      return '--';
    }
  }
}
