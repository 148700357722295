import {BaseListComponent} from '../../../abstract/base-list.component';
import {ErrorConstants} from '../../../shared/constants/error-constants';
import { Directive } from "@angular/core";
import {Pagination, ResponseWrapper} from '../../../data/model/APiWrapper/response-wrapper';

type Campaign = {
  campaignName: string;
  campaignDescription: string;
  campaignNotes?: string;
};

@Directive()
export abstract class NewCampaignCreationAction extends BaseListComponent {
    protected getListUrl: string;
    protected urlwithLimit: string;
    protected campaignApiPostUrl: string;
    payees:ResponseWrapper<any>;

    protected campaignApiCall(url) {
      this.loadFlag = true;
        this.dataManager.getRequest( url, 0)
          .subscribe(
            (response) => {
              this.loadFlag = false;
                this.passCampaignResponse(response);
                if (response.data.length === 0) {
                    this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
                  } else {
                    this.errorMsg = null;
                  }
            },
            error => {
              this.loadFlag = false;
              this.networkErrorHandler(error);
              this.passCampaignError(error.error);
            }
          );
      }
    
      protected campaignApiPost(body: Campaign) {
        this.loadFlag2 = true;
        let url = this.campaignApiPostUrl;
          this.dataManager.postRequest( url, body, 0)
            .subscribe(
              (response) => {
                this.loadFlag2 = false;
                  this.passCampaignPostResponse(response);
                  if (response.data.length === 0) {
                      this.errorMsg = ErrorConstants.campaignsErrorMsg[0];
                    } else {
                      this.errorMsg = null;
                    }
              },
              error => {
                this.loadFlag2 = false;
                this.passCampaignError(error.error);
                this.networkErrorHandler(error);
              }
            );
        }

    protected deleteCampaignApi(url){
      this.loadFlag = true;
          this.dataManager.deleteRequest(url)
              .subscribe((res)=>{
                  this.loadFlag = false;
                  this.passDeleteResponse(res);
              },
              error=>{
                  this.loadFlag = false;
                  this.passDeleteError(error);
                  this.networkErrorHandler(error);
              })
          
      }
      protected getListPayee(url){
        this.loadFlag = true;
        this.dataManager.getRequest(url,0)
          .subscribe(res=>{
            this.loadFlag = false;
            
            this.passPayeesResponse(res);
          })
      }
      protected abstract passCampaignResponse(campaign);
      protected abstract passCampaignError(error);
      protected abstract passCampaignPostResponse(response);
      protected abstract passDeleteError(error);
      protected abstract passDeleteResponse(response);
      protected abstract passPayeesResponse(response);
      protected  getListRes(res){};

      protected  getListErr(err){};

}
